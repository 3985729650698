import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 700;
const distance = '20rem';
export default class CoolTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    node.style.position = 'relative';
    const { reverse } = this.props;

    const value = reverse ? [`${distance}`, 0] : [`-${distance}`, 0];

    anime({
      targets: node,
      translateX: {
        value: value
      },
      scale: [0.95, 1],
      opacity: [0, 1],
      duration: duration,
      easing: 'easeInOutCirc'
    });
  };

  setInitialHeight(node) {
    if (!node.parentNode) return;
    node.parentNode.style.minHeight = 'auto';
  }

  setHeight(node) {
    if (!node.parentNode) return;
    node.parentNode.style.minHeight = `${node.offsetHeight}px`;
  }

  onExit = (node, index) => {
    if (!node) return null;
    const { reverse } = this.props;

    this.setHeight(node);
    node.style.position = 'absolute';
    const value = reverse ? [0, `-${distance}`] : [0, `${distance}`];

    anime({
      targets: node,
      scale: [1, 0.95],
      translateX: {
        value: value
      },
      opacity: {
        value: [1, 0],
        duration: duration / 2
      },
      duration: duration,
      easing: 'easeInOutCirc',
      complete: () => this.setInitialHeight(node)
    });
  };

  onExiting = (node, index) => {};
  onExited = node => {};

  render() {
    const { children, transitionkey, unmountOnExit = true, in: inProp } = this.props;

    return (
      <Transition
        timeout={{ enter: duration, exit: duration }}
        key={transitionkey}
        in={inProp}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        onExited={this.onExited}
        mountOnEnter={true}
        unmountOnExit={unmountOnExit}>
        {children}
      </Transition>
    );
  }
}
CoolTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element,
  reverse: PropTypes.bool
};
