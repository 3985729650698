import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Placeholder } from 'components/common/Loader';
import LazyLoad, { forceCheck } from 'react-lazyload';

import { LazyLoadImageContainer, FadeInImage } from './index';

export default class LazyLoadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      placeHolderImageHasLoaded: false,
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  imageHasLoaded = () => {
    const { onLoad } = this.props;

    this.setState({ loaded: true });
    if (onLoad) onLoad();
    forceCheck();
  };

  placeHolderImageHasLoaded = () => {
    this.setState({ placeHolderImageHasLoaded: true });
  };

  getLoader() {
    const { placeholder, asBackground, ...rest } = this.props;
    if (placeholder) {
      return (
        <FadeInImage
          {...rest}
          onImageLoad={this.placeHolderImageHasLoaded}
          absolute
          src={placeholder}
          asBackground={asBackground}
          blur={true}
          fadein={true}
          key={'loader_image'}
        />
      );
    }
    return <Loader key={'loader_animation'} absolute loaded={this.state.loaded} />;
  }

  render() {
    const { loaded, mounted, placeHolderImageHasLoaded } = this.state;
    if (!mounted) return null;
    const {
      src,
      alt,
      offset,
      asBackground,
      title,
      width,
      maxwidth,
      maxheight,
      minheight,
      center,
      nolazy,
      placeholder,
      fit,
      containerStyles = {},
      style: propStyles = {},
      ...rest
    } = this.props;

    let style = {
      opacity: loaded ? 1 : 0,
      // height: '100%',
      ...propStyles
    };
    const image = (
      <FadeInImage
        key={'fade_in_image'}
        style={style}
        onImageLoad={this.imageHasLoaded}
        fit={fit}
        src={src}
        title={title}
        alt={alt}
        width={width}
        asBackground={asBackground}
        center={center}
        maxwidth={maxwidth}
        maxheight={maxheight}
        minheight={minheight}
        {...rest}
      />
    );

    return (
      <LazyLoadImageContainer
        className="LazyLoadImageContainer"
        asBackground={asBackground}
        width={width}
        maxwidth={maxwidth}
        maxheight={maxheight}
        style={containerStyles}
        centermargin={center}>
        <LazyLoad
          key={`${src}_placeholder`}
          placeholder={<Placeholder hidden={placeHolderImageHasLoaded} />}
          overflow={false}
          once
          height="auto"
          offset={offset ? offset + 500 : 500}>
          {this.getLoader()}
        </LazyLoad>
        {!nolazy && (
          <LazyLoad key={src} overflow={false} height="auto" offset={offset || 150}>
            {image}
          </LazyLoad>
        )}
        {nolazy && [this.getLoader(), image]}
      </LazyLoadImageContainer>
    );
  }
}

LazyLoadImage.propTypes = {
  //low res image url
  asBackground: PropTypes.bool,
  title: PropTypes.string,
  alt: PropTypes.string,
  offset: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
  placeholder: PropTypes.string,
  src: PropTypes.string,
  nolazy: PropTypes.bool
};
