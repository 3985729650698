import styled from 'styled-components/macro';
import { targetBrowser } from 'style/media';
export const ShowMoreContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1.667rem;
  margin-bottom: 1.667rem;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};

  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;
