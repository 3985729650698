import React from 'react';
// import RevokeCookies from '../../features/CookieBar/components/RevokeCookies';
import styled from 'styled-components/macro';
import { fonts } from 'style/fonts';
import { Title3 } from 'components/text';
import CookieBlock from './CookieBlock';

const CookieBlockContainerWrapper = styled.div`
  font-family: ${fonts.LLBrown.REGULAR};
  max-width: calc(46.25rem - 1.6666rem);
  margin: 0 auto;
  width: 100%;
`;

const CookieNessecaryTitle = styled(Title3)`
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  margin: 22px 0 1.667rem 0;
  padding: 1rem 0.8333rem 0 0.8333rem;
  line-height: 38px;
  font-size: 32px;
`;

export default class CookieContainerBlock extends React.Component {
  render() {
    const { cookieBlockContainerArea } = this.props;
    const necessaryList = cookieBlockContainerArea.filter(i => {
      if (i.category.filter(x => x.selected && x.value === 'NECESSARY').length) return i;
      else return null;
    });
    const performanceList = cookieBlockContainerArea.filter(i => {
      if (i.category.filter(x => x.selected && x.value === 'PERFORMANCE').length) return i;
      else return null;
    });
    const statisticsAndMarketingList = cookieBlockContainerArea.filter(i => {
      if (i.category.filter(x => x.selected && x.value === 'MARKETING_AND_STATISTICS').length)
        return i;
      else return null;
    });
    return (
      <CookieBlockContainerWrapper>
        <CookieNessecaryTitle>Nödvändiga</CookieNessecaryTitle>
        {necessaryList.map((i, index) => {
          return <CookieBlock {...i} key={index} />;
        })}
        <CookieNessecaryTitle>Prestanda</CookieNessecaryTitle>
        {performanceList.map((i, index) => {
          return <CookieBlock {...i} key={index} />;
        })}
        <CookieNessecaryTitle>Statistik & Marknadsföring</CookieNessecaryTitle>
        {statisticsAndMarketingList.map((i, index) => {
          return <CookieBlock {...i} key={index} />;
        })}
      </CookieBlockContainerWrapper>
    );
  }
}
