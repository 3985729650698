import styled from 'styled-components/macro';

export const StandardPageContainer = styled.div.attrs(props => ({
  paddingright: props.relatedaside ? '7.083rem' : 0,
  marginleft: props.relatedaside ? '7.083rem' : 0
}))`
  // padding-right: ${props => props.paddingright};
  // margin-left: ${props => props.marginleft};

`;

export const StandardPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
