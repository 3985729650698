import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from './index';
import debounce from 'lodash.debounce';
const DEFAULT_ANIMATION_LENGTH = 200;
const FADE = { IN: 1, OUT: 0 };

class FadeText extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.any]),
    animationLength: PropTypes.number
  };

  constructor(props) {
    super(props);

    const currentChild = props.children;

    this.state = {
      animationLength: props.animationLength || DEFAULT_ANIMATION_LENGTH,
      currentChild,
      /* Direction determines the opacity; if fading out, we set the opacity to 0,
       * if fading in, we set the opacity to 1 */
      direction: currentChild ? FADE.IN : FADE.OUT
    };

    //this.fade = debounce(this.fade, props.animationLength || DEFAULT_ANIMATION_LENGTH);
    this.queueNextChild = debounce(
      this.queueNextChild,
      props.animationLength || DEFAULT_ANIMATION_LENGTH
    );
    this.nextChild = null;
    this.timeoutId = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.changingValue) {
      if (this.props.changingValue !== prevProps.changingValue) {
        this.nextChild = this.props.children;
        this.nextValue = this.props.changingValue;
        this.fade();
      }
      return null;
    }
    if (prevProps.children !== this.props.children) {
      this.nextChild = this.props.children;
      this.fade();
    }
  }

  fade() {
    /*  Fade immediately if there is no current child */
    if (!this.state.currentChild) {
      this.queueNextChild();
      return;
    }
    /* If timeoutId is not set, then no fade is currently in progress, so let's
     * start the fade! */
    if (!this.timeoutId) {
      this.timeoutId = setTimeout(
        this.queueNextChild,
        this.props.animationLength || DEFAULT_ANIMATION_LENGTH
      );
      this.setState(({ direction }) => ({ direction: +!direction }));
      return;
    }

    if (this.nextChild === this.state.currentChild) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      this.setState(({ direction }) => ({ direction: +!direction }));
      return;
    }
  }

  queueNextChild = () => {
    const currentChild = this.nextChild;
    const currentValue = this.nextValue;
    this.nextChild = null;
    this.nextValue = null;
    this.timeoutId = null;

    if (this.props.changingValue) {
      this.setState(({ direction }) => ({
        ...(currentValue ? { direction: +!direction } : {}),
        currentChild
      }));
      return null;
    }

    this.setState(({ direction }) => ({
      ...(currentChild ? { direction: +!direction } : {}),
      currentChild
    }));
  };

  render() {
    const { direction, animationLength, currentChild } = this.state;
    const { faded, color } = this.props;
    const blur = direction === 1 ? 0 : 1;
    const props = {
      animationlength: animationLength,
      blur: blur,
      direction: faded ? 0.2 : direction
    };
    return <Text {...props} color={color} children={currentChild} />;
  }
}

export default FadeText;
