import React from 'react';
import PropTypes from 'prop-types';
import { FlexContainer } from 'components/flex';
import { Message } from 'components/message';
import { ListBlockItem } from './components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from '../../utils';
import { Block, CenteredBlockInner, BlockHeader } from '../components';
import BlockArea from 'features/BlockArea';
import { fetchListBlockItems } from '../../api/SearchApi';
import { Loader } from 'components/common/Loader';
import { SlideInLeft } from 'components/Animations';
import { PrimaryButton, ButtonText } from 'components/button';

class ListBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadMore: 10,
      items: [],
      totalItems: 0,
      error: {
        hasError: false,
        message: ''
      },
      first: true
    };
  }

  componentDidMount() {
    const { filter: countyFilter } = this.props;
    const { selected: selectedCounty } = countyFilter;
    if (isEmpty(selectedCounty)) return false;
    this.fetchListBlockItems();
  }

  fetchListBlockItems = async () => {
    const { id, filter, filterarea } = this.props;
    if (id === undefined) return;
    const { selected } = filter;
    const { selectedFilters } = filterarea;
    const result = await fetchListBlockItems(id, selected.id, selectedFilters);
    if (isEmpty(result.items)) {
      this.setState({
        error: { hasError: true, message: 'Inga träffar' },
        items: [],
        first: false,
        isLoading: false,
        totalItems: 0
      });
    } else {
      this.setState({
        items: result.items,
        error: {
          hasError: false,
          message: ''
        },
        isLoading: false,
        first: false,
        totalItems: result.total
      });
    }
  };

  receivedNewCountyFilter = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { first } = this.state;
    const { filter } = this.props;
    if (isEmpty(filter.selected)) return false;

    if (filter.selected.id !== prevFilter.selected.id || first) return true;
    return false;
  };

  receivedNewCategoryfilter = prevProps => {
    const { filterarea: prevFilterarea } = prevProps;
    const { filterarea } = this.props;
    if (prevFilterarea.selectedFilters !== filterarea.selectedFilters) return true;
  };

  loadMore = () => {
    this.setState({ loadMore: this.state.loadMore + 10 });
  };
  componentDidUpdate(prevProps) {
    if (this.receivedNewCountyFilter(prevProps) || this.receivedNewCategoryfilter(prevProps)) {
      this.setState({ first: false, isLoading: true });

      this.fetchListBlockItems();
    }
  }

  render() {
    const { filterContentArea, heading, hideDate } = this.props;
    const { error, isLoading, items, loadMore, totalItems } = this.state;
    const showLoadmoreButton = !isLoading && totalItems > loadMore;
    return (
      <Block>
        <CenteredBlockInner>
          <BlockArea nopadding propertyName="filterContentArea" contentarea={filterContentArea} />
          {heading && <BlockHeader centerTextSmall>{heading}</BlockHeader>}
          <Loader loaded={!isLoading} />

          <FlexContainer alignItems="flex-start" justifycontent="center" column>
            <Message relative visible={error.hasError} key="error">{`${error.message}`}</Message>
            {!error.hasError &&
              !isLoading &&
              items.slice(0, loadMore).map((comp, index) => {
                return (
                  <SlideInLeft delay={`${index / 10}s`} key={index}>
                    <ListBlockItem hideDate={hideDate} {...comp} />
                  </SlideInLeft>
                );
              })}
          </FlexContainer>

          {showLoadmoreButton && (
            <FlexContainer justifycontent="center">
              <PrimaryButton onClick={this.loadMore}>
                <ButtonText>Ladda mer</ButtonText>
              </PrimaryButton>
            </FlexContainer>
          )}
        </CenteredBlockInner>
      </Block>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected
  },
  filterarea: {
    selectedFilters: state.filterarea.selectedFilters
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListBlock);

ListBlock.propTypes = {
  //Block contentLinkID
  id: PropTypes.number,
  header: PropTypes.string
};
