import styled from 'styled-components/macro';
import { OnlyDesktop } from 'components/containers';
/**
 * NavigationLinksContainer (div)
 */
export const NavigationLinkContainer = styled(OnlyDesktop).attrs(props => ({
  margin: props.right ? '0 0 0 auto' : '0 auto 0 0rem'
}))`
  margin: ${props => props.margin};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
