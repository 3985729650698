import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import fonts from 'style/fonts/fonts';
import { colors as Colors } from 'constants/colors';
import { UnderlineAfter } from 'components/common';

const UnderlineText = UnderlineAfter.withComponent('span');
export const Text = styled(UnderlineText)`
  font-family: ${fonts.LLBrown.BOLD};
  font-size: 1em;
  margin: 0.5rem 0 0.5rem 0;
  color: ${Colors.BLACK};
  text-align: center;
  position: relative;
`;

Text.propTypes = {
  children: PropTypes.any
};
