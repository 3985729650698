import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

/**
 * CardCarouselBlockItemContainer (div)
 */

//Borde skicka med in en property som är bakgrundsbilden, den sätts här via props => etc
export const CardCarouselBlockItemContainer = styled.div.attrs(props => ({
  background: props.image ? props.image : Colors.TRANSPARENT,
  minheight: props.nominheight ? 'auto' : 'auto',
  padding: props.nopadding ? 0 : ' 2rem 1rem 2rem 1rem'
}))`
  padding: ${props => props.padding};
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7) 70%),
    url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
  height: 500px;
  background-color: rgb(247, 248, 249);
  border: 10px solid white;
  margin: 1rem;
  ${media.desktop`
    height: 405px;
  `};
  ${media.phone`
    height: 315px;
  `};
`;

export const CardCarouselBlockItemContent = styled.div.attrs(props => ({}))`
  padding-top: 35%;
  color: white;
  width: 100%;
  ${media.carousel4K`
    padding-top: 50%;
 `};
  ${media.phone`
    padding-top: 30%;
 `};
`;

export const CustomCarouselLink = styled.a.attrs(props => ({
  href: props.href,
  target: props.targetBlank
}))`
  text-decoration: none;

  ${props =>
    props.href != null
      ? `:hover {
        cursor: pointer;
    }`
      : `:hover {cursor:default}`}
`;
