import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { fontSize } from 'style/fonts';
import NavigationLink from './NavigationLink';
import { targetBrowser } from 'style/media';

const activeClassName = 'nav-item-active';
export const ChildNavigationLink = styled(NavigationLink).attrs({
  activeClassName
})`
  font-size: ${fontSize(9, 13)};
  color: ${Colors.SLIGHTLY_GARY};
  line-height: 0.65em;
  &:after {
    content: '';
    margin: 0.2rem auto auto auto;
    display: block;
    height: 1px;
    width: 0px;
    background: transparent;
    transition: width 0.3s ease, background-color 0.5s ease;

    //fix for firefox resize bug
    ${targetBrowser.FIREFOX4ANDHIGHER`
      width: 100%;
    `}
  }
  &:hover {
    &:after {
      width: 100%;
      background: ${props => props.underlinecolor};
    }
  }
`;
