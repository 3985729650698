import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'components/common/EpiOnPageEdit';
import { LazyLoadImage } from 'features/LazyImage';
import { colors as Colors } from 'constants/colors';
import { Title2, Paragraph } from 'components/text';
import { UnderlineContainer } from 'components/UnderlineContainer';
import { uberSmall, reFormat } from '../../../utils';
import { DoubleContainerItem, DoubleContainer, Push } from '../../components';
import { ImageWrapper } from './ImageWrapper';
import { SecondaryButtonLink, ButtonText } from 'components/button';
import { Date } from 'components/common/Date';
import { UnderlineContainerText } from '../components';
import { Date as FormattedDate } from 'features/Date';
import { OnlySmall, OnlyDesktop } from 'components/containers/';
import { InternalLinkWrapper } from 'components/link';
import fonts from 'style/fonts';

import Truncate from 'react-truncate';
class NewsItem extends React.Component {
  render() {
    const {
      imageUrl,
      ingress,
      date,
      name,
      linkUrl,
      imageleft,
      topNews,
      pageTypeHeader,
      isSmall
    } = this.props;
    if (isSmall === undefined) return null;
    const imageWidth = topNews ? 20 : 14;
    const imageHeight = imageWidth * 0.625;
    const maxImageWidth = 30;
    const maxImageHeight = maxImageWidth * 0.625;
    const textItemWidth = topNews ? null : '100%';

    return (
      <DoubleContainer extra maxwidth="40.33rem" margin="0.5rem 0" padding="1rem">
        <DoubleContainerItem
          order={imageleft ? 1 : 2}
          width={isSmall ? '100%' : '50%'}
          minwidth={`${imageWidth}rem`}>
          <Push amount="0" left={!imageleft} on>
            <ImageWrapper>
              <OnlyDesktop className="OnlyDesktop">
                <LazyLoadImage
                  width={`${imageWidth}rem`}
                  height={`${imageHeight}rem`}
                  placeholder={uberSmall(imageUrl)}
                  src={reFormat(imageUrl)}
                />
              </OnlyDesktop>
            </ImageWrapper>
            <OnlySmall>
              <ImageWrapper>
                <LazyLoadImage
                  maxwidth={`${maxImageWidth}rem`}
                  maxheight={`${maxImageHeight}rem`}
                  width={`100vw`}
                  height={`calc(100vw * 0.625)`}
                  placeholder={uberSmall(imageUrl)}
                  src={imageUrl}
                  center
                  centermargin
                />
              </ImageWrapper>
            </OnlySmall>
          </Push>
        </DoubleContainerItem>
        <DoubleContainerItem
          order={imageleft ? 2 : 1}
          width={textItemWidth}
          smallmargintop="1.5rem">
          <Push amount="0" on={!imageleft}>
            <OnlyDesktop>
              <UnderlineContainer
                margin="1rem 0 0.5357rem 0"
                position="bottom"
                color={Colors.ORANGE}>
                <OPE propertyName="date">
                  <UnderlineContainerText center color={Colors.BLACK}>
                    <FormattedDate wrapper={<Date />} date={date} format="D MMMM YYYY" />
                  </UnderlineContainerText>
                </OPE>
              </UnderlineContainer>
            </OnlyDesktop>
          </Push>
          <Push amount="0" left={imageleft} on>
            <InternalLinkWrapper to={linkUrl}>
              <Title2
                margin="0 0 0.5714rem 0"
                largelineheight
                medium
                min28onmobile
                color={Colors.TOTALLY_BLACK}
                centerTextSmall>
                {name}
              </Title2>
            </InternalLinkWrapper>
            <OnlySmall>
              <FormattedDate
                title={pageTypeHeader}
                font={fonts.APERCU.MONO}
                date={date}
                format="D MMMM YYYY"
              />
            </OnlySmall>
          </Push>
          <Push amount="0" left={imageleft} on>
            <Paragraph
              width="100%"
              min14onmobile
              smallest
              opacity="0.6"
              lineheight="1.57em"
              centerTextSmall>
              <Truncate lines={topNews || isSmall ? 5 : 3}>{ingress}</Truncate>
            </Paragraph>
            <OnlyDesktop>
              <OPE propertyName="buttonLink">
                <SecondaryButtonLink
                  nomargin="true"
                  backgroundcolor="transparent"
                  bordercolor={Colors.ORANGE}
                  borderradius="5px"
                  to={linkUrl}>
                  <OPE propertyName="buttonText">
                    <ButtonText
                      color={Colors.TOTALLY_BLACK}
                      weight="bold"
                      forcedFontSize={[12, 12]}
                      forcedLineHeight="2.5em">
                      {'Läs vidare'}
                    </ButtonText>
                  </OPE>
                </SecondaryButtonLink>
              </OPE>
            </OnlyDesktop>
          </Push>
        </DoubleContainerItem>
      </DoubleContainer>
    );
  }
}

export default NewsItem;

NewsItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  ingress: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  imageleft: PropTypes.bool
};
