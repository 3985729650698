import * as TYPES from './filterAreaTypes';
import { LocalStorage } from '../../utils';

const Storage = new LocalStorage();

const cookie = Storage.getJSON(TYPES.FILTER_AREA_COOKIE);
const initialState = {
  filters: [],
  filterIsLoading: false,
  selectedFilters: [],
  error: {
    hasError: false,
    message: ''
  }
};

if (!cookie) {
  Storage.set(TYPES.FILTER_AREA_COOKIE, initialState);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FILTER_AREA_REQUESTED:
      return {
        ...state,
        filterIsLoading: true,
        error: {
          hasError: false,
          message: ''
        }
      };
    case TYPES.FILTER_AREA_REQUEST_DONE:
      return {
        ...state,
        filterIsLoading: false,
        filters: action.filters
      };
    case TYPES.FILTER_AREA_ADD_SELECTED:
      return {
        ...state,
        selectedFilters: action.filter
      };
    case TYPES.FILTER_AREA_REMOVE_FROM_SELECTED: {
      return {
        ...state,
        selectedFilters: action.filter
      };
    }
    case TYPES.FILTER_AREA_ERROR:
      return {
        ...state,
        hasError: true,
        message: 'Error when getting filter categories'
      };
    case TYPES.FILTER_AREA_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
