import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login, logout, validate, setLoginFormVisibility } from '../accountActions';
import { FixedCenter } from 'components/containers';
import { colors as Colors } from 'constants/colors';
import { shadow } from 'constants/shadows';
import { getSearchParams } from '../../../utils';
import { PopIn } from 'features/Transitions';
import { ChangeViewTransition } from '../components';
import { HamburgareIcon } from 'components/button';
import { Logo } from 'components/common/Logo';
import { Paragraph } from 'components/text';
import LoginForm from './LoginForm';
import { SubmitButton } from 'components/button';
import RequestLoginForm from './RequestLoginForm';
import { Link } from 'components/link';

import { ButtonText } from 'components/button';
import { FlexContainer } from 'components/flex';
import { Loader } from 'components/common/Loader';
const LoginFormWrapper = styled.div`
  max-width: 100vw;
  width: calc(30rem - 4.2vw);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  align-items: flex-start;
  position: relative;
  background: white;
  padding: 2rem;
  box-shadow: ${shadow(3)};
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoginView: true };
    this.loginForm = {};
  }

  componentDidMount() {
    const { setLoginFormVisibility } = this.props;
    const showLogin = getSearchParams(window.location.href, 'showlogin');
    if (!showLogin) return;
    setLoginFormVisibility(true);
  }

  handleChangeView = isLoginView => {
    this.setState({ isLoginView: isLoginView });
  };

  render() {
    if (!this.props) return null;
    const { account, setLoginFormVisibility } = this.props;
    const { isLoginView } = this.state;
    const { showLoginForm, isAuthenticated, message, isLoading, isAdmin } = account;

    return (
      <FixedCenter>
        <PopIn transitionkey="loginform" in={showLoginForm}>
          <LoginFormWrapper ref={loginForm => (this.loginForm = loginForm)}>
            <HamburgareIcon
              absolute
              expanded={true}
              onClick={() => setLoginFormVisibility(false)}
            />
            <Logo center />
            <ChangeViewTransition in={isLoginView && !isAuthenticated} transitionkey="1">
              <LoginForm onChangeView={() => this.handleChangeView(false)} />
            </ChangeViewTransition>
            <ChangeViewTransition in={!isLoginView && !isAuthenticated} transitionkey="2">
              <RequestLoginForm onChangeView={() => this.handleChangeView(true)} />
            </ChangeViewTransition>
            <ChangeViewTransition in={isLoginView && isAuthenticated} transitionkey="3">
              <FlexContainer column>
                <Paragraph nomargin>
                  {message}{' '}
                  {isAdmin && (
                    <Link external href="/episerver">
                      Gå till redigerarläget
                    </Link>
                  )}
                </Paragraph>
                <SubmitButton
                  active={true}
                  onClick={() => this.props.logout()}
                  bordercolor={Colors.ORANGE}>
                  <Loader
                    width="2.5rem"
                    height="3rem"
                    size={'contain'}
                    white
                    absolute
                    loaded={!isLoading}
                  />
                  <ButtonText hide={isLoading} color={Colors.BLACK} large>
                    Logga ut
                  </ButtonText>
                </SubmitButton>
              </FlexContainer>
            </ChangeViewTransition>
          </LoginFormWrapper>
        </PopIn>
      </FixedCenter>
    );
  }
}

const mapStateToProps = state => ({
  account: {
    user: state.account.user,
    isAuthenticated: state.account.isAuthenticated,
    showLoginForm: state.account.showLoginForm,
    message: state.account.message,
    isLoading: state.account.isLoading,
    isAdmin: state.account.isAdmin
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      logout,
      validate,
      setLoginFormVisibility
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

Login.propTypes = {
  login: PropTypes.func
};
