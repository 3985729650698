import React from 'react';

import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { Paragraph } from '../../../../components/text/Text';
import ArrowImage from 'images/arrow.min.svg';

const CategoryPillArrow = styled.span`
  background: no-repeat url(${ArrowImage}) center;
  transform: translateX(0px);
  transition: transform 0.3s;
  height: 100%;
  width: 40px;
  min-width: 40px;
`;

const CategoryPillContainer = styled.button`
  padding: 1rem;
  background: ${Colors.GRAY};
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;

    ${CategoryPillArrow} {
      transform: translateX(10px);
    }
  }
`;

const CategoryPill = ({ text = '', onClick = () => {} }) => {
  return (
    <CategoryPillContainer title={`Sök på ${text}`} onClick={onClick}>
      <Paragraph weight="bold" fontSize="20px" margin="0">
        {text}
      </Paragraph>
      <CategoryPillArrow />
    </CategoryPillContainer>
  );
};

export default CategoryPill;
