import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setFilter, addSelectedFilter, removeFromSelected } from './filterAreaActions';
import {
  getSelectedFromSelectionProperty,
  isEmpty,
  getAllSearchParams,
  appendOrReplaceSearchParam,
  removeSearchParam
} from '../../utils';
import { FilterAreaContainer } from './components';
import { Tag } from 'features/Tag';
//import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
class FilterArea extends React.Component {
  constructor(props) {
    super(props);
    this.parameter = 'categoryids[]';
    this.state = { addedFromUrl: false };
  }

  getSelectedRootNoteId = (list = []) => {
    if (list.length === 0) return '';
    const selected = list.find(x => x.selected);
    if (!selected) return '';
    if (selected.hasOwnProperty('value')) return selected.value;
    return '';
  };

  componentDidMount() {
    const { setFilter, rootcategory, includeRoot, isEduadminFilter } = this.props;
    const title = getSelectedFromSelectionProperty(rootcategory, 'value');
    setFilter(title, includeRoot, isEduadminFilter);
  }

  componentDidUpdate(prevProps) {
    const { filterarea, addSelectedFilter } = this.props;
    const { filters } = filterarea;
    if (isEmpty(filters) || this.state.addedFromUrl) return null;

    const categoriesFromUrl = getAllSearchParams(window.location.href, this.parameter);
    let selectedFilters = [];

    for (let i = 0; i < categoriesFromUrl.length; i++) {
      const cat = categoriesFromUrl[i];
      const id = parseInt(cat, 10);
      const category = filters.find(f => f.id === id);
      const exists = selectedFilters.some(f => f.id === id);
      if (!exists && category) {
        selectedFilters = [...selectedFilters, category];
      }
    }
    addSelectedFilter(selectedFilters);
    this.setState({ addedFromUrl: true });
  }
  handleClick(item) {
    const { removeFromSelected, addSelectedFilter, filterarea, push } = this.props;

    const exists = filterarea.selectedFilters.some(f => f.id === item.id);

    if (exists) {
      const selectedFilter = [...filterarea.selectedFilters.filter(f => f.id !== item.id)];

      const params = removeSearchParam(window.location.href, this.parameter);
      let currentParams = new URLSearchParams(params);

      selectedFilter.forEach(ele => {
        currentParams.append(this.parameter, ele.id);
      });

      push(`?${currentParams}`);
      removeFromSelected(selectedFilter);
    } else {
      const selectedFilter = [...filterarea.selectedFilters, item];

      let params = appendOrReplaceSearchParam(
        window.location.href,
        this.parameter,
        item.id,
        (params, key, value) => false
      );
      push(`?${params}`);
      addSelectedFilter(selectedFilter);
    }
  }

  render() {
    const { filters, selectedFilters } = this.props.filterarea;
    const { isPuffBlockFilter } = this.props;
    if (!filters) return null;
    return (
      <FilterAreaContainer>
        {filters.map(item => {
          const selected = selectedFilters.some(x => x.id === item.id);
          return (
            <Tag
              isPuffBlockFilter={isPuffBlockFilter}
              selected={selected}
              selectCallback={() => this.handleClick(item)}
              key={item.id}
              name={item.name}
              value={item.id}
            />
          );
        })}
      </FilterAreaContainer>
    );
  }
}

const mapStateToProps = state => ({
  filterarea: {
    filters: state.filterarea.filters,
    selectedFilters: state.filterarea.selectedFilters
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setFilter, addSelectedFilter, removeFromSelected, push }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterArea);

FilterArea.propTypes = {
  filtertype: PropTypes.string,
  rootcategory: PropTypes.array
};
