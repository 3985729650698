import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import PropTypes from 'prop-types';
import tagIcon from 'images/tag-icon.svg';

const BaseCategoryLink = styled.div`
  display: inline-block;
  text-decoration: none;
  color: ${Colors.BLACK};
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const CategoryLink = styled(BaseCategoryLink)``;

export const CategoryLinkWithIcon = styled(CategoryLink).attrs(props => ({}))`
  position: relative;
  margin-left: ${({ icon, large }) =>
    icon ? (large ? '0.9583rem' : '1.2em') : large ? '0.9583rem' : 0};
  &:nth-of-type(2) {
    margin-left: 1.5rem;
  }
  &:before {
    content: '';
    position: absolute;
    background: ${({ icon }) => (icon ? `url(${icon})` : 'initial')};
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: ${({ icon, large }) => (large ? '0.9583rem' : '1em')};
    width: ${({ icon, large }) => (large ? '0.9583rem' : '1em')};
    left: ${({ icon, large }) => (large ? '-1.4rem' : '-1.3em')};
    top: 50%;
    transform: translateY(-50%);
    overflow: visible;
  }
`;

CategoryLinkWithIcon.propTypes = {
  icon: PropTypes.string,
  large: PropTypes.bool
};

export const InlineWithIcon = styled(CategoryLinkWithIcon).attrs(() => ({ as: 'span' }))``;

InlineWithIcon.propTypes = {
  icon: PropTypes.string
};

export const CategoryTagContainer = styled.div`
  text-decoration: none;
  color: ${Colors.BLACK};
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
`;

export const Tag = styled.span`
  background: url(${tagIcon});
  background-size: contain;
  margin-right: 5px;
  height: 1em;
  width: 1em;
`;
