import React from 'react';
import styled, { css } from 'styled-components/macro';
import LockIcon from 'images/lock-solid.svg';
import LockIconWhite from 'images/lock-solid-white.svg';
import LockIconOrange from 'images/lock-solid-orange.svg';
import { colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import { media } from 'style/media';
import { MainBody } from 'components/text';

const defaultLength = 24;

const LockStyles = css`
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
`

const LockBlackStyles = css`
  background-image: url(${LockIcon});
  ${LockStyles}
`
const LockWhiteStyles = css`
  background-image: url(${LockIconWhite});
  ${LockStyles};
`

const LockOrangeStyles = css`
  background-image: url(${LockIconOrange});
  ${LockStyles};
`

const LockCircle = styled.div`
  height: ${defaultLength}px;
  width: ${defaultLength}px;
  padding: ${defaultLength / 2}px;
  z-index: ${zIndex.UNDER_MODALS};
  border-radius: 50px;
  position: relative;

  ${media.smalltablet`
    height: ${defaultLength / 2}px;
    width: ${defaultLength / 2}px;
  `}

  ${({ topright }) => topright && `
    position: absolute;
    top: ${defaultLength / 4}px;
    right: ${defaultLength / 4}px;
    ${media.smalltablet`
      top: ${defaultLength / 8}px;
      right ${defaultLength / 8}px;
    `}
  `}

  &:after {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
  }
`

const PillShapedContainer = styled.div`
  z-index: ${zIndex.UNDER_MODALS};
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.755rem;

  ${({ bgcolor }) => bgcolor && `
    background-color: ${bgcolor};
  `}

  ${({ topright }) => topright && `
    position: absolute;
    top: ${defaultLength / 4}px;
    right: ${defaultLength / 4}px;
    ${media.smalltablet`
      top: ${defaultLength / 8}px;
      right ${defaultLength / 8}px;
    `}
  `}
`

export const LockOrangeCircle = styled(LockCircle)`
  background-color: ${colors.ORANGE};
  &:after {
    ${LockWhiteStyles}
  }
`

export const LockWhiteCircle = styled(LockCircle)`
  background-color: ${colors.WHITE};
  &:after {
    ${LockBlackStyles}
  }
`

export const LockBlackCircle = styled(LockCircle)`
  background-color: ${colors.BLACK};
  &:after {
    ${LockWhiteStyles}
  }
`

export const Lock = styled.span`
  ${({ lockColor }) => 
    lockColor === 'orange' ? LockOrangeStyles : lockColor === 'white' ? LockWhiteStyles : LockBlackStyles
  };
  width: ${({ size }) => size} !important;
  height: ${({ size }) => size};
  display: ${({ display }) => display ? display : 'block'};
  margin: ${({ margin }) => margin ? margin : '0'};
`;

export const LockPillShape = ({ bgcolor, topright, text }) => {
  if (!text) text = 'Denna artikel kräver inloggning';

  return (
    <PillShapedContainer bgcolor={bgcolor} topright={topright}>
      <Lock size='0.75rem' margin='0 0.25rem 0 0' />
      <MainBody>
        {text}
      </MainBody>
    </PillShapedContainer>
  )
}