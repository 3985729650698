import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { targetBrowser } from 'style/media';
import fonts from 'style/fonts/fonts';

export const SquareDateContainer = styled.div.attrs(props => ({
  size: props.height ? props.height : '100%',
  fontSize: props.fontSize ? props.fontSize : '1.367rem',
  position: props.absolute ? 'absolute' : 'relative',
  width: props.width ? props.width : 'auto'
}))`
  font-family: ${fonts.APERCU.WEB};
  font-size: ${props => props.fontSize};
  background: ${Colors.ORANGE};
  height: ${props => props.size};
  color: ${Colors.WHITE};
  position: ${props => props.position};
  padding: ${({ hasEndDate }) => hasEndDate ? '0 2rem' : '0 0.5rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: ${props => props.width};
  top: 0;
  flex: 0 0 2.708rem;
  min-width: ${({ nominwidth }) => (nominwidth ? 'unset' : '2.709rem')};
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;

SquareDateContainer.propTypes = {
  height: PropTypes.string,
  fontSize: PropTypes.string
};
