import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts';
import { PrimaryButton } from 'components/button';
import { media } from 'style/media';

export const CalculateButton = styled(PrimaryButton)`
  font-size: 16px;
  padding: 0.25rem 1.5rem;
  border: 1px solid ${Colors.ORANGE};
  font-family: ${fonts.LLBrown.REGULAR};
  margin: 0.5rem 0.25rem 0.5rem 0;
  color: ${Colors.WHITE};
  min-height: 40px;
  &:hover,
  :active,
  :focus {
    background-color: ${Colors.WHITE};
    color: ${Colors.ORANGE};
  }

  ${media.mediumtablet`
  flex-basis: 50%;

  `};
`;

export const ClearCalcButton = styled(PrimaryButton)`
  font-size: 16px;
  font-family: ${fonts.LLBrown.REGULAR};
  padding: 0.25rem 1.5rem;
  border: 1px solid ${Colors.ORANGE};
  background-color: ${Colors.WHITE};
  margin: 0.5rem 0.25rem;
  min-height: 40px;
  color: ${Colors.BLACK};
  &:hover,
  :active,
  :focus {
    background-color: ${Colors.ORANGE};
    color: ${Colors.WHITE};
  }
  ${media.mediumtablet`
    flex-basis: 50%;
  `};
`;

export const CalcButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
`;
