import styled from 'styled-components/macro';
// import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { targetBrowser } from 'style/media';
/**
 * CountyFilterList (ul)
 */
export const CountyFilterList = styled.ul`
  list-style: none;
  list-style-type: none;
  padding: 1rem;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  background-color: ${Colors.DARK_GAY};
  flex-direction: column;
  max-height: 100%;
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `} width:100%;
`;

CountyFilterList.propTypes = {};
