const display = [
  'inline',
  'block',
  'flex',
  'inline-block',
  'inline-flex ',
  'inline-table',
  'list-item',
  'run-in',
  'table',
  'table-caption',
  'table-column-group',
  'table-header-group',
  'table-footer-group',
  'table-row-group',
  'table-cell',
  'table-column',
  'table-row',
  'none',
  'initial',
  'inherit'
];
export const propTypes = {
  DISPLAY: display
};
