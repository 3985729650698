import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { settings } from 'constants/settings';
/**
 * RelatedContainer (aside)
 */

export const RelatedContainer = styled.aside.attrs(props => ({
  marginright: props.marginright ? props.marginright : 'auto',
  paddingtop: props.hasimage ? `calc(${settings.MENU_HEIGHT} + 18rem)` : settings.MENU_HEIGHT
}))`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.marginright};
  min-width: 7.083rem;
  max-width: 7.083rem;
  padding-top: ${props => props.paddingtop};
  position: relative;
  right: -4rem;
  top: 0;
`;

RelatedContainer.propTypes = {
  marginright: PropTypes.string
};
