import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { fonts } from 'style/fonts';
const animSpeed = '0.2s';
export const Message = styled.span.attrs(props => ({
  rotation: props.visible ? 0 : '-45deg',
  opacity: props.visible ? 1 : 0,
  position: props.relative ? 'relative' : 'absolute',
  display: (props.relative && props.visible) || !props.relative ? 'block' : 'none',
  bottom: props.relative ? 0 : 'calc(-1em - 0.2em)',
  margin: props.isSearchMessage ? '1rem' : 0
}))`
  font-family: ${fonts.LLBrown.REGULAR};
  transition: opacity ${animSpeed} ease-in;
  opacity: ${props => props.opacity};
  padding: 0.2rem 1rem;
  background: ${Colors.ORANGE};
  position: ${props => props.position};
  color: ${Colors.WHITE};
  bottom: ${props => props.bottom};
  margin: ${props => props.margin};
  text-align: center;
  display: ${props => props.display};
  width: auto;
  height: 1.8rem;
`;
