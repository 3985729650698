export const PAGES = {
  STANDARD: 'StandardPage',
  UTBILDNING: 'UtbildningPage',
  START: 'StartPage',
  CALENDAR: 'CalendarPage',
  TJANSTE: 'TjanstePage',
  REPORT: 'ReportPage',
  REMISS: 'RemissPage',
  SEARCH: 'SearchPage',
  CONTACT: 'ContactPage',
  FOLDER: 'FolderPage',
  WORKBUSTER: 'WorkbusterPage',
  EDITORS_MANUAL_PAGE: 'EditorsManualPage',
  CONTACT_PAGE: 'ContactPage',
  LOCKED_PAGE: 'LockedPage',
  XTRACTOR_PAGE: 'XtractorPage',
  OFFICE_PAGE: 'OfficePage',
  CONTACT_OFFICE_PAGE: 'ContactOfficePage',
  BLOCK_CONTAINER_PAGE: 'BlockContainerPage',
  SUB_MENU_PAGE: 'SubMenuPage',
  SERVICE_PAGE: 'ServicePage',
  COURSE_LIST_PAGE: 'CourseListPage',
  COURSE_CONTAINER_PAGE: 'CourseContainerPage',
  JOB_AD_PAGE: 'JobAdPage',
  DOCUMENT_PAGE: 'DocumentPage',
  NOT_FOUND_PAGE: 'NotFoundPage'
};
