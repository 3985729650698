import styled from 'styled-components/macro';
import React from 'react';
import { colors as Colors } from 'constants/colors';
import { MainBody } from '../text';

const StyledSquareButton = styled(MainBody).attrs(props => ({
  as: 'button',
  content: props.content ? props.content : '+'
}))`
  margin-bottom: 1rem;
  margin-right: auto;
  background: none;
  border: none;
  width: auto;
  flex: 1;
  padding: 0 0 0 2rem;
  cursor: pointer;
  position: relative;
  line-height: 1.5rem;
  &:before {
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    color: ${Colors.WHITE};
    content: '${props => props.content}';
    position: absolute;
    width: 1.5rem;
    left: 0;
    height: 1.5rem;
    background: ${Colors.BRAND_DARK};
    border: solid 1px ${Colors.TRANSPARENT};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }

  &:last-of-type {
    margin-bottom: 1rem;
    margin-right: auto;
  }
`;

export const SquareButton = ({ label, content, ...rest }) => {
  return (
    <StyledSquareButton content={content} {...rest}>
      {label}
    </StyledSquareButton>
  );
};
