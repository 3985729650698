import styled from 'styled-components/macro';
import { media } from 'style/media';

/**
 * OnlySmall (div)
 */
export const OnlyMobile = styled.div.attrs(props => ({
  flex: props.flex
}))`
  display: none;
  ${media.tablet`
    display: ${props => (props.flex ? 'flex' : 'block')};
    justify-content: ${props => (props.flex ? 'center' : 'left')};
  `};
`;
