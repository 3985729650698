import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { fontSize } from 'style/fonts/';
import NavigationLink from './NavigationLink';

export const SmallNavigationLink = styled(NavigationLink)`
  text-transform: uppercase;
  font-size: ${fontSize(8, 10)};
  color: ${Colors.DARKER_GRAY};
  top: 0;
  &:after {
    ${'' /* display:none; */};
  }
  &:hover {
    color: ${Colors.BLUE};
  }
`;
