import React from 'react';
import PropTypes from 'prop-types';
import { Title2, Paragraph } from 'components/text';
import { Loader } from 'components/common/Loader';
import { UnderlineContainer, UnderlineContainerText } from 'components/UnderlineContainer';
import { FlexContainer } from 'components/flex';
import { ArrowButtonRight, ArrowButtonText } from 'components/button';
import { colors as Colors } from 'constants/colors';
import { CarouselBlockContainer } from './index';
import { Carousel } from 'features/Carousel';
import { RelatedItem } from 'features/Related';

export default class CarouselBlock extends React.Component {
  render() {
    const {
      nomarginbottom,
      carouselItems,
      children,
      textHeading,
      ingress,
      buttonLink,
      loading,
      nominheight,
      nopadding,
      heading
    } = this.props;
    const iconItems =
      carouselItems &&
      !children &&
      carouselItems.map((item, index) => {
        return <RelatedItem horizontal carousel {...item} key={index} />;
      });
    return (
      <CarouselBlockContainer
        nopadding={nopadding}
        nominheight={nominheight}
        first={this.props.first}>
        <FlexContainer
          smallAlignContent="center"
          column
          alignItems="center"
          justifycontent="center">
          {heading && !textHeading && (
            <UnderlineContainer color={Colors.ORANGE} center centertext position="bottom">
              <UnderlineContainerText> {heading || 'Relaterat'}</UnderlineContainerText>
            </UnderlineContainer>
          )}
          {textHeading && (
            <Title2 uppercase center large>
              {textHeading}
            </Title2>
          )}
          {ingress && (
            <Paragraph centerTextSmall center maxwidth="40rem">
              {ingress}
            </Paragraph>
          )}
        </FlexContainer>

        {buttonLink && (
          <ArrowButtonRight smallbackgroundcolor={Colors.GRAY} to={buttonLink}>
            <ArrowButtonText>{'Se alla aktiviteter'}</ArrowButtonText>
          </ArrowButtonRight>
        )}
        <Carousel nomarginbottom={nomarginbottom}>{children || iconItems}</Carousel>
        <Loader absolute loaded={!loading} />
      </CarouselBlockContainer>
    );
  }
}

CarouselBlock.propTypes = {
  //contentId of the block. Will maybe be used later to fetch block from epi.
  nomarginbottom: PropTypes.bool,
  id: PropTypes.number,
  heading: PropTypes.string,
  textHeading: PropTypes.string,
  ingress: PropTypes.string,
  blockType: PropTypes.oneOf(['CarouselBlock']),
  carouselItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};
