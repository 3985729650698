import * as TYPES from './calendarlistblockTypes';
import { fetchEventItems } from '../../api/SearchApi';
import { isEmpty } from '../../utils';
export const fetchItems = (id, category) => {
  return async dispatch => {
    dispatch({ type: TYPES.CAL_LIST_BLOCK_FETCH, category });
    try {
      const items = await fetchEventItems(id, category);

      if (!isEmpty(items)) {
        dispatch({ type: TYPES.CAL_LIST_BLOCK_FETCHED, items });
      } else {
        dispatch({ type: TYPES.CAL_LIST_BLOCK_ERROR, message: 'Inga träffar för ' });
      }
    } catch (e) {
      throw e;
    }
  };
};
