import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export const NoArrowLinkWrapper = styled(NavLink).attrs(props => ({
  background: props.background ? props.background : props.secondary ? Colors.ORANGE : Colors.GRAY,
  right: props.left ? 'auto' : 0,
  left: props.left ? 0 : 'auto',
  padding: props.left ? '0 0 0 1rem' : '0 1rem 0 0',
  translateamount: props.left ? '-0.3rem' : '0.3rem',
  rotateamount: props.left ? '180deg' : 0
  // margin: props => (props.center ? 'auto' : 0),
  // maxwidth: props => (props.center ? 'calc(36.25rem - 0.8333rem * 2)' : 'inherit')
}))`
  background: ${props => props.background};
  margin-bottom: 0.25rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  transform-origin: center center;
  text-decoration: none;
  color: ${Colors.BLACK};
  min-height: 2.333rem;
  max-height: 2.333rem;
  height: 2.3333rem;
  margin-left: ${props => props.margin};
  margin-right: ${props => props.margin};
  max-width: ${props => props.maxwidth};

  &:hover {
    cursor: pointer;
  }
`;

NoArrowLinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  background: PropTypes.oneOf(Object.values(Colors)),
  secondary: PropTypes.oneOf([1, 0])
};
