import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 700;
const exitDuration = 200;
export default class GenericTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    anime({
      targets: node,
      opacity: [0.8, 1],
      translateX: [50, 0],
      duration: duration,
      easing: 'easeOutElastic',
      delay: () => anime.random(0, 100),
      elasticity: 100
    });
  };

  onExit = (node, index) => {
    if (!node) return null;
    anime({
      targets: node,
      translateX: [0, 70],
      opacity: [1, 0],
      duration: exitDuration,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: duration, exit: duration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onExit={this.onExit}
        mountOnEnter={true}
        unmountOnExit={true}>
        {children}
      </Transition>
    );
  }
}
GenericTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  children: PropTypes.element
};
