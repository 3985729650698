import styled from 'styled-components/macro';

export const ScrollToContainer = styled.div`
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ bottom }) => (bottom !== undefined ? `bottom: ${bottom}` : '')};
  margin: 0.5rem 0 0.5rem 0;
`;
