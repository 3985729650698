import React from 'react';
import PropTypes from 'prop-types';
import { ArrowButton } from '../../../../components/button';
import OPE from '../../../../components/common/EpiOnPageEdit';
import { colors as Colors } from 'constants/colors';
import { SmallText, LargeText } from '../../../../components/text';
import { UnderlineContainer } from '../../../../components/UnderlineContainer';
import { EventItem } from './EventItem';
import { LargeDate } from '../../../../components/common/Date';
import { FlexContainer } from '../../../../components/flex';
import { Link } from '../../../../components/link';
import { Date } from '../../../../features/Date';
export default class Event extends React.Component {
  render() {
    const { text, linkUrl, startDate, location, endDate } = this.props;

    return (
      <EventItem>
        <FlexContainer column>
          <OPE propertyName="header">
            <Link internal href={linkUrl}>
              <LargeText brown uppercase color={Colors.WHITE}>
                {text}
              </LargeText>
            </Link>
          </OPE>
          <OPE propertyName="date">
            <Date
              wrapper={<LargeDate color={Colors.WHITE} nouppercase />}
              endDate={endDate}
              date={startDate}
              format="DD MMMM YYYY"
            />
          </OPE>
          <UnderlineContainer
            color={Colors.ORANGE}
            position="top"
            alignwhensmall="center"
            smallunderlinewhensmall>
            <OPE propertyName="location">
              <SmallText uppercase color={Colors.WHITE} textalignwhensmall="center">
                {location}
              </SmallText>
            </OPE>
          </UnderlineContainer>
        </FlexContainer>
        <OPE propertyName="buttonLink">
          <ArrowButton to={linkUrl} />
        </OPE>
      </EventItem>
    );
  }
}

Event.propTypes = {
  text: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired
};
