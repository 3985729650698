import styled from 'styled-components/macro';
import React from 'react';
import { media, targetBrowser } from 'style/media';
import PropTypes from 'prop-types';
import { zIndex } from '../../constants/settings';

/**
 * Image (img)
 */
export const BasicImage = styled.img.attrs(props => ({
  width: props.width ? props.width : '100%',
  height: props.height ? props.height : props.width ? 'auto' : '100%',
  maxwidth: props.maxwidth ? props.maxwidth : 'none',
  maxheight: props.maxheight ? props.maxheight : 'none',
  minheight: props.minheight ? props.minheight : 'none',
  objectfit: props.scaledown ? 'scale-down' : props.fit ? props.fit : 'cover'
}))`
  ${'' /* will-change: opacity; */} width: ${props => props.width};
  display: block;
  transition: opacity 1s cubic-bezier(0.37, 0, 0.81, 1);
  height: ${props => props.height};
  max-width: ${props => props.maxwidth};
  max-height: ${props => props.maxheight};
  min-height: ${props => props.minheight};
  opacity: ${props => (props.loaded && props.fadein ? '1' : '0')};
  filter: ${props => (props.blur ? 'blur(10px)' : 'none')};
  object-fit: ${props => props.objectfit};
  object-position: center;
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  ${media.desktop`
    display: ${props => (props.hideSmall ? 'none' : 'block')};
  `};
  ${targetBrowser.IE10ANDHIGHER`
      font-family: "object-fit: ${props => props.objectfit}"; 
  `};
`;

BasicImage.propTypes = {
  transparent: PropTypes.bool,
  loaded: PropTypes.bool,
  src: PropTypes.string,
  fadein: PropTypes.bool,
  blur: PropTypes.bool
};

/**
 * Picture (<picture>)
 */
export const Picture = styled.span.attrs(props => ({
  size: props.loaded ? '100%' : '100%'
}))`
  width: ${props => (props.center ? 'auto' : props.size)};
  height: ${props => (props.center ? 'auto' : props.size)};
  display: ${props => (props.hide ? 'none' : 'initial')};
`;

export const Image = props => {
  let { loaded, center, hide, ...rest } = props;
  return (
    <Picture className="Image" loaded={loaded} center={center} hide={hide}>
      <BasicImage className="BasicImage" {...rest} loaded={loaded} />
    </Picture>
  );
};

export const StyledImageAsBackground = styled(BasicImage).attrs(props => ({
  objectfit: props.scaledown ? 'scale-down' : props.fit ? props.fit : 'cover',
  fitsmall: props.scaledown ? 'scale-down' : 'cover',
  maxwidth: props.maxwidth ? props.maxwidth : 'none',
  maxheight: props.maxheight ? props.maxheight : 'none'
}))`
  max-width: ${({ maxwidth }) => maxwidth};
  max-height: ${({ maxheight }) => maxheight};
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: ${zIndex.LEVEL_BELOW_ALL};
  object-fit: ${props => props.fit};
  font-family: "object-fit: ${props => props.objectfit}"; 
  
  ${media.desktop`
    object-fit: ${props => props.fitsmall};
    font-family: "object-fit: ${props => props.fitsmall}"; 
  `};
`;

export const ImageAsBackground = props => {
  return (
    <Picture className="ImageAsBackground" loaded={props.loaded}>
      <StyledImageAsBackground className="StyledImageAsBackground" {...props} />
    </Picture>
  );
};

StyledImageAsBackground.propTypes = {
  src: PropTypes.string
};
