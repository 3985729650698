import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledBaseAnimation = styled.div`
  animation-duration: ${props => props.duration};
  animation-timing-function: ${props => props.timingFunction};
  animation-delay: ${props => props.delay};
  animation-iteration-count: ${props => props.iterationCount};
  animation-direction: ${props => props.direction};
  animation-fill-mode: ${props => props.fillMode};
  animation-play-state: ${props => props.playState};
  display: ${props => props.display};
`;

const BaseAnimation = ({ children, zIndex, width, display, flexWrap, ...props }) => (
  <div
    style={{
      perspective: '50rem',
      zIndex: zIndex,
      width: width || '100%',
      display,
      flexWrap
    }}>
    <StyledBaseAnimation {...props}>{children}</StyledBaseAnimation>
  </div>
);

BaseAnimation.defaultProps = {
  duration: '.45s',
  timingFunction: 'ease',
  delay: '0s',
  iterationCount: '1',
  direction: 'normal',
  fillMode: 'both',
  playState: 'running',
  display: 'block'
};

BaseAnimation.propTypes = {
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timingFunction: PropTypes.oneOf([
    'linear',
    'ease',
    'ease-in',
    'ease-out',
    'ease-in-out',
    'step-start',
    'step-end',
    'steps(int,start,end)',
    'cubic-bezier(n,n,n,n)',
    'initial',
    'inherit'
  ]),
  delay: PropTypes.string,
  iterationCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.string,
  fillMode: PropTypes.string,
  playState: PropTypes.string,
  display: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default BaseAnimation;
