import React from 'react';
import PropTypes from 'prop-types';
import { ErrorHandler } from 'utils';
import { colors as Colors } from 'constants/colors';

class LazyBackground extends React.Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
    const { bg } = this.props;
    this.state = {
      imageIsLoaded: false,
      placeholderBgIsLoaded: false,
      bg: bg
    };
  }

  componentDidMount = async () => {
    this.mounted = true;

    const { bg } = this.state;
    if (!bg) {
      console.error('LazyBackground: bg props was not set.');
      return null;
    }
    const { rootmargin = -50 } = this.props;
    const config = {
      rootMargin: `${rootmargin}px ${rootmargin}px`,
      threshold: 0
    };

    if ('IntersectionObserver' in window) {
      // eslint-disable-next-line compat/compat
      this.observer = new IntersectionObserver(this.handleIntersection, config);
      this.observer.observe(this.element.current);
    } else {
      // IO is not supported.
      // Just load image
      this.loadImage();
    }
  };

  componentDidUpdate = ({ bg: prevBg }) => {
    const { bg } = this.props;
    if (bg === prevBg) return null;
    this.loadImage();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleIntersection = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        this.loadPlaceHolderImage();
        this.loadImage();
      }
    });
  };

  loadPlaceHolderImage = async () => {
    const { placeholderBg } = this.props;

    await this.fetchImage(placeholderBg);
    this.setState({ placeholderBgIsLoaded: true });
  };

  loadImage = async () => {
    const { onLoad, bgdelay, bg } = this.props;

    try {
      await this.fetchImage(bg);
      if (!this.mounted) return null;
      setTimeout(() => this.mounted && this.setState({ imageIsLoaded: true, bg: bg }), bgdelay);
      onLoad();
    } catch (ex) {
      ErrorHandler.LogErrorSilently('Error in LazyBackground', ex);
    }
  };

  fetchImage = url => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = resolve;
      image.onerror = reject;
    });
  };

  createCommonStyles = () => {
    return {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0
    };
  };

  createCommonParallaxStyles = () => {
    return {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0
    };
  };

  render() {
    const {
      children,
      style,
      placeholderBg,
      placeholderColor,
      className,
      show,
      ...rest
    } = this.props;
    const { imageIsLoaded, placeholderBgIsLoaded, bg } = this.state;

    const tempBg =
      placeholderBg && placeholderBgIsLoaded ? `url(${placeholderBg})` : placeholderColor;
    const realBgStyles = {
      transition: 'opacity 1s ease-in-out',
      opacity: imageIsLoaded ? 1 : 0,
      ...this.createCommonStyles(),
      backgroundImage: this.props.opacity
        ? this.props.color === Colors.WHITE
          ? `linear-gradient(to right, rgba(0,0,0,${this.props.opacity}), rgba(0,0,0,${this.props.opacity})), url(${bg})`
          : this.props.color === Colors.TOTALLY_BLACK
          ? `linear-gradient(to right, rgba(255,255,255,${this.props.opacity}), rgba(255,255,255,${this.props.opacity})), url(${bg})`
          : `linear-gradient(to right, rgba(0,0,0,${this.props.opacity}), rgba(0,0,0,${this.props.opacity})), url(${bg})`
        : `url(${bg})`,
      zIndex: -1 //z-index: -1 so that it doesn't hide hovering color filters in multipleitemsblock
    };
    const realBgParallaxStyles = {
      transition: 'opacity 1s ease-in-out',
      opacity: imageIsLoaded ? 1 : 0,
      ...this.createCommonParallaxStyles(),
      backgroundImage: this.props.opacity
        ? this.props.color === Colors.WHITE
          ? `linear-gradient(to right, rgba(0,0,0,${this.props.opacity}), rgba(0,0,0,${this.props.opacity})), url(${bg})`
          : this.props.color === Colors.TOTALLY_BLACK
          ? `linear-gradient(to right, rgba(255,255,255,${this.props.opacity}), rgba(255,255,255,${this.props.opacity})), url(${bg})`
          : `linear-gradient(to right, rgba(0,0,0,${this.props.opacity}), rgba(0,0,0,${this.props.opacity})), url(${bg})`
        : `url(${bg})`,
      zIndex: -1 //z-index: -1 so that it doesn't hide hovering color filters in multipleitemsblock
    };
    const tempBgStyles = {
      transition: 'opacity 1.5s ease-in-out',
      opacity: placeholderBgIsLoaded && !imageIsLoaded ? 1 : 0,
      ...this.createCommonStyles(),
      backgroundImage: tempBg,
      filter: 'blur(8px)'
    };

    const tempColorStyles = {
      transition: 'opacity 1s ease-in-out',
      ...this.createCommonStyles(),
      background: placeholderColor,
      opacity: !placeholderBgIsLoaded && !imageIsLoaded ? 1 : 0
    };

    return (
      <div {...rest} className={className} style={style} ref={this.element}>
        {className.includes('Parallax') ? (
          <span className="lazy-load-bg__real parallax-img" style={realBgParallaxStyles} />
        ) : (
          <span className="lazy-load-bg__real" style={realBgStyles} />
        )}
        <span className="lazy-load-bg__temp" style={tempBgStyles} />
        <span className="lazy-load-bg__temp--color" style={tempColorStyles} />
        {children}
      </div>
    );
  }
}

LazyBackground.defaultProps = {
  onLoad: () => {},
  bgdelay: 1000,
  placeholderBg: undefined,
  placeholderColor: '#f7f8f9'
};

LazyBackground.propTypes = {
  bg: PropTypes.oneOfType([PropTypes.string]),
  onLoad: PropTypes.func,
  bgdelay: PropTypes.number,
  placeholderBg: PropTypes.string
};

export { LazyBackground };
