import styled from 'styled-components/macro';

/**
 * Footer
 */
export const AppBasePage = styled.div.attrs(props => ({
  height: props.loading ? '100vh' : '100%'
}))`
  perspective: 1200px;
  transform-style: preserve-3d;
  width: 100%;
  min-height: 100vh;
  height: ${props => props.height};
`;
