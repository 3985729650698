import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { animateIn, animateOut } from 'style/animations';
import { Input } from 'components/input';
import { SearchButton, ButtonText } from 'components/button';
import { colors as Colors } from 'constants/colors';
import AutoComplete, { AutoCompleteContainer } from 'features/AutoComplete';
export const SearchInputContainer = styled.div.attrs(({ showloader, ...props }) => ({
  animation: showloader ? animateIn : animateOut,
  ...props
}))`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.9rem;
  width: 100%;
  &:hover {
    cursor: pointer;
  }

  ${Input} {
    background: ${({ secondary }) => (secondary ? Colors.WHITE : Colors.GRAY)};
    padding: 0 0 0 0.5em;
    font-size: 1.2em;
    caret-color: ${Colors.ORANGE};
  }

  ${AutoCompleteContainer} {
    left: 0;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 2.8rem;
`;

export const SearchInput = ({
  isSearching,
  secondary,
  error = undefined,
  useAutocomplete,
  active,
  latestQuery,
  onKeyDown = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClick = () => {},
  ...rest
}) => {
  const [inputValue, setValue] = useState('');
  const [isInputFocused, setFocused] = useState(false);
  const [buttonWidth, setButtonWidth] = useState('100%');
  const prevLatestQuery = usePrevious(latestQuery);
  const wrapper = useRef(null);
  const button = useRef(null);
  const input = useRef(null);

  const handleClickOutside = e => {
    if (wrapper.current.contains(e.target)) return;
    onBlur();
    setFocused(false);
  };

  const setFocusOnInput = () => {
    try {
      input.current.focus();
    } catch (e) {
      //swallow
    }
  };

  useEffect(() => {
    setFocusOnInput();
  }, [active]);

  useEffect(() => {
    if (prevLatestQuery !== latestQuery) {
      setValue(latestQuery);
    }
  });

  useEffect(() => {
    if (isInputFocused) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    const { width } = button.current.getBoundingClientRect();
    setButtonWidth(`${width}px`);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isInputFocused]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  } //TODO: place this somewhere were we can reuse custom hooks

  return (
    <SearchInputContainer ref={wrapper} showloader={isSearching} secondary={secondary}>
      <SearchInputWrapper>
        <Input
          ref={input}
          height="inherit"
          fullwidth
          autoFocus
          type="text"
          placeholder="Sök"
          nouppercase
          fontSize="32px"
          {...rest}
          onFocus={() => {
            onFocus();
            setFocused(true);
          }}
          value={inputValue}
          onChange={({ target: { value } }) => setValue(value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setFocused(false);
            } else {
              setFocused(true);
            }
            onKeyDown(e, inputValue);
          }}
          noBorder
        />
        <SearchButton
          ref={button}
          isSearching={isSearching}
          onClick={() => {
            onClick(inputValue);
            setFocused(false);
          }}>
          <ButtonText search>Sök</ButtonText>
        </SearchButton>
      </SearchInputWrapper>
      {useAutocomplete && isInputFocused && !error && (
        <AutoComplete
          css={{ width: `calc(100% - ${buttonWidth})` }}
          highlight={inputValue}
          setFocusOnInput={setFocusOnInput}
          onItemSelect={value => {
            setFocusOnInput();
            setValue(value);
          }}
        />
      )}
    </SearchInputContainer>
  );
};
