export const CONTACT_LIST_BLOCK_ITEMS_FETCH = 'contactListBlock/CONTACT_LIST_BLOCK_FETCH';
export const CONTACT_LIST_BLOCK_ITEMS_FETCHED = 'contactListBlock/CONTACT_LIST_BLOCK_FETCHED';
export const CONTACT_LIST_BLOCK_ERROR = 'contactListBlock/CONTACT_LIST_BLOCK_ERROR';
export const CONTACT_LIST_BLOCK_FILTER = 'contactListBlock/CONTACT_LIST_BLOCK_FILTER';
export const CONTACT_LIST_BLOCK_SET_FILTER = 'contactListBlock/CONTACT_LIST_BLOCK_SET_FILTER';
export const CONTACT_LIST_BLOCK_START_SET_LOADING =
  'contactListBlock/CONTACT_LIST_BLOCK_START_SET_FILTER';
export const CONTACT_LIST_BLOCK_CHANGE_SELECTED_PAGE_ID =
  'contactListBlock/CONTACT_LIST_BLOCK_CHANGE_CURRENT_PAGE_ID';
export const CONTACT_LIST_BLOCK_LAST_SEARCHED_CATEGORY =
  'contactListBlock/CONTACT_LIST_BLOCK_LAST_SEARCHED_CATEGORY';
