import React from 'react';
import styled from 'styled-components/macro';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import fonts from 'style/fonts';

export const CountyButton = styled.a`
  color: ${Colors.WHITE};
  background-color: ${Colors.ORANGE};
  padding: 0.3rem 1rem;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  font-family: ${fonts.LLBrown.REGULAR};
  margin: 0.2rem 0.6rem 0.2rem 0;
  text-decoration: none;
  &:hover {
    background-color: ${LightenDarkenColor(Colors.ORANGE, -15)};
  }
`;

export const CountyButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const CountyButtons = ({ countyLinks, categoryList }) => {
  if (!countyLinks) return null;
  if (!countyLinks.length) return null;
  const CountyItems = countyLinks.map((item, ix) => {
    let cat = categoryList.find(x => x.id === item);
    if (cat === 'undefined' || cat == null || cat === undefined) return null;
    return (
      <CountyButton
        key={ix}
        href={`${window.location.pathname}?region=${cat.name.replace(/\s+/g, '')}`}>
        {cat.name}
      </CountyButton>
    );
  });

  return <CountyButtonWrapper>{CountyItems}</CountyButtonWrapper>;
};
