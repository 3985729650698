import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { media } from 'style/media';

export const PrioritizedArticleBlockContent = styled.div`
  padding: 0.5rem 8rem 2.5rem 8rem;
  max-width: 50rem;
  ${media.giant`
    width:100%;
  `};
  ${media.desktop`
    padding: 1.5rem 2rem 3rem 2rem;     
    display: flex;
    flex-direction: column;
  `};
`;

PrioritizedArticleBlockContent.propTypes = {
  right: PropTypes.bool
};
