import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import anime from 'animejs';
import { FilterCartItem, FilterCartText, FilterCartTextContainer } from './components';
import {
  setAnimationDone,
  resetFilter,
  toggleModal
} from 'features/CountyFilter/countyFilterActions';
import { sizes } from 'style/media';
import { randomInt } from '../../utils/Helpers';
import { ErrorHandler } from '../../utils';
import { CollapsingArrow } from 'components/images/ArrowDown';

class FilterCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRender: true,
      currentFilter: this.props.filter.current
    };
    this.staticItem = null;
    this.animatedItem = null;
  }

  flyToAnimation(fromPos, whenDoneAnimation) {
    let initRect = this.staticItem.getBoundingClientRect();
    const toPos = {
      x: initRect.left,
      y: initRect.top
    };

    anime({
      targets: this.animatedItem,
      duration: 0,
      opacity: 1,
      left: fromPos.x,
      top: fromPos.y
    });

    anime({
      targets: this.animatedItem,
      duration: this.props.filter.animationDuration,
      left: toPos.x,
      top: toPos.y,
      opacity: 1,
      easing: 'easeInCubic',
      elasticity: 500,
      complete: () => {
        if (whenDoneAnimation) whenDoneAnimation();
      }
    });
  }

  flyToAnimationDone = () => {
    this.props.setAnimationDone();
    anime({
      diration: 100,
      targets: this.animatedItem,
      opacity: 0
    });
    anime({
      targets: this.staticItem,
      duration: 0,
      scale: [0.7, 1],
      rotateZ: {
        value: [randomInt(-10, 10), 0],
        duration: 0,
        easing: 'easeOutElastic'
      },
      elasticity: 600
    });
  };

  componentDidCatch(error, info) {
    this.setState({ shouldRender: false });
    ErrorHandler.LogError(error, info);
  }

  handleResetFilter = () => {
    const { reset, current, categoryList } = this.props.filter;
    const { resetFilter } = this.props;
    if (reset.id === current.id || current.id === categoryList[0].id) return null;
    anime({
      targets: this.staticItem,
      duration: 0,
      rotateY: {
        value: [0, 0],
        duration: 0,
        easing: 'easeOutElastic'
      },
      elasticity: 600
    });
    resetFilter(categoryList[0]);
  };

  componentDidUpdate(prevProps) {
    if (this.props && this.props.filter.position !== prevProps.filter.position) {
      this.flyToAnimation(this.props.filter.position, this.flyToAnimationDone);
    }
  }

  handleClick = () => {
    const { toggleModal = () => { }, filter = {} } = this.props;
    const { isModalOpen } = filter;
    if (!isModalOpen) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
    toggleModal(!isModalOpen);


  };

  render() {
    const { shouldRender } = this.state;
    if (!shouldRender) return null;
    const { animationDone, expanded, current, selected, isModalOpen } = this.props.filter;
    if (!selected || !current) return null;

    let scale = window.innerWidth < sizes.breakpointSmall || window.innerWidth > sizes.desktop ? 0.5 : 0.6;
    const { name } = selected || {};
    // const { name: currentName } = current || {};
    let show = animationDone && !expanded;
    return (
      <FilterCartItem
        onClick={this.handleClick}
        className="normalCartItem"
        show={show}
        key={0}
        ref={descriptor => (this.staticItem = descriptor)}>
        <FilterCartTextContainer>
          <FilterCartText>{name}</FilterCartText>
          <CollapsingArrow scale={scale} black collapsed={!isModalOpen} expandedTop={scale === 0.5 ? "9px" : "8px"} top="5px" countyFilter />
        </FilterCartTextContainer>
      </FilterCartItem>
      // ,
      // <FlyingFilterCartItem
      //   className="flyingCartItem"
      //   show={!animationDone}
      //   key={1}
      //   ref={descriptor => (this.animatedItem = descriptor)}>
      //   {currentName}
      // </FlyingFilterCartItem>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    current: state.filter.current,
    selected: state.filter.selected,
    position: state.filter.position,
    reset: state.filter.reset,
    animationDuration: state.filter.animationDuration,
    animationDone: state.filter.animationDone,
    expanded: state.filter.expanded,
    categoryList: state.filter.categoryList,
    isModalOpen: state.filter.isModalOpen
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAnimationDone,
      resetFilter,
      toggleModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCart);

FilterCart.propTypes = {};
