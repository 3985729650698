import { colors as Colors } from 'constants/colors';
import checkmark from 'images/check-mark-black.svg';
import React from 'react';
import { components } from 'react-select';
import { fonts } from 'style/fonts';
import styled from 'styled-components/macro';

const LocalOption = styled(components.Option)`
  &&& {
    color: ${Colors.BLACK};
    background: ${({ isFocused }) => (isFocused ? Colors.LIGHTER_GRAY : Colors.TRANSPARENT)};
    font-family: ${fonts.LLBrown.REGULAR};
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Checkmark = styled.span`
  background: url(${checkmark}) center;
  width: 16px;
  height: 16px;
  background-size: contain;
`;

export const Option = props => {
  return (
    <LocalOption {...props}>
      {props.children}
      {props.isSelected && <Checkmark />}
    </LocalOption>
  );
};
