import * as TYPES from './domeventsTypes';

const initialState = {
  windowWidth: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.WINDOW_WIDTH_CHANGED: {
      return {
        ...state,
        isSmall: action.windowWidth < 992 ? true : false,
        windowWidth: action.windowWidth
      };
    }
    default:
      return state;
  }
};
