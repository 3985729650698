import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { Perspective } from 'components/common';
const ModalContainer = styled(Perspective).attrs(() => ({
  perspective: '900px'
}))`
  transition: background 0.4s ease;
  position: fixed;
  display: flex;
  width: ${({ isOpen }) => (isOpen ? '100%' : 0)};
  height: ${({ isOpen }) => (isOpen ? '100%' : 0)};
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate3d(-50%, -50%, 0);
  background: ${({ isOpen }) => (isOpen ? Colors.TRANSAPRENT_BLACK : Colors.TRANSPARENT)};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: 1px;
`;

export default ModalContainer;
