import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import fonts from 'style/fonts/fonts';

const BaseTitle = styled.h1.attrs(props => ({
  textalign: props.center ? 'center' : 'left',
  texttransform: props.uppercase ? 'uppercase' : 'none',
  margin: props.margin ? props.margin : '1.375rem 0 0.5rem 0',
  display: props.inline ? 'inline' : 'block',
  width: props.width ? props.width : 'auto',
  min28onmobile: props.min28onmobile,
  spaceWrap: props.spaceWrap ? 'nowrap' : 'normal'
}))`
  display: ${props => props.display};
  font-family: ${fonts.APERCU.WEB};
  position: relative;
  margin: ${props => props.margin};
  text-align: ${props => props.textalign};
  color: ${props => props.color};
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: initial;
  white-space: ${props => props.spaceWrap};
  width: ${props => props.width};
  color: ${({ orange, white, color }) =>
    color ? color : orange ? Colors.ORANGE : white ? Colors.WHITE : Colors.BLACK};
  ${media.desktop`
    ${({ min28onmobile }) =>
      min28onmobile &&
      `
      font-size: calc(27px + 0.3vw);
    `}
  `}
`;

/**
 * Title (h1)
 */
export const Title = styled(BaseTitle).attrs(props => ({
  textalignsmall: props.centerTextSmall ? 'center' : 'left',
  min28onmobile: props.min28onmobile
}))`
  line-height: 66px;
  display: inline-block;
  font-stretch: normal;
  letter-spacing: -0.6px;
  font-size: 60px;
  ${({ margin, large }) => (!margin ? `margin-bottom: ${large ? '0.8333rem' : '1rem'};` : '')}
  margin-top: 22px;
  ${media.desktop`
    max-width:100%;
    flex-direction: row;
    text-align: ${props => props.textalignsmall};    
  `};
  ${media.mediumtablet`
    font-size: 36px;
    line-height: 42px;
  `};
`;

/**
 * Title2 (h2)26px
 */
const TempTitle2 = BaseTitle.withComponent('h2');
export const Title2 = styled(TempTitle2).attrs(
  ({
    transform,
    fontSize,
    small,
    medium,
    large,
    mega,
    center,
    nopadding,
    color,
    font,
    centerTextSmall,
    largelineheight,
    displayas,
    weight,
    min28onmobile
  }) => ({
    texttransform: transform ? transform : 'none',
    fontSize: fontSize
      ? fontSize
      : small
      ? '0.75rem'
      : medium
      ? '1.25rem'
      : large
      ? '1.54rem'
      : mega
      ? '2.375rem'
      : '40px',
    textalign: center ? 'center' : 'left',
    padding: nopadding ? '0' : '5rem 0 0 0',
    color: color ? color : Colors.BLACK,
    font: font ? font : fonts.APERCU.WEB,
    textalignsmall: centerTextSmall ? 'center' : 'left',
    lineheight: medium ? '1.03em' : largelineheight ? '1.18' : '46px',
    display: displayas ? displayas : 'block',
    weight: weight ? weight : 'initial',
    min28onmobile
  })
)`
  display: ${({ displayas }) => displayas};
  font-weight: ${({ weight }) => weight};
  font-family: ${props => props.font};
  margin-top: 22px;
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  letter-spacing: 0;
  text-align: ${props => props.textalign};
  text-transform: ${props => props.texttransform};
  line-height: ${props => props.lineheight};
  color: ${props => props.color};
  ${media.desktop`
    flex-direction: row;
    text-align: ${props => props.textalignsmall};    
  `};
  ${media.mediumtablet`
    font-size: 28px;
    line-height: 34px;
  `};
`;

Title2.propTypes = {
  //text color
  color: PropTypes.oneOf(Object.values(Colors)),
  //text should be center?
  center: PropTypes.bool
};

/**
 * Title3 (h3)
 */
const TempTitle3 = BaseTitle.withComponent('h3');
export const Title3 = styled(TempTitle3).attrs(props => ({
  fontSize: props.fontSize
    ? props.fontSize
    : props.small
    ? '0.70rem'
    : props.medium
    ? '1rem'
    : props.large
    ? '1.3'
    : props.mega
    ? '1.375rem'
    : '32px',
  textalign: props.center ? 'center' : 'left',
  padding: props.nopadding ? '0' : '5rem 0 0 0',
  font: props.font ? props.font : fonts.APERCU.WEB,
  margin: props.margin ? props.margin : 0,
  texttransform: props.capitalize ? 'none' : 'uppercase',
  wordbreak: props.breakword ? 'break-word' : props.breakall ? 'break-all' : 'none',
  display: props.displayas ? props.displayas : 'block'
}))`
  display: ${({ display }) => display};
  font-family: ${props => props.font};
  margin-top: 22px;
  margin: ${props => props.margin};
  line-height: 38px;
  font-size: ${props => props.fontSize};
  letter-spacing: 0;
  text-align: ${props => props.textalign};
  word-break: ${props => props.wordbreak};
  text-transform: ${props => props.texttransform};
  color: ${props => (props.color ? props.color : Colors.black)};
  &:first-letter {
    text-transform: ${props => props.texttransform};
  }
  ${media.mediumtablet`
    font-size: 24px;
    line-height: 30px;
  `};
`;

Title3.propTypes = {
  //text color
  color: PropTypes.oneOf(Object.values(Colors)),
  //text should be center?
  center: PropTypes.bool
};

/**
 * Title4 (h4)
 */
const TempTitle4 = BaseTitle.withComponent('h4');
export const Title4 = styled(TempTitle4).attrs(props => ({
  fontSize: props.fontSize
    ? props.fontSize
    : props.small
    ? '0.70rem'
    : props.medium
    ? '.9rem'
    : props.large
    ? '1.3'
    : props.mega
    ? '1.375rem'
    : '24px',
  texttransform: props.capitalize ? 'capitalize' : props.uppercase ? 'uppercase' : 'none',
  textalign: props.center ? 'center' : 'left',
  flex: props.flex ? props.flex : 'initial',
  marginTop: props.marginTop ? props.marginTop : '22px',
  marginBottom: props.marginBottom ? props.marginBottom : '1rem'
}))`
  font-family: ${fonts.LLBrown.BOLD};
  font-size: ${props => props.fontSize};
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0;
  height: ${({ height }) => height && height};
  text-align: ${props => props.textalign};
  text-transform: ${props => props.texttransform};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  flex: ${props => props.flex};
  ${media.mediumtablet`
    font-size: 20px;
    line-height: 26px;
  `};
`;

Title4.propTypes = {
  //text color
  color: PropTypes.oneOf(Object.values(Colors)),
  //text should be center?
  center: PropTypes.bool
};

/**
 * Title5 (h5)
 */
const TempTitle5 = BaseTitle.withComponent('h5');
export const Title5 = styled(TempTitle5).attrs(
  ({ marginTop, flex, marginBottom, fontSize, noCapitalize }) => ({
    marginTop: marginTop ? marginTop : '22px',
    flex: flex ? flex : 'initial',
    marginBottom: marginBottom ? marginBottom : '0.2em',
    fontSize: fontSize ? fontSize : '0.8em',
    capitalize: noCapitalize ? 'none' : 'capitalize'
  })
)`
  font-family: ${fonts.LLBrown.BOLD};
  font-size: ${props => props.fontSize};
  font-weight: bold;
  line-height: 1.03;
  letter-spacing: 0;
  text-align: left;
  text-transform: ${props => props.capitalize};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  flex: ${props => props.flex};
`;

Title5.propTypes = {
  //text color
  color: PropTypes.oneOf(Object.values(Colors)),
  //text should be center?
  center: PropTypes.bool
};
