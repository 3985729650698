import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { zIndex } from '../../../constants/settings';
import PropTypes from 'prop-types';

/**
 * CountyFilterContainer (div)
 */
export const CountyFilterContainer = styled.div.attrs(props => ({
  display: props.active ? 'flex' : 'flex',
  background: props.background ? props.background : Colors.TRANSPARENT,
  align: props.center ? 'center' : 'flex-start'
}))`
  z-index: ${zIndex.MODALS};
  position: relative;
  display: ${props => props.display};
  flex-direction: column;
  justify-content: center;
  perspective: 1000px;
  width: 100%;
  align-items: ${props => props.align};
`;

CountyFilterContainer.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)),
  active: PropTypes.bool
};
