import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const CarouselContainer = styled.div.attrs(props => ({
  marginbottom: props.marginbottom !== undefined ? props.marginbottom : '2rem'
}))`
  display: block;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: ${props => props.marginbottom};
  position: relative;
  ul {
  }
`;

CarouselContainer.propTypes = {
  marginbottom: PropTypes.string
};
