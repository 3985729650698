import styled from 'styled-components/macro';
import { zIndex } from 'constants/settings';
export const Perspective = styled.div.attrs(props => ({
  perspective: props.perspective ? props.perspective : '500px',
  zindex: props.zindex ? props.zindex : zIndex.ABOVE_MODALS
}))`
  perspective: ${props => props.perspective};
  position: relative;
  z-index: ${props => props.zindex};
`;
