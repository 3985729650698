import styled from 'styled-components/macro';
import { Title4 } from 'components/text';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts/fonts';
import { targetBrowser, media } from 'style/media';

export const RelatedBlockHeader = styled(Title4).attrs(() => ({
  fontSize: '0.9rem',
  color: Colors.BLACK
}))`
  margin: 0;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
  display: block;
  line-height: normal;
  font-family: ${fonts.LLBrown.BOLD};
  width: 100%;
  max-width: 100%;
  /* top: 17%; */
  padding: 0 0.5rem;
  ${media.desktop`
    font-size: 18px;
  line-height: normal;

  `};
  ${media.phone`
    font-size: 16px;
  line-height: normal;

  `};

  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 1 100%;
    transform: translateX(-50%);
  `}
`;
