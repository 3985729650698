import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setLoginFormVisibility } from '../accountActions';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';
import LockIcon from 'images/lockicon.svg';
import styled from 'styled-components/macro';
import { LazyLoadImage } from '../../LazyImage';
const Wrapper = styled.button.attrs(props => ({
  display: props.hide ? 'none' : 'flex',
  afterdisplay: props.faded ? 'block' : 'none',
  background: props.faded ? Colors.TRANSPARENT : Colors.TRANSPARENT_ORANGE
}))`
  background: ${props => props.background};
  height: 100%;
  z-index: ${zIndex.MODALS};
  width: 100%;
  cursor: pointer;
  display: ${props => props.display};
  align-items: center;
  justify-content: center;
  position: absolute;
  border: none;
  color: ${Colors.WHITE};
  &:focus {
    outline: none;
  }
  &:after {
    display: ${props => props.afterdisplay};
    content: '';
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 0,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
`;

class LoginToReadMore extends React.Component {
  render() {
    const { account, hide, faded, setLoginFormVisibility, ...rest } = this.props;
    const { showLoginForm, isAuthenticated } = account;
    if (!this.props) return null;
    return (
      <Wrapper
        faded={faded}
        onClick={() => setLoginFormVisibility(!showLoginForm)}
        {...rest}
        hide={hide || isAuthenticated}>
        <LazyLoadImage width={'2rem'} src={LockIcon} />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  account: {
    user: state.account.user,
    isAuthenticated: state.account.isAuthenticated,
    showLoginForm: state.account.showLoginForm
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({ setLoginFormVisibility }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginToReadMore);

LoginToReadMore.propTypes = {
  account: PropTypes.shape({
    user: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    showLoginForm: PropTypes.bool
  }),
  hide: PropTypes.bool
};
