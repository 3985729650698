import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import fonts, { fontSize } from 'style/fonts';

export const Span = styled.span.attrs(props => ({
  color: props.color ? props.color : Colors.BLACK,
  fontWeight: props.normal ? 'normal' : 'bold',
  marginleft: props.pushright ? '1rem' : 0,
  width: props.width ? props.width : '100%'
}))`
  font-size: 1.5em;
  font-weight: ${props => props.fontweight};
  line-height: 1.03;
  text-align: left;
  width: ${props => props.width};
  margin-left: ${props => props.marginleft};
  display: block;
  color: ${props => props.color};
  font-weight: initial;
`;

Span.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)),
  size: PropTypes.string,
  uppercase: PropTypes.bool
};

export const Quote = styled(Span).attrs(props => ({
  margin: `0 ${props.marginright ? props.marginright : '0'} 1.3rem ${
    props.marginleft ? props.marginleft : '0'
  }`
}))`
  color: ${Colors.ORANGE};
  margin: ${props => props.margin};
  font-family: ${fonts.APERCU.WEB};
  transform: translateX(-5rem);
  font-size: 1.333rem;
  ${media.desktop`
    transform: translateX(-.3rem); 
  `};
`;

export const Quote2 = styled(Span)`
  color: ${Colors.ORANGE};
  margin: 1.3rem 1.3rem 1.3rem 6rem;
  padding-right: 2rem;
  font-family: ${fonts.APERCU.WEB};
  transform: translateX(-5rem);
  font-size: 1.333rem;
  ${media.desktop`
  `};
`;

export const Quote3 = styled(Quote2)`
  margin: 1rem 1.3rem 1rem 6rem;
  font-size: 1rem;
`;

export const Quote4 = styled(Quote)`
  font-size: 1.25rem;
`;

export const Quote5 = styled(Quote)`
  font-size: 1rem;
`;

export const SmallText = styled(Span).attrs(props => ({
  font: fonts.LLBrown.REGULAR,
  textalignwhensmall: props.textalignwhensmall
}))`
  font-family: ${props => props.font};
  letter-spacing: 0.1em;
  margin-top: 0.6rem;
  font-size: 0.6rem;
  font-weight: normal;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  ${media.desktop`
    ${({ textalignwhensmall }) =>
      textalignwhensmall &&
      `
      text-align: ${textalignwhensmall};
    `}
  `}
`;

export const MediumText = styled(SmallText)`
  font-family: ${props => props.font};
  letter-spacing: ${props => (props.spacing ? '0em' : '0.1em')};
  margin-top: 0.6rem;
  font-size: 0.9rem;
  font-weight: normal;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`;

export const LargeText = styled(Span).attrs(props => ({
  font: props.brown ? fonts.LLBrown.BOLD : fonts.APERCU.WEB
}))`
  font-family: ${props => props.font};
  font-size: 2em;
  letter-spacing: 0.225rem;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  margin-bottom: 0.7em;
  line-height: 1.25;

  ${media.extraGiant`
    font-size: 1.9em;
  `}

  ${media.giant`
    font-size: 1.5em;
  `}

  ${media.desktop`
    font-weight: bold;
    line-height: 1.04;
    letter-spacing: 2.6px;
    text-align: center;
  `};
`;

/**
 * Paragraph (p)
 * */
export const Paragraph = styled.p.attrs(
  ({
    center,
    padding,
    maxwidth,
    width,
    font,
    light,
    fontSize: fontSizeProp,
    small,
    smallest,
    min14onmobile,
    color,
    nomargin,
    margin,
    marginright,
    marginleft,
    weight,
    lineheight,
    display,
    italic,
    opacity,
    flex,
    centerTextSmall
  }) => ({
    textalign: center ? 'center' : 'left',
    padding: padding ? '0 .2rem 0 .2rem' : 0,
    maxwidth: maxwidth ? maxwidth : 'none',
    width: width ? width : 'inherit',
    font: font ? font : light ? fonts.LLBrown.REGULAR : fonts.LLBrown.REGULAR,
    size: fontSizeProp ? fontSizeProp : small ? '1rem' : smallest ? '16px' : '16px',
    color: color ? color : Colors.BLACK,
    margin: nomargin
      ? 0
      : margin
      ? margin
      : `0 ${marginright ? marginright : '0'} 1.3em ${marginleft ? marginleft : '0'}`,
    weight: weight ? weight : 'normal',
    lineheight: lineheight ? lineheight : small ? '1.4rem' : '24px',
    display: display ? display : 'inline-block',
    fontStyle: italic ? 'italic' : 'normal',
    opacity: opacity ? opacity : 1,
    centerTextSmall: centerTextSmall,
    flex: flex ? flex : 'initial',
    min14onmobile
  })
)`
  width: ${props => props.width};
  opacity: ${props => props.opacity};
  font-family: ${props => props.font};
  display: ${props => props.display};
  font-size: ${props => props.size};
  line-height: ${props => props.lineheight};
  letter-spacing: 0px;
  text-align: ${props => props.textalign};
  color: ${props => props.color};
  font-weight: ${props => props.weight};
  margin-left: ${props => props.leftmargin};
  margin: ${props => props.margin};
  max-width: ${props => props.maxwidth};
  padding: ${props => props.padding};
  font-style: ${props => props.fontStyle};
  flex: ${props => props.flex};
  ${media.desktop`
    line-height: 1.4em;  
    max-width: none;
    text-align: ${props => (props.centerTextSmall ? 'center' : 'left')}
  `};
  ${media.mediumtablet`
    font-size: ${props =>
      props.fontSize
        ? props.fontSize
        : props.small
        ? '1rem'
        : props.smallest
        ? fontSize(14, 16)
        : '14px'};
    
    line-height: ${props =>
      props.lineheight ? props.lineheight : props.small ? '1.4rem' : '20px'};
  `};
  span {
    width: ${props => props.width};
  }
`;

export const ContactOfficePageSmallText = styled(Paragraph).attrs(props => ({
  font: fonts.LLBrown.REGULAR,
  textalignwhensmall: props.textalignwhensmall
}))`
  font-family: ${props => props.font};
  letter-spacing: 0;
  line-height: 1.7;
  margin-top: 0;
  font-weight: normal;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  ${media.desktop`
    ${({ textalignwhensmall }) =>
      textalignwhensmall &&
      `
      text-align: ${textalignwhensmall};
    `}
  `}
`;

export const ImageText = styled(Paragraph)`
  font-size: calc(14px + 0.02vw);
  margin: 0 0 1.3rem 0;
  padding: 0;
  line-height: 0.8rem;
  ${media.desktop`
  font-size: calc(12px + 0.3vw);
  `}
`;

export const MainIntro = styled(Paragraph)`
  font-size: 16px;
  text-align: left;
  line-height: 22px;
  color: ${Colors.MAIN_INTRO};
  ${media.mediumtablet`
    font-size: 16px;
    line-height: 22px;
  `}
`;

export const Ingress = styled(MainIntro).attrs(props => ({
  maxwidth: props.maxwidth ? props.maxwidth : 'none'
}))`
  font-size: 20px;
  line-height: 26px;
  max-width: ${props => props.maxwidth};
  ${media.mediumtablet`
      font-size:18px;
      line-height: 24px;
  max-width: ${props => props.maxwidth};

  `};
`;

export const MainBody = styled(Paragraph).attrs(props => ({
  display: props.block ? 'block' : 'inline-block',
  width: props.width ? props.width : 'inherit',
  fontsize14onsmall: props.fontsize14onsmall
}))`
  width: ${props => props.width};
  font-size: 16px;
  display: ${props => props.display};
  text-align: left;
  line-height: 22px;
  color: ${Colors.MAIN_BODY};

  ${media.mediumtablet`
    font-size: 16px;
    line-height: 22px;
  `};
`;

export const Strong = styled.strong`
  margin-right: 0.2em;
`;

export const ContactText = styled(Strong)`
  ${media.smalltablet`
  display: ${({ show }) => (show ? 'block' : 'none')};
  `}
`;

export const CenteredParagraph = styled(Paragraph)`
  position: relative;
  display: inline-block;
  margin: 0 auto 1rem 4.5rem;
`;

export const HighlightText = styled.strong`
  color: ${Colors.TOTALLY_BLACK};
`;

export const HighlightBackground = styled(HighlightText)`
  background: ${Colors.SUPER_TRANSPARENT_ORANGE};
`;

export const Label = styled.label.attrs(props => ({
  font: props.bold ? fonts.APERCU.WEB : fonts.APERCU.REGULAR
}))`
  font-family: ${props => props.font};
  color: ${({ black }) => (black ? Colors.TOTALLY_BLACK : 'inherit')};
  font-size: ${fontSize(16, 22)};
  //height: 1.3em;
`;

export const AlternativeLabel = styled(Label)`
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: 20px;
`;

export const FormFeedbackMessage = styled(MainBody).attrs(props => ({
  color: props.fail ? Colors.ERROR : 'green'
}))`
  color: ${props => props.color};
  text-align: left;
  margin: 0;
  &:last-of-type {
    margin-bottom: 0rem;
  }
`;

export const NeedToLoginText = styled(MainIntro)`
  margin: 0;
  width: auto;

  ${media.smalltablet`
    text-align: center;
  `}
`;
