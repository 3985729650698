import styled from 'styled-components/macro';
import { LazyBackground } from 'features/LazyImage';
import { colors as Colors } from 'constants/colors';
import { LinkWrapper } from 'components/link';
import { targetBrowser, media } from 'style/media';

export const ImageLinkWrapper = styled(LinkWrapper)`
  display: block;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ImageLinkContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 0.3s cubic-bezier(1, 0.13, 0.4, 0.87);
  transform: translateY(50%) translateY(-1.3rem);
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 1 100%;
    transform: translateY(50%) translateY(-2.3rem);
  `};
`;

export const ImageLinkContainer = styled(LazyBackground).attrs(({ bgdelay }) => ({
  bgdelay: bgdelay
}))`
  transition: 0.34s transform ease, opacity 0.1s ease-in;
  transition-delay: ${({ showdelay = 0 }) => `${showdelay}ms`};
  width: 16.5rem;
  height: 9.5rem;
  color: white;
  display: block;
  margin: calc(1.417rem / 2);
  overflow: hidden;
  transform: scale(${({ show }) => (show ? 1 : 0.8)});
  opacity: ${({ show }) => (show ? 1 : 0)};

  ${media.mediumtablet`
    width: calc(50% - 1.5rem);
    height: calc(9.5rem + 2vw);`}
  ${media.smalltablet`
    width: 90vw;
    max-width: 400px;
    height: calc(0.565 * 90vw);
    max-height: calc(0.565 * 400px);
  `}

  &:before {
    transition: opacity 0.3s ease;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background: ${Colors.MORE_TRANSPARENT_ORANGE};
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    ${ImageLinkContentWrapper} {
      transform: translateY(0);

      ${targetBrowser.IE10ANDHIGHER`
        transform: translateY(-10%);
      `};
    }
  }
`;
