import { FlexContainer } from 'components/flex';
import { Paragraph } from 'components/text/Text';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import styled, { css } from 'styled-components/macro';
import cancel from 'images/cancel-gray.svg';

export const SearchResultLayoutFilterContainer = styled(FlexContainer)`
  flex: 25%;
  margin-left: 1em;
  flex-direction: column;
  min-width: 7em;
`;

export const SearchFilterPillContainer = styled.button`
  display: flex;
  transition: all 0.3s ease;
  border: none;
  box-shadow: none;
  background: ${Colors.GRAY};
  padding: 0.75rem;
  margin-bottom: 1rem;
  cursor: pointer;

  &:disabled {
    cursor: default;
    ${Paragraph} {
      color: ${Colors.TRANSPARENT_DARK_GRAY};
    }
  }

  &:hover,
  &:focus {
    &:not(:disabled) {
      box-shadow: ${`inset 0 0 0 2px ${Colors.ORANGE}`};
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: ${`inset 0 0 0 2px ${Colors.ORANGE}`};

      ${Paragraph} {
        transition: color 0.3s ease;
        text-decoration: underline;
      }
    `};
`;

export const SearchFilterPillLabel = styled(Paragraph)`
  font-weight: bold;
  margin: 0;
`;

export const FilterButtonMobile = styled.button`
  border: none;
  background-color: ${Colors.ORANGE};
  color: ${Colors.WHITE};
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding: 0.75rem 0.8rem;
  margin: 1rem 0;
`;

export const SearchFilterMobileContainer = styled.div`
  z-index: ${zIndex.MODALS};
  right: 0;
  top: 0;
  height: 100vh;
  position: fixed;
  width: 100%;
  background: ${Colors.WHITE};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const SearchFilterCloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: url(${cancel});
  background-size: contain;
  align-self: flex-end;
`;

export const SearchFilterMobileFlex = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
`;
