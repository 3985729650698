import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import { media, targetBrowser } from 'style/media';
export const DropdownWrapper = styled.ul`
  display: flex;
  margin: 0;
  height: 100%;
  padding: 0;
  z-index: ${zIndex.HIGHEST};
`;

export const DropdownFlexWrapper = styled.div.attrs(props => ({
  indexProp: props.indexProp
}))`
  opacity: 0;
  top: 100%;
  position: absolute;
  left: -0.75rem;

  ${targetBrowser.IE10ANDHIGHER`
    left: -1rem;
    right: auto;
  `}
`;

export const DropdownContainer = styled.ul.attrs(props => ({
  bigLinksHeight:
    props.numOfBigLinks < 7
      ? 2 * props.numOfBigLinks
      : props.numOfBigLinks % 2 > 0
      ? props.numOfBigLinks + 1
      : props.numOfBigLinks,
  smallLinksHeight:
    props.numOfBigLinks < 7 ? 0.75 * props.numOfSmallLinks : 0.5 * props.numOfSmallLinks
}))`
  align-content: flex-start;
  background: ${Colors.LESS_TRANSPARENT_WHITE};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 0px 1px 1px 1px rgba(151, 151, 151, 0.31);
  max-height: 24rem;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  // workaround for flexbox bug
  // otherwise the container won't stretch to fit children's width
  // we reset this property in the child element
  flex-flow: row wrap;
  writing-mode: vertical-lr;

  min-height: ${({ bigLinksHeight, smallLinksHeight }) =>
    bigLinksHeight + smallLinksHeight < 24 ? bigLinksHeight + smallLinksHeight : 24}rem;

  //fix for firefox resize bug
  ${targetBrowser.FIREFOX4ANDHIGHER`
    min-height: ${({ bigLinksHeight, smallLinksHeight }) =>
      bigLinksHeight + smallLinksHeight < 24
        ? 0.25 + bigLinksHeight + smallLinksHeight
        : 24}rem;    
  `} 

  //workaround for IE11
  ${targetBrowser.IE10ANDHIGHER`
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
  `};
`;

export const DropdownParent = styled.li`
  display: flex;
  height: 100%;
  position: relative;
  text-decoration: none;
  padding: 0 0.75rem;
  margin: 0;
  align-items: center;
  ${media.bigdesktop`
    padding: 0 0.45rem;
  `}

  ${DropdownFlexWrapper} {
    ${({ show }) => (show ? 'display: block' : 'display: none')};
    opacity: ${({ show }) => (show ? 1 : 0)};
  }
`;

export const DropdownItem = styled.li`
  display: block;
  text-decoration: none;
  white-space: nowrap;
  padding: 0.3rem 1rem;
  // reset because of a workaround for flexbox bug
  // in parent element DropdownContainer
  writing-mode: horizontal-tb;
`;

export const DropdownSmallContainer = styled.ul`
  display: block;
  padding: 0;
`;

export const DropdownSmallItem = styled.li`
  display: block;
  text-decoration: none;
  margin: 0 0 -0.3rem 0;
  padding: 0;
  white-space: nowrap;
`;
