import styled from 'styled-components/macro';
import { media } from 'style/media';

export const PuffListBlockCountyContainer = styled.div`
  width: 50%;
  margin: 2rem 0;

  ${media.desktop`
  margin-left: -1rem;
    margin-right: -1rem;
    width: auto;
    margin-top: 0;
  `};
`;
