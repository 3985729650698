import styled from 'styled-components/macro';

export const DeleteButton = styled.span`
  position: absolute;
  top: -0.35em;
  right: -0.25em;
  cursor: pointer;
  height: 0;
  line-height: 0.5;
  font-size: 1em;
`;
