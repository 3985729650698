import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import { media } from 'style/media';
import { LazyBackground } from 'features/LazyImage';
import DOMHelper from 'utils/DOMHelper';

/**
 * HeroBlockContainer (div)
 */
// const menuHeight = `calc(${settings.MENU_HEIGHT})`;

export const OuterHeroContainer = styled.div``;

export const HeroBlockContainer = styled(LazyBackground).attrs(props => ({
  paddingtop: props.first ? 0 : props.nopadding ? 0 : '2rem',
  bgdelay: 300,
  ismodalopen: props.ismodalopen
}))`
  padding: 0;
  padding-top: ${props => props.paddingtop};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: ${DOMHelper.inEpiEditMode() ? 'auto' : 'calc(100vh - 5.5rem)'};
  min-height: ${DOMHelper.inEpiEditMode() ? 'none' : '770px'};
  max-height: ${DOMHelper.inEpiEditMode() ? 'none' : '940px'};
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  ${media.desktop`
    background-image: none !important;
    background-color: ${Colors.WHITE};
    padding-top: ${props => (props.ismodalopen ? '1rem' : props.first ? '4rem' : '0')};
max-height: none;
min-height: 0;
flex-direction: column;
height: auto;

    .lazy-load-bg__real{
  display: none;
}

    .lazy-load-bg__temp{
  display: none;
}

    .lazy-load-bg__temp--color{
  display: none;
}
`};
`;

export const HeroBlockContainerVideo = styled.div.attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT
}))`
  padding: 0;
  padding-top: ${props => props.paddingtop};
  background: none;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
  height: ${DOMHelper.inEpiEditMode() ? 'auto' : 'calc(100vh - 5.5rem)'};
  min-height: ${DOMHelper.inEpiEditMode() ? 'none' : '770px'};
  max-height: ${DOMHelper.inEpiEditMode() ? 'none' : '940px'};
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  ${media.desktop`
    background-image: none !important;
    background-color: none !important;
    padding-top: 2rem;
    max-height: none;
    min-height: 0;
    flex-direction: column;
    height: auto;

    .lazy-load-bg__real{
      display:none;
    }

    .lazy-load-bg__temp{
      display:none;
    }

    .lazy-load-bg__temp--color{
      display:none;
    }
  `};
`;

export const HeroBlockContainerVideoSmall = styled(HeroBlockContainerVideo)`
  display: none;
  ${media.desktop`
      display: flex;
    `};
`;

HeroBlockContainer.propTypes = {
  first: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([1, 0])]).isRequired,
  gradient: PropTypes.shape({
    direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
    color: PropTypes.oneOf(Object.values(Colors)),
    width: PropTypes.string
  }),
  maxHeight: PropTypes.bool,
  ismodalopen: PropTypes.string
};
