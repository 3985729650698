import * as TYPES from './pageListBlockTypes';
import { fetchPageListBlockItems } from '../../api/SearchApi';
import { isEmpty } from '../../utils';

export const fetchItems = (id, category) => {
  return async dispatch => {
    dispatch({ type: TYPES.PAGE_LIST_BLOCK_FETCH, category });
    try {
      const items = await fetchPageListBlockItems(id, category);

      if (!isEmpty(items)) {
        dispatch({ type: TYPES.PAGE_LIST_BLOCK_FETCHED, items });
      } else {
        dispatch({ type: TYPES.PAGE_LIST_BLOCK_ERROR, message: 'Inga resultat för ' });
      }
    } catch (e) {
      throw e;
    }
  };
};

export const changeActivePage = (id, index = 0) => {
  return async dispatch => {
    dispatch({ type: TYPES.PAGE_LIST_BLOCK_SELECTED_PAGE_CHANGE });
    dispatch({ type: TYPES.PAGE_LIST_BLOCK_SELECTED_PAGE_CHANGED, id, index });
  };
};
