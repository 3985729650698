import styled from 'styled-components/macro';
import { OnlySmall } from 'components/containers/OnlySmall';
import { colors as Colors } from 'constants/colors';

export const HeroBlockTopImage = styled(OnlySmall).attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  filter: props.blur ? 'blur(5px)' : 'blur(0)'
}))`
  transition: background-image 8s ease-in-out, filter 1s ease-in-out;
  filter: ${props => props.filter};
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 15rem;
`;
