import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Title2, Paragraph } from 'components/text';
import { ArrowButton } from 'components/button';
import {
  PrioritizedArticleBlockContainer,
  PrioritizedArticleBlockContentItem,
  PrioritizedArticleBlockContent
} from './components';
import OPE from 'components/common/EpiOnPageEdit';
import { LazyLoadImage } from 'features/LazyImage';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import { SHADOW_TYPES } from 'constants/shadows';
import { Date } from 'components/common/Date';
import { Date as FormattedDate } from 'features/Date';
import { UnderlineContainer, UnderlineContainerText } from 'components/UnderlineContainer';
import fonts from 'style/fonts';
import { uberSmall, reFormat } from 'utils';
import { OnlySmall, OnlyDesktop } from 'components/containers';
import WithAuthentication from 'features/WithAuthentication';
import { LockWhiteCircle, LockPillShape } from 'features/Account/components';

class PrioritizedArticleBlock extends React.Component {
  shouldShow = () => {
    const { prioritizedArticleReference, selectedFilter } = this.props;
    const { pageData } = prioritizedArticleReference || {};
    if (!pageData) return true;
    const { category = [] } = pageData;

    const isInPageCatList = category.some(id => id === selectedFilter);

    return isInPageCatList;
  };

  render() {
    const { image, prioritizedArticleReference, first, locked, hideDate } = this.props;
    const { listData } = prioritizedArticleReference;
    const { date, name, ingress, pageTypeHeader, linkUrl } = listData;
    if (!this.shouldShow()) return null;
    return (
      <PrioritizedArticleBlockContainer nopadding first={first}>
        <PrioritizedArticleBlockContentItem
          mediaSmallOrder={1}
          flexdirection="row"
          shadowType={SHADOW_TYPES.RIGHT}
          zIndex={zIndex.LEVEL_1}
          left
          backgorundcolor={Colors.ORANGE}
          width="63%">
          <PrioritizedArticleBlockContent right>
            <OnlyDesktop>
              {locked && <LockPillShape bgcolor={Colors.WHITE} topright />}
              <UnderlineContainer
                position="bottom"
                color={Colors.WHITE}
                underlinemargin={'0.375rem 0 0 0'}>
                <UnderlineContainerText color={Colors.BLACK} opacity="1">
                  {pageTypeHeader}
                </UnderlineContainerText>
                {!hideDate && (
                  <FormattedDate
                    wrapper={<Date small opacity="0.6" />}
                    date={date}
                    format="D MMMM YYYY"
                  />
                )}
              </UnderlineContainer>
            </OnlyDesktop>
            <OPE propertyName="title">
              <Title2
                fontSize="1.28rem"
                font={fonts.APERCU.WEB}
                medium
                min28onmobile
                margin="0 0 0.9167rem 0"
                centerTextSmall
                color={Colors.TOTALLY_BLACK}>
                {name}
              </Title2>
            </OPE>

            {hideDate ? (
              <OnlySmall flex={true}>
                {locked && <LockWhiteCircle bgcolor={Colors.WHITE} topright />}
                <Paragraph center={true} font={fonts.APERCU.MONO}>
                  {pageTypeHeader}
                </Paragraph>
              </OnlySmall>
            ) : (
              <OnlySmall>
                {locked && <LockWhiteCircle bgcolor={Colors.WHITE} topright />}
                <FormattedDate
                  title={pageTypeHeader}
                  font={fonts.APERCU.MONO}
                  date={date}
                  format="D MMMM YYYY"
                />
              </OnlySmall>
            )}

            <OPE propertyName="text">
              <Paragraph
                font={fonts.LLBrown.REGULAR}
                color={Colors.TOTALLY_BLACK}
                smallest
                min14onmobile
                lineheight="1.57"
                centerTextSmall>
                {ingress}
              </Paragraph>
            </OPE>
            {/* <OPE propertyName="buttonLink">
              <SecondaryButtonLink shadow={SHADOW_TYPES.TWO} to={linkUrl}>
                <OPE propertyName="buttonText">
                  <ButtonText color={Colors.TOTALLY_BLACK} weight="bold">
                    {buttonText}
                  </ButtonText>
                </OPE>
              </SecondaryButtonLink>
            </OPE> */}
          </PrioritizedArticleBlockContent>
          <ArrowButton incornerwhensmall={1} to={linkUrl} />
        </PrioritizedArticleBlockContentItem>
        <PrioritizedArticleBlockContentItem right mediaSmallOrder={0} width="37%">
          <OPE propertyName="image">
            <LazyLoadImage placeholder={uberSmall(image)} src={reFormat(image)} />
          </OPE>
        </PrioritizedArticleBlockContentItem>
      </PrioritizedArticleBlockContainer>
    );
  }
}

const mapStateToProps = ({ filter: { selected } }) => ({
  selectedFilter: selected.id
});

const WithAuth = WithAuthentication(PrioritizedArticleBlock);
export default connect(mapStateToProps)(WithAuth);

PrioritizedArticleBlock.propTypes = {
  first: PropTypes.bool,
  prioritizedArticleReference: PropTypes.shape({
    pageData: PropTypes.object,
    url: PropTypes.string,
    listData: PropTypes.shape({
      ingress: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
      linkUrl: PropTypes.string,
      documentType: PropTypes.string,
      tag: PropTypes.string,
      text: PropTypes.string,
      useFullWidthImage: PropTypes.bool,
      fileType: PropTypes.string,
      pageTypeHeader: PropTypes.string
    })
  }),
  hideDate: PropTypes.bool,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string
};
