import React from 'react';
import PropTypes from 'prop-types';
import { SquareDate } from 'features/SquareDate';
import { ArrowLink, TextWrapper } from 'features/ArrowLink';

export default class CalendarListBlockItem extends React.PureComponent {
  render() {
    const { text, linkUrl, startDate, endDate } = this.props;
    return (
      <ArrowLink to={linkUrl}>
        <SquareDate width="5rem" absolute startdate={startDate} enddate={endDate} format="dd" />
        <TextWrapper pushright={'5.5rem'} medium>
          {text}
        </TextWrapper>
      </ArrowLink>
    );
  }
}
CalendarListBlockItem.propTypes = {
  ingress: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string
};
