import * as TYPES from './hamburgareTypes';

const initialState = {
  expanded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.HAMBURGARE_TOGGLE: {
      return {
        ...state,
        expanded: action.show
      };
    }
    default:
      return state;
  }
};
