import styled from 'styled-components/macro';
import { HamburgareIcon } from 'components/button';
import { media } from 'style/media';
import { isMobile } from 'utils';

const CloseModal = styled(HamburgareIcon).attrs(() => ({
  expanded: true,
  scale: 1.6
}))`
  position: absolute;
  top: 2.25rem;
  right: 2.25rem;
  ${!isMobile.any() &&
    media.desktop`
    top: 2.5rem;
    right: 2.5rem;
  `}
`;

export default CloseModal;
