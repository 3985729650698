import styled from 'styled-components/macro';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';

import { fonts } from 'style/fonts';
import checkMark from 'images/check-mark.svg';

export const Input = styled.input.attrs(props => ({
  zindex: props.ontop ? zIndex.ABOVE_MODALS : 'auto',
  height: props.height ? props.height : '2.8rem',
  width: props.fullwidth ? '100%' : 'auto',
  color: props.isinvalid ? Colors.ERROR : Colors.MAIN_BODY
}))`
  border: ${props => (props.multinetoutline ? '1px solid grey' : 'none')};
  background: ${Colors.WHITE};
  padding: 0.65em;
  position: relative;
  border-radius: ${({ noBorder }) => (noBorder ? 0 : '3px')};
  z-index: ${props => props.zindex};
  width: ${props => props.width};
  font-size: 16px;
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${props => props.color};
  width: 100%;
  height: ${props => props.height};
  &:active,
  &:focus {
    color: ${Colors.MAIN_BODY};
  }

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  &::placeholder {
    text-transform: ${props => (props.nouppercase ? 'capitalize' : 'uppercase')};
    font-size: 0.7em;
  }
`;

export const InputWrapper = styled.span.attrs(props => ({
  checkscale: !props.isinvalid ? 1 : 0,
  displaynone: props.displaynone ? 'none' : 'inline',
  alwaysmarginbottom: props.alwaysmarginbottom ? '0.5417rem' : '0',
  filewrapper: props.filewrapper
}))`
  display: ${props => props.displaynone};
  flex-direction: column;
  align-items: ${({ left }) => (left ? 'flex-start' : 'center')};
  position: relative;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${props => props.alwaysmarginbottom};

  &:not(:last-of-type) {
    margin-bottom: 0.5417rem;
  }

  ${Input} {
    margin-bottom: ${props => props.alwaysmarginbottom};
  }

  &:before {
    overflow: hidden;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6rem;
    width: 2rem;
    height: ${props => (props.filewrapper ? '100%' : 'calc(100% + 1em)')};
    content: '';
    background-image: url(${checkMark});
    transform: scale(${props => props.checkscale});
    opacity: ${props => props.checkscale};
    right: 0;
    top: 0;
    z-index: ${zIndex.LEVEL_1};
  }
`;

export const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
