import { css } from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';

export const UnderLineCss = (args = {}) => css`
  &:after {
    position: absolute;
    content: '';
    bottom: -0.2917rem;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 2.4rem;
    background: ${Colors.ORANGE};
    ${css(args)};
  }
`;
