import styled from 'styled-components/macro';
import { Title2 } from 'components/text';

export const BlockHeader = styled(Title2).attrs(props => ({
  margin: props.center ? '0 auto' : '0 0 .3rem 0'
}))`
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  margin: ${props => props.margin};
`;
