import styled from 'styled-components/macro';
import { OnlySmall } from 'components/containers/OnlySmall';
import { colors as Colors } from 'constants/colors';

export const SubHeroBlockTopImageSubMenu = styled(OnlySmall).attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  filter: props.blur ? 'blur(5px)' : 'blur(0)'
}))`
  transition: background-image 8s ease-in-out, filter 1s ease-in-out;
  filter: ${props => props.filter};
  background: ${props =>
    props.color === Colors.WHITE
      ? props =>
          `linear-gradient(to right, rgba(0,0,0,${props.opacity}), rgba(0,0,0,${
            props.opacity
          })), url(${props.background})`
      : props =>
          props.color === Colors.TOTALLY_BLACK
            ? props =>
                `linear-gradient(to right, rgba(255,255,255,${props.opacity}), rgba(255,255,255,${
                  props.opacity
                })), url(${props.background})`
            : props =>
                `linear-gradient(to right, rgba(0,0,0,${props.opacity}), rgba(0,0,0,${
                  props.opacity
                })), url(${props.background})`} !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 15rem;
`;

export const SubHeroBlockTopImageSubMenuMobile = styled(OnlySmall).attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  filter: props.blur ? 'blur(5px)' : 'blur(0)'
}))`
  transition: background-image 8s ease-in-out, filter 1s ease-in-out;
  filter: ${props => props.filter};
  background: ${props =>
    props.color === Colors.WHITE
      ? props =>
          `linear-gradient(to right, rgba(0,0,0,${props.opacity}), rgba(0,0,0,${
            props.opacity
          })), url(${props.background})`
      : props =>
          props.color === Colors.TOTALLY_BLACK
            ? props =>
                `linear-gradient(to right, rgba(255,255,255,${props.opacity}), rgba(255,255,255,${
                  props.opacity
                })), url(${props.background})`
            : props =>
                `linear-gradient(to right, rgba(0,0,0,${props.opacity}), rgba(0,0,0,${
                  props.opacity
                })), url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* height: 16rem; */
`;

export const SubHeroBlockTopImage = styled(OnlySmall).attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  filter: props.blur ? 'blur(5px)' : 'blur(0)'
}))`
  transition: background-image 8s ease-in-out, filter 1s ease-in-out;
  filter: ${props => props.filter};
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 15rem;
`;
