import styled from 'styled-components/macro';
import { media } from 'style/media';

export const JobListBlockSmallImageContainer = styled.div`
  max-width: 118px;
  max-height: 80px;
  ${media.desktop`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  max-width: 100%;
  max-height: 100%;
`};
`;
