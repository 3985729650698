import { fetchNavigation } from '../../api/ContentApi';
import * as TYPES from './navigationTypes';

export const getMenuItems = () => {
  // if (catid === undefined || catid === null) catid = 2;
  var error = new Error();
  return async dispatch => {
    dispatch({ type: TYPES.NAVIGATION_REQUESTED });
    try {
      const navigationItems = await fetchNavigation();
      dispatch({ type: TYPES.NAVIGATION, value: navigationItems });
    } catch (e) {
      error = new Error(e);
      throw error;
    }
  };
};

export const toggleSearch = () => {
  return async dispatch => {
    dispatch({ type: TYPES.NAVIGATION_TOGGLE_SEARCH });
  };
};

export const setActiveItem = item => {
  return async dispatch => {
    dispatch({ type: TYPES.NAVIGATION_SET_ACTIVE_ITEM, item });
  };
};
