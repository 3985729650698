import styled from 'styled-components/macro';
import React from 'react';
import { NavigationLink } from 'features/Navigation/components';
import { colors as Colors } from 'constants/colors';

const activeClassName = 'nav-item-active';
const SearchIconNavigationLink = styled(NavigationLink).attrs(props => ({
  activeClassName,
  ...props
}))`
  svg #icon {
    fill: ${({ active }) => (active ? Colors.ORANGE : Colors.ORANGE)};
  }

  &.${activeClassName} {
    svg #icon {
      fill: ${Colors.ORANGE};
    }
  }

  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
  &:active,
  &:focus {
    background: none;
    border: none;
    outline: none;
  }
`;

export const SearchIcon = ({ to, button, css, ...props }) => {
  let searchProps = {
    ...props,
    ...(button && { as: 'button' }),
    ...(!button && { to: to }),
    ...(css && { style: css })
  };

  return (
    <SearchIconNavigationLink alternative={1} {...searchProps}>
      <svg
        width="23px"
        height="23px"
        viewBox="0 0 23 23"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <defs />
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="icon" fill="#FC8600">
            <g id="Group-8">
              <g id="Group-9-Copy">
                <path
                  d="M1.71589688,9.55681818 C1.71589688,5.51530682 5.00390357,2.22727273 9.04538125,2.22727273 C13.0868589,2.22727273 16.3748656,5.51530682 16.3748656,9.55681818 C16.3748656,13.5983295 13.0868589,16.8863636 9.04538125,16.8863636 C5.00390357,16.8863636 1.71589688,13.5983295 1.71589688,9.55681818 M15.7555242,15.2298864 C17.0535759,13.6972784 17.8407625,11.7183011 17.8407625,9.55681818 C17.8407625,4.70685795 13.8953011,0.761363636 9.04538125,0.761363636 C4.19546144,0.761363636 0.25,4.70685795 0.25,9.55681818 C0.25,14.4067784 4.19546144,18.3522727 9.04538125,18.3522727 C11.2068462,18.3522727 13.185807,17.5650795 14.7191351,16.2662841 L20.9873101,22.5352443 C21.1302351,22.6781705 21.3178699,22.75 21.5055047,22.75 C21.6931395,22.75 21.8807743,22.6781705 22.0236992,22.5352443 C22.3102821,22.2486591 22.3102821,21.7854318 22.0236992,21.4988466 L15.7555242,15.2298864 Z"
                  id="Fill-1"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SearchIconNavigationLink>
  );
};
