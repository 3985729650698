import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { MainBody, Title2, Title3, Title4 } from 'components/text';
import { fontSize } from 'style/fonts';
import { UnorderedList, OrderedList, ListItem } from 'components/common';
export const CustomAreaBlockContainer = styled.div`
  position: relative;
  padding: 0.3337rem 2.292rem 0.3337rem 0.3337rem;
  display: block;
  width: 100%;
  margin: 0;
  ${MainBody} {
    word-wrap: break-word;
    white-space: normal;
    font-size: ${fontSize(12, 15.5)};
    color: ${Colors.LIGHT_GRAY};
  }
  ${MainBody} {
    word-wrap: break-word;
    white-space: normal;
    font-size: ${fontSize(12, 15.5)};
  }
  ${Title2}, ${Title3}, ${Title4}, ${MainBody}, ${UnorderedList}, ${OrderedList}, ${ListItem} {
    color: ${Colors.LIGHT_GRAY};
  }
`;
