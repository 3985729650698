import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
import { colors as Colors } from 'constants/colors';
import { ErrorHandler, getSearchParams } from '../../utils';
import {
  CountyFilterHeader,
  CountyFilterHeaderBase,
  CountyFilterListContainer,
  CountyFilterContainer,
  CountyFilterHeaderContainer,
  CountyFilterListItem,
  CountyFilterListItemSpan,
  CountyFilterList,
  CountyFilterWrapper
} from './components';
import { Perspective } from 'components/common';
import { Message } from 'components/message';
import { CollapsingArrow } from 'components/images/ArrowDown';
import { CountyFilterPuffList } from './CountyFilterPuffList';
// import { isMobile } from '../../utils';
import {
  setSelectedFilter,
  setCurrentFilter,
  setHover,
  expandCollapse,
  setHeaderHover,
  fetchFilters
} from './countyFilterActions';
import { FadeText } from 'features/FadeText';

class CountyFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.listItems = [];
    this.container = null;
    this.mobileSelect = null;
    this.state = { selectedFromSelect: props.filter.selected.id };
  }

  componentDidMount() {
    const { filter } = this.props;
    // fetchFilters();
    this.collapseList();
    document.addEventListener('click', this.handleClickOutside);

    const categoriesFromUrl = getSearchParams(window.location.href, 'region');
    if (categoriesFromUrl) {
      const filterInUrl = filter.categoryList.find(({ name }) => name === categoriesFromUrl);
      if (!filterInUrl) return;
      this.onItemSelect(filterInUrl);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (!this.props.filter.expanded) return null;
    if (this.container && event.target.contains(this.container)) {
      this.collapseList();
    }
  };

  getCountyList = () => {
    const { filter } = this.props;
    const { categoryList, selected } = filter;
    if (!categoryList || !Array.isArray(categoryList)) return;
    return categoryList.map((county, index) => {
      return (
        <CountyFilterListItem
          onMouseEnter={() => this.onItemMouseEnter(county)}
          onClick={() => this.onItemSelect(county)}
          ref={item => {
            this.listItems[index] = { id: county.id, element: item };
          }}
          key={index}>
          <CountyFilterListItemSpan selected={county.id === selected.id}>
            {county.name}
          </CountyFilterListItemSpan>
        </CountyFilterListItem>
      );
    });
  };

  getPuffCountyList = () => {
    const { filter } = this.props;
    const { categoryList, selected } = filter;
    if (!categoryList || !Array.isArray(categoryList)) return;
    return (
      <CountyFilterPuffList
        categoryList={categoryList}
        selected={selected}
        onItemSelect={i => this.onItemSelect(i)}
      />
    );
  };

  getSelectList = () => {
    const { filter } = this.props;
    const { categoryList } = filter;
    if (!categoryList) return;
    return categoryList.map((county, index) => {
      return <option value={county.id}>{county.name}</option>;
    });
  };

  collapseList() {
    this.props.expandCollapse(false);
    const list = this.list;
    anime({
      targets: list,
      opacity: { value: 0, duration: 100, delay: 300 },
      rotateX: { value: '-90deg', duration: 100 },
      translateX: {
        value: '-50%',
        duration: 0
      },
      easing: 'easeInExpo',
      elasticity: 800
    });
  }

  expandList = () => {
    if (this.props.filter.expanded) return;
    this.props.expandCollapse(true);
    const list = this.list;
    const sectionHeight = list.scrollHeight;

    anime({
      targets: list,
      opacity: { value: 1, duration: 100 },
      height: sectionHeight,
      rotateX: '0deg',
      translateX: {
        value: '-50%',
        duration: 0
      },
      duration: 600,
      elasticity: 200
    });
  };

  toggle() {
    if (!this.props.filter.expanded) {
      this.expandList();
      // if (isMobile.any()) this.mobileSelect.focus();
    }
    if (this.props.filter.expanded) {
      // if (isMobile.any()) this.mobileSelect.blur();
      this.collapseList();
    }
  }

  onItemMouseEnter(item) {
    const { setCurrentFilter, setHover } = this.props;
    setCurrentFilter(item);
    setHover(true);
  }

  onItemMouseLeave() {
    this.props.setHover(false);
  }

  onItemSelect(item) {
    if (!item) return;
    const { setHover, setSelectedFilter } = this.props;
    setHover(false);
    this.collapseList();

    const selectedElement = this.listItems.find(i => {
      return i.id === item.id;
    });

    if (!selectedElement) {
      ErrorHandler.LogErrorSilently(`Item with id '${item.id}' does not exist in the list`);
    }

    let selectedItemRect = selectedElement.element.getBoundingClientRect();
    const position = {
      x: selectedItemRect.left,
      y: selectedItemRect.top
    };
    setSelectedFilter(item, position);
  }

  render() {
    const {
      filter,
      headercolor,
      setHeaderHover,
      header,
      background,
      noHitsText,
      center,
      isPuffBlockFilter
    } = this.props;
    const { expanded, selected, current, headerHover, message } = filter;
    const Categories = this.getCountyList();
    const activeFilter = expanded ? current.name : selected.name;
    const color = headercolor;
    const firstText = (message && noHitsText) || header || 'Aktuellt i ';
    return (
      <>
        <Perspective zIndex="10">
          <CountyFilterWrapper className="CountyFilter">
            <CountyFilterContainer
              background={background}
              center={center}
              ref={container => (this.container = container)}
              active={expanded}>
              <CountyFilterHeaderContainer
                center={center}
                active={expanded}
                ispuffblockfilter={isPuffBlockFilter}>
                <CountyFilterHeaderBase
                  color={color}
                  active={true}
                  ispuffblockfilter={isPuffBlockFilter}>
                  <FadeText color={color}>{firstText}</FadeText>
                </CountyFilterHeaderBase>
                <CountyFilterHeader
                  onMouseEnter={() => setHeaderHover(true)}
                  onMouseLeave={() => setHeaderHover(false)}
                  onClick={() => this.toggle()}
                  cursor="pointer"
                  color={Colors.ORANGE}
                  ispuffblockfilter={isPuffBlockFilter}>
                  <FadeText faded={expanded}>{activeFilter}</FadeText>
                </CountyFilterHeader>
                <CollapsingArrow
                  hover={headerHover}
                  collapsed={!expanded}
                  onClick={() => this.toggle()}
                />
                <Message visible={message}>{message}</Message>
              </CountyFilterHeaderContainer>
            </CountyFilterContainer>
          </CountyFilterWrapper>
          <CountyFilterListContainer
            onMouseLeave={() => this.onItemMouseLeave()}
            ref={list => (this.list = list)}
            active={expanded}
            isPuffBlockFilter={isPuffBlockFilter}>
            <CountyFilterList>{Categories}</CountyFilterList>
          </CountyFilterListContainer>
        </Perspective>

        {isPuffBlockFilter && this.getPuffCountyList()}
      </>
    );
  }
}

const mapStateToProps = ({ filter, app }) => ({
  filter: {
    hover: filter.hover,
    current: filter.current,
    selected: filter.selected,
    loading: filter.loading,
    expanded: filter.expanded,
    last: filter.last,
    headerHover: filter.headerHover,
    categoryList: filter.categoryList,
    message: filter.message
  },
  app: {
    counties: app.counties
  },
  rootCountyId: app.settings.rootCounty
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedFilter,
      setCurrentFilter,
      setHover,
      expandCollapse,
      setHeaderHover,
      fetchFilters,
      push
    },
    dispatch
  );

CountyFilter.defaultProps = {
  filter: {
    categoryList: []
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountyFilter);

CountyFilter.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  header: PropTypes.string,
  headercolor: PropTypes.string,
  lasthitcategory: PropTypes.number
};
