import styled from 'styled-components/macro';

export const HtmlImage = styled.span.attrs(props => ({
  float: props.left ? 'left' : props.right ? 'right' : 'none'
}))`
  position: relative;
  display: inline-block;
  float: ${props => props.float};
  max-width: 100%;
`;
