import Informed from './Informed';
export { Form } from './Form';
export {
  TextInput,
  TextAreaInput,
  Radio,
  RadioGroup,
  Checkbox,
  ValueCheckbox,
  CheckboxGroup,
  Scope,
  AntiForgeryInput
} from './Informed';
export { ErrorMessage } from './ErrorMessage';

export default Informed;
