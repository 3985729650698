import { Logo } from 'components/common/Logo';
import { SearchIcon } from 'components/link/SearchIcon';
import { LoginLogout } from 'features/Account/Login';
import { FilterCart } from 'features/FilterCart';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import fonts from 'style/fonts/';
import { FilterByCounty } from 'utils';
import Dropdown, { DropdownWrapper } from '../Dropdown';
import {
  NavigationContainer,
  NavigationItem,
  NavigationLink,
  NavigationLinkContainer,
  NavigationSearchWrapper,
  SecondNavigationContainer,
  SecondNavigationItem
} from './components';

export class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavAvailable: false,
      mounted: false,
      is404: false
    };
  }

  componentDidMount = () => this.setState({ mounted: true });

  shouldComponentUpdate = (nextProps, nextState) => {
    const { isNavAvailable, is404 } = this.state;
    const { isNavAvailable: nextIsNavAvailable } = nextState;

    const { app, filter } = this.props;
    const { rootCountyId } = app;
    const { selectedId } = filter;

    const { app: nextApp, filter: nextFilter } = nextProps;
    const { rootCountyId: nextRootCountyId } = nextApp;
    const { selectedId: nextSelectedId } = nextFilter;

    if (!isNavAvailable || is404 !== nextProps.is404) return true;
    if (
      nextRootCountyId !== rootCountyId ||
      nextSelectedId !== selectedId ||
      nextIsNavAvailable !== isNavAvailable
    )
      return true;

    return false;
  };

  getMainNavigation() {
    const { isNavAvailable } = this.state;
    const { app, filter } = this.props;
    const { mainNav, rootCountyId } = app;
    const { selectedId, countyCategories } = filter;
    const filteredMain = FilterByCounty(mainNav, selectedId, rootCountyId, countyCategories);
    if (!isNavAvailable || !filteredMain) return null;

    return (
      <DropdownWrapper>
        {filteredMain.map((navItem, index) => {
          return <Dropdown navItem={navItem} indexProp={index} key={navItem.name + index} />;
        })}
      </DropdownWrapper>
    );
  }

  getSmallNavigation() {
    const { isNavAvailable } = this.state;
    const { app, filter } = this.props;
    const { sideNav, rootCountyId } = app || {};
    const { selectedId, countyCategories } = filter;
    if (!isNavAvailable || !sideNav) return null;
    const filteredSideNav = FilterByCounty(sideNav, selectedId, rootCountyId, countyCategories);
    return filteredSideNav.map((navItem, index) => {
      const props = {
        key: index,
        name: navItem.name,
        to: navItem.url[0],
        id: navItem.id,
        pageType: navItem.pageType
      };
      const { pageType, ...restProps } = props;

      const isExternalLink = navItem.isExternalLink ? true : false;

      return (
        <NavigationLink
          orange={1}
          alternative={1}
          spacebetween={1}
          font={fonts.LLBrown.REGULAR}
          external={isExternalLink}
          {...restProps}>
          {props.name}
        </NavigationLink>
      );
    });
  }

  componentDidUpdate(prevProps) {
    const { app, is404 } = this.props;
    const { mainNav, sideNav } = app;
    if (mainNav && sideNav) {
      if (mainNav.length && sideNav.length) this.setState({ isNavAvailable: true, is404 });
    } else if (prevProps && prevProps.is404 !== is404) {
      this.setState({ is404 });
    }
  }

  render() {
    const {
      app: { searchUrl }
    } = this.props;

    const is404 = this.state.is404;

    return (
      <>
        <NavigationContainer id="main-navigation">
          {!is404 && <FilterCart />}
          <NavigationSearchWrapper>
            <SearchIcon searchIcon to={searchUrl} />
          </NavigationSearchWrapper>
          <NavigationItem>
            <NavLink to="/">
              <Logo />
            </NavLink>
          </NavigationItem>
        </NavigationContainer>

        <SecondNavigationContainer>
          <SecondNavigationItem>
            {!is404 && (
              <NavigationLinkContainer>{this.getMainNavigation()}</NavigationLinkContainer>
            )}
            <NavigationLinkContainer right>
              <LoginLogout />
              {!is404 && this.getSmallNavigation()}
              {!is404 && <SearchIcon searchIcon to={searchUrl} />}
            </NavigationLinkContainer>
          </SecondNavigationItem>
        </SecondNavigationContainer>
      </>
    );
  }
}

const mapStateToProps = ({ filter, app, navigation }) => ({
  filter: {
    lastId: filter.last.id,
    selectedId: filter.selected.id,
    countyCategories: filter.categoryList
  },
  app: {
    sideNav: app.sideNav,
    mainNav: app.mainNav,
    rootCountyId: app.settings.rootCounty,
    searchUrl: app.search
  }
});

export default connect(mapStateToProps, null)(Navigation);

Navigation.propTypes = {
  app: PropTypes.shape({
    sideNav: PropTypes.array,
    mainNav: PropTypes.array,
    hamburgerNav: PropTypes.array
  })
};
