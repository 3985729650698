import React from 'react';
import PropTypes from 'prop-types';
import { SquareDateContainer } from './SquareDateContainer';
import format from 'date-fns/format';

export default class SquareDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      start: '',
      end: '',
      width: ''
    };
  }

  componentDidMount() {
    const { startdate, enddate, widthSet } = this.props;
    const start = this.getFormatedDate(startdate);
    const formattedEndDate = this.getFormatedDate(enddate);
    const end = start !== formattedEndDate && formattedEndDate && `-${formattedEndDate}`;
    const width = this.getWidth(end);
    if (widthSet) widthSet(width);
    this.setState({ start: start, end: end, width: width });
  }

  getFormatedDate = date => {
    if (!date) return '';
    return format(new Date(date), 'D');
  };

  getWidth = (endDate = 0, widthSet) => {
    const width = endDate ? '4.5rem' : '4rem';
    return width;
  };

  render() {
    const { height, fontSize, absolute, wide, width: propsWidth, ...rest } = this.props;
    const { start, end, width } = this.state;

    return (
      <SquareDateContainer
        width={propsWidth ? propsWidth : wide ? 'auto' : width}
        absolute={absolute}
        fontSize={fontSize}
        height={height}
        hasEndDate={end ? 1 : 0}
        {...rest}>
        {start}
        {end}
      </SquareDateContainer>
    );
  }
}
SquareDate.propTypes = {
  format: PropTypes.string.isRequired,
  startdate: PropTypes.string.isRequired,
  enddate: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  widthSet: PropTypes.func
};
