import React from 'react';
import styled from 'styled-components/macro';
import { FrequentSearch } from '../../pages/searchpage/components';
import { BlockHeader } from '../components';

const StyledLink = styled.a`
  text-decoration: none;
`;
const ContractCategoryBlockContainer = styled.div`
  padding: 1rem;
`;

export const contractCategoryBlock = ({ header, contractCategoryCollection }) => {
  return (
    <>
      <ContractCategoryBlockContainer>
        <BlockHeader>{header}</BlockHeader>
        {contractCategoryCollection && contractCategoryCollection.length && (
          <FrequentSearch
            pillComponent={StyledLink}
            margin="2rem 0"
            items={contractCategoryCollection.map(({ linkUrl, text }) => ({
              title: text,
              url: linkUrl
            }))}
          />
        )}
      </ContractCategoryBlockContainer>
    </>
  );
};
export default contractCategoryBlock;
