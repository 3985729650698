import React from 'react';
import ReactPlayer from 'react-player';
import { DoubleContainer, DoubleContainerItem } from '../components';
import { colors as Colors } from 'constants/colors';
import PlayIcon from './components/play-icon.svg';
import { VideoContainer, VideoContainerNoText, WideVideoContainerNoText } from './components';
import { IconButton } from 'features/IconButton';
import OPE from 'components/common/EpiOnPageEdit';
import { FlexContainer } from 'components/flex';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import './components/video.css';
import { PageContent } from 'pages/components';

class VideoBlock extends React.PureComponent {
  render() {
    const { youtubeUrl, vimeoUrl, introText, video, videoImage, wideVideo } = this.props;
    return introText === null || introText === '' ? (
      wideVideo ? (
        <WideVideoContainerNoText pageContentWidth wideVideo>
          <ReactPlayer
            className="react-player"
            url={
              youtubeUrl != null
                ? youtubeUrl
                : vimeoUrl != null
                ? vimeoUrl
                : video != null
                ? video
                : null
            }
            controls={true}
            light={videoImage != null ? videoImage : false}
            muted
            playing={this.props.autoPlay}
            alt={this.props.iframeTitle}
            playIcon={<IconButton imageUrl={PlayIcon} neverHover={true} specialIcon={false} />}
            width="100%"
            height="100%"
          />
        </WideVideoContainerNoText>
      ) : (
        <PageContent>
          <VideoContainerNoText pageContentWidth>
            <ReactPlayer
              className="react-player"
              url={
                youtubeUrl != null
                  ? youtubeUrl
                  : vimeoUrl != null
                  ? vimeoUrl
                  : video != null
                  ? video
                  : null
              }
              playing={this.props.autoPlay}
              controls={true}
              light={videoImage != null ? videoImage : false}
              muted
              alt={this.props.iframeTitle}
              playIcon={<IconButton imageUrl={PlayIcon} neverHover={true} specialIcon={false} />}
              width="100%"
              height="100%"
            />
          </VideoContainerNoText>
        </PageContent>
      )
    ) : (
      <FlexContainer backgroundImage={Colors.WHITE} margin="0 0 0.8333rem 0">
        <DoubleContainer
          maxwidth="calc(46.25rem - 0.8333rem)"
          background={Colors.WHITE}
          margin="0 auto">
          <DoubleContainerItem>
            <OPE propertyName="introText" floating={false}>
              <HtmlComponentContainer
                smallmargin="0rem 0rem 1rem 0rem"
                margin="0rem 0rem 1rem 0rem">
                <HtmlComponent html={introText} />
              </HtmlComponentContainer>
            </OPE>
          </DoubleContainerItem>
          <DoubleContainerItem order="2" setPadding="2rem 0 2rem 0" wide row {...this.props}>
            <VideoContainer>
              <ReactPlayer
                url={
                  youtubeUrl != null
                    ? youtubeUrl
                    : vimeoUrl != null
                    ? vimeoUrl
                    : video != null
                    ? video
                    : ''
                }
                className="react-player"
                playing={this.props.autoPlay}
                controls={true}
                light={videoImage != null ? videoImage : false}
                alt={this.props.iframeTitle}
                playIcon={<IconButton imageUrl={PlayIcon} neverHover={true} specialIcon={false} />}
                width="100%"
                height="100%"
              />
            </VideoContainer>
          </DoubleContainerItem>
        </DoubleContainer>
      </FlexContainer>
    );
  }
}

export default VideoBlock;
