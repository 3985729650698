import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { Paragraph } from 'components/text';
import { media } from 'style/media';

export const PuffListBlockItemBottom = styled.div`
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding: 0.2rem 0.3rem;
  background: ${Colors.GRAY};

  ${media.desktop`
  padding: 0.5rem;
  `};
`;

export const PuffListBlockItemBottomHeader = styled(Paragraph)`
  margin: 0.5rem 0 0.2rem;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  display: inline;
  color: ${Colors.ORANGE};
  ${media.mediumtablet`
  margin: 0.5rem 0 0.2rem;
  font-size: 16px;
  `};
`;

export const PuffListBlockItemBottomText = styled(Paragraph)`
  text-align: left;
  color: ${Colors.BLACK};
  line-height: 20px;
  font-size: 16px;

  display: inline;
  ${media.mediumtablet`
  text-align: left;
  color: ${Colors.BLACK};
  line-height: 20px;
  font-size: 16px;
  `};
`;
