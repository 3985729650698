import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 700;
export default class ChangeViewTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    node.style.display = 'block';

    anime({
      targets: node,
      opacity: [0.8, 1],
      translateX: [-50, 0],
      duration: duration,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  onEntering = (node, isAppearing, index) => {};

  onEntered = (node, isAppearing, index) => {};

  onExit = (node, index) => {
    if (!node) return null;
    node.style.display = 'none';
    anime({
      targets: node,
      translateX: [0, '100%'],
      opacity: [1, 0],
      duration: duration,
      easing: 'easeInOutCirc'
    });
  };

  onExiting = (node, index) => {};
  onExited = node => {};

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: duration, exit: duration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        mountOnEnter={true}
        unmountOnExit={true}>
        {children}
      </Transition>
    );
  }
}
ChangeViewTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  children: PropTypes.element
};
