import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

const Gradient = styled.div.attrs(props => ({
  backgroundimage: props.gradient.background ? `url(${props.gradient.background}),` : ''
}))`
  &:before {
    display: ${props => (props.gradient ? 'block' : 'none')};
    content: '';
    width: ${props => (props.gradient ? props.gradient.width : '50%')};
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-image: ${props => props.backgroundimage}
      linear-gradient(
        to ${props => (props.gradient ? props.gradient.direction : '')},
        ${props => (props.gradient ? props.gradient.color : Colors.WHITE)},
        rgba(255, 255, 255, 0)
      );
    filter: contrast(2) opacity(0.9);
  }
`;

/**
 * ActivityBlockContainer (div)
 */
export const ActivityBlockContainer = styled(Gradient).attrs(props => ({
  margin: props.center ? '0 auto' : '0',
  padding: 0,
  paddingtop: '0',
  background: props.background ? props.background : Colors.TRANSPARENT
}))`
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingtop};
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 25rem;
  background-position: center;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
  margin: ${props => props.margin};

  ${media.desktop`
    padding-top:0;
    max-height: none;
    flex-direction: column;
`};
`;

ActivityBlockContainer.propTypes = {
  center: PropTypes.bool,
  first: PropTypes.bool.isRequired,
  gradient: PropTypes.shape({
    direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
    color: PropTypes.oneOf(Object.values(Colors)),
    width: PropTypes.string
  })
};
