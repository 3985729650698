import RequestService from './RequestService';
import queryString from 'querystring';
import * as TYPES from 'features/CountyFilter/countyFilterTypes';
import { LocalStorage } from 'utils';
const API_BASE_URL = `/api/`;
const CONTENT = 'content/';
const PAGE_URL = `${API_BASE_URL}${CONTENT}pages/`;
const APP_URL = `${API_BASE_URL}${CONTENT}app/`;
const NAVIGATION_URL = `${API_BASE_URL}${CONTENT}navigation/`;
const ROUTE_URL = `${API_BASE_URL}${CONTENT}routes/`;
const COUNTY_FILTER_URL = `${API_BASE_URL}filter/county/`;
const CATEGORY_FILTER_URL = `${API_BASE_URL}filter/categories/`;
const COOKIE_SET = `${API_BASE_URL}cookie/set`;
const COOKIE_CLEAR = `${API_BASE_URL}cookie/clear`;

const Storage = new LocalStorage();

/**
 * Gets a pages as json.
 * @param {*The page id to request form Epi} pageId
 */
export async function fetchApp() {
  try {
    const result = RequestService.fetchAsync(APP_URL);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The page id to request form Epi} pageId
 */
export async function fetchPage(pageId) {
  if (pageId === undefined) {
    throw new Error('fetchPage, Page id parameter is required.');
  }
  try {
    const result = RequestService.fetchAsync(`${PAGE_URL}?id=${pageId}`);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The page id to request form Epi} pageId
 */
export async function fetchContent(contentId) {
  if (contentId === undefined) {
    throw new Error('fetchContent, contentId parameter is required.');
  }
  return RequestService.fetchAsync(PAGE_URL + contentId);
}

/**
 * Gets the Epi navigation tree structure.
 * @param {*The page id to request form Epi}
 */
export async function fetchNavigation(catid) {
  try {
    const cookie = Storage.getJSON(TYPES.FILTER_COOKIE);
    const category = catid ? catid : cookie.selected.id || 2;
    const navigation = RequestService.fetchAsync(`${NAVIGATION_URL}?id=${category}`);
    return navigation;
  } catch (e) {
    return e;
  }
}

/**
 * Gets a route object by url
 * @param {*The page url to request form Epi}
 */
export async function fetchRouteByUrl(url) {
  const q = queryString.stringify({
    url: url
  });

  const route = await RequestService.fetchAsync(`${ROUTE_URL}?${q}`);
  return route;
}

/**
 * Gets county filters
 */
export async function fetchCountyFilters() {
  return RequestService.fetchAsync(`${COUNTY_FILTER_URL}`);
}

/**
 * Gets category filters
 */
export async function fetchCategoriesFilter(rootcatid = '', includeroot, isEduadminFilter = false) {
  return RequestService.fetchAsync(
    `${CATEGORY_FILTER_URL}${rootcatid}${`?root=${includeroot}&eduAdmin=${isEduadminFilter}`}`
  );
}

/**
 * Set cookies
 * statistics=${statistics}&
 */
export async function setCookies(setall = false, marketing = false, performance = false) {
  return RequestService.fetchAsync(
    `${COOKIE_SET}${`?marketingStatistics=${marketing}&performance=${performance}&setall=${setall}`}`
  );
}

/**
 * Clear all cookies
 */
export async function clearCookies() {
  return RequestService.fetchAsync(`${COOKIE_CLEAR}`);
}
