/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const duration = 700;
const exitDuration = 300;
export default class MultiTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    const { animationOnEnter } = this.props;
    const { animation, props: animProps } = animationOnEnter;
    animation(node, { ...animProps, duration: duration });
  };

  onEntering = (node, isAppearing, index) => {};

  onEntered = (node, isAppearing, index) => {
    if (!node) return null;
    const { animationOnEntered } = this.props;
    const { animation, props: animProps } = animationOnEntered;
    animation(node, { ...animProps, duration: duration });
  };

  onExit = (node, index) => {
    if (!node) return null;
    const { animationOnExit } = this.props;
    const { animation, props: animProps } = animationOnExit;

    animation(node, animProps);
  };

  onExiting = (node, index) => {};
  onExited = node => {
    const { animationOnExited } = this.props;
    const { animation, props: animProps } = animationOnExited;
    animation(node, { ...animProps, duration: exitDuration });
  };

  render() {
    const { children, transitionkey, unmountOnExit = true, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: duration, exit: exitDuration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        mountOnEnter={true}
        unmountOnExit={unmountOnExit}>
        {children}
      </Transition>
    );
  }
}
MultiTransition.defaultProps = {
  animationOnEnter: () => {},
  animationOnExit: () => {},
  animationOnEntered: {
    animation: () => {},
    props: {}
  },
  animationOnExited: () => {}
};

MultiTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.element.isRequired,
  animationOnEnter: PropTypes.func,
  animationOnEntered: { animation: PropTypes.function, props: PropTypes.shape({}) },
  animationOnExited: PropTypes.func,
  in: PropTypes.bool.isRequired
};
