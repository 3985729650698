import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ArrowImage from 'images/arrow.min.svg';
import ArrowImageWhite from 'images/arrow-white.min.svg';
import { zIndex } from 'constants/settings';
import { media } from 'style/media';

export const ArrowLinkWrapper = styled(NavLink).attrs(props => ({
  background: props.background ? props.background : props.secondary ? Colors.ORANGE : Colors.GRAY,
  right: props.left ? 'auto' : 0,
  left: props.left ? 0 : 'auto',
  padding: props.left ? '0 0 0 1rem' : '0 1rem 0 0',
  translateamount: props.left ? '-0.3rem' : '0.3rem',
  rotateamount: props.left ? '180deg' : 0
  // margin: props => (props.center ? 'auto' : 0),
  // maxwidth: props => (props.center ? 'calc(36.25rem - 0.8333rem * 2)' : 'inherit')
}))`
  background: ${props => props.background};
  margin-bottom: 0.25rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  transform-origin: center center;
  text-decoration: none;
  color: ${Colors.BLACK};
  min-height: 2.333rem;
  max-height: 2.333rem;
  height: 2.3333rem;
  margin-left: ${props => props.margin};
  margin-right: ${props => props.margin};
  max-width: ${props => props.maxwidth};

  &:after,
  &:before {
    content: '';
    height: 100%;
    position: absolute;
    right: ${props => props.right};
    left: ${props => props.left};
    padding: ${props => props.padding};
    top: 0;
    ${media.tablet`
      display: ${({ nosmallarrow }) => (nosmallarrow ? 'none' : 'block')};
    `};
  }
  &:after {
    z-index: ${zIndex.LEVEL_2};
    width: 3rem;
    transition: transform 0.25s ease-in-out;
    background: no-repeat url(${props => (props.secondary ? ArrowImageWhite : ArrowImage)}) center;
    transform: rotateZ(${props => props.rotateamount});
  }
  &:before {
    width: 4rem;
    background-image: linear-gradient(
      to right,
      ${Colors.TRANSPARENT} 0%,
      ${props => props.background} 30%,
      ${props => props.background} 100%
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0.001) 0%,
      ${props => props.background} 30%,
      ${props => props.background} 100%
    );

    z-index: ${zIndex.LEVEL_2};
  }
  &:hover {
    cursor: pointer;
    &:after {
      transform: translateX(${props => props.translateamount})
        rotateZ(${props => props.rotateamount});
    }
  }
`;

ArrowLinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  background: PropTypes.oneOf(Object.values(Colors)),
  secondary: PropTypes.oneOf([1, 0])
};

export const ArrowLinkWrapperAsLink = styled(ArrowLinkWrapper.withComponent('a'))``;
ArrowLinkWrapperAsLink.propTypes = {
  background: PropTypes.oneOf(Object.values(Colors))
};
