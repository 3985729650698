import styled from 'styled-components/macro';
import { Paragraph, Title } from 'components/text/';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const SearchHeroBlockImage = styled.div.attrs(props => ({
  background: props.background
}))`
  background-image: url(${props => props.background});
  min-width: 100%;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 5rem;
  ${media.desktop`
    padding: 2rem;
    `}
  display: flex;
  flex-direction: column;
`;

export const HeroTitle = styled(Title)`
  color: ${Colors.WHITE};
`;

export const PreambleText = styled(Paragraph).attrs(props => ({
  small: true
}))`
  font-size: 0.8333rem;
  line-height: 1.4rem;
  margin: 0 0 1.25rem 0;
  color: ${Colors.WHITE};

  ${media.desktop`
    color: ${Colors.WHITE};
    font-size: max(1rem, calc(13px + 0.3vw));
  `};
`;

export const SearchHeroBlockContainer = styled.div`
  width: 100%;
  min-height: 5rem;
`;
