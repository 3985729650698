import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DateWrapper, DateTitle } from './components';
import format from 'date-fns/format';
import sv from 'date-fns/locale/sv';

const formatDate = format;

class Date extends React.Component {
  getFormatedDate = ({ date, endDate, format }) => {
    if (endDate) {
      const formattedStartDate = formatDate(date, 'DD', { locale: sv });
      const formattedEndDateDay = formatDate(endDate, 'DD', { locale: sv });

      const formattedEndDate = formatDate(endDate, format, { locale: sv });

      return formattedStartDate === formattedEndDateDay
        ? formatDate(date, format, { locale: sv })
        : `${formattedStartDate}-${formattedEndDate}`;
    }

    if (format) return formatDate(date, format, { locale: sv });

    return formatDate(date, 'YYYY-MM-DD', { locale: sv });
  };

  render() {
    const { wrapper, title, font } = this.props;
    const formattedDate = this.getFormatedDate(this.props);
    if (wrapper) {
      return React.cloneElement(
        wrapper,
        {
          ...wrapper.props
        },
        formattedDate
      );
    }

    if (title) {
      return (
        <DateWrapper
          className="Date"
          font={font}
          textalign="center"
          fontsize="10.15px"
          texttransform="uppercase"
          margin="0 0 0.9167rem 0">
          {title && (
            <DateTitle
              displayas="inline-block"
              font={font}
              margin="0 0.5rem 0 0"
              weight="700"
              transform="uppercase">
              {title}
            </DateTitle>
          )}
          {formattedDate}
        </DateWrapper>
      );
    }

    return <DateWrapper className="Date">{formattedDate}</DateWrapper>;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Date);

Date.propTypes = {
  date: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  format: PropTypes.string,
  wrapper: PropTypes.element
};
