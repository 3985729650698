import React from 'react';
import { FlexContainer } from 'components/flex';
import { Paragraph, Title2 } from 'components/text';
import { colors as Colors } from 'constants/colors';

class InstructionBlock extends React.Component {
  render() {
    return (
      <FlexContainer
        borderLeft="20px solid white"
        borderRight="20px solid white"
        minHeight="100%"
        style={{ background: Colors.WHITE }}
        padding={'1rem 1rem 1rem 1.5rem'}
        smallpadding={'1rem 2rem'}
        column>
        <Title2 medium weight={'bold'} style={{ margin: '0 0 0.3rem 0', letterSpacing: '0' }}>
          {this.props.header}
        </Title2>
        <Paragraph smallest margin={'0 0 0.2rem 0'} lineheight={'0.9rem'}>
          {this.props.mainBody}
        </Paragraph>
      </FlexContainer>
    );
  }
}

export default InstructionBlock;
