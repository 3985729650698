import { fetchCategoriesFilter } from '../../api/ContentApi';
import * as TYPES from './filterAreaTypes';
import { LocalStorage } from 'utils';

const Storage = new LocalStorage();

export const setFilter = (rootcatid, includeroot = false, isEduadminFilter = false) => {
  return async dispatch => {
    dispatch({ type: TYPES.FILTER_AREA_REQUESTED });
    try {
      const filters = await fetchCategoriesFilter(rootcatid, includeroot, isEduadminFilter);
      if (!filters) {
        dispatch({ type: TYPES.FILTER_AREA_ERROR });
        return [];
      }

      const cookie = Storage.getJSON(TYPES.FILTER_AREA_COOKIE);
      Storage.set(TYPES.FILTER_AREA_COOKIE, { ...cookie, filters: filters });
      dispatch({ type: TYPES.FILTER_AREA_REQUEST_DONE, filters, rootcatid });

      return filters;
    } catch (e) {
      throw e;
    }
  };
};

export const addSelectedFilter = filter => {
  return async dispatch => {
    const cookie = Storage.getJSON(TYPES.FILTER_AREA_COOKIE);
    const newCookie = { ...cookie, selectedFilters: filter };

    Storage.set(TYPES.FILTER_AREA_COOKIE, newCookie);

    dispatch({ type: TYPES.FILTER_AREA_ADD_SELECTED, filter });
  };
};

export const removeFromSelected = filter => {
  return async dispatch => {
    const cookie = Storage.getJSON(TYPES.FILTER_AREA_COOKIE);
    const newCookie = { ...cookie, selectedFilters: filter };

    Storage.set(TYPES.FILTER_AREA_COOKIE, newCookie);

    dispatch({ type: TYPES.FILTER_AREA_REMOVE_FROM_SELECTED, filter });
  };
};

export const resetFilter = () => {
  return dispatch => {
    Storage.remove(TYPES.FILTER_AREA_COOKIE);
    dispatch({ type: TYPES.FILTER_AREA_RESET });
  };
};
