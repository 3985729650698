import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'components/common/EpiOnPageEdit';
import { colors as Colors } from 'constants/colors';
import { UnderlineContainer } from 'components/UnderlineContainer';
import { getSelectedFromSelectionProperty } from '../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMessage } from 'features/CountyFilter/countyFilterActions';
import BlockArea from 'features/BlockArea';
import { FlexBlock } from '../components';
import { NewsItem, UnderlineContainerText } from './components';
import { fetchNewsItems } from './newsblockActions';
import { ArrowButtonRight, ArrowButtonText } from 'components/button';
class NewsBlock extends React.PureComponent {
  componentDidMount() {
    this.fetchNews();
  }

  fetchNews = async (category = null) => {
    const { id, filter, fetchNewsItems } = this.props;
    await fetchNewsItems(id, category || filter.selected.id || 0);
  };

  async componentDidUpdate(prevProps) {
    const { selected: prevSelected, initialized: prevInitialized } = prevProps.filter;
    const { initialized, selected } = this.props.filter;
    if (prevInitialized !== initialized || prevSelected !== selected) {
      await this.fetchNews();

      const { lastHitCategory, hasError } = this.props.newsblock;

      if (hasError) {
        const cat = this.props.filter.categoryList.find(x => x.id === lastHitCategory);
        this.props.setMessage(`Visar träffar för ${cat.name}`);
      } else {
        this.props.setMessage('', lastHitCategory);
      }
    }
  }

  render() {
    const {
      filterContentArea,
      pageTypeCategory,
      newsblock,
      buttonLink,
      background,
      buttonText,
      domevents
    } = this.props;
    const backgroundColor = getSelectedFromSelectionProperty(background, 'value') || Colors.WHITE;
    const { news } = newsblock;
    const { isSmall } = domevents;

    return (
      <FlexBlock
        wide
        column
        className="Block"
        background={backgroundColor}
        setPadding="2rem 0 4rem 0">
        <UnderlineContainer position="bottom" center={1} centertext color={Colors.ORANGE}>
          <OPE propertyName="heading">
            <UnderlineContainerText center={1} color={Colors.BLACK}>
              {getSelectedFromSelectionProperty(pageTypeCategory)}
            </UnderlineContainerText>
          </OPE>
        </UnderlineContainer>

        <BlockArea propertyName="filterContentArea" nopadding contentarea={filterContentArea} />

        {news &&
          news.map((newsItem, index) => {
            return index === 0 && !isSmall ? (
              <NewsItem isSmall={isSmall} topNews imageleft={true} key={index} {...newsItem} />
            ) : (
              <NewsItem isSmall={isSmall} imageleft={false} key={index} {...newsItem} />
            );
          })}

        <OPE propertyName="buttonLink">
          <ArrowButtonRight
            center={1}
            hastext={buttonText}
            smallbackgroundcolor={Colors.GRAY}
            backgroundcolor={Colors.GRAY}
            to={buttonLink}>
            <ArrowButtonText makeroomforarrow apercu fontsize="0.75rem" smallfontsize="16px">
              {buttonText}
            </ArrowButtonText>
          </ArrowButtonRight>
        </OPE>
      </FlexBlock>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchNewsItems, setMessage }, dispatch);

const mapStateToProps = state => ({
  domevents: {
    isSmall: state.domevents.isSmall
  },
  filter: {
    selected: state.filter.selected,
    categoryList: state.filter.categoryList,
    message: state.filter.message,
    initialized: state.filter.initialized
  },
  newsblock: {
    news: state.newsblock.news
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsBlock);

NewsBlock.propTypes = {
  first: PropTypes.bool,
  buttonLink: PropTypes.string,
  background: PropTypes.array
};
