'use strict';

if (typeof Promise === 'undefined') {
  require('core-js/es/promise');
}
require('whatwg-fetch');
if (typeof Array.prototype.findIndex === 'undefined') {
  require('core-js/features/array/find-index');
}
require('core-js/es/map');
require('core-js/features/array/from');
require('core-js/features/array/flat');
require('core-js/features/array/includes');
require('core-js/features/array/find');
require('core-js/features/array/fill');
require('core-js/features/object/entries');
require('core-js/features/object/assign');
require('core-js/features/object/values');
require('smoothscroll-polyfill').polyfill();
require('core-js/es/symbol');
require('url-polyfill');
require('url-search-params-polyfill');
require('core-js/features/object/keys');
require('core-js/features/string/ends-with');
require('core-js/features/string/includes');
require('core-js/features/string/starts-with');