import { FlexContainer } from 'components/flex';
import { ModalPortal } from 'features/Modal';
import React, { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
  FilterButtonMobile,
  SearchFilterCloseButton,
  SearchFilterMobileContainer,
  SearchFilterMobileFlex
} from './SearchFilterComponents';
import { SearchFilterPill } from './SearchFilterPill';
import { SearchFilterTransition } from './SearchFilterTransition';

const SearchFilterMobile = ({
  clearFilters = () => {},
  filterHitsLabel = '',
  categoryFilterState = [],
  handleFilterClick = () => {},
  filterState = [],
  hitsBeforeFilter = 0
}) => {
  const [show, setShow] = useState(false);
  const closeRef = useRef(null);
  const triggerFilterRef = useRef(null);
  useEffect(() => {
    if (!show) return triggerFilterRef.current.focus();
    closeRef.current.focus();
  }, [show]);

  return (
    <>
      <FilterButtonMobile ref={triggerFilterRef} onClick={() => setShow(true)}>
        {filterHitsLabel}
      </FilterButtonMobile>
      <SearchFilterTransition in={show}>
        <ModalPortal>
          <SearchFilterMobileContainer>
            <FocusLock disabled={!show}>
              <FlexContainer>
                <SearchFilterCloseButton
                  title="Stäng filtrering"
                  ref={closeRef}
                  onClick={() => setShow(false)}
                />
                <SearchFilterMobileFlex>
                  <SearchFilterPill
                    handleFilterClick={clearFilters}
                    item={{ filterName: 'Alla träffar', filterCount: hitsBeforeFilter }}
                    selected={!filterState.length}
                  />
                  {!!categoryFilterState &&
                    categoryFilterState.map((item, ix) => (
                      <SearchFilterPill
                        key={ix}
                        handleFilterClick={handleFilterClick}
                        item={item}
                        selected={filterState.find(x => x.filterId === item.filterId)}
                      />
                    ))}
                </SearchFilterMobileFlex>
                <FilterButtonMobile onClick={() => setShow(false)}>
                  Visa resultat
                </FilterButtonMobile>
              </FlexContainer>
            </FocusLock>
          </SearchFilterMobileContainer>
        </ModalPortal>
      </SearchFilterTransition>
    </>
  );
};

export default SearchFilterMobile;
