import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import fonts from 'style/fonts/fonts';
import { media } from 'style/media';

/**
 * FilterCartItem (div)
 */
export const FilterCartItem = styled.button.attrs(props => ({
  opacity: props.show ? 1 : 1
}))`
  border: none;
  outline: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  opacity: ${props => props.opacity};
  color: ${Colors.BLACK};
  font-size: 0.6em;
  font-family: ${fonts.LLBrown.REGULAR};
  background: ${Colors.WHITE};
  margin-right: 3rem;
  padding: 0.35rem 0.35rem 0.1rem 0.35rem;
  border-bottom: 3px solid ${Colors.ORANGE};
  right: 0.3rem;
  position: absolute;
  z-index: ${zIndex.LEVEL_3};
  display: flex;
  align-items: centeR;
  justify-content: center;

  ${media.desktop`
    top: -3.2rem;
    width: 100%;
    right: 0;
    margin-right: 0;
    height: 3.2rem;
     border-bottom: none; 
  `};
`;

/**
 * FlyingFilterCartItem (div)
 */
export const FlyingFilterCartItem = styled(FilterCartItem)`
  color: ${Colors.ORANGE};
  background: none;
  z-index: ${zIndex.LEVEL_0};
  display: block;
`;

export const FilterCartText = styled.span`
  font-weight: bold;
  letter-spacing: 0.6px;

  ${media.desktop`
    position: relative;
    font-size: 0.8rem;
  `};
`;

export const FilterCartTextContainer = styled.div`
  ${media.desktop`
  top: 25%;
  position: absolute;
  right: 5%;
  `}
  ${media.smalltablet`
    top: 21%;
  `};
  ${media.breakpointSmall`
    top: 10%;
  `};
    `;

FilterCartItem.propTypes = {};

//top: calc(${settings.MENU_HEIGHT} + 0.3rem);
//  text-transform: uppercase;
