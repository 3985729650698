import React from 'react';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const exitDuration = 700;
const enterDuration = 425;
export const SearchFilterTransition = ({ children, transitionkey, in: inProp }) => {
  const onEnter = node => {
    if (!node) return null;

    anime({
      targets: node,
      translateX: ['100%', '0'],
      opacity: {
        value: [0.5, 1],
        delay: enterDuration / 10,
        easing: 'easeOutElastic',
        elasticity: 10
      },
      elasticity: 0,
      easing: 'easeOutQuad',
      duration: enterDuration
    });
  };

  const onExit = node => {
    if (!node) return null;
    anime({
      targets: node,
      translateX: [0, '100%'],
      duration: exitDuration,
      easing: 'easeOutElastic',
      elasticity: 10
    });
  };

  return (
    <Transition
      appear={true}
      enter={true}
      exit={true}
      in={inProp}
      timeout={{ enter: 0, exit: exitDuration }}
      key={transitionkey}
      onEnter={onEnter}
      onExit={onExit}
      mountOnEnter={true}
      unmountOnExit={false}>
      {children}
    </Transition>
  );
};
