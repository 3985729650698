import React from 'react';
import anime from 'animejs';
import { TransitionGroup, Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Title2 } from 'components/text';
import { LinkListItemWrapper } from './LinkListItemWrapper';
import { ArrowLink } from 'features/ArrowLink';

const enterDuration = 500;
const exitDuration = 250;
export default class LinkListItem extends React.Component {
  constructor(props) {
    super(props);
    this.titles = [];
  }

  onEnter = (node, delay) => {
    if (!node) return null;
    anime({
      targets: node,
      opacity: [0.5, 1],
      translateX: [30, 0],
      delay: delay,
      scaleY: [0.5, 1],
      duration: delay,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  onExit = (node, delay) => {
    if (!node) return null;

    anime({
      targets: node,
      opacity: [1, 0],
      translateZ: [0, -10],
      height: 0,
      duration: exitDuration,
      delay: delay,
      scaleY: [1, 0],
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  render() {
    const { subPages = [], header } = this.props;
    if (!subPages.length) return null;
    return (
      <LinkListItemWrapper>
        <Title2 margin="1rem 0 0.25rem 0" medium>
          {header}
        </Title2>
        <TransitionGroup>
          {subPages.map((item, index) => {
            const { linkUrl, imageUrl, documentType, altText, isTopNews, ...rest } = item;

            return (
              <Transition
                appear={false}
                timeout={{
                  enter: enterDuration,
                  exit: exitDuration
                }}
                onEnter={(node, isAppearing) =>
                  this.onEnter(node, isAppearing, enterDuration + index * 10)
                }
                onExit={node => this.onExit(node, exitDuration)}
                key={`${linkUrl}_${index}}`}>
                <ArrowLink to={linkUrl} {...rest} key={index} />
              </Transition>
            );
          })}
        </TransitionGroup>
      </LinkListItemWrapper>
    );
  }
}

LinkListItem.propTypes = {
  header: PropTypes.string,
  subPages: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      ingress: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
      linkUrl: PropTypes.string,
      documentType: PropTypes.string,
      tag: PropTypes.string,
      text: PropTypes.string,
      useFullWidthImage: PropTypes.bool,
      fileType: PropTypes.string,
      isTopNews: PropTypes.bool
    })
  ),
  index: PropTypes.number
};
