import styled from 'styled-components/macro';
import React from 'react';
import { colors as Colors } from 'constants/colors';
import PropTypes from 'prop-types';
import { zIndex } from '../../constants/settings';
import { targetBrowser } from 'style/media';
/**
 * RelatedItemImage (img)
 */

export const RelatedItemImage2 = styled.img.attrs(props => ({
  flex: '1',
  fit: props.isimage ? 'cover' : 'scale-down',
  height: '7.708rem',
  width: '7.083rem'
}))`
  z-index: ${zIndex.LEVEL_2};
  flex: ${({ flex }) => flex};
  object-fit: ${({ fit }) => fit};
  object-position: center;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;

  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
    widtH:100%;
    height:100%;
    font-family: "${`object-fit: ${props => props.fit};`}";
  `};
`;

export const RelatedItemImage = props => {
  return <RelatedItemImage2 {...props} />;
};

RelatedItemImage.propTypes = {
  isimage: PropTypes.bool,
  src: PropTypes.string
};

/**
 * RelatedItemImageContainer (div)
 */
export const RelatedItemImageContainer = styled.div.attrs(props => ({
  background: props.hover && props.round ? Colors.ORANGE : Colors.TRANSPARENT,
  width: props.isimage ? '100%' : '6rem',
  height: props.square ? '6rem' : props.isimage ? '7.708rem' : '6rem',
  borderradius: props.round ? '50%' : '0'
}))`
  transition: background 0.23s ease-in;
  background: ${props => props.background};
  border-radius: ${props => props.borderradius};
  overflow: hidden;
  width: ${props => props.width};
  max-width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height};
  min-height: ${props => props.height};
  max-height: ${props => props.height};
  display: flex;
  align-items: center;

  justify-content: center;
  z-index: ${zIndex.LEVEL_0};
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
    // widtH:100%;
    // max-width:100%;
    // height:100%;
  `};
`;

RelatedItemImageContainer.propTypes = {
  //parent hover state
  hover: PropTypes.bool,
  isimage: PropTypes.bool
};
