import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts';
import { media } from 'style/media';

export const RentCalculationTableWrapper = styled.table`
  display: table;
  flex-direction: row;
  background-color: ${Colors.WHITE};
  width: 100%;
  border-collapse: collapse;
  border: solid 1px ${Colors.RENT_TABLE_GRAY};

  ${media.mediumtablet`
  display: none;
  `};
`;

export const RentCalculationTableHeaderRow = styled.tr`
  border-bottom: solid 1px ${Colors.BLACK};
`;

export const RentCalculationTableRow = styled.tr`
  &:nth-of-type(odd) {
    background-color: ${Colors.RENT_TABLE_GRAY};
  }
`;

export const RentCalculationTableHeader = styled.th`
  padding: 0.5rem 1rem;
  text-align: left;
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: 18px;
  line-height: 22px;
  padding: 0.5rem 1rem;
`;

export const RentCalculationTableData = styled.td`
  font-family: ${fonts.LLBrown.REGULAR};
  padding: 0.5rem 1rem;
  font-size: 16px;
  line-height: 22px;
  width: 15%;
  &:nth-of-type(2) {
    background-color: ${Colors.RENT_TABLE_OVERLAY_ORANGE};
    width: 10%;
  }
`;

export const RentCalculationMobileTableWrapper = styled.div`
  display: none;
  ${media.mediumtablet`
  flex-direction: column;
  background-color: ${Colors.GRAY};
  display: flex;
  `};
`;

export const RentCalculationMobileTableItemContainer = styled.div`
  background-color: ${Colors.WHITE};
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  border: 1px solid ${Colors.RENT_TABLE_GRAY};
`;

export const RentCalculationMobileTableItem = styled.div`
  font-family: ${fonts.LLBrown.REGULAR};
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  margin: 0.5rem 0;
`;

export const RentCalculationMobileTableItemHeader = styled.h3`
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

export const RentCalculationMobileTableItemText = styled.p.attrs(props => ({
  fontColor: props.orange ? Colors.ORANGE : Colors.BLACK
}))`
  font-family: ${fonts.LLBrown.REGULAR};
  margin: 0.2rem 0 0;
  font-size: 18px;
  color: ${props => props.fontColor};
`;
