import styled from 'styled-components/macro';
import { Paragraph } from 'components/text';
import { colors as Colors } from 'constants/colors';
import fonts, { fontSize } from 'style/fonts';
import { media, targetBrowser } from 'style/media';

export const RelatedBlockText = styled(Paragraph).attrs(props => ({
  color: Colors.BLACK
}))`
  transition: transform 0.3s ease;
  text-align: left;
  display: block;
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: ${fontSize(12, 14)};
  margin: 0;
  word-break: break-word;
  padding: 0 0.5rem;
  line-height: 1.29;
  position: relative;
  overflow: hidden;
  max-height: 4.1rem;
  margin-top: 0.3rem;
  ${media.desktop`
  font-size: 14px;
  line-height: 1.1em;

  `};
  ${media.phone`
    top: -2%;
    line-height: 1.1em;
    max-height: 3.9rem;
  `};

  ${targetBrowser.IE10ANDHIGHER`
    transform: translateX(-50%);
    top: calc(50% + 1.9rem);

  `};
`;
