import objectFitImages from 'object-fit-images';
import DOMHelper from './utils/DOMHelper';
import store from './store/store';
// eslint-disable-next-line no-unused-vars
import { App } from './pages/app';
import './index.css';
import Initializer from './initializer';
import registerServiceWorker from './registerServiceWorker';
import { ErrorHandler } from './utils';
import { LocalStorage } from 'utils';
import { getApp, getNavigation } from './pages/app/appActions';
import { fetchFilters } from './features/CountyFilter/countyFilterActions';

const Storage = new LocalStorage();

Storage.setVersion(process.env.VERSION);

store.dispatch(getApp());
store.dispatch(getNavigation());
store.dispatch(fetchFilters());

console.log(`FE-V: ${process.env.VERSION}`);

if (!DOMHelper.inEpiEditMode()) {
  Initializer.setupRavenLogger();
  Initializer.renderApp(store);
  registerServiceWorker();
} else if (document.getElementById('root-edit-mode')) {
  Initializer.renderEditModeApp(store);
  Initializer.renderBlockPreview(store);
}

window.addEventListener('load', function () {
  objectFitImages(null, { watchMQ: true });
  if (ErrorHandler.DebugEnabled()) {
    console.log('LOGS ENABLED');
  }
}
);
