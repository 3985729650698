import styled from 'styled-components/macro';
import { media } from 'style/media';

export const NeedToLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1rem;
  
  ${media.smalltablet`
        align-items: center;
        margin: 0;
    `}
`;
