import * as TYPES from './calendarlistblockTypes';

const initialState = {
  isLoading: false,
  items: [],
  progress: 0,
  lastUpdatedCategory: undefined,
  error: {
    hasError: false,
    message: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CAL_LIST_BLOCK_FETCH: {
      return {
        ...state,
        isLoading: true,
        progress: 25,
        lastUpdatedCategory: action.category
      };
    }
    case TYPES.CAL_LIST_BLOCK_FETCHED: {
      return {
        ...state,
        isLoading: false,
        items: action.items,
        error: { hasError: false, message: '' },
        progress: 100
      };
    }
    case TYPES.CAL_LIST_BLOCK_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: { hasError: true, message: action.message }
      };
    }
    default:
      return state;
  }
};
