import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { media } from 'style/media';

/**
 * OnlyDesktopAndTablet (div)
 */
export const OnlyDesktopAndTablet = styled.div`
  display: block;
  ${media.tablet`
    display: none;
  `};
`;

OnlyDesktopAndTablet.propTypes = {
  bypass: PropTypes.bool
};

/**
 * OnlyDesktopAndTablet (div)
 */
export const Hidden = styled.div`
  opacity: 0;
  height: 0;
  width: 0;
  overflow: none;
  position: absolute;
`;
