import * as TYPES from './domeventsTypes';
import debounce from 'lodash.debounce';
export const setUpDomEvents = () => {
  return dispatch => {
    resizeEvent(dispatch);
  };
};

const onResize = dispatch => {
  const windowWidth = window.innerWidth;
  return dispatch({
    type: TYPES.WINDOW_WIDTH_CHANGED,
    windowWidth
  });
};

const resizeEvent = dispatch => {
  window.addEventListener('resize', debounce(() => onResize(dispatch), 300));
  window.addEventListener('load', () => onResize(dispatch), 300);
};
