import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BlockArea from 'features/BlockArea';
import { ImageLink } from 'components/ImageLink';
import { BlockInner } from '../components';
import { Container } from './components';
import { ShowMore } from 'features/ShowMore';
import { getSelectedValueFromSelectionFactory } from 'utils';
import { BLOCKS } from 'constants/blockTypes';
import { colors as Colors } from 'constants/colors';

class MultipleItemsBlock extends Component {
  constructor(props) {
    super(props);

    let isExtraSmall;
    if (window && window.innerWidth) isExtraSmall = window.innerWidth < 666;

    this.state = {
      linkItems: [],
      maxItems: isExtraSmall ? 2 : 6,
      lastMaxItems: 6,
      isLoading: true,
      showMoreActive: false,
      numberOfLinkItems: 0,
      forceFilter: false,
      fallbackItems: [],
      showMoreClicked: false
    };

    this.initialState = { ...this.state };
  }

  componentDidMount = () => {
    const { rootCounty } = this.props;
    this.filterItems(1);
    const { items } = this.getItemsByCategory(rootCounty);

    this.setState({ fallbackItems: items });
    this.initialState = { ...this.initialState, fallbackItems: items };
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedCountyFilterId } = this.props;
    const { selectedCountyFilterId: prevId } = prevProps;
    const { maxItems, forceFilter } = this.state;
    const { maxItems: prevMaxItems } = prevState;

    if (forceFilter) {
      this.setState({ forceFilter: false });
      this.filterItems();
      return;
    }

    const hasSelectedChanged = selectedCountyFilterId !== prevId;
    if (hasSelectedChanged) {
      this.reset();
      return;
    }
    const hasMaxItemsChanged = maxItems !== prevMaxItems;
    if (!hasMaxItemsChanged && !hasSelectedChanged) return null;
    this.filterItems();
  };

  getItemsByCategory = (countyId = undefined) => {
    const { multipleItemsCountyBlocks = [], selectedCountyFilterId } = this.props;
    const { maxItems } = this.state;
    const idToUse = countyId ? countyId : selectedCountyFilterId;
    if (!multipleItemsCountyBlocks || !multipleItemsCountyBlocks.length)
      return { items: [], totalNumberOfItems: 0 };

    const filteredByCategory =
      multipleItemsCountyBlocks.filter(({ categories }) => categories.includes(idToUse)) || [];

    if (!filteredByCategory.length) return { items: [], totalNumberOfItems: 0 };

    const linkItems = []
      .concat(...filteredByCategory)
      .map(({ linkBlocks }) => linkBlocks)
      .reduce((a, b) => {
        return [...a, ...b];
      });

    const numberOfItems = linkItems.length;

    const spliced = [...linkItems].splice(0, maxItems);

    return { items: spliced, totalNumberOfItems: numberOfItems };
  };

  filterItems = (timeout = 400) => {
    this.setState({ isLoading: true });

    const { items, totalNumberOfItems } = this.getItemsByCategory();

    this.setState({ linkItems: items, numberOfLinkItems: totalNumberOfItems, forceFilter: false });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, timeout);
  };

  handleOnShowMore = () => {
    const { maxItems, numberOfLinkItems } = this.state;
    const nextmaxItems = maxItems + 3;
    if (numberOfLinkItems <= maxItems) return null;
    this.setState({
      maxItems: nextmaxItems,
      lastMaxItems: maxItems,
      showMoreActive: true,
      showMoreClicked: true
    });
  };

  handleOnShowLess = () => {
    //TODO: scroll to top of this block
    this.reset();
  };

  sholdAnimateIn = index => {
    const { lastMaxItems, showMoreActive, isLoading } = this.state;

    if (!showMoreActive) return !isLoading;

    if (index < lastMaxItems) return true;

    if (!isLoading) return true;
    return false;
  };

  reset = () => {
    this.setState({ ...this.initialState, forceFilter: true });
  };

  render() {
    const { filterContentArea, backgroundColor } = this.props;
    const {
      linkItems = [],
      numberOfLinkItems,
      maxItems,
      fallbackItems = [],
      showMoreClicked
    } = this.state;
    const imageLinks = linkItems.length ? linkItems : fallbackItems;
    const selectedBg = getSelectedValueFromSelectionFactory(backgroundColor);
    const bg = selectedBg ? selectedBg : Colors.WHITE;
    return (
      <BlockInner background={bg} overflowvisible>
        <BlockArea nomargin contentarea={filterContentArea} />

        <Container>
          {imageLinks.map((item, index) => {
            const show = this.sholdAnimateIn(index);
            return <ImageLink key={index} show={show} delay={index * 100} {...item} />;
          })}
          {!imageLinks.length && <div>Inga träffar</div>}
        </Container>
        <ShowMore hide={numberOfLinkItems <= maxItems} onClick={this.handleOnShowMore} />
        <ShowMore
          text="Visa färre"
          arrowUp
          hide={!(showMoreClicked && numberOfLinkItems <= maxItems)}
          onClick={this.handleOnShowLess}
        />
      </BlockInner>
    );
  }
}

MultipleItemsBlock.defaultProps = {
  multipleItemsCountyBlocks: []
};

MultipleItemsBlock.propTypes = {
  //TODO lägg till rätt proptypes
  filterContentArea: PropTypes.object,
  id: PropTypes.number.isRequired,
  backgroundColor: PropTypes.any,
  multipleItemsCountyBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.oneOf([BLOCKS.MULTIPLE_ITEMS_COUNTY_BLOCK]),
      linkBlocks: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          ingress: PropTypes.string,
          link: PropTypes.string,
          image: PropTypes.string.isRequired
        })
      )
    })
  ),
  blockType: PropTypes.oneOf([BLOCKS.MULTIPLE_ITEMS_BLOCK]),
  categories: PropTypes.arrayOf(PropTypes.number),
  rootCounty: PropTypes.number
};

const mapStateToProps = ({ filter, app }) => ({
  selectedCountyFilterId: filter.selected.id,
  rootCounty: app.settings.rootCounty
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const ConnectedMultipleItemsBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleItemsBlock);

export { ConnectedMultipleItemsBlock as MultipleItemsBlock };
