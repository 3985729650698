import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { NavLink } from 'react-router-dom';
import ArrowImage from 'images/arrow.min.cop.svg';
//mport { OfficeInfoLi } from 'pages/contactofficepage/components/ContactOfficePageContainer.js';

export const ArrowLinkWrapperCOP = styled(NavLink)`
  margin-bottom: 0rem;
  position: relative;
  display: flex;
  transform-origin: center center;
  text-decoration: none;
  background: no-repeat url(${ArrowImage});
  color: ${Colors.BLACK};
  background-size: 2rem;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  min-height: 2rem;
  max-height: 2rem;
`;