import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts/fonts';
import Polymorph from 'features/Polymorph';
import { targetBrowser } from 'style/media';

/**
 * Unordered list (ul)
 */

const left = 1.1;
export const UnorderedList = styled(Polymorph).attrs(props => ({
  as: 'ul',
  paddingleft: props.nopadding ? 0 : `${left}em`,
  nomarginbottom: props.nopadding ? '0' : '1em'
}))`
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${Colors.DARKER_GRAY};
  margin-top: 0;
  margin-bottom: ${props => props.nomarginbottom};
  list-style-type: none;
  padding-left: ${props => props.paddingleft};
  position: relative;
`;
UnorderedList.propTypes = {
  test: PropTypes.any
};

/**
 * Paragraph (p)
 * */
const lineHeight = '2px';
export const ListItem = styled.li`
  font-size: calc(16px + 0.02vw);
  margin: 0;
  line-height: 1.63em;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 0.7rem;
    height: ${lineHeight};
    background: ${Colors.ORANGE};
    left: ${`-${left}rem`};
    top: calc(1.63em / 2 - 1px);
  }
`;

export const NormalizedListItem = styled.li.attrs(props => ({
  display: props.flex ? 'flex' : props.inlineBlock ? 'inline-block' : 'initial',
  justification: props.flex ? 'center' : 'initial'
}))`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: ${props => props.display};
  ${props => (props.flex ? `align-items: center;` : '')};
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;

ListItem.propTypes = {
  test: PropTypes.any
};

export const OrderedList = styled(UnorderedList).attrs(() => ({ as: 'ol' }))`
  list-style-type: decimal;
  ${ListItem} {
    &:before {
      display: none;
    }
  }
`;
