import React from 'react';
import PropTypes from 'prop-types';
import { fonts } from 'style/fonts';
import { colors as Colors } from 'constants/colors';
import { connect } from 'react-redux';
import { zIndex } from 'constants/settings';
import StyledSelect from './components';

const countyPickerStyles = {
  container: () => {
    const styles = {
      background: Colors.WHITE,
      margin: '0 auto',
      width: '80%',
      overflow: 'visible',
      zIndex: zIndex.HIGHEST,
      maxHeight: '0px'
    };
    return styles;
  },
  indicatorsContainer: (provided, state) => {
    const styles = {
      ...provided,
      cursor: 'pointer'
    };
    return styles;
  },
  indicatorSeparator: (provided, state) => {
    const styles = {
      ...provided,
      background: 'red'
    };
    return styles;
  },
  option: (provided, { isSelected, isFocused }) => {
    const transition = 'background 300ms';
    const styles = {
      ...provided,
      fontFamily: fonts.LLBrown.REGULAR,
      cursor: 'pointer',
      fontSize: '.8rem',
      transition
    };

    return styles;
  },
  placeholder: (provided = {}) => {
    const styles = {
      ...provided,
      color: Colors.BLACK
    };

    return styles;
  },
  control: (provided, { isFocused, isSelected, ...rest }) => {
    const transition = 'background 300ms, border 1s ease';
    const hoverColor = isFocused ? Colors.TRANSPARENT : Colors.WHITE;
    const color = isFocused ? Colors.BLACK : Colors.ORANGE;
    const background = isFocused ? Colors.WHITE : Colors.GRAY;
    const hoverBackground = isFocused ? Colors.WHITE : Colors.TRANSPARENT_ORANGE;
    const indicatorColor = isFocused ? Colors.ORANGE : Colors.ORANGE;
    const styles = {
      display: 'flex',
      fontFamily: fonts.APERCU.WEB,
      fontSize: '.8rem',
      color: color,
      background: background,
      border: `2px solid ${Colors.ORANGE}`,
      borderRadius: '5px',
      padding: 0,
      alignItems: 'center',
      transition,
      '&:hover': {
        background: hoverBackground,
        color: hoverColor
      },
      '.StyledSelect__indicators': {
        svg: {
          color: indicatorColor
        }
      },
      '.StyledSelect__indicator-separator': {
        background: indicatorColor
      }
    };
    return styles;
  },
  noOptionsMessage: provided => {
    const styles = {
      ...provided,
      fontFamily: fonts.APERCU.WEB,
      fontSize: '.75rem',
      background: Colors.WHITE,
      color: Colors.BLACK
    };

    return styles;
  },
  input: (provided, state) => {
    const styles = {
      fontFamily: fonts.APERCU.WEB,
      color: Colors.BLACK,
      width: '100%'
    };

    return styles;
  },
  valueContainer: (provided, test) => {
    const styles = {
      ...provided,
      height: '100%'
    };
    return styles;
  },
  singleValue: ({ color, ...provided }, { isDisabled, ...rest }) => {
    const opacity = isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const styles = {
      ...provided,
      opacity,
      cursor: 'text',
      transition
    };

    return styles;
  },
  menu: (provided = {}) => {
    const styles = {
      ...provided,
      height: 'auto',
      margin: 0,
      borderRadius: '3px',
      border: '1px solid ' + Colors.ORANGE,
      borderStyle: 'none solid solid solid',
      position: 'relative',
      zindex: 9999999999,
      overflow: 'auto'
    };

    return styles;
  },
  menuList: (provided = {}) => {
    const styles = {
      ...provided,
      padding: 0,
      overflow: 'auto'
      // zindex: 99999999999
    };

    return styles;
  }
};

const customStyles = {
  container: () => {
    const styles = {
      background: Colors.WHITE
    };
    return styles;
  },
  indicatorsContainer: (provided, state) => {
    const styles = {
      ...provided,
      cursor: 'pointer'
    };
    return styles;
  },
  indicatorSeparator: (provided, state) => {
    const styles = {
      ...provided,
      background: 'red'
    };
    return styles;
  },
  option: (provided, { isSelected, isFocused }) => {
    const transition = 'background 300ms';
    const styles = {
      ...provided,
      // borderBottom: `1px solid ${Colors.ORANGE}`,
      color: isSelected ? Colors.WHITE : Colors.TEXT_GRAY,
      background: isSelected ? Colors.TRANSPARENT_ORANGE : Colors.TRANSPARENT,
      fontFamily: fonts.LLBrown.REGULAR,
      cursor: 'pointer',
      fontSize: '.8rem',
      transition,
      '&:hover': {
        // color: isSelected
        background: isSelected ? Colors.TRANSPARENT_ORANGE : Colors.SUPER_TRANSPARENT_ORANGE
      }
    };

    return styles;
  },
  placeholder: (provided = {}) => {
    const styles = {
      ...provided,
      color: Colors.WHITE
    };

    return styles;
  },
  control: (provided, { isFocused, isSelected, ...rest }) => {
    const transition = 'background 300ms, border 1s ease';
    const hoverColor = isFocused ? Colors.TRANSPARENT : Colors.WHITE;
    const color = isFocused ? Colors.ORANGE : Colors.ORANGE;
    const background = isFocused ? Colors.WHITE : Colors.ORANGE;
    const hoverBackground = isFocused ? Colors.WHITE : Colors.TRANSPARENT_ORANGE;
    const indicatorColor = isFocused ? Colors.ORANGE : Colors.WHITE;
    const styles = {
      display: 'flex',
      fontFamily: fonts.APERCU.WEB,
      fontSize: '.8rem',
      color: color,
      background: background,
      border: `2px solid ${isFocused ? Colors.ORANGE : 'transparent'}`,
      padding: 0,
      alignItems: 'center',
      // borderBottom: "none",
      transition,
      '&:hover': {
        background: hoverBackground,
        color: hoverColor
      },
      '.StyledSelect__indicators': {
        svg: {
          color: indicatorColor
        }
      },
      '.StyledSelect__indicator-separator': {
        background: indicatorColor
      }
    };

    return styles;
  },
  noOptionsMessage: provided => {
    const styles = {
      ...provided,
      fontFamily: fonts.APERCU.WEB,
      fontSize: '.75rem',
      background: Colors.ERROR,
      color: Colors.WHITE
    };

    return styles;
  },
  input: (provided, state) => {
    const styles = {
      fontFamily: fonts.APERCU.WEB,
      color: Colors.ORANGE,
      width: '100%'
      // background: Colors.ORANGE
    };

    return styles;
  },
  valueContainer: (provided, test) => {
    const styles = {
      ...provided,
      height: '100%'
      // justifycontent: 'center'
    };
    return styles;
  },
  singleValue: ({ color, ...provided }, { isDisabled, ...rest }) => {
    const opacity = isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const styles = {
      ...provided,
      opacity,
      cursor: 'text',
      // color: Colors.WHITE,
      transition
      // display:
    };

    return styles;
  },
  menu: (provided = {}) => {
    const styles = {
      ...provided,
      margin: 0,
      borderRadius: 0,
      position: 'relative'
    };

    return styles;
  },
  menuList: (provided = {}) => {
    const styles = {
      ...provided,
      padding: 0
    };

    return styles;
  }
};

class ReactSelect extends React.Component {
  componentDidUpdate = (prevProps, prevState) => {
    const {
      selected: { value }
    } = this.props;
    const {
      selected: { value: prevValue }
    } = prevProps;
    if (value === prevValue) return null;
  };

  handleChange = value => {
    const { onChange = () => {}, options = [] } = this.props;
    const newValue = value ? value : options[0];
    onChange(newValue);
  };

  render() {
    const { selected, countypicker } = this.props;
    const props = {
      ...this.props,
      value: selected.name ? selected.name : selected,

      noOptionsMessage: () => 'Inga träffar',
      // isClearable: true,
      blurInputOnSelect: false
    };
    return (
      <StyledSelect
        ref={e => (this.selectElement = e)}
        {...props}
        isSearchable={!countypicker}
        placeholder={selected.name}
        styles={countypicker ? countyPickerStyles : customStyles}
        onChange={this.handleChange}
      />
    );
  }
}

ReactSelect.defaultProps = {
  options: [],
  selected: {}
};

const filterItemShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
});

ReactSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  selected: filterItemShape,
  autowidth: PropTypes.bool
};

const mapStateToProps = ({ filter }) => ({
  selected: filter.selected
});

export default connect(mapStateToProps)(ReactSelect);
