import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const RentCalculationBlockWrapper = styled.div`
  background-color: ${Colors.GRAY};
  margin: 0 auto;
  max-width: calc(46.25rem - 1.6666rem);
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  margin-bottom: 2rem;

  ${media.desktop`
    padding: 1.25rem;
    padding-top: 0;
    margin-bottom: 2rem;
  `};
`;
