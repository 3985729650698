import * as TYPES from './cookiebarTypes';
import { CookieHandler as CookieManager } from '../../utils';
import { clearCookies, setCookies } from '../../api/ContentApi';

export const acceptCookie = (setAll = false, marketing = false, performance = false) => {
  return async dispatch => {
    const CookieHandler = new CookieManager(true);
    const accepted = true;

    CookieHandler.set(TYPES.COOKIEBAR_COOKIE, { accepted: accepted, set: true });
    const setComplete = await setCookies(setAll ? true : false, marketing, performance);
    if (setComplete) {
      dispatch({ type: TYPES.COOKIEBAR_COOKIE_SET, accepted });
      window.location.reload(false);
    }
  };
};

export const declineCookie = () => {
  return dispatch => {
    const CookieHandler = new CookieManager();
    const accepted = false;
    CookieHandler.set(TYPES.COOKIEBAR_COOKIE, { accepted: accepted, set: true }, 1);
    dispatch({ type: TYPES.COOKIEBAR_COOKIE_SET, accepted });
  };
};

export const removeCookies = () => {
  return async dispatch => {
    const cookieCleared = await clearCookies();
    if (cookieCleared) {
      dispatch({ type: TYPES.COOKIEBAR_COOKIE_CLEARALL });
    }
  };
};
