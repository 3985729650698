import Raven from 'raven-js';

class ErrorHandler {
  DebugEnabled() {
    const forceLog = document.querySelector('body[data-debug-on]');
    if (process.env.NODE_ENV === 'development' || forceLog) return true;
    return false;
  }

  LogError(e, info) {
    Raven.captureException(e, {
      extra: info
    });
  }

  ConsoleLog(message = '', extra = '', data = null) {
    if (!this.DebugEnabled()) return;
    if (data) console.log(message, extra, data);
    else console.log(message, extra);
  }

  LogErrorSilently(message, errorObject = undefined, extra = {}) {
    Raven.captureException(message, { extra: extra });
    if (!this.DebugEnabled()) return;
    console.error(message);
    if (errorObject) console.error(errorObject);
  }

  LogWarningSilently(message, errorObject = undefined, extra = {}) {
    Raven.captureException(message, { extra: extra, level: 'warning' });
    if (!this.DebugEnabled()) return;
    console.warn(message);
    if (errorObject) console.warn(errorObject);
  }

  AddEventListener() {
    window.onerror = (message, url, line, col, error) => {
      if (!this.DebugEnabled()) {
        console.error(error);
        console.error(url);
        console.error(line, col);
        console.error(message);
      }

      let result = `${message}\n At ${line}:${col} of ${url}`;
      Raven.captureException(result);
    };
  }
}

export default new ErrorHandler();
