import './LLBrownRegular/bold/stylesheet.css';
import './LLBrownRegular/regular/stylesheet.css';
import './apercu/apercu-bold-pro-web/stylesheet.css';
import './apercu/apercu-mono-medium-pro-web/stylesheet.css';
import './apercu/apercu-regular-pro/stylesheet.css';

export default {
  LLBrown: {
    REGULAR: 'LL Brown Regular Web',
    BOLD: 'LL Brown Bold Web'
  },
  BROWN_PRO: {
    BOLD: 'BrownPro-Bold',
    LIGHT: 'BrownPro-Light',
    ITALIC: 'BrownPro-Italic',
    REGULAR: 'BrownPro'
  },
  APERCU: {
    MONO: 'apercu-mono-medium-pro',
    WEB: 'apercu-bold-pro-web',
    REGULAR: 'apercu-regular-pro'
  }
};
