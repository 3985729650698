import React from 'react';
import RentCalculationTable from './RentCalculationTable';
import { CalculateButton, ClearCalcButton, CalcButtonWrapper } from './FormulaButtons';

class FormulaComponents extends React.Component {
  render() {
    return (
      <>
        <CalcButtonWrapper>
          <CalculateButton onClick={this.props.calculateRent}>Beräkna</CalculateButton>
          <ClearCalcButton onClick={this.props.clearTable}>Rensa</ClearCalcButton>
        </CalcButtonWrapper>
        <RentCalculationTable yearList={this.props.yearList} />
      </>
    );
  }
}

export default FormulaComponents;
