import styled from 'styled-components/macro';
// import PropTypes from 'prop-types';

/**
 * LazyLoadImageContainer (div)
 */
export const LazyLoadImageContainer = styled.span.attrs(props => ({
  //Bör vi sätta width till width??
  width: props.width ? 'auto' : '100%',
  height: props.width ? 'auto' : '100%',
  maxwidth: props.maxwidth ? props.maxwidth : 'none',
  maxheight: props.maxheight ? props.maxheight : 'none',
  position: props.asBackground ? 'initial' : 'relative',
  margin: props.centermargin ? '0 auto' : '0'
}))`
  width: ${props => props.width};
  height: ${props => props.height};
  max-width: ${props => props.maxwidth};
  max-height: ${props => props.maxheight};
  position: ${props => props.position};
  overflow: hidden;
  display: block;
  margin: ${({ margin }) => margin};
`;

//LazyLoadImageContainer.propTypes = {};
