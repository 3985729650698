import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { zIndex, settings } from 'constants/settings';
import { media } from 'style/media';
import { LazyBackground } from 'features/LazyImage';
import DOMHelper from 'utils/DOMHelper';
import { MediumText } from 'components/text';

/**
 * SubHeroBlockContainer (div)
 */
// const menuHeight = `calc(${settings.MENU_HEIGHT})`;

export const SubHeroBlockContainer = styled(LazyBackground).attrs(({ nopadding, first }) => ({
  paddingtop: first ? 0 : nopadding ? 0 : '2rem',
  bgdelay: 300
}))`
  padding: 0;
  padding-top: ${props => props.paddingtop};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 22rem;
  max-height: ${DOMHelper.inEpiEditMode() ? 'none' : '500px'};
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  ${media.desktop`
    background-image: none !important;

    padding-top:${settings.MENU_HEIGHT};
    max-height: none;
    min-height: 0;
    flex-direction: column;
    height: auto;

    .lazy-load-bg__real{
      display:none;
    }

    .lazy-load-bg__temp{
      display:none;
    }

    .lazy-load-bg__temp--color{
      display:none;
    }
  `};
`;

export const SubHeroBlockContainerMobile = styled(LazyBackground).attrs(({ nopadding, first }) => ({
  paddingtop: first ? 0 : nopadding ? 0 : '2rem',
  bgdelay: 300
}))`
  padding: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 16rem;
  max-height: ${DOMHelper.inEpiEditMode() ? 'none' : '500px'};
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  ${media.desktop`
    background-image: none !important;
    background-color: ${Colors.WHITE};
    padding-top: 0;
    max-height: none;
    min-height: 0;
    flex-direction: column;
    height: auto;

    .lazy-load-bg__real{
      display:none;
    }

    .lazy-load-bg__temp{
      display:none;
    }

    .lazy-load-bg__temp--color{
      display:none;
    }
  `};
`;

export const SubHeroBlockContainerVideo = styled.div.attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT
}))`
  padding: 0;
  padding-top: ${props => props.paddingtop};
  background: none;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
  height: ${DOMHelper.inEpiEditMode() ? 'auto' : '22rem'};
  min-height: ${DOMHelper.inEpiEditMode() ? 'none' : '22rem'};
  max-height: ${DOMHelper.inEpiEditMode() ? 'none' : '940px'};
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  padding-top: 1rem;
  ${media.desktop`
    background-image: none !important;
    background-color: none !important;
    padding-top: 1.5rem;
    max-height: none;
    min-height: 0;
    flex-direction: column;
    height: auto;

    .lazy-load-bg__real{
      display:none;
    }

    .lazy-load-bg__temp{
      display:none;
    }

    .lazy-load-bg__temp--color{
      display:none;
    }
  `};
`;

export const LinkButton = styled.div`
  display: inline-block;
  margin: 1rem 0 0 0;
  padding: 0.55rem 1.2rem;
  background-color: ${Colors.ORANGE};
  color: ${Colors.BLACK};
  border: solid 2px ${Colors.ORANGE};
  width: fit-content;
  :hover {
    cursor: pointer;
  }
  ${media.breakpointSmall`
  padding:0.55rem 1.2rem;
  margin: 0;
  `}
`;

export const LinkButtonText = styled(MediumText).attrs(props => ({
  modal: props.modal
}))`
  font-size: 0.8rem;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;
  color: ${Colors.WHITE};
  :hover {
    cursor: pointer;
  }
`;

export const SubHeroBlockContainerVideoSmall = styled(SubHeroBlockContainerVideo)`
  display: none;
  ${media.desktop`
      display: flex;
    `};
`;

SubHeroBlockContainer.propTypes = {
  first: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([1, 0])]).isRequired,
  gradient: PropTypes.shape({
    direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
    color: PropTypes.oneOf(Object.values(Colors)),
    width: PropTypes.string
  }),
  maxHeight: PropTypes.bool
};
