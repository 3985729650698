import { keyframes, css } from 'styled-components/macro';

export const animateInKeyframes = keyframes`

  0% {
    transform: translateX(100%);
    opacity: .1;
    
  }

  50%{
    transform: translateX(-30%);    
  }

  100% {
    
    transform: translateX(0);
    opacity: 1;
    
  }
`;

export const animateIn = css`
  ${animateInKeyframes} .35s ease-in forwards;
`;

export const animateOutKeyframes = keyframes`

  0% {
    opacity: 1;
  }

  50%{
    opacity: 0.3;
  }

  100% {
    opacity: 0;
`;

export const animateOut = css`
  ${animateOutKeyframes} .3s ease-in forwards;
`;
