import React from 'react';
import styled from 'styled-components/macro';
import { Input } from './Input';
import { NormalizedListItem } from 'components/common/List';
import filterIcon from 'images/filter.svg';
import cancelIcon from 'images/cancel.svg';
import loaderImage from 'images/loader.min.svg';

import { zIndex } from 'constants/settings';
import { animateIn, animateOut } from 'style/animations';

const FilterListItem = styled(NormalizedListItem).attrs(props => ({
  background: props.cancel ? cancelIcon : filterIcon,
  margin: props.radio ? '0 0 0 1rem' : '0 0 0 0',
  style: props.style,
  as: 'span'
}))`
  overflow: hidden;
  z-index: ${zIndex.LEVEL_1};
  margin: ${props => props.margin};
`;

const Cancel = styled.button.attrs(props => ({
  animation: props.show ? animateIn : animateOut
}))`
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  background: url(${cancelIcon});
  right: 1em;
  background-size: cover;
  transform: translateZ(0);
  z-index: ${zIndex.ABOVE_MODALS + 2};
  animation: ${props => props.animation};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const Filter = styled(Cancel).attrs(props => ({
  display: props.courseListFilter ? 'none' : 'flex'
}))`
  background-image: url(${filterIcon});
  z-index: ${zIndex.ABOVE_MODALS + 1};
  display: ${props => props.display};`;

const Loader = styled(Cancel)`
  background-image: url(${loaderImage});
  z-index: ${zIndex.ABOVE_MODALS + 1};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const FilterInputComponent = styled(Input).attrs(props => ({
  style: props.style,
  isvalid: 1
}))` 
  font-size: 1rem;
  width: auto;
  height: 100%;
`;

export const FilterInput = props => {
  const {
    active = false,
    handleClear = () => {},
    loading = false,
    margin,
    fillwidth,
    style = {},
    radio,
    ontop,
    courseListFilter,
    ...rest
  } = props;

  return (
    <FilterListItem style={style} radio={props.radio} margin={margin} fillwidth={fillwidth} flex>
      <Cancel onClick={handleClear} show={active && !loading} />
      <Filter courseListFilter={courseListFilter} show={!active && !loading} />
      <Loader show={loading} />
      <FilterInputComponent {...rest} style={style} />
    </FilterListItem>
  );
};
