import styled from 'styled-components/macro';
import { media } from 'style/media';
import { zIndex } from '../../constants/settings';

export const ParallaxBlockContentItem = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.LEVEL_0};
  ${media.desktop`
    width:100%;
    align-items: center;    
  `};
`;

export const ParallaxBlockContentItemMobile = styled.div`
  display: table;
  table-layout: fixed;
  height: 12rem;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.LEVEL_0};
`;