import { createStore, applyMiddleware, compose } from 'redux';
//import { routerMiddleware } from 'react-router-redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import logger from 'redux-logger';
import createRootReducer from '../reducers/index';

const inDevMode = false; //process.env.NODE_ENV === 'development';
export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = inDevMode
  ? [thunk, routerMiddleware(history), logger]
  : [thunk, routerMiddleware(history)];

if (inDevMode) {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(createRootReducer(history), initialState, composedEnhancers);

export default store;
