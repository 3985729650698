import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import loaderImage from 'images/loader.min.svg';
import whiteLoaderImage from 'images/loader.white.min.svg';
/**
 * Loader (div)
 */
export const Loader = styled.span.attrs(props => ({
  position: props.absolute ? 'absolute' : 'relative',
  left: props.absolute ? '50%' : 'auto',
  transoformxamount: props.absolute ? '-50%' : 0,
  width: props.width ? props.width : '100%',
  height: props.height ? props.height : '100%',
  minheight: props.height ? 'auto' : '300px',
  size: props.size ? props.size : 'initial'
}))`
  width: ${props => props.width};
  height: ${props => props.height};
  min-height: ${props => props.minheight};
  display: ${props => (props.loaded ? 'none' : 'inline-block')};
  opacity: ${props => (props.loaded ? 0 : 1)};
  background: url(${({ white }) => (white ? whiteLoaderImage : loaderImage)}) no-repeat center;
  background-size: ${props => props.size};
  position: ${props => props.position};
  left: ${props => props.left};
  top: ${props => props.left};
  transform: translateX(${props => props.transoformxamount})
    translateY(${props => props.transoformxamount});
`;

export const Placeholder = styled.span.attrs(props => ({
  opacity: props.hidden ? 0 : 1
}))`
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => props.opacity};
  background: ${Colors.MEDIUM_GRAY};
  width: 100%;
  height: 100%;
`;

Loader.propTypes = {
  //Hide loader if loading is done
  loaded: PropTypes.bool,
  //Should the loader be absolute positioned?
  absolute: PropTypes.bool
};
