import styled from 'styled-components/macro';
import { targetBrowser } from 'style/media';

export const RelatedItemContentWrapper = styled.div.attrs(props => ({
  flex: props.flex ? 1 : 'unset'
}))`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${props => props.flex};
  width: 100%;
  ${targetBrowser.IE10ANDHIGHER`
        flex: 0 0 auto;
    `};
`;
