import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 700;
const exitDuration = 250;
export default class ChangeNameTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    const { animateInStarted, transitionkey } = this.props;
    animateInStarted(transitionkey);
    anime({
      targets: node,
      opacity: {
        value: [0, 1],
        easing: [0, 0.6, 0, 1]
      },
      translateY: ['3rem', 0],
      rotateX: [-anime.random(25, 35), 0],
      duration: duration,
      delay: () => anime.random(0, 100),
      elasticity: 800,
      easing: [0.91, -0.54, 0.29, 1.56]
    });
  };

  onExit = (node, index) => {
    if (!node) return null;
    const { animateOutComplete, transitionkey } = this.props;
    anime({
      targets: node,
      rotateX: [0, -85],
      opacity: [1, 0],
      duration: exitDuration,
      easing: 'easeInOutCirc',
      complete: () => animateOutComplete(transitionkey)
    });
  };

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        childFactory={React.cloneElement}
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: duration, exit: duration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onExit={this.onExit}
        mountOnEnter={true}
        unmountOnExit={true}>
        {children}
      </Transition>
    );
  }
}

ChangeNameTransition.defaultProps = {
  animateOutComplete: () => {},
  animateInStarted: () => {}
};

ChangeNameTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element,
  animateOutComplete: PropTypes.func,
  animateInStarted: PropTypes.func
};
