import styled from 'styled-components/macro';
// import PropTypes from 'prop-types';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
/**
 * Main (main)
 */
export const MainContainer = styled.main`
  background: ${Colors.WHITE};
  position: relative;
  z-index: ${zIndex.LEVEL_1};
  /* ${media.desktop`
  top: -2rem;
  `}; */
`;
