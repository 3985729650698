import RequestService from './RequestService';
import { array2SearchParams, array2SearchCategoryParams } from '../utils';
import queryString from 'query-string';

const SEARCH_BASE_URL = `/api/search/`;
const SEARCH_BLOCK_URL = `${SEARCH_BASE_URL}pagelistblock/`;
const SEARCH_ACTIVITY_BLOCK_URL = `${SEARCH_BASE_URL}activityblock/`;
const SEARCH_NEWS_BLOCK_URL = `${SEARCH_BASE_URL}newsblock/`;
const SEARCH_LIST_BLOCK_URL = `${SEARCH_BASE_URL}listblock/`;
const SEARCH_PUFF_LIST_BLOCK_URL = `${SEARCH_BASE_URL}pufflistblock/`;
const SEARCH_COURSE_LIST_BLOCK_URL = `${SEARCH_BASE_URL}courselistblock/`;
const SEARCH_LINK_LIST_BLOCK_URL = `${SEARCH_BASE_URL}linklistblock/`;
const SEARCH_CONTACT_LIST_BLOCK_URL = `${SEARCH_BASE_URL}contacts`;
const SEARCH_EVENT_BLOCK_URL = `${SEARCH_BASE_URL}events/`;
const SEARCH_CONTENT_URL = `${SEARCH_BASE_URL}content`;
const SEARCH_AUTOCOMPLETE_URL = `${SEARCH_BASE_URL}autocomplete/`;
const SEARCH_COURSES = `${SEARCH_BASE_URL}courses/`;
const SEARCH_RELATED_MATERIAL_BLOCK_URL = `${SEARCH_BASE_URL}relatedmaterialblock/`;
const SEARCH_JOB_AD_PAGES_URL = `${SEARCH_BASE_URL}jobadpages/`;

/**
 * Gets autocomplete for query
 * @param {*search query } query
 */
export async function fetchAutocomplete(query = '') {
  if (query === undefined) {
    throw new Error('fetchAutocomplete, query is required.');
  }
  try {
    const q = queryString.stringify({
      q: query
    });

    const url = `${SEARCH_AUTOCOMPLETE_URL}?${q}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*search query } query
 */
export async function search(query, categories, sort, take = undefined, skip = undefined) {
  if (query === undefined) {
    throw new Error('SearchApi, Page id parameter is required.');
  }
  try {
    const filterIds = array2SearchCategoryParams(categories, 'categories', o => o.filterId);

    const q = queryString.stringify({
      q: query,
      take: take,
      skip: skip
    });

    const url = `${SEARCH_CONTENT_URL}?${q}${filterIds !== 0 ? filterIds : ''}${
      sort ? `&sort=${sort}` : ''
    }`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*search query } query
 */
export async function searchCourses(query = '') {
  try {
    const url = `${SEARCH_COURSES}?${query}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 */
export async function fetchPageListBlockItems(blockId, category) {
  if (blockId === undefined) {
    throw new Error('fetchPageListBlockItems, Page id parameter is required.');
  }
  try {
    const url = `${SEARCH_BLOCK_URL}${blockId}/${category}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 */
export async function fetchNewsBlockItems(blockId, category) {
  if (blockId === undefined) {
    throw new Error('fetchNewsBlockItems, Page id parameter is required.');
  }
  try {
    const url = `${SEARCH_NEWS_BLOCK_URL}${blockId}/${category}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 * @param {*Array of category id's } categories
 */
export async function fetchRelatedMaterialBlockItems(blockId, countyCat, categories) {
  if (blockId === undefined) {
    throw new Error('fetchRelatedMaterialBlockItems, Block id parameter is required.');
  }
  try {
    const filterIds = array2SearchParams(categories, 'categoryids', o => o.value);

    const url = `${SEARCH_RELATED_MATERIAL_BLOCK_URL}${blockId}/${countyCat}${
      filterIds !== 0 ? filterIds : ''
    }`;

    const result = RequestService.fetchAsync(url);

    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 */
export async function fetchActivityBlockItems(blockId, category) {
  if (blockId === undefined) {
    throw new Error('fetchActivityBlockItems, Page id parameter is required.');
  }
  try {
    const url = `${SEARCH_ACTIVITY_BLOCK_URL}${blockId}/${category}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 * @param {*Array of category id's } categories
 */
export async function fetchLinkListBlockItems(blockId, countyCat, categories = []) {
  if (blockId === undefined) {
    throw new Error('fetchLinkListBlockItems, Block id parameter is required.');
  }
  try {
    const filterIds = array2SearchParams(categories, 'categoryids', o => o.id);
    const url = `${SEARCH_LINK_LIST_BLOCK_URL}${blockId}/${countyCat}${
      filterIds !== 0 ? filterIds : ''
    }`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 * @param {*Array of category id's } categories
 */
export async function fetchPuffListBlockItems(blockId, countyCat, categories = []) {
  if (blockId === undefined) {
    throw new Error('fetchPuffListBlockItems, Block id parameter is required.');
  }
  try {
    const filterIds = array2SearchParams(categories, 'categoryids', o => o.id);
    const url = `${SEARCH_PUFF_LIST_BLOCK_URL}${blockId}/${countyCat}${
      filterIds !== 0 ? filterIds : ''
    }`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

export async function fetchCourseListItems(blockId, countyCat, categories = []) {
  if (blockId === undefined) {
    throw new Error('fetchCourseListBlockItems, Block id parameter is required.');
  }
  try {
    const filterIds = array2SearchParams(categories, 'categoryids', o => o.id);
    const url = `${SEARCH_COURSE_LIST_BLOCK_URL}${blockId}/${countyCat}${
      filterIds !== 0 ? filterIds : ''
    }`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 * @param {*Array of category id's } categories
 */
export async function fetchContacts(blockId, countyCat, categories = [], sort, fromParent = false) {
  if (blockId === undefined) {
    throw new Error('fetchContacts, Block id parameter is required.');
  }
  try {
    const mappedCategories = categories.map(({ id }) => id);

    const query = {
      categoryids: mappedCategories,
      sort: sort,
      fromParent: fromParent
    };

    const url = `${SEARCH_CONTACT_LIST_BLOCK_URL}/${blockId}/${countyCat}?${queryString.stringify(
      query
    )}`;

    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 * @param {*Array of category id's } categories
 */
export async function fetchListBlockItems(blockId, countyCat, categories = []) {
  if (blockId === undefined) {
    throw new Error('fetchListBlockItems, Block id parameter is required.');
  }
  if (!countyCat) {
    throw new Error('fetchListBlockItems, Block id parameter is required.');
  }
  try {
    const filterIds = categories.reduce((prevVal, currVal, idx) => {
      return idx === 0 ? `?categoryids=${currVal.id}` : `${prevVal}&categoryids=${currVal.id}`;
    }, 0);
    const url = `${SEARCH_LIST_BLOCK_URL}${blockId}/${countyCat}${
      filterIds !== 0 ? filterIds : ''
    }`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*The block id to request form Epi} blockId
 * @param {*The category id to request form Epi} category
 */
export async function fetchEventItems(blockId, category) {
  if (blockId === undefined) {
    throw new Error('fetchEventItems, Block id parameter is required.');
  }
  try {
    const url = `${SEARCH_EVENT_BLOCK_URL}${blockId}/${category}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*search query } query
 */
export async function fetchJobAdPages() {
  try {
    const url = `${SEARCH_JOB_AD_PAGES_URL}`;
    const result = RequestService.fetchAsync(url);
    return result;
  } catch (e) {
    throw e;
  }
}
