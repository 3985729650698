import styled from 'styled-components/macro';
import { Paragraph } from '../../text';
import { colors as Colors } from 'constants/colors';
import fonts, { fontSize } from 'style/fonts';
import { media, targetBrowser } from 'style/media';

export const ImageLinkText = styled(Paragraph).attrs(props => ({
  color: Colors.WHITE
}))`
  transition: transform 0.3s ease;
  text-align: center;
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: ${fontSize(12, 14)};
  margin: 0;
  line-height: 1.29;
  letter-spacing: 0.3px;
  margin-top: calc(1.8rem / 2 - 1.29em / 2);
  max-width: 70%;
  width: 70%;
  position: absolute;
  top: calc(50% + 0.9rem);
  ${media.desktop`
    max-widtH: 80%;
    text-align: center;
  `};

  ${targetBrowser.IE10ANDHIGHER`
    transform: translateX(-50%);
    top: calc(50% + 1.9rem);

  `};
`;
