import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { colors as Colors } from 'constants/colors';
import fonts, { fontSize } from 'style/fonts';

export const ImageDescriptor = styled.span.attrs(props => ({
  display: props.hide ? 'none' : 'block'
}))`
  background: ${Colors.DARK_GAY};
  display: ${props => props.display};
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: ${fontSize(11, 14)};
  text-transform: uppercase;
  padding-right: 1rem;
  font-size: ${fontSize(10, 12)};
  text-align: right;
  font-family: ${fonts.LLBrown.REGULAR};
  width: 100% !important;
`;

ImageDescriptor.propTypes = {
  title: PropTypes.string
};
