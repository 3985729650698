// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components/macro';


//The size mediumtablet is used for almost all general text components (p/h1/h2/h3/h4/ingress etc) as breakpoint to switch between large/small font sizes
//Since all font sizes were changed to px for most of the text components as a design request.
//Be careful not to remove or change it too much without double-checking the results of these texts :)
export const sizes = {
  carousel4K: 2280,
  huge: 1921,
  extraGiant: 1440,
  giant: 1300,
  biggerdesktop: 1240,
  bigdesktop: 1140,
  desktop: 992,
  mediumtablet: 777, //Please read above if you intend to change this
  tablet: 768,
  smalltablet: 666,
  breakpointSmall: 400,
  phone: 376
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const browsers = {
  //IE10+
  IE10ANDHIGHER: 'screen and (-ms-high-contrast: active), (-ms-high-contrast: none)',
  //IE9 & 10
  IE9AND10: 'screen and (min-width:0\0)',
  SAFARI6TO10: 'screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)',
  FIREFOX4ANDHIGHER: 'screen and (min--moz-device-pixel-ratio:0)'
};

export const ifCond = () => { };

// iterate through the sizes and create a media template
export const targetBrowser = Object.keys(browsers).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/

  accumulator[label] = (...args) => css`
    @media ${browsers[label]} {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
