import React from 'react';
import { CategoryTagContainer, Tag } from './CategoryLink';

const CategoryTag = ({ category = '' }) => {
  return (
    <CategoryTagContainer>
      <Tag />
      {category}
    </CategoryTagContainer>
    // <CategoryLinkWithIcon icon={tagIcon} href={category}>
    //   {category}
    // </CategoryLinkWithIcon>
  );
};

export default CategoryTag;
