import styled from 'styled-components/macro';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';
import { SecondaryButton, ButtonText } from 'components/button';
import { fonts } from 'style/fonts';
import { media } from 'style/media';
import { MainBody } from 'components/text';

import { InternalLink, ExternalLink } from 'components/link';
export const CookieBarContainer = styled.div`
  position: fixed;
  display: flex;
  background: ${Colors.COOKIE_BACKGROUND};
  border-top: 1px solid ${Colors.COOKIE_BORDER};
  width: 100%;
  bottom: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: ${zIndex.ABOVE_MODALS};
`;

export const CookieBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0.8333rem;
  max-width: calc(46.25rem - 0.8333rem);
  ${InternalLink}, ${ExternalLink} {
    color: ${Colors.ORANGE};
    text-decoration: underline;
  }
  & > div {
    ${media.desktop`
      width: 100%;
      margin-bottom: .8rem;
      `};
    & > p {
      font-size: 14px;
      width: 90%;
      font-weight: bold;
      line-height: 18px;
    }
  }
  ${MainBody} {
    margin: 0;
  }
  ${media.desktop`
    flex-direction: column;
  `};
`;

export const CookieSettingsLink = styled.a`
  min-width: 155px;
  background-color: 'transparent';
  text-decoration: underline solid ${Colors.SLIGHTLY_GARY};
  align-items: center;

  display: flex;
  justify-content: center;
  margin: 0 0 0.5rem 0;
  ${ButtonText} {
    color: ${Colors.SLIGHTLY_GARY};
    font-family: ${fonts.APERCU.WEB};
    line-height: unset;
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    ${media.desktop`
      padding: 0.7rem 0.5rem 0.7rem 0.5rem;
      font-size: 14px;
    `};
  }

  ${media.desktop`
    width: 50%;
    min-width: auto;
  `};
`;

export const CookieButton = styled(SecondaryButton)`
  border-color: ${Colors.ORANGE};
  margin-left: auto;
  background: ${Colors.ORANGE};
  border-radius: 0.2rem;
  min-width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;

  &:hover,
  &:focus {
    border-color: ${Colors.ORANGE};
    outline: ${Colors.ORANGE};
    background: 'transparent';
  }

  & * {
    &:after,
    &:before {
      transition: 0.3s all ease-in-out;
    }
  }
  &:last-of-type {
    margin-left: 0.5rem;
  }
  ${ButtonText} {
    color: ${Colors.WHITE};
    font-family: ${fonts.APERCU.WEB};
    line-height: unset;
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    ${media.desktop`
      padding: 0.7rem 0.5rem 0.7rem 0.5rem;
      font-size: 14px;
    `};
  }

  ${media.desktop`
    width: 50%;
    min-width: auto;
  `};
`;

export const CookieSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & + & {
    margin: 0 0 0 1rem;
  }

  .react-switch-bg {
    border: 1px solid ${Colors.COOKIE_BORDER};
  }

  ${media.desktop`
    & + & {
      margin: 1.5rem 0 0 0;
    } 
  `};

  & > span {
    margin-left: 0.4rem;
    font-family: ${fonts.APERCU.REGULAR};
    font-size: 14px;
  }
`;
