import * as TYPES from './accountTypes';
import { loginUser, logoutUser, validateUser, createUser } from '../../api/AccountApi';
import { getSearchParams } from '../../utils';

export const login = (username, password) => {
  return async dispatch => {
    dispatch({ type: TYPES.ACCOUNT_LOGIN });
    try {
      const result = await loginUser(username, password);
      const { isAuthenticated, username: loggedInUser, message, isAdmin /*hasError*/ } = result;

      if (isAuthenticated) {
        dispatch({
          type: TYPES.ACCOUNT_LOGGED_IN_SUCCESS,
          isAuthenticated,
          loggedInUser,
          message,
          isAdmin
        });
        const param = getSearchParams(window.location.href, 'ReturnUrl');
        if (param) window.location.href = param;
      } else {
        dispatch({ type: TYPES.ACCOUNT_LOGGED_IN_FAIL, isAuthenticated, message });
      }
      return result;
    } catch (e) {
      throw e;
    }
  };
};

/**
 * Gets a page as json.
 * @param {*data} data
 */
export const create = ({
  nameValue,
  emailValue,
  phoneValue,
  lastNameValue,
  passwordValue,
  memberNumberValue
}) => {
  return async dispatch => {
    if (
      nameValue === undefined ||
      emailValue === undefined ||
      phoneValue === undefined ||
      lastNameValue === undefined ||
      passwordValue === undefined
    ) {
      throw new Error('create user, needs parameters');
    }
    dispatch({ type: TYPES.ACCOUNT_CREATE });

    try {
      const result = await createUser({
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
        lastname: lastNameValue,
        password: passwordValue,
        membernumber: memberNumberValue
      });
      if (!result) {
        dispatch({ type: TYPES.ACCOUNT_CREATE_FAIL, message: '' });
      }
      const { success, message } = result;

      if (success) {
        dispatch({ type: TYPES.ACCOUNT_CREATE_SUCCESS, message, user: emailValue, success });
      } else {
        dispatch({ type: TYPES.ACCOUNT_CREATE_FAIL, message });
      }

      return result;
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_CREATE_FAIL });

      throw e;
    }
  };
};

export const logout = () => {
  return async dispatch => {
    dispatch({ type: TYPES.ACCOUNT_LOGOUT });
    try {
      const result = await logoutUser();
      const { isAuthenticated, message } = result;

      if (!isAuthenticated) {
        dispatch({ type: TYPES.ACCOUNT_LOGGED_OUT_SUCCESS, isAuthenticated, message });
      } else {
        dispatch({ type: TYPES.ACCOUNT_LOGGED_OUT_FAIL, isAuthenticated, message });
      }
      return result;
    } catch (e) {
      throw e;
    }
  };
};

export const validate = () => {
  return async dispatch => {
    dispatch({ type: TYPES.ACCOUNT_VALIDATE });
    const result = await validateUser();

    const { isAuthenticated, username: user, message, isAdmin } = result;

    dispatch({ type: TYPES.ACCOUNT_VALIDATE_SUCCESS, isAuthenticated, user, message, isAdmin });

    try {
    } catch (e) {
      throw e;
    }
  };
};

export const setLoginFormVisibility = show => {
  return dispatch => {
    dispatch({ type: TYPES.ACCOUNT_SHOW_LOGIN_FORM, show });
  };
};
