import styled from 'styled-components/macro';
import fonts, { fontSize } from 'style/fonts';

export const TextWrapper = styled.p.attrs(props => ({
  fontSize: props.fontSize
    ? props.fontSize
    : props.small
    ? fontSize(14, 18)
    : props.medium
    ? fontSize(16, 19)
    : fontSize(16, 24),
  paddingleft: props.pushright ? props.pushright : 0,
  width: props.width ? props.width : props.fullwidth ? '100%' : 'auto'
}))`
  /* margin: 0.8rem 0; */
  /* margin-left: 1rem; */
  /* margin-right: 1em; */
  padding: 0 0 0 ${props => props.paddingleft};
  white-space: nowrap;
  overflow: hidden;
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: ${props => props.fontSize};
  width: ${props => props.width};
  min-width: ${props => props.width};
  margin: 0;
`;
