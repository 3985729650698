import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { media } from 'style/media';

export const Rectangle = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 30rem;
  min-height: 5rem;
  position: relative;
  left: 50%;
  transform: translate3d(-50%, -0%, 0);
  border: 2px solid ${colors.ORANGE};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;

  ${media.smalltablet`
        flex-direction: column;
        width: 95vw;
        max-width: 22.5rem;
        padding: 0.5rem 0.1rem;
    `}
`;

export const RectangleWrapper = styled.div`
  margin-top: 2rem;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate3d(-50%, -0%, 0);
`;
