import styled from 'styled-components/macro';
import { media } from 'style/media';
import { Title2 } from 'components/text';
import fonts from 'style/fonts/fonts';

export const QuoteBlockText = styled(Title2).attrs(props => ({
  // background: props.backgroundColor,
  // color: props.color
}))`
  display: flex;
  font-weight: bold;
  font-family: ${fonts.BROWN_PRO};
  margin: 2rem 14rem;
  font-size: 1rem;
  letter-spacing: 0px;
  padding: 20px 0;
  text-align: center;
  line-height: 1.4rem;
  max-width: 800px;
  // color: ${props => props.color};
  ${media.desktop`
    flex-direction: row;
    padding: 2rem 1rem;    
    text-align: center;
    margin: 2rem 4rem;
  `};
  ${media.smalltablet`
  font-size: 1.5rem;
  line-height: 1.6rem;
  margin: 2rem 1rem;
  `};
`;
