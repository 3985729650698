import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchContacts } from '../contactlistblock/contactlistblockActions';
import { colors as Colors } from 'constants/colors';
import Contact from 'features/Contact';
import { Block, CenteredBlockInner, BlockHeader } from '../components';
import { BLOCKS } from 'constants/blockTypes';
import { FlexContainer } from 'components/flex/';
import { ChangeTransition } from 'features/Transitions';
import { getSelectedValueFromSelectionFactory, isEmpty } from 'utils';

class ContactBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: !isEmpty(props.contactBlockContact) ? props.contactBlockContact : undefined,
      isLoading: false
    };
  }

  componentDidMount() {
    const { contactBlockContact } = this.props;
    if (!isEmpty(contactBlockContact)) return;
    this.fetchBlockItems();
  }

  receivedNewCountyFilter = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { filter } = this.props;
    if (isEmpty(filter.selected)) return false;
    if (filter.selected.id !== prevFilter.selected.id) return true;
    return false;
  };

  receivedNewCategoryfilter = prevProps => {
    const { filterarea: prevFilterarea } = prevProps;
    const { filterarea } = this.props;
    if (prevFilterarea.selectedFilters !== filterarea.selectedFilters) return true;
    return false;
  };

  componentDidUpdate(prevProps) {
    const { contactBlockContact } = this.props;
    const { isLoading } = this.state;
    if (!isEmpty(contactBlockContact)) return;
    if (isLoading) return;
    if (this.receivedNewCountyFilter(prevProps) || this.receivedNewCategoryfilter(prevProps)) {
      this.fetchBlockItems();
    }
  }

  async fetchBlockItems() {
    const { id, filter, filterarea, fetchContacts } = this.props;
    if (id === undefined) return;
    const { selected } = filter;
    if (isEmpty(selected)) return false;
    const { selectedFilters } = filterarea;
    this.setState({ isLoading: true });
    await fetchContacts(id, selected.id, selectedFilters);

    const { contacts: contactFetched } = this.props;

    const { contactlist } = contactFetched;
    if (!contactlist.length) {
      this.setState({ isLoading: false });
      return;
    }

    const flattened = contactlist.map(({ data }) => data);
    const flattenedArray = [].concat(...flattened);

    this.setState({ isLoading: false, contact: flattenedArray[0] });
  }

  render() {
    const { heading, backgroundColor } = this.props;
    const { contact, isLoading } = this.state;
    if (!contact) return null;
    const selectedBg = getSelectedValueFromSelectionFactory(backgroundColor);
    const bg = selectedBg ? selectedBg : Colors.GRAY;

    return (
      <React.Fragment>
        {heading && (
          <CenteredBlockInner padding="0.8333rem 0.8333rem 0 0.8333rem">
            {<BlockHeader>{heading}</BlockHeader>}
          </CenteredBlockInner>
        )}

        <Block smallMarginBottom wide background={bg}>
          <CenteredBlockInner overflowvisible>
            <FlexContainer
              margin="1rem 0 1rem 0"
              column
              justifycontent="center"
              alignItems="center">
              <ChangeTransition in={!isLoading} transitionkey={contact.email} key={contact.email}>
                <Contact nomargin {...contact} />
              </ChangeTransition>
            </FlexContainer>
          </CenteredBlockInner>
        </Block>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected,
    reset: state.filter.reset,
    list: state.filter.categoryList
  },
  filterarea: {
    selectedFilters: state.filterarea.selectedFilters
  },
  contacts: {
    contactlist: state.contacts.contacts,
    error: state.contacts.error
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchContacts }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactBlock);

ContactBlock.propTypes = {
  //id of the block
  id: PropTypes.number,
  ignoreFilters: PropTypes.bool,
  backgroundColor: PropTypes.any,
  blockType: PropTypes.oneOf([BLOCKS.CONTACT_BLOCK])
};
