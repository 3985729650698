import React from 'react';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import { DoubleContainerItem, DoubleContainer } from '../components';
import { PageContent } from 'pages/components';

class DualTextBlock extends React.Component {
  render() {
    const { mainBodyLeft, mainBodyRight } = this.props;
    return (
      <PageContent>
        <DoubleContainer marginbottom="1.667rem" margintop="0" nopadding flexstart>
          <DoubleContainerItem specialMargin="0 1.5rem 0 0" >
            <HtmlComponentContainer smallmargin={'0'} >
              {/* <HtmlComponentContainer smallmargin={'0'} >margin={'0 0.5rem 0 0'} */}
              <HtmlComponent html={mainBodyLeft} />
            </HtmlComponentContainer>
          </DoubleContainerItem>
          <DoubleContainerItem specialMargin="0 0.2rem 0 0" >
            <HtmlComponentContainer smallmargin={'0.4rem 0 0 0'}>
              {/* <HtmlComponentContainer smallmargin={'0.4rem 0 0 0'} margin={'0 0 0 0.7rem'}> */}
              <HtmlComponent html={mainBodyRight} />
            </HtmlComponentContainer>
          </DoubleContainerItem>
        </DoubleContainer>
      </PageContent>
    );
  }
}

export default DualTextBlock;
