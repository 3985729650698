import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CookieBarContainer,
  CookieBarWrapper,
  CookieButton,
  CookieSwitchWrapper,
  CookieSettingsLink
} from './components';
import { HtmlComponent } from 'features/HtmlComponent';
import { ButtonText } from 'components/button';
import { acceptCookie, declineCookie } from './cookiebarActions';
// import { CollapsingArrow } from 'components/images/ArrowDown';
import { CookieTransition, ToggleTransition, MultiTransition } from 'features/Transitions';
import Switch from 'react-switch';
import { colors as Colors } from 'constants/colors';
import { FlexContainer } from 'components/flex/';
import { fromBelow, animateHeight, changeColor } from 'features/Transitions/Animations';
import { CookieHandler as CookieManager } from '../../utils';

class CookieBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      currentTimer: 3,
      showSettings: false,
      performance: false,
      statisticsMarketing: false,
      atleastOne: false
    };
    this.intervalId = 0;
  }

  componentDidMount() {
    const { cookie } = this.props;
    const CookieHandler = new CookieManager(true);
    this.intervalId = setInterval(this.delay, 1000);
    this.setState({
      performance:
        CookieHandler.get('AcceptedPerformanceCookies') !== undefined || !cookie.accepted,
      statisticsMarketing: CookieHandler.get('AcceptedMarketingAndStatisticsCookies') !== undefined,
      atleastOne:
        CookieHandler.get('AcceptedPerformanceCookies') !== undefined ||
        CookieHandler.get('AcceptedMarketingAndStatisticsCookies') !== undefined ||
        CookieHandler.get('AcceptedPerformanceCookies') !== undefined
    });
  }

  componentDidUpdate() {
    const { showSettings, atleastOne } = this.state;
    let isAtleastOne = showSettings;
    if (isAtleastOne !== atleastOne) {
      this.setState({
        atleastOne: isAtleastOne
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  delay = () => {
    const { currentTimer } = this.state;
    this.setState({ currentTimer: currentTimer - 1 });

    if (currentTimer <= 0) {
      clearInterval(this.intervalId);
      this.setState({ mounted: true });
    }
  };

  toggleSettings = () => {
    const { showSettings } = this.state;
    this.setState({
      showSettings: !showSettings
    });
  };

  handleCookieChange = (checked, e, id) => {
    this.setState({ [id]: checked });
  };

  handleBtnClick = () => {
    const { atleastOne, statisticsMarketing, performance } = this.state;
    const { acceptCookie } = this.props;
    if (atleastOne) {
      acceptCookie(false, statisticsMarketing, performance);
    } else {
      this.toggleSettings();
    }
  };

  render() {
    const { text = '', acceptButtonText, acceptCookie, cookie } = this.props;

    const { mounted, showSettings, statisticsMarketing, performance, atleastOne } = this.state;

    const acceptText = acceptButtonText || 'Acceptera';
    const settingsText = atleastOne ? 'Godkänn valda' : 'Inställningar';
    const { accepted = true, set = false } = cookie;
    return (
      <CookieTransition transitionkey="cookiebar" in={!set && mounted}>
        <CookieBarContainer className="CookieBar">
          <CookieBarWrapper>
            <FlexContainer column width="70%" margin="0 2rem 0 0" smallmargin="0">
              <CookieTransition
                transitionkey="cookie_html"
                in={text.length > 0 && (!accepted && mounted)}>
                <HtmlComponent html={text} />
              </CookieTransition>
              <ToggleTransition in={showSettings} transiotionkey="cookie_settings">
                <FlexContainer maxwidth="calc(46.25rem - 0.8333rem)" padding="1rem 0">
                  <MultiTransition
                    in={showSettings}
                    animationOnExit={{ animation: animateHeight, props: { to: 0 } }}
                    animationOnExited={{
                      animation: changeColor,
                      props: { color: '#FFF', duration: 500 }
                    }}
                    animationOnEnter={{ animation: fromBelow }}>
                    <FlexContainer>
                      <CookieSwitchWrapper>
                        <Switch
                          checkedIcon={false}
                          width={60}
                          height={24}
                          disabled
                          onColor={Colors.ORANGE}
                          onChange={this.handleCookieChange.bind(this)}
                          checked={true}
                        />
                        <span>Nödvändiga</span>
                      </CookieSwitchWrapper>
                      <CookieSwitchWrapper>
                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          offColor={Colors.SWITCH_OFF_BG}
                          offHandleColor={Colors.SWITCH_OFF_HANDLE}
                          onColor={Colors.ORANGE}
                          width={60}
                          height={24}
                          onChange={this.handleCookieChange.bind(this)}
                          id="performance"
                          checked={performance}
                        />
                        <span>Prestanda</span>
                      </CookieSwitchWrapper>
              
                      <CookieSwitchWrapper>
                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          offColor={Colors.SWITCH_OFF_BG}
                          offHandleColor={Colors.SWITCH_OFF_HANDLE}
                          onColor={Colors.ORANGE}
                          width={60}
                          height={24}
                          onChange={this.handleCookieChange.bind(this)}
                          id="statisticsMarketing"
                          checked={statisticsMarketing}
                        />
                        <span>Statistik & marknadsföring</span>
                      </CookieSwitchWrapper>
                    </FlexContainer>
                  </MultiTransition>
                </FlexContainer>
              </ToggleTransition>
            </FlexContainer>
            <FlexContainer width="30%" alignself="self-start" smallrow>
              {/* If the customer wants the button-look for the accept chose-button, uncomment this code */}
              {/* {!atleastOne ? ( */}
              <CookieSettingsLink onClick={() => this.handleBtnClick()}>
                <ButtonText forcedFontSize={[14, 14]}>{settingsText}</ButtonText>
              </CookieSettingsLink>
              {/* // ) : (
              //   <CookieButton
              //     onClick={() => this.handleBtnClick()}
              //     bordercolor="orange"
              //     hovercolor="white">
              //     <ButtonText forcedFontSize={[14, 14]} color={Colors.TOTALLY_BLACK}>
              //       {settingsText}
              //     </ButtonText>
              //     {!atleastOne && (
              //       <CollapsingArrow scale={0.5} black collapsed={!showSettings} nomargin />
              //     )}
              //   </CookieButton>
              // )} */}
              {acceptText && (
                <CookieButton
                  onClick={() => acceptCookie(true)}
                  orange
                  hovercolor="white"
                  backgroundcolor={Colors.ORANGE}>
                  <ButtonText forcedFontSize={[14, 14]} color={Colors.TOTALLY_BLACK}>
                    {acceptText}
                  </ButtonText>
                </CookieButton>
              )}
            </FlexContainer>
          </CookieBarWrapper>
        </CookieBarContainer>
      </CookieTransition>
    );
  }
}

const mapStateToProps = state => ({
  cookie: {
    accepted: state.cookie.accepted,
    set: state.cookie.set
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ acceptCookie, declineCookie }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieBar);

CookieBar.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  cookie: PropTypes.shape({
    accepted: PropTypes.bool
  }),
  acceptCookie: PropTypes.func
};
