import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { fadeOut, scaleIn } from 'style/animations/fades';

/**
 * FadeIn (div)
 */
export const Fade = styled.div`
  ${'' /* will-change: transform, opacity; */} animation-delay: ${props => props.delay};
  visibility: ${props => (props.out ? 'hidden' : 'visible')};
  animation: ${props => (props.out ? fadeOut : scaleIn)};
  display: block;
`;

Fade.propTypes = {
  delay: PropTypes.string,
  out: PropTypes.bool
};
