export const randomInt = (min, max) => {
  return ((Math.random() * (6 - min)) | 0) + min;
};

export const isEmpty = obj => {
  if (obj === undefined || obj === null) return true;
  switch (obj.constructor) {
    case {}.constructor:
      return Object.keys(obj).length === 0;
    case [].constructor:
      return obj.length === 0;
    case ''.constructor:
      return obj.length === 0;
    default:
      return true;
  }
};

export const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));

export const addEndingIfNeeded = (text = '', ending = '', addIf) => {
  if (!text || !ending) return false;
  const shouldAdd = addIf ? addIf(text, ending) : !text.endsWith(ending);
  return shouldAdd ? `${text}${ending}` : text;
};

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const flatObject = xs => {
  return xs.reduce((acc, x) => {
    x = Object.assign({}, x);
    acc = acc.concat(x);
    if (x.childNavigationLinks) {
      acc = acc.concat(flatObject(x.childNavigationLinks));
      x.childNavigationLinks = [];
    }
    return acc;
  }, []);
};

export const flattenObject = (x, path) => {
  var result = [];

  path = path || [];
  Object.keys(x).forEach(function(key) {
    if (!x.hasOwnProperty(key)) return;

    var newPath = path.slice();
    newPath.push(key);

    var vals = [];
    if (typeof x[key] === 'object') {
      vals = flattenObject(x[key], newPath);
    } else {
      vals.push({ path: newPath, val: x[key] });
    }
    vals.forEach(function(obj) {
      return result.push(obj);
    });
  });

  return result;
};

export const removeDuplicates = (object, key) => {
  return object.filter((obj, index, self) => index === self.findIndex(o => o[key] === obj[key]));
};

export const isInternalUrl = url => {
  if (!url) return true;
  return !(url.startsWith('http://') || url.startsWith('https://') || url.startsWith('mailto:'));
};

export const generateParamsUrl = obj => {
  return Object.keys(obj)
    .map(k => {
      return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]);
    })
    .join('&');
};

export const sleep = (ms = 0) => {
  return new Promise(r => setTimeout(r, ms));
};

export function debounce(func, wait, immediate) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    }, wait);
    if (immediate && !timeout) func.apply(this, [...args]);
  };
}

export const removeSearchParam = (url, key, removeIf = () => true) => {
  if (!url || !key) return;
  const search = new URL(url).search;

  let searchParams = new URLSearchParams(search);

  if (removeIf(searchParams, key)) {
    searchParams.delete(key);
    return searchParams.toString();
  }

  return searchParams.toString();
};

export const appendOrReplaceSearchParam = (url, key, value, replaceIf = () => false) => {
  if (!url || !key || !value) return;
  const search = new URL(url).search;
  let searchParams = new URLSearchParams(search);

  if (replaceIf(searchParams, key, value)) {
    searchParams.delete(key);
    searchParams.append(key, value);
    return searchParams.toString();
  }

  searchParams.append(key, value);

  if (!searchParams) return '';
  return searchParams.toString();
};

export const getAllSearchParams = (url, param) => {
  if (!url || !param) return false;

  const classedUrl = new URL(url);
  const params = classedUrl.searchParams.getAll(param);

  return params;
};

export const getSearchParams = (url, param) => {
  if (!url || !param) return false;

  const classedUrl = new URL(url);
  const params = classedUrl.searchParams.get(param);

  return params;
};

export const getCaseInsensitiveSearchParams = (url, param) => {
  if (!url || !param) return false;

  const classedUrl = new URL(url);
  let result = '';
  classedUrl.searchParams.forEach(function(value, key) {
    if (key.toLowerCase() === param.toLowerCase()) {
      result = value;
    }
  });

  return result ? result : false;
};

export const array2SearchParams = (array, param, selectorCb) => {
  const filterIds = array.reduce((prevVal, currVal, idx) => {
    return idx === 0
      ? `?${param}=${selectorCb(currVal)}`
      : `${prevVal}&${param}=${selectorCb(currVal)}`;
  }, 0);

  return filterIds;
};

export const array2SearchCategoryParams = (array, param, selectorCb) => {
  const filterIds = array.reduce((prevVal, currVal, idx) => {
    return idx === 0
      ? `&${param}=${selectorCb(currVal)}`
      : `${prevVal}&${param}=${selectorCb(currVal)}`;
  }, 0);

  return filterIds;
};

export const isMobile = {
  Android: () => {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: () => {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: () => {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: () => {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: () => {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  }
};
