import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts/fonts';

export const ListBlockItemDate = styled.h2`
  font-family: ${fonts.LLBrown.REGULAR};
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 300;
  color: ${Colors.TEXT_GRAY};
`;
