import styled from 'styled-components/macro';

import { shadow } from 'constants/shadows';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';

export const AutoCompleteContainer = styled.div.attrs()`
  position: absolute;
  top: 100%;
  z-index: ${zIndex.MODALS};
  background: ${Colors.WHITE};
  width: 100%;
  transform-origin: top center;
  box-shadow: ${shadow(3)};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
`;
