import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts/fonts';

export const Decision = styled.span`
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${Colors.BLACK};
  padding: 1rem 1rem 1rem 1.5rem;
  border: ${Colors.ORANGE} 1px solid;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: inline-block;
`;
