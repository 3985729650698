import React from 'react';
import PropTypes from 'prop-types';
import { CountyFilter } from 'features/CountyFilter';

export default class FilterBlock extends React.Component {
  getHeaderColor(colors = []) {
    if (''.constructor === colors.constructor) return colors;
    if (colors.length === 0) return '';
    const selected = colors.find(x => x.selected);
    if (!selected) return '';
    if (selected.hasOwnProperty('value')) return selected.value;
    return '';
  }

  render() {
    const { headercolor, background, header, center, noHitsText, isPuffBlockFilter } = this.props;
    return (
      <CountyFilter
        isPuffBlockFilter={isPuffBlockFilter}
        noHitsText={noHitsText}
        background={background}
        headercolor={this.getHeaderColor(headercolor)}
        header={header}
        center={center}
      />
    );
  }
}
FilterBlock.propTypes = {
  headercolor: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.array,
    PropTypes.string
  ]),
  background: PropTypes.any
};
