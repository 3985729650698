import React, { Component } from 'react';

const withFormTracker = WrappedComponent => {
  const sendCustomEvent = event => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  };

  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    componentDidMount() {}

    componentDidUpdate(prevProps) {}
    render() {
      return <WrappedComponent onFormSuccess={sendCustomEvent} {...this.props} />;
    }
  };

  return HOC;
};

export default withFormTracker;
