import React from 'react';
import { connect } from 'react-redux';

export default function WithAuthentication(WrappedComponent) {
  class Authenticate extends React.Component {
    render() {
      const { onlyForAuthenticatedVisitors, account } = this.props;
      let isAuthenticated = false;
      if (account) isAuthenticated = account.isAuthenticated;
      const locked = onlyForAuthenticatedVisitors && !isAuthenticated;
      
      return (
        <WrappedComponent locked={locked} {...this.props} />
      );
    }
  }

  const mapStateToProps = state => {
    return {
      account: {
        isAuthenticated: state.account.isAuthenticated
      }
    };
  };

  return connect(mapStateToProps)(Authenticate);
}
