import styled from 'styled-components/macro';
import { ScrollTo } from 'features/ScrollTo';

import { media } from 'style/media';

export const HeroScrollTo = styled(ScrollTo).attrs(props => ({
  relative: true,
  next: true,
  bottom: '1rem',
  scrollto: 'top'
}))`
  margin-top: auto;

  ${media.desktop`
    display:none;
  `};
`;
