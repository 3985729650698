import React from 'react';

class Polymorph extends React.Component {
  static propTypes = {
    as: 'div'
  };

  static defaultProps = {
    as: 'div'
  };

  render() {
    const { as: Representation, ...props } = this.props;
    return <Representation {...props} />;
  }
}

export default Polymorph;
