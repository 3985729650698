import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
export const Icon = styled.img.attrs(props => ({
  brightness: props.hover ? '300%' : '100%'
}))`
  transition: filter 0.3s ease-in-out;
  width: 2rem;
  filter: brightness(${props => props.brightness});
  transform: translateZ(0);
`;

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};
