import React from 'react';
import { FrequentSearchFlex } from './FrequentSearchComponents';
import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { ArrowLink } from 'components/styledarrowlink/ArrowLink';

const CategoryPillContainer = styled.button`
  background: ${Colors.GRAY};
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
`;

const FrequentSearch = ({ items = [], handleClick = () => {}, pillComponent, ...styles }) => {
  return (
    <FrequentSearchFlex {...styles}>
      {!!items.length &&
        items.map((item, index) => {
          const { title, url } = item;
          return (
            <CategoryPillContainer
              key={`category-pill-${index}`}
              as={pillComponent}
              href={url}
              title={title}
              onClick={handleClick}>
              <ArrowLink isBold text={title ? title : item} />
            </CategoryPillContainer>
          );
        })}
    </FrequentSearchFlex>
  );
};

export default FrequentSearch;
