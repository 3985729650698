import RequestService from './RequestService';

const FORM_BASE_URL = `/api/form`;
const SUBMIT_FORM = `${FORM_BASE_URL}`;

/**
 * Gets a page as json.
 * @param {*data} data
 */
export async function submitForm(data, id) {
  if (data === undefined) {
    throw new Error('submitForm, data parameter cannot be empty');
  }
  try {
    const result = await RequestService.postAsync(`${SUBMIT_FORM}/submit/${id}`, data);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Gets a page as json.
 * @param {*data} a formData object
 */
export async function submitToEpiForms(formData, guid) {
  //check if formData is an instance of FormData?
  if (formData === undefined) {
    throw new Error('submitToEpiForms, data parameter cannot be empty');
  }
  try {
    const result = await RequestService.postFormAsync(
      `/EPiServer.Forms/DataSubmit/Submit`,
      formData
    );
    console.log(result)
    return result;
  } catch (e) {
    throw e;
  }
}
