import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

/**
 * CarouselBlockContainer (div)
 */

export const CarouselBlockContainer = styled.div.attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  minheight: props.nominheight ? 'auto' : 'auto',
  padding: props.nopadding ? 0 : ' 2rem 1rem 2rem 1rem'
}))`
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${props => props.background};
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  z-index: 0;
  max-width: ${props => props.maxwidth};
  margin: ${props => props.margin};
  perspective: 900px;
  min-height: ${props => props.minheight};
  justify-content: center;
  ${media.desktop`
    max-height: none;
    flex-direction: column;
  `};
`;

CarouselBlockContainer.propTypes = {
  //deprecated
  nominheight: PropTypes.bool,
  background: PropTypes.string,
  first: PropTypes.bool.isRequired
};
