import styled from 'styled-components/macro';
// import PropTypes from 'prop-types';
import fonts, { fontSize } from 'style/fonts';
import { colors as Colors } from 'constants/colors';

/**
 * RelatedItemText (aside)
 */
export const RelatedItemText = styled.span`
  font-family: ${fonts.LLBrown.REGULAR};
  display: inline-block;
  font-size: ${fontSize(14, 18)};
  padding: calc(0.2em + 0.1rem) 0.2em calc(0.2em + 0.1rem) 0.2em;
  text-align: center;
  color: ${Colors.BLACKER_GRAY};
  max-width: 100%;
  text-align: center;
`;

RelatedItemText.propTypes = {};
