export const settings = {
  MENU_HEIGHT: '5.75rem',
  MENU_HEIGHT_NO_COUNTYPICKER: '3.75rem',
  MENU_PADDING: '1rem'
};
//The header was reworked for desktop, so the menu_height was left in to avoid destroying
//mobile settings, but the value is not always used for desktop header settings

export const footer = {
  HEIGHT: '18rem'
};

export const zIndex = {
  LEVEL_BELOW_ALL: -2,
  LEVEL_0: 0,
  LEVEL_1: 10,
  LEVEL_2: 20,
  LEVEL_3: 30,
  UNDER_MODALS: 999,
  MODALS: 1000,
  ABOVE_MODALS: 1001,
  FILTER: 2000,
  HIGHEST: 9999
};

export const RELATED_ITEMS_BREAK_POINT = 4;
