import React from 'react';
import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import fonts, { fontSize } from 'style/fonts/';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { media, targetBrowser } from 'style/media';
import { Lock } from 'features/Account/components/Lock';
import WithAuthentication from 'features/WithAuthentication';

const activeClassName = 'nav-item-active';
export const StyledNavigationLink = styled(NavLink).attrs(props => ({
  activeClassName,
  underlinecolor: props.nounderline
    ? Colors.TRANSPARENT
    : props.alternative
    ? Colors.ORANGE
    : Colors.BRAND_DARK,
  dropdown: props.dropdown,
  searchIcon: props.searchIcon
}))`
  font-family: ${({ font }) => (font ? font : fonts.LLBrown.REGULAR)};
  position: relative;
  font-size: ${fontSize(12, 16)};
  line-height: ${({ lineheight }) => (lineheight ? lineheight : '1em')};
  transition: all 0.2s ease-in-out;
  color: ${({ orange }) => (orange ? Colors.ORANGE : Colors.BLACK)};
  text-decoration: none;
  letter-spacing: ${({ font }) => (font === fonts.APERCU.MONO ? '-0.1em' : '0.5px')};
  text-align: left;
  display: inline-block;
  &:after {
    position: absolute;
    content: '';
    margin: ${({ dropdown, searchIcon }) => (dropdown ? '0.2rem' : searchIcon ? '0.33rem' : '0.50rem')} auto auto auto;
    display: block;
    height: ${({ dropdown }) => (dropdown ? '2px' : '3px')};
    width: ${({ forceactive }) => (forceactive ? `100%` : `0px`)};
    background: ${({ forceactive, alternative }) =>
      forceactive ? (alternative ? Colors.ORANGE : Colors.BRAND_DARK) : `transparent`};
    transition: width 0.3s ease, background-color 0.5s ease;

    //fix for firefox resize bug
    ${targetBrowser.FIREFOX4ANDHIGHER`
      width: 100%;
    `}
  }
  &:hover {
    &:after {
      width: 100%;
      background: ${({ nounderline, alternative }) =>
        nounderline ? Colors.TRANSPARENT : alternative ? Colors.ORANGE : Colors.BRAND_DARK};
    }
  }
  &.${activeClassName} {
    &:after {
      width: 100%;
      background: ${({ noactiveunderline, underlinecolor }) =>
        noactiveunderline ? Colors.TRANSPARENT : underlinecolor};
    }
  }

  ${({ spacebetween }) =>
    spacebetween &&
    `
    margin: 0 0.75rem;
  `}

  ${media.bigdesktop`
  &:after {
    margin: ${({ dropdown, searchIcon }) => (dropdown ? '0.2rem' : searchIcon ? '0.22rem' : '0.449rem' )} auto auto auto;
  }
    ${({ spacebetween }) =>
      spacebetween &&
      `
      margin: 0 0.45rem;
    `}
  `}
`;

const NavigationLink = ({
  children,
  onlyForAuthenticatedVisitors,
  locked,
  external,
  to,
  dispatch,
  staticContext,
  ...rest
}) => {
  const { selected } = rest.filter;
  const internalTo =
    !external &&
    selected.name &&
    selected.id !== 2 &&
    (to !== undefined && to.split('/')[1].toLowerCase() === 'tjanster')
      ? `${to}?region=${selected.name.replace(/\s+/g, '')}`
      : to;
      return external ? (
    <StyledNavigationLink as="a" href={to} {...rest}>
      {locked && <Lock display="inline-block" size="0.75em" margin="0 0.25em 0 0" />}
      {children}
    </StyledNavigationLink>
  ) : (
    <StyledNavigationLink to={internalTo} {...rest}>
      {locked && <Lock display="inline-block" size="0.75em" margin="0 0.25em 0 0" />}
      {children}
    </StyledNavigationLink>
  );
};

const mapStateToProps = state => ({
  app: {
    routes: state.app.routes
  },
  filter: {
    selected: state.filter.selected
  }
});

export default connect(mapStateToProps)(withRouter(WithAuthentication(NavigationLink)));
