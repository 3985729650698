import React, { Component } from 'react';
import { ErrorHandler } from '../../utils';

const withTracker = (WrappedComponent, key = 'XXX', options = {}) => {
  //const debug = false;
  ErrorHandler.ConsoleLog(`GA-key: ${key}`);
  // ReactGA.initialize(key, {
  //   debug: debug
  // });

  const trackPage = page => {
    return;
  };

  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    componentDidMount() {
      const { location } = this.props;
      const { pathname } = location;
      const page = pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      // const { location } = prevProps;
      // const { pathname: currentPage } = location;
      // const nextPage = this.props.location.pathname + this.props.location.search;
      // if (currentPage !== nextPage) {
      //   trackPage(nextPage);
      // }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
