import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const enterDuration = 500;
const exitDuration = 250;
export default class ToggleTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;

    node.style.display = 'block';
    anime({
      targets: node,
      opacity: [0.7, 1],
      translateY: [-10, 0],
      duration: enterDuration,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  onExit = (node, index) => {
    if (!node) return null;
    anime({
      targets: node,
      translateY: [0, -15],
      opacity: [1, 0],
      duration: exitDuration,
      easing: 'easeInOutCirc',
      complete: () => (node.style.display = 'none')
    });
  };

  onExiting = (node, index) => {};
  onExited = node => {};

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: enterDuration, exit: 0 }}
        key={transitionkey}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        mountOnEnter={true}
        unmountOnExit={false}>
        {children}
      </Transition>
    );
  }
}
ToggleTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element
};
