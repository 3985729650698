import React from 'react';
import { CollapsibleArrowContainer, CollapsibleContainer, CollapsiblePanelChildren, CollapsiblePanelDiv, CollapsibleTitle } from './CollapsibleItem';
import { CollapsingArrow } from 'components/images/ArrowDown';
import { sizes } from 'style/media';
import CollapsibleTransition from './components';

class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { children, title } = this.props;
    let scale = window.innerWidth < sizes.breakpointSmall || window.innerWidth > sizes.desktop ? 0.6 : 0.85;
    return (
      <CollapsibleContainer>
        <CollapsiblePanelDiv onClick={(e) => this.togglePanel(e)} open={this.state.open}>
          <CollapsibleTitle open={this.state.open}>
            {title}
          </CollapsibleTitle>

          <CollapsibleArrowContainer>
            <CollapsingArrow scale={scale} collapsed={!this.state.open}></CollapsingArrow>
          </CollapsibleArrowContainer>

        </CollapsiblePanelDiv>
        <CollapsibleTransition in={this.state.open} >
          <CollapsiblePanelChildren show={this.state.open}>
            {children}
          </CollapsiblePanelChildren>
        </CollapsibleTransition>
      </CollapsibleContainer>

    );
  }
}

export default Collapsible;