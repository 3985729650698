import styled from 'styled-components/macro';
import { media } from 'style/media';
import { UnderlineContainer } from 'components/UnderlineContainer';


export const ContactUnderlineContainer = styled(UnderlineContainer)`
    margin: 0;
    ${media.huge`
    margin: 0;

  `};
  ${media.bigdesktop`
  margin: 0;

  `};
  ${media.desktop`
  margin: 0;

  `}
`;