import styled from 'styled-components/macro';

/**
 * StandardPageImageContainer
 */
export const StandardPageImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 3rem;
`;
