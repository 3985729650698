import styled from 'styled-components/macro';
import React from 'react';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import fonts from 'style/fonts/fonts';
import { media } from 'style/media';

/**
 * ListItem (div)
 */

export const StyledCountyListItem = styled.li`
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out;
  color: ${({ isSelected }) => (isSelected ? Colors.ORANGE : Colors.DARKER_GRAY)};
  font-size: 0.68rem;
  position: relative;
  font-family: ${fonts.LLBrown.BOLD};
  padding: 0.3rem 0.9rem;
  display: block;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.36 : 1)};
  &:hover {
    color: ${({ isDisabled }) => (isDisabled ? Colors.DARKER_GRAY : Colors.TOTALLY_BLACK)};
    /* cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')}; */
    cursor: pointer;
    &::before {
      transition: background 0.2s ease-in, width 0.3s ease-out;
      background: ${Colors.ORANGE};
      box-shadow: ${'inset 0px 0px 0 2px #FFF'};
    }
  }
  &::before {
    content: '';
    position: absolute;
    left: -0px;
    top: 11px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid ${Colors.BLACK};
    background: ${({ isSelected }) => (isSelected ? Colors.ORANGE : 'none')};
    box-shadow: ${({ isSelected }) => (isSelected ? 'inset 0px 0px 0 2px #FFF' : 'none')};
    ${media.biggerdesktop`
      top: 10px;
    `};
    ${media.bigdesktop`
      top: 9px;
    `};
    ${media.desktop`
      top: 8px;
    `};
  }
`;

const Underline = styled.span.attrs(props => ({
  color: props.isSelected ? Colors.ORANGE : Colors.TRANSPARENT,
  width: props.isSelected ? '100%' : '80%'
}))`
  &:before {
    transition: background 0.2s ease-in, width 0.3s ease-out;
    content: '';
    position: absolute;
    background: ${props => props.color};
    width: ${props => props.width};
    height: 2px;
    bottom: -0.4rem;
    transform: translateX(-50%);
    left: 50%;
  }

  &:hover:before {
    background: ${LightenDarkenColor(Colors.DARKER_GRAY, 50)};
    width: 100%;
  }
  &:active:before {
    background: ${Colors.ORANGE};
  }
`;

/**
 * ListItemSpan (div)
 */
export const CountyListItemSpan = styled(Underline)`
  color: ${({ isSelected }) => (isSelected ? Colors.ORANGE : 'inherit')};
  position: relative;
  display: inline-block;
  &:before,
  &:after {
    opacity: ${({ isDisabled }) => (isDisabled ? 0 : 1)};
  }
`;

const CountyListItem = ({ value, isSelected = false, isDisabled, ...props }) => {
  return (
    <StyledCountyListItem isDisabled={isDisabled} {...props} isSelected={isSelected}>
      {value}
    </StyledCountyListItem>
  );
};

export default CountyListItem;
