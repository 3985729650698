import styled from 'styled-components/macro';
import { targetBrowser } from 'style/media';
const ModalWrapper = styled.div`
  transform-origin: top center;
  transition: background 0.4s ease, transform 0.3s ease;
  position: relative;
  padding: 1rem;
  width: ${props => props.modalClose ? `` : `100%`};
  max-width: 50rem;
  display: flex;
  height: auto;
  min-height: 1px;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  transform: ${({ isOpen }) => `scale(${isOpen ? 1 : 0}) rotateX(${isOpen ? '0deg' : '-100deg'}) `};
  flex-direction: column;
  ${targetBrowser.IE10ANDHIGHER`
    height: 20rem;
  `};
`;

export default ModalWrapper;
