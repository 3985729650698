import styled from 'styled-components/macro';
import { MainBody } from 'components/text';
import { colors as Colors } from 'constants/colors';

export const ErrorMessage = styled(MainBody).attrs(props => ({
  color: props.fail ? Colors.ERROR : 'green',
  align: props.left ? 'left' : 'right'
}))`
  color: ${props => props.color};
  text-align: ${props => props.align};
  margin: 0;
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0rem;
  }
`;
