import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { targetBrowser } from 'style/media';

export const ListBlockItemContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 1rem;
  background: ${Colors.TRANSPARENT};
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
    widtH:100%;
    max-width:35rem;
    height:100%;
  `};
`;
