import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import fonts from 'style/fonts/fonts';

/**
 * Date (span)
 */
export const Date = styled.span.attrs(props => ({
  opacity: props.opacity ? props.opacity : '1',
  nouppercase: props.nouppercase
}))`
  color: ${props => props.color || Colors.BLACK};
  font-family: ${fonts.APERCU.MONO};
  text-transform: ${({ nouppercase }) => (nouppercase ? 'none' : 'uppercase')};
  display: block;
  font-size: ${props => (props.small ? '10px' : '10px')};
  text-align: left;
  letter-spacing: 1.1px;
  opacity: ${props => props.opacity};
`;

/**
 * LargeDate (span)
 */
export const LargeDate = styled(Date)`
  font-family: ${fonts.APERCU.REGULAR};
  font-size: 1.35em;
  opacity: 1;
  line-height: 1.09em;
  letter-spacing: 3px;
  ${media.desktop`
    line-height: 1.2em;
    letter-spacing: 1.9px;
    text-align: center;
  `};
`;

Date.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors))
};
