import styled from 'styled-components/macro';
import { Paragraph } from 'components/text';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const ParallaxMainIntro = styled(Paragraph).attrs(props => ({
  centerTextSmall: true,
  small: true
}))`
  font-size: 0.8333rem;
  line-height: 1.4rem;
  margin: 0 0 1.25rem 0;
  width: 24rem;
  color: ${({ color }) => color || Colors.BLACK};

  ${media.desktop`
    color: ${Colors.BLACK};
    font-size: max(1rem, calc(13px + 0.3vw));
    opacity: 0.6;
  `};
`;

export const ParallaxMainIntroMobile = styled(Paragraph).attrs(props => ({
  centerTextSmall: true,
  small: true
}))`
  display: block;
  font-size: 0.95rem;
  letter-spacing: 0;
  line-height: 1.4rem;
  margin: 0 0 1rem 0 !important;
  color: ${({ color }) => color || Colors.BLACK};
  text-align: left !important;
`;
