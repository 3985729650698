import styled from 'styled-components/macro';
import React from 'react';
import { zIndex, settings } from 'constants/settings';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

const duration = '.3s';
const top = `calc((${settings.MENU_HEIGHT} + 2.65rem) / 2 )`;

const Buns = styled.span.attrs(props => ({
  transformafter: props.expanded ? 'rotate(45deg) translateY(0)' : 'rotate(0) translateY(-0.825em)',
  transformbefore: props.expanded
    ? 'rotate(-45deg) translateY(0)'
    : 'rotate(0) translateY(0.825em)',
  background: props.expanded ? 'transparent' : 'orange'
}))`
  position: relative;
  width: 2.5em;
  height: 0.3125em;
  transition: $all 1s;
  border-radius: 2.5em;
  background: ${props => props.background};

  &:before,
  &:after {
    transition: all ${duration};
    transform: translate3d(0, 0, 0);
    display: block;
    content: '';
    height: 0.3125em;
    width: 2.5em;
    background: ${Colors.ORANGE};
    position: absolute;
    z-index: -1;
    transition: all ${duration};
    border-radius: 1em;
  }

  &:after {
    transform: ${props => props.transformafter};
  }
  &:before {
    transform: ${props => props.transformbefore};
  }
`;

const StyledHamburgareIcon = styled.button.attrs(props => ({
  transformafter: ({ expanded }) =>
    expanded ? 'rotate(45deg) translateY(0)' : 'rotate(0) translateY(-1em)',
  transformbefore: ({ expanded }) =>
    expanded ? 'rotate(-45deg) translateY(0)' : 'rotate(0) translateY(1em)',
  position: props.absolute ? 'absolute' : 'sticky',
  display: props.hideOnDesktop ? 'none' : 'flex'
}))`
  display: ${props => props.display};
  ${media.desktop`
    display: flex;
    left:92%;
  `}
  ${media.smalltablet`
  left: 90%;
  `};
  ${media.phone`
  top: ${props => (props.isModalOpen ? '4.2rem' : props.expanded ? '1rem' : top)};
  `};
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: ${props => props.position};
  cursor: pointer;
  z-index: ${({ expanded }) => (expanded ? zIndex.ABOVE_MODALS : zIndex.UNDER_MODALS)};
  left: 92%;
  font-size: 0.5em;
  top: ${props => (props.isModalOpen ? '3rem' : props.expanded ? '1rem' : top)};
  margin-top: -2.5rem;
  padding: 1em 0;
  transition: all ${duration};
  opacity: 0.8;
  background: none;
  border: none;
  transform: ${({ scale }) => (scale ? `scale(${scale})` : ` scale(1)`)};
  &:hover {
    opacity: 1;
    ${Buns} {
      &:before {
        transform: ${props => props.transformbefore};
      }
      &:after {
        transform: ${props => props.transformafter};
      }
    }
  }
`;

export const HamburgareIcon = props => {
  const { expanded, ...rest } = props;
  return (
    <StyledHamburgareIcon expanded={expanded} {...rest}>
      <Buns expanded={expanded} />
    </StyledHamburgareIcon>
  );
};
