import React from 'react';
import { connect } from 'react-redux';
import {
  DropdownParent,
  DropdownFlexWrapper,
  DropdownContainer,
  DropdownItem,
  DropdownSmallContainer,
  DropdownSmallItem
} from './components';
import { NavigationLink, ChildNavigationLink } from '../Navigation';
import { fonts } from 'style/fonts';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  hide = () => {
    this.setState({ show: false });
  };

  show = () => {
    this.setState({ show: true });
  };

  getNumOfBigLinks = childLinks => {
    return childLinks.length;
  };

  getNumOfSmallLinks = childLinks => {
    return childLinks
      .map(navItem => navItem.childNavigationLinks.length)
      .reduce((acc, curr) => {
        return curr > 0 ? acc + curr + 1 : acc + curr;
      }, 0);
  };

  render() {
    const { show } = this.state;
    const { navItem, indexProp, router } = this.props;
    const { location } = router;
    let activeClassName =
      navItem.url[0] === '/' && location.pathname !== '/' ? '' : 'nav-item-active';
    const hasChildLinks = navItem.HasChildNavigationLinks;
    const childLinks = hasChildLinks ? navItem.childNavigationLinks : [];

    return (
      <DropdownParent
        show={show}
        onMouseEnter={this.show}
        onMouseLeave={this.hide}
        onClick={this.hide}>
        <NavigationLink
          font={fonts.LLBrown.BOLD}
          activeClassName={activeClassName}
          forceactive={show ? 1 : 0}
          to={navItem.url[0]}
          href={navItem.url[0]}
          external={navItem && navItem.isExternalLink}
          id={navItem.id}
        // alternative
        >
          {navItem.name}
        </NavigationLink>
        {hasChildLinks && !!childLinks.length && (
          <DropdownFlexWrapper indexProp={indexProp}>
            <DropdownContainer
              onMouseLeave={this.hide}
              numOfBigLinks={this.getNumOfBigLinks(childLinks)}
              numOfSmallLinks={this.getNumOfSmallLinks(childLinks)}>
              {childLinks.map((navItem, index) => {
                const hasChildLinks = navItem.HasChildNavigationLinks;
                const childLinks = navItem.childNavigationLinks;
                const filteredName = navItem.name.replace("$", "");

                return (
                  <DropdownItem key={navItem.name + index}>
                    <NavigationLink
                      font={fonts.LLBrown.BOLD}
                      activeClassName={activeClassName}
                      to={navItem.url[0]}
                      href={navItem.url[0]}
                      id={navItem.id}
                      dropdown="true"
                      external={navItem && navItem.isExternalLink}
                      onlyForAuthenticatedVisitors={navItem.onlyForAuthenticatedVisitors}>
                      {filteredName}
                    </NavigationLink>
                    {hasChildLinks && !!childLinks.length && (
                      <DropdownSmallContainer>
                        {childLinks.map((navItem, index) => {
                          const filteredChildName = navItem.name.replace("$", "");
                          return (
                            <DropdownSmallItem key={navItem.name + index}>
                              <ChildNavigationLink
                                font={fonts.LLBrown.REGULAR}
                                activeClassName={activeClassName}
                                to={navItem.url[0]}
                                id={navItem.id}
                                onlyForAuthenticatedVisitors={navItem.onlyForAuthenticatedVisitors}>
                                {filteredChildName}
                              </ChildNavigationLink>
                            </DropdownSmallItem>
                          );
                        })}
                      </DropdownSmallContainer>
                    )}
                  </DropdownItem>
                );
              })}
            </DropdownContainer>
          </DropdownFlexWrapper>
        )}
      </DropdownParent>
    );
  }
}

const mapStateToProps = state => ({
  router: state.router
});

export default connect(mapStateToProps)(Dropdown);
