import React from 'react';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { related, reFormat } from '../../utils';
import { FlexContainer } from 'components/flex';
import {
  RelatedItemContainer,
  RelatedItemText,
  RelatedItemImage,
  RelatedItemImageContainer,
  RelatedItemLink,
  RelatedItemContentWrapper,
  RelatedItemTypeHeader
} from './index';
import { LockOrangeCircle } from 'features/Account/components';
import WithAuthentication from 'features/WithAuthentication';

class RelatedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      imageUrl: null,
      linkUrl: null
    };
  }

  useHoverEffect = () => {
    const { carousel, type } = this.props;

    return carousel && type !== 'dokument';
  };

  handleMouseOver = () => {
    this.setState({ hover: true });
  };

  handleMouseleave = () => {
    this.setState({ hover: false });
  };

  getLink = (url, documentType) => {
    const { openIn } = this.props;
    switch (documentType) {
      case 'dokument':
        return openIn === '_blank' ? (
          <RelatedItemLink external target={openIn} to={url} rel="noopener noreferrer" />
        ) : (
          <RelatedItemLink external target={openIn || '_self'} to={url} />
        );
      case 'externallink':
        return openIn === '_blank' ? (
          <RelatedItemLink external target={openIn} to={url} rel="noopener noreferrer" />
        ) : (
          <RelatedItemLink external target={openIn || '_self'} to={url} />
        );
      default:
        return openIn === '_blank' ? (
          <RelatedItemLink target={openIn} to={url} rel="noopener noreferrer" />
        ) : (
          <RelatedItemLink to={url} />
        );
    }
  };

  getHeader = (documentType, hidetype) => {
    if (documentType === 'dokument' && !hidetype) {
      return (
        <RelatedItemTypeHeader center bottom color={Colors.ORANGE}>
          {documentType}
        </RelatedItemTypeHeader>
      );
    }
    return null;
  };

  render() {
    const {
      linkUrl,
      imageUrl,
      text,
      tag,
      documentType,
      useFullWidthImage,
      fileType,
      hidefiletype,
      carousel,
      hidetype,
      children,
      altText,
      square,
      locked
    } = this.props;

    return (
      <FlexContainer justifycontent="center" alignItems="center">
        <RelatedItemContainer
          square={square}
          className="RelatedItem"
          horizontal={carousel ? 1 : 0}
          isimage={useFullWidthImage}
          usebackground={documentType === 'dokument'}
          usehovereffect={this.useHoverEffect()}
          onMouseOver={this.handleMouseOver}
          onMouseLeave={this.handleMouseleave}>
          {locked && <LockOrangeCircle topright />}
          {this.getLink(linkUrl, documentType)}
          <RelatedItemContentWrapper>
            {this.getHeader(documentType, hidetype)}
            <RelatedItemImageContainer
              round={fileType === 'PDF' && carousel}
              isimage={useFullWidthImage}
              square={square}
              hover={this.useHoverEffect() && this.state.hover}>
              {imageUrl && (
                <RelatedItemImage
                  alt={altText}
                                isimage={useFullWidthImage}
                                relatedItem={true}
                  src={useFullWidthImage ? related(imageUrl) : reFormat(imageUrl)}
                />
              )}
            </RelatedItemImageContainer>
          </RelatedItemContentWrapper>
          <RelatedItemContentWrapper flex>
            {text && <RelatedItemText>{text}</RelatedItemText>}
            {!hidefiletype && (
              <RelatedItemTypeHeader center position="top" color={Colors.ORANGE}>
                {tag && tag}
              </RelatedItemTypeHeader>
            )}
            {children}
          </RelatedItemContentWrapper>
        </RelatedItemContainer>
      </FlexContainer>
    );
  }
}

export default WithAuthentication(RelatedItem);

RelatedItem.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  tag: PropTypes.string,
  type: PropTypes.oneOf(['dokument', 'pagelink', 'image']),
  carousel: PropTypes.bool,
  minwidth: PropTypes.string,
  minheight: PropTypes.string,
  hidefiletype: PropTypes.bool,
  openIn: PropTypes.oneOf(['_blank', '_top', '_self'])
};
