import styled, { css } from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import PropTypes from 'prop-types';
import Polymorph from 'features/Polymorph';

const BaseInlineLink = styled(Polymorph).attrs(() => ({ as: 'a' }))`
  display: inline-block;
  text-decoration: none;
  color: ${Colors.BLACK};
  position: relative;
  display: flex;
  align-items: center;
  /* white-space: nowrap; */
`;

export const InlineLink = styled(BaseInlineLink)``;

export const InlineLinkWithIcon = styled(InlineLink)`
  position: relative;
  cursor: pointer;
  word-break: break-all;

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};

  strong {
    min-width: fit-content;
    word-break: keep-all;
  }

  &:before {
    content: '';
    background: ${({ icon }) => (icon ? `url(${icon})` : 'initial')};
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: ${({ icon, large }) => (large ? '0.9583rem' : '1em')};
    width: ${({ icon, large }) => (large ? '0.9583rem' : '1em')};
    margin-right: 0.5em;
  }
`;

InlineLinkWithIcon.propTypes = {
  icon: PropTypes.string,
  large: PropTypes.bool
};

export const InlineWithIcon = styled(InlineLinkWithIcon).attrs(() => ({ as: 'span' }))``;

InlineWithIcon.propTypes = {
  icon: PropTypes.string
};
