import styled from 'styled-components/macro';
import { Perspective } from 'components/common';
import { zIndex } from 'constants/settings';
import { media } from 'style/media';

export const HamburgarePerspective = styled(Perspective).attrs(props => ({
  smallwidth: props.show ? '100%' : 0
}))`
  z-index: ${zIndex.MODALS};
  right: 0;
  height: 100%;
  position: fixed;
  ${media.desktop`
    width: ${props => props.smallwidth};
    max-width: 100%;
  `};
`;
