import styled from 'styled-components/macro';
import { media } from 'style/media';

export const SliderContainer = styled.div`
  margin: 0 0 1.667rem 0;
  padding-bottom: 2rem;
  ${media.desktop`
        padding-bottom: 0;
        margin: 0 0 2.667rem 0;
    `};
`;
