export default {
  reactPageElementExist: selector => {
    return getPageElement(selector) !== null;
  },
  getReactPageElement: selector => {
    return getPageElement(selector);
  },
  reactBlockElementExist: selector => {
    return getBlockElement(selector) !== null;
  },
  getReactBlockElement: selector => {
    return getBlockElement(selector);
  },
  reactComponentElementExist: selector => {
    return GetComponentElement(selector) !== null;
  },
  getReactComponentElement: selector => {
    return GetComponentElement(selector);   
  },
  getProps: element => {
    return JSON.parse(element.getAttribute('data-model'));
  },
  getJsonPage: element => {
    try {
      return JSON.parse(element.getAttribute('data-json-page'));
    } catch (e) {
      return {};
    }
  },
  inEpiEditMode: () => {
    const isInEpiEditMode = document.querySelectorAll('[data-episerver]').length > 0;
    return isInEpiEditMode;
  },
  getCurrentPageId: () => {
    const element = document.querySelectorAll('[data-current-page-id]');
    if (!element || element.length <= 0) return '';
    const first = element[0];
    return parseInt(first.getAttribute('data-current-page-id'), 10);
  }
};

let getBlockElement = selector => {
  return document.querySelector('[data-react-block="' + selector + '"]');
};

let getPageElement = selector => {
  return document.querySelector('[data-react-page="' + selector + '"]');
};

let GetComponentElement = selector => {
  return document.querySelector('[data-react-component="' + selector + '"]');
};

export const setMetaTags = (metaSelector, content) => {
  if (!document.querySelector(`meta[${metaSelector}]`)) return;
  document.querySelector(`meta[${metaSelector}]`).setAttribute('content', content);
};

export const getMetaTagContent = metaSelector => {
  if (!document.querySelector(`meta[${metaSelector}]`)) return;
  return document.querySelector(`meta[${metaSelector}]`).getAttribute('content');
};

export const getGoogleAnalyticsId = () => {
  return document.querySelector('html').dataset.googleAnalytics;
};
export const getAntiforgeryToken = () => {
  try {
    let tokenElement = document.getElementById('anti_forgery_token');
    return tokenElement.value;
  } catch (e) {
    return '';
  }
};

export const isElementInViewport = (el, fullyVisible = false) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  if (fullyVisible) {
    return elemTop >= 0 && elemBottom <= window.innerHeight;
  }
  // Partially visible elements return true:
  const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
};
