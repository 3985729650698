import styled from 'styled-components/macro';
import { settings as Settings, zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const NavigationSearchWrapper = styled.div`
  position: absolute;
  left: 1.5rem;
  > button:first-of-type {
    margin: 0;
    padding: 0;
  }
  display: none;

  ${media.desktop`
    display: block;
  `};
`;

export const NavigationContainer = styled.nav`
  z-index: ${zIndex.LEVEL_3};
  width: 100%;
  top: 0;
  position: sticky;
  min-height: 3.51rem;
  height: 3.51rem;
  padding: 0 0 0 4.458rem;
  background: ${Colors.WHITE};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
  ${media.extraGiant`
    padding: 0 0 0 2.5rem;
  `}
  ${media.giant`
    padding: 0 0 0 2rem;
  `}
  ${media.biggerdesktop`
    padding: 0 0 0 1rem;
  `}
  ${media.bigdesktop`
    padding: 0 0 0 0.5rem;
  `}
  ${media.desktop`
  background: ${Colors.GRAY};
    padding: ${Settings.MENU_PADDING} 0 ${Settings.MENU_PADDING} 0;
    justify-content: center;
    min-height: ${Settings.MENU_HEIGHT_NO_COUNTYPICKER};
    height: ${Settings.MENU_HEIGHT_NO_COUNTYPICKER};
    top: 3rem;
  `};
`;

export const SecondNavigationContainer = styled(NavigationContainer)`
min-height: 2rem;
height: 2rem;
position: sticky;
top: 3.5rem;
display: flex;
background: ${Colors.GRAY};
${media.extraGiant`
padding: 0 0 0 2.5rem;
min-height: 2rem;
height: 2rem;
`}
${media.giant`
padding: 0 0 0 2rem;
`}
${media.biggerdesktop`
padding: 0 0 0 1rem;
`}
${media.bigdesktop`
padding: 0 0 0 0.5rem;
`}
${media.desktop`
  display: none;
`};
`;

NavigationContainer.propTypes = {};
