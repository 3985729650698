import styled from 'styled-components/macro';
import { media } from 'style/media';

export const LeftContainer = styled.div`
    margin: 1rem 0;
    min-width: 30%;

    ${media.desktop`
    min-width: 0%;
    `};
`;
