import styled, { css } from 'styled-components/macro';
import chevron from 'images/chevron.svg';

export const PaginationContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PaginationArrow = styled.button.attrs(props => ({
  rotate: props.right ? '90deg' : '270deg'
}))`
  margin: 0 1.5rem;
  border: none;
  transform: rotate(${props => props.rotate});
  width: 18px;
  height: 18px;
  background: no-repeat url(${chevron});
  background-size: cover;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}
`;
