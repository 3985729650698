import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { MediumText } from 'components/text';
const extraGiant = 1440;

export const LinkButton = styled.div`
  display: inline-block;
  margin: 1rem 0;
  padding: 0.55rem 1.2rem;
  background-color: ${Colors.WHITE};
  color: ${Colors.BLACK};
 border: solid 2px ${Colors.ORANGE};
 width: fit-content;
 border-radius: 4px;
  :hover {
    cursor: pointer;
  }
  ${media.phone`
  padding:0.55rem 1.2rem;
  margin: 1rem 0;
  `}
`;

export const LinkButtonText = styled(MediumText).attrs(props => ({
  modal: props.modal
}))`
  font-size: 0.8rem;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;
  color: ${Colors.BLACK};
  :hover {
    cursor: pointer;
  }
`;

export const PageContent = styled.div.attrs(props => ({}))`
  @media (max-width: ${extraGiant}px) {
    max-width: ${({ hasrelated }) => hasrelated ? 'calc(42.25rem - 0.8333rem + 7.083rem)' : 'calc(42.25rem - 0.8333rem)'};
  }
  @media (min-width: ${extraGiant}px) {
    max-width: ${({ hasrelated }) => hasrelated ? 'calc(46.25rem - 0.8333rem + 7.083rem)' : 'calc(46.25rem - 0.8333rem)'};
  }
  width: 100%;
  padding: 0 0.8333rem 0 0.8333rem;
  padding-top: 0;
  background: ${Colors.TRANSPARENT};
  position: relative;
  margin: 0 auto ${({ bottommargin }) => (bottommargin ? '1.667rem' : 0)} auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    padding: 0 .5rem 0 .5rem;
  `};
`;