import { fetchPage } from '../api/ContentApi';
import * as TYPES from './pageTypes';
import { setMetaTags as utilsSetMetaTags } from '../utils';

export const setCurrentPage = (id) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAGE_FETCH });
      const page = await fetchPage(id);
      dispatch({ type: TYPES.PAGE_FETCHED, page: page, id: id });
      return page;
    } catch (e) {
      throw e;
    }
  };
};

export const setCurrentPageId = id => {
  return async dispatch => {
    dispatch({ type: TYPES.PAGE_SET_ID, id });
  };
};

export const setTitle = ({ metaTitle }) => {
  document.title = metaTitle;
};

export const setMetaTags = ({ metaTitle, metaDescription, metaKeyword }) => {
  utilsSetMetaTags(`name="description"`, metaDescription);
  utilsSetMetaTags(`name="keywords"`, metaKeyword);
  utilsSetMetaTags(`property="og:title"`, metaTitle);
};
