import styled from 'styled-components/macro';
import { media } from 'style/media';
import { footer } from 'constants/settings';

export const PageWrapper = styled.div.attrs(props => ({
  width: props.width ? props.width : '100%',
  paddingtop: props.page ? (props.isModalOpen ? '1.25rem' : '4.25rem') : 0
}))`
  min-height: calc(100vh - ${footer.HEIGHT});
  width: ${props => props.width};
  height: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  ${media.desktop`
  padding-top: ${props => props.paddingtop};
  `};
`;
