import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { fonts } from 'style/fonts';
import { SecondaryButtonLink, ButtonText } from 'components/button';
import icon from 'images/external-link.svg';

export const RelatedBlockWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 55%;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  background: ${Colors.GRAY};

  ${media.mediumtablet`
    height: 50%;
  `}
`;

export const RelatedBlockContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: ${Colors.GRAY};
`;

export const RelatedBlockImage = styled.div`
  background-image: ${({ image }) => (image ? 'url(' + image + ')' : 'none')};
  height: 45%;
  background-position: center center;
  background-size: cover;
  ${media.mediumtablet`
    height: 50%;
  `}
`;

export const RelatedBlockContainer = styled.div`
  width: calc(33% - 0.5rem);
  height: 19.5rem;
  color: white;
  display: block;
  /* margin: 0.5rem 0.3rem; */
  overflow: hidden;
  ${media.mediumtablet`
    width: 100%;
    max-width: 400px;
    height: calc(1.15 * 90vw);
    max-height: calc(1.15 * 400px);
  `}
`;

export const RelatedBlockCategoryContainer = styled.div`
  font-family: ${fonts.LLBrown.REGULAR};
  display: flex;
  font-size: calc(1 * (12px + (14 - 12) * (100vw - 400px) / (1440 - 400)));
  position: relative;
  margin-bottom: 0.7rem;
  top: 4%;
  left: 4%;
  ${media.mediumtablet`
      left: 3%;
    `};
`;

export const SingleRelatedBlockContainer = styled(RelatedBlockContainer)`
  width: 31.5rem;
  height: 11.5rem;
  max-width: none;
  max-height: none;
  ${media.mediumtablet`
    width: 31.5rem;
    max-width: none;
    height: 11.5rem;
    max-height: none;
  `}
  ${media.smalltablet`
    width: 90vw;
    max-width: 400px;
    height: calc(1.15 * 90vw);
    max-height: calc(1.15 * 400px);

    `};
`;
export const SingleRelatedBlockWrapper = styled(RelatedBlockWrapper)`
  width: 45%;
  height: 100%;

  ${media.mediumtablet`
  width: 45%;
  height: 100%;
  `};
  ${media.smalltablet`
    height: 50%;
    display: block;
  z-index: 1;
  width: 100%;
  position: relative;
  `}
`;
export const SingleRelatedBlockContentWrapper = styled(RelatedBlockContentWrapper)`
  align-items: flex-start;
`;
export const SingleRelatedBlockImage = styled(RelatedBlockImage)`
  float: right;
  height: 100%;
  width: 55%;
  ${media.mediumtablet`
  width: 55%;
  height: 100%;
  `};
  ${media.smalltablet`
float: none;
background-image: ${({ image }) => (image ? 'url(' + image + ')' : 'none')};
height: 50%;
width: 100%;
background-position: center;
background-size: 100% auto;
`}
`;
export const SingleRelatedBlockCategoryContainer = styled(RelatedBlockCategoryContainer)`
  left: 4%;
  ${media.mediumtablet`
      left: 4%;
    `};
`;

export const RelatedBlockButton = styled(SecondaryButtonLink).attrs({ as: 'a' })`
  border: 2px solid ${Colors.ORANGE};
  border-radius: 5px;
  width: fit-content;
  bottom: 6%;
  position: absolute;
  left: 3%;
  text-decoration: none;
`;

export const RelatedBlockButtonText = styled(ButtonText)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ExternalIcon = styled.div`
  background: url(${icon});
  border: none;
  background-size: 16px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0.5em;
`;
