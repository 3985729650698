import styled from 'styled-components/macro';
import { media } from 'style/media';

export const ContractCollectionItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 0.7rem 0 0 0;

  ${media.tablet`
    grid-template-columns: repeat(1, 1fr);    
    padding: 1rem 0 1rem 0;
  `};
`;
