import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { NavigationLink } from 'features/Navigation/components';

export const JobListBlockLargeContainer = styled.div`
  background-color: ${Colors.GRAY};
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  max-height: 305px;
  ${media.desktop`
  max-height: none;
    flex-direction: column;
        height: auto;
    `}
`;
export const JobListBlockSmallContainer = styled(NavigationLink)`
  background-color: ${Colors.GRAY};
  display: flex;
  height: 80px;
  margin-bottom: 1rem;
  justify-content: flex-start;
  font-size: 18px;
  align-items: center;
  padding-right: 1rem;
  width: auto;
  ${media.desktop`
    flex-direction: column;
        height: auto;
        border: 1px solid ${Colors.COOKIE_BORDER}
        text-align: left;
        align-items: flex-start;
    padding: 20px;
    `}

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 7px ${Colors.COOKIE_BORDER};

    &:after {
      display: none;
    }
  }
`;
