import styled from 'styled-components/macro';
import { media } from 'style/media';

export const FrequentSearchFlex = styled.div`
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: ${({ margin }) => (margin ? margin : '2rem 4rem 0')};
  ${media.tablet`
  grid-template-columns: 1fr;
  gap: 1rem;
  margin: 2rem 0 0;
  `};
`;
