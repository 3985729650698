class EpiFormHelper {
  constructor({ guid }) {
    this.FormsApi = undefined;
    this.guid = guid;
    // this.onLoad = onLoad;
    // this.initializationInterval = null;
    // const initialized = this.initialize();
    // this.loadInitScript();
  }

  loadInitScript = () => {
    // const existingScript = document.getElementById(`epiforms-script_${this.guid}`);
    // if (!existingScript) {
    //   const script = document.createElement('script');
    //   script.src = `${window.location.protocol}//${
    //     window.location.host
    //   }/EPiServer.Forms/DataSubmit/GetFormInitScript?formGuid=${this.guid}`;
    //   script.id = 'epiforms-script';
    //   document.body.appendChild(script);
    //   script.onload = () => {
    //     console.log(window.epi.EPiServer.Forms);
    //     this.onLoad();
    //   };
    // }
  };

  getFormData = (form, language = 'sv', pageId = -1) => {
    try {
      let formData = new FormData();

      formData.append('__FormGuid', this.guid);
      formData.append('__FormHostedPage', pageId);
      formData.append('__FormLanguage', language);
      formData.append('__FormCurrentStepIndex', 0);
      formData.append('__FormWithJavaScriptSupport', 'true');

      let ovalue;
      for (let key in form) {
        if (!form.hasOwnProperty(key)) {
          continue;
        }

        ovalue = form[key];
        // checking file upload elements, item must be File if any,
        // for using Object.getPrototypeOf(variable) variable must be object type
        if (
          Array.isArray(ovalue) &&
          ovalue.length > 0 &&
          ovalue[0] !== null &&
          typeof ovalue[0] === 'object' &&
          ovalue[0].file &&
          Object.getPrototypeOf(ovalue[0].file) === File.prototype
        ) {
          let files = ovalue,
            fileNames = '',
            ofile;

          // append each upload file with a unique key (bases on element's key) so that the server side can see it through the Request.Files,
          // concat all the files' name and assign with the element's Id
          for (let idx = 0; idx < files.length; idx++) {
            ofile = files[idx].file;
            formData.append(`${key}_file_${idx}`, ofile);
            fileNames += ofile.name + '|'; // charactor | cannot be used in filename and then is safe for splitting later
          }
          formData.append(key, fileNames);
        } else {
          formData.append(key, ovalue);
        }
      }
      return formData;
    } catch (e) {
      return { IsSuccess: false, ErrorMessage: 'couldnt parse formdata' };
    }
  };
}

export default EpiFormHelper;
