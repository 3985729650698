import React from 'react';
import PropTypes from 'prop-types';
import { Image, ImageAsBackground } from 'components/images/Image';

export default class FadeInImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      mounted: false
    };

    this.image = null;
  }

  onImageLoad = () => {
    this.props.onImageLoad();
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.setState({ mounted: true });
    const imgSrc = this.props.src;
    this.image = new window.Image();
    this.image.src = imgSrc;
    this.image.onload = this.onImageLoad;
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.removeEventListener('onload', this.onImageLoad);
      this.image.onload = null;
      this.image = null;
    }
  }

  render() {
    const { onImageLoad, ...restprops } = this.props;
    const settings = {
      ...restprops,
      fadein: true,
      loaded: this.state.loaded,
      center: this.props.center,
      fit: this.props.fit
    };

    let image = this.props.asBackground ? (
      <ImageAsBackground key={2} {...settings} />
    ) : (
      <Image key={3} {...settings} />
    );
    return image;
  }
}

FadeInImage.defaultProps = {
  onImageLoad: () => {}
};

FadeInImage.propTypes = {
  //if as background is true. Then we'll render a div with background instead of <img/>.
  //this will be absolute anv places behind other divs.
  asBackground: PropTypes.bool,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  onImageLoad: PropTypes.func
};
