import React from 'react';
import ReactDOM from 'react-dom';
import DOMHelper from './utils/DOMHelper';
//import { ConnectedRouter } from 'react-router-redux';
import { ConnectedRouter } from 'connected-react-router';

import { Provider } from 'react-redux';
import store, { history } from './store/store';
//Block
import BlockArea from './features/BlockArea';


//Components
import { Navigation as Menu } from './features/Navigation';
import Raven from 'raven-js';

import { App } from './pages/app';
import { Route } from 'react-router-dom';
import withTracker from './features/WithTracker';
import { getGoogleAnalyticsId } from './utils';

import { MainContainer } from './pages/app/MainContainer';

export default {
  renderBlockPreview: () => {
    if (!DOMHelper.reactBlockElementExist('base')) return;
    let props = DOMHelper.getProps(DOMHelper.getReactBlockElement('base'));
    const { propertyName, ...block } = props;
    return ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BlockArea propertyName={propertyName} contentarea={[block]} />
        </ConnectedRouter>
      </Provider>,
      DOMHelper.getReactBlockElement('base')
    );
  },
  renderMenu: props => {
    if (!DOMHelper.reactComponentElementExist('menu')) return;
    return ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Menu {...props} />
        </ConnectedRouter>
      </Provider>,
      DOMHelper.getReactComponentElement('menu')
    );
  },
  renderApp: store => {
    const currentPageId = DOMHelper.getCurrentPageId();
    //console.log(DOMHelper.getJsonPage(DOMHelper.getReactPageElement("basepage")))
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route
            path="/"
            component={withTracker(
              () => (
                <App currentid={currentPageId} />
              ),
              getGoogleAnalyticsId()
            )}
          />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
  },
  renderEditModeApp: store => {
    if (!DOMHelper.reactPageElementExist('basepage')) return;
    let props = DOMHelper.getProps(DOMHelper.getReactPageElement('basepage'));
    return ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MainContainer>
            <Route path="/" component={() => <App currentid={props.id} />} />
          </MainContainer>
        </ConnectedRouter>
      </Provider>,
      DOMHelper.getReactPageElement('basepage')
    );
  },
  setupRavenLogger: () => {
    // Raven.config('https://5ad3fad3d46740c9a692dbd64894faaf@sentry.io/251965', {
    //   onError: () => console.error('Error sentry'),
    //   onSuccess: () => console.log('Sent error')
    // }).install();
    if (process.env.NODE_ENV !== 'development') {
      Raven.config('https://594b0038bb2d4148929b481fdc33729c@sentry.io/251964', {
        release: process.env.VERSION
      }).install();
    }
  }
};
