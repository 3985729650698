import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OPE from 'components/common/EpiOnPageEdit';
import { colors as Colors } from 'constants/colors';
import { Message } from 'components/message';
import { ErrorHandler } from 'utils';
import { UnderlineContainer, UnderlineContainerText } from 'components/UnderlineContainer';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import { isEmpty, getSelectedFromSelectionProperty } from '../../utils';
import { fetchLinkListBlockItems } from '../../api/SearchApi';
import { resetFilter } from 'features/FilterArea/filterAreaActions';
import { Block, CenteredBlockInner, BlockHeader } from '../components';
import { LinkList } from 'features/LinkList';

import BlockArea from 'features/BlockArea';
class LinkListBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      error: {
        hasError: false,
        message: ''
      },
      first: true
    };
  }

  componentDidMount() {
    const { filter: countyFilter } = this.props;
    const { selected: selectedCounty } = countyFilter;
    if (isEmpty(selectedCounty)) return false;
    resetFilter();
    this.fetchListBlockItems();
  }

  fetchListBlockItems = async () => {
    const { id, filter: countyFilter, filterarea, ignoreFilters } = this.props;
    if (id === undefined) return;
    const { selected: selectedCounty, list } = countyFilter;
    if (isEmpty(selectedCounty)) return false;
    const { selectedFilters } = filterarea;

    const finalCountyFilter = ignoreFilters ? list[0] && list[0].id : selectedCounty.id;

    const categoryFilters = ignoreFilters ? undefined : selectedFilters;

    const result = await fetchLinkListBlockItems(id, finalCountyFilter, categoryFilters);

    if (isEmpty(result.items)) {
      ErrorHandler.LogWarningSilently(
        `no hits in LinkListBlock ${id} (${finalCountyFilter}).`,
        undefined,
        { id: id, filter: finalCountyFilter, categories: categoryFilters }
      );

      this.setState({
        error: { hasError: true, message: 'Inga träffar' },
        items: [],
        first: false
      });
    } else {
      this.setState({
        items: result.items,
        error: {
          hasError: false,
          message: ''
        },
        first: false
      });
    }
  };

  receivedNewCountyFilter = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { first } = this.state;
    const { filter } = this.props;
    if (isEmpty(filter.selected)) return false;
    if (filter.selected.id !== prevFilter.selected.id || first) return true;
    return false;
  };

  receivedNewCategoryfilter = prevProps => {
    const { filterarea: prevFilterarea } = prevProps;
    const { filterarea } = this.props;
    if (prevFilterarea.selectedFilters !== filterarea.selectedFilters) return true;
    return false;
  };

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    const { list } = filter;

    if (isEmpty(list)) return;

    if (this.receivedNewCountyFilter(prevProps) || this.receivedNewCategoryfilter(prevProps)) {
      this.fetchListBlockItems();
    }
  }

  render() {
    const { pageTypeCategory, mainBody, filterContentArea, heading } = this.props;
    const { items, error } = this.state;
    const text = getSelectedFromSelectionProperty(pageTypeCategory);

    return (
      <Block>
        <CenteredBlockInner>
          {filterContentArea && (
            <BlockArea propertyName="filterContentArea" nopadding contentarea={filterContentArea} />
          )}
          {text && (
            <UnderlineContainer hide={!text} position="bottom" color={Colors.ORANGE}>
              <OPE propertyName="type">
                <UnderlineContainerText>{text}</UnderlineContainerText>
              </OPE>
            </UnderlineContainer>
          )}
          {heading && <BlockHeader>{heading}</BlockHeader>}
          <OPE propertyName="mainBody" floating={false}>
            <HtmlComponentContainer margin="1rem 0rem 0 0rem">
              <HtmlComponent html={mainBody} />
            </HtmlComponentContainer>
          </OPE>

          <Message relative visible={error.hasError} key="error">{`${error.message}`}</Message>

          {!error.hasError && <LinkList data={items} />}
        </CenteredBlockInner>
      </Block>
    );
  }
}

LinkListBlock.protoTypes = {
  filter: PropTypes.objectOf({
    selected: PropTypes.object,
    categoryList: PropTypes.array
  })
};

const mapStateToProps = ({ filter, filterarea }) => ({
  filter: {
    selected: filter.selected,
    list: filter.categoryList
  },
  filterarea: {
    selectedFilters: filterarea.selectedFilters
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LinkListBlock);
