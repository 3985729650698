import React from 'react';
import { bindActionCreators } from 'redux';
import { colors as Colors } from 'constants/colors';
import { connect } from 'react-redux';
import { zIndex } from 'constants/settings';
import { setSelectedFilter, fetchFilters, toggleModal } from '../CountyFilter/countyFilterActions';
import {
  CountyList,
  CountyListItem,
  CountyFilterTransition,
  CountyFilterDesktop,
  CountyFilterMobile,
  CountyListBelow,
  CountyListTitle,
  CountyListTitleMobile,
  CountyListSmallText
} from './components';
import { FlexContainer } from 'components/flex';
import { UnderlineContainer } from 'components/UnderlineContainer';
import ReactSelect from 'features/ReactSelect';
import { Logo } from 'components/common/Logo';
import swedenLogo from 'images/fastighetsagarna-sverigekarta.svg';
import { push } from 'connected-react-router';
import { push as gtmPush } from 'utils';
class ModalCountyFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledCounties: [],
      gtmPushTriggered: false
    };
  }

  handleOnClick = value => {
    const {
      setSelectedFilter,
      toggleModal,
      currentPage,
      selected: currentFilter,
      cookie
    } = this.props;
    //If disabled counties is to return at some point, just uncomment these two lines and uncomment the cursor in CountyListItem.js
    // const { disabledCounties } = this.state;
    const { hideCountyInUrl } = currentPage;

    if (cookie.accepted && currentFilter.id !== value.id) {
      gtmPush('user_selected_county', { county_location: value.name });
    }

    // if (disabledCounties.includes(value.id)) return null;
    setSelectedFilter(value, null, hideCountyInUrl);
    toggleModal(false);
  };

  showCountyModal = () => {
    const {
      selected: { id },
      toggleModal,
      currentPage
    } = this.props;
    const { forceCountyPicker } = currentPage;
    const { disabledCounties } = this.state;

    if (!forceCountyPicker) return null;
    if (!disabledCounties.includes(id)) return null;

    toggleModal(true, true);
  };

  setDisabledCounties = () => {
    const { rootCountyId, categoryList, currentPage = {} } = this.props;

    const { forceCountyPicker, category = [] } = currentPage || {};
    const disabledCounties = !forceCountyPicker
      ? []
      : categoryList.map(({ id }) => id).filter(id => !category.includes(id));
    this.setState(
      {
        disabledCounties: [
          ...disabledCounties,
          forceCountyPicker ? rootCountyId : undefined
        ].filter(x => x !== undefined)
      },
      () => {
        this.showCountyModal();
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { selected, cookie } = this.props;

    if (cookie.accepted && !this.state.gtmPushTriggered) {
      gtmPush('selected_county', {
        county_location: selected.name
      });

      this.setState({
        gtmPushTriggered: true
      });
    }

    const { currentPage } = this.props;
    const { currentPage: prevPage } = prevProps;

    if (currentPage === prevPage) return null;
    this.setDisabledCounties();
  };

  shouldComponentUpdate = prevProps => {
    const {
      selected: nextSelected,
      isModalOpen: nextIsModalOpen,
      currentPageId: prevPageId
    } = prevProps;
    const { selected, isModalOpen, currentPageId } = this.props;

    if (nextSelected !== selected) return true;
    if (nextIsModalOpen !== isModalOpen) return true;
    if (nextIsModalOpen !== isModalOpen) return true;
    if (prevPageId !== currentPageId) return true;
    return false;
  };

  fetchCategoryNames(categoryList) {
    const list = categoryList.map(i => i.name);
    return list;
  }

  render() {
    const {
      categoryList = [],
      isModalOpen,
      selected,
      rootCountyId,
      currentPage = {},
      textAboveCountyPicker,
      textUnderCountyPicker,
      renamedAllCounties,
      textUnderAllCounties
    } = this.props;
    if (!categoryList || !Array.isArray(categoryList)) return null;
    const { disabledCounties } = this.state;
    const { forceCountyPicker } = currentPage || {};
    const { id: selectedId } = selected;

    if (!isModalOpen) return null;
    return (
      <>
        <CountyFilterTransition in={isModalOpen}>
          <CountyFilterDesktop isModalOpen>
            <FlexContainer
              padding="1rem 0 0 0"
              zindex={zIndex.LEVEL_2}
              justifycontent="center"
              backgroundImage={
                'url(' +
                swedenLogo +
                ') no-repeat 80vw, linear-gradient(to bottom, #ffffff, #f8f9f9);'
              }
              column>
              <CountyListTitle paddingbottom="0px" paddingtop="0.7rem" orange>
                {textAboveCountyPicker}
              </CountyListTitle>
              <CountyListTitle black small>
                {textUnderCountyPicker}
              </CountyListTitle>
              <CountyList>
                {categoryList.map(({ id, name }, index) => {
                  const isSelected = id === selectedId;
                  const isHidden = forceCountyPicker && id === rootCountyId;
                  if (isHidden) return null;
                  if (id === 2) return null;
                  const isDisabled = disabledCounties.includes(id);
                  return (
                    <CountyListItem
                      isHidden={isHidden}
                      isDisabled={isDisabled}
                      key={index}
                      isSelected={isSelected}
                      onClick={() => this.handleOnClick({ id, name })}
                      value={name}
                    />
                  );
                })}
              </CountyList>
              <UnderlineContainer
                countyUnderline
                position="left"
                height="2px"
                color={Colors.ORANGE}
              />
              {categoryList.map(({ id, name }, index) => {
                const isSelected = id === selectedId;
                if (id === 2)
                  return (
                    <CountyListBelow key={index}>
                      <CountyListItem
                        key={index}
                        isSelected={isSelected}
                        onClick={() => this.handleOnClick({ id, name })}
                        value={renamedAllCounties != null ? renamedAllCounties : name}
                      />
                      <CountyListSmallText>{textUnderAllCounties}</CountyListSmallText>
                    </CountyListBelow>
                  );
                else {
                  return null;
                }
              })}
            </FlexContainer>
          </CountyFilterDesktop>
        </CountyFilterTransition>
        <CountyFilterTransition in={isModalOpen}>
          <CountyFilterMobile>
            <FlexContainer
              overflow="visible"
              minheight="285px"
              padding="0 0 4rem 0"
              justifycontent="center"
              backgroundImage={
                'url(' +
                swedenLogo +
                ') no-repeat 70vw, linear-gradient(to bottom, #ffffff, #f8f9f9);'
              }>
              <Logo countyPicker />
              <CountyListTitleMobile mobileTitle black>
                {textAboveCountyPicker}
              </CountyListTitleMobile>
              <CountyListTitleMobile black small>
                {textUnderCountyPicker}
              </CountyListTitleMobile>
              <ReactSelect
                countypicker
                options={categoryList.map(({ id, name }) => ({ value: id, label: name }))}
                onChange={obj => this.handleOnClick({ id: obj.value, name: obj.label })}
              />
            </FlexContainer>
          </CountyFilterMobile>
        </CountyFilterTransition>
      </>
    );
  }
}

const mapStateToProps = ({ filter, app, page, cookie }) => ({
  selected: filter.selected,
  categoryList: filter.categoryList,
  isModalOpen: filter.isModalOpen,
  forceMode: filter.forceMode,
  app: {
    counties: app.counties
  },
  rootCountyId: app.settings.rootCounty,
  currentPage: page.page,
  currentPageId: page.currentId,
  textAboveCountyPicker: app.settings.textAboveCountyPicker,
  textUnderCountyPicker: app.settings.textUnderCountyPicker,
  renamedAllCounties: app.settings.renamedAllCounties,
  textUnderAllCounties: app.settings.textUnderAllCounties,
  cookie: {
    accepted: cookie.accepted,
    set: cookie.set
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedFilter,
      fetchFilters,
      toggleModal,
      push
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalCountyFilter);

ModalCountyFilter.defaultProps = {
  selected: {},
  currentPage: {}
};
ModalCountyFilter.propTypes = {};
