import React from 'react';
import { NavigationLink } from 'features/Navigation';
import fonts from 'style/fonts';
import { connect } from 'react-redux';

class LoginLogout extends React.Component {
  RenderLink = (isAuthenticated, urlLogin, urlLogout) => {
    return (
      <NavigationLink
        external
        orange={1}
        alternative={1}
        spacebetween={1}
        font={fonts.LLBrown.REGULAR}
        to={isAuthenticated ? urlLogout : urlLogin}>
        {this.GetLinkText(isAuthenticated)}
      </NavigationLink>
    );
  };

  GetLinkText = isAuthenticated => {
    return isAuthenticated ? 'Logga ut' : 'Logga in';
  };

  render() {
    const { app, account, page } = this.props;
    const { urlLogin, urlLogout } = app;
    const { currentPage } = page;
    const { isAuthenticated } = account;

    if (!urlLogin || !urlLogout || !currentPage) return null;
    
    // //in case county is stockholm (id 27) then always render:
    // const { selectedId } = this.props.filter;
    // if (selectedId && selectedId === 27) return this.RenderLink(isAuthenticated, urlLogin, urlLogout); 

    const { onlyForAuthenticatedVisitors } = currentPage;

    if (!onlyForAuthenticatedVisitors && !isAuthenticated) return null;
    return this.RenderLink(isAuthenticated, urlLogin, urlLogout);
  }
}

const mapStateToProps = ({ app, account, page, filter }) => ({
  app: {
    urlLogin: app.oidcRoutes.urlLogin,
    urlLogout: app.oidcRoutes.urlLogout,
  },
  account: {
    isAuthenticated: account.isAuthenticated,
  },
  page: {
    currentPage: page.page
  },
  filter: {
    selectedId: filter.selected.id,
  }
});

export default connect(
  mapStateToProps,
)(LoginLogout);
