import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const Block = styled.div.attrs(
  ({
    background,
    wide,
    first,
    padding,
    marginbottom,
    smallpadding,
    smallbackground,
    formpadding,
    smallMarginBottom,
    hideoverflow,
    ...rest
  }) => ({
    background: background ? background : Colors.TRANSPARENT,
    smallbackground: smallbackground ? smallbackground : Colors.TRANSPARENT,
    width: wide ? '100%' : 'auto',
    margintop: first ? '2rem' : 0,
    marginbottom: marginbottom ? '1.667rem' : smallMarginBottom ? '1.23rem' : 0,
    padding: padding ? '0 0.8333rem 0 0.8333rem' : 0,
    smallpadding: smallpadding ? '0 0.5rem 0 0.5rem' : formpadding ? '0 1rem' : 0,
    overflow: hideoverflow ? 'hidden' : 'unset',
    ...rest
  })
)`
  background: ${props => props.background};
  position: relative;
  margin-top: ${props => props.margintop};
  padding: ${props => props.padding};
  margin-bottom: ${props => props.marginbottom};
  overflow: ${props => props.overflow};
  ${media.desktop`
    padding: ${props => props.smallpadding};   
  background: ${props => props.smallbackground};

  `};
`;

export const InlineBlockContent = styled(Block)``;

export const FlexBlock = styled(Block).attrs(props => ({
  direction: props.column ? 'column' : 'row',
  setPadding: props.setPadding
}))`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: center;
  justify-content: center;
  ${props =>
    props.setPadding &&
    `
    padding: ${props.setPadding};
  `}
`;

export const BlockInner = styled(Block).attrs(props => ({
  padding: props.padding ? props.padding : props.nopadding ? 0 : '0.8333rem',
  smallpadding: props.nopadding ? 0 : '1rem',
  maxwidth: props.maxwidth ? props.maxwidth : '100%',
  overflow: props.overflowvisible ? 'visible' : 'hidden',
  border: props.border ? `1px solid ${Colors.LIGHT_GRAY}` : 'none'
}))`
  padding: ${props => props.padding};
  max-width: ${props => props.maxwidth};
  overflow: ${props => props.overflowvisible};
  margin: ${({ margin }) => margin};
  border: ${props => props.border};
  ${media.desktop`
    padding: ${props => props.smallpadding};   
  `};
`;

export const CenteredBlockInner = styled(BlockInner).attrs(props => ({
  margin: props.margin ? props.margin : '0 auto'
}))`
  max-width: calc(36.25rem - 0.8333rem);
  width: 100%;
  margin: ${props => props.margin};
`;

export const DoubleContainer = styled(BlockInner).attrs(props => ({
  direction: props.column ? 'column' : 'row',
  smallmargin: props.noextramargin ? '2rem' : '4rem',
  margintop: props.margintop ? props.margintop : '2rem',
  marginbottom: props.marginbottom ? props.marginbottom : '2rem',
  margin: props.margin ? props.margin : false,
  flexstart: props.flexstart ? 'flex-start' : 'center',
  maxwidth: props.maxwidth ? props.maxwidth : ''
}))`
  display: flex;
  align-items: ${props => props.flexstart};
  justify-content: center;
  margin-top: ${props => props.margintop};
  overflow: initial;
  margin-bottom: ${props => props.marginbottom};
  width: 100%;
  max-width: ${props => props.maxwidth};
  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
  `}
  ${media.desktop`
    flex-direction: column;
    margin-top: 0;
    &:last-of-type{
      margin-bottom: 4rem;
    }
  `};
`;

const marginAmount = '0.5rem';
export const DoubleContainerItem = styled.div.attrs(props => ({
  order: props.order ? props.order : 1,
  margin: props.nopadding
    ? 0
    : props.specialMargin
    ? props.specialMargin
    : props.order === 1
    ? `0 ${marginAmount} 0 0`
    : `0 0 0 ${marginAmount}`,
  smallmargintop: props.smallmargintop ? props.smallmargintop : '0',
  width: props.width ? props.width : '100%'
}))`
  width: ${props => props.width};
  max-width: 30rem;
  ${props =>
    props.minwidth &&
    `
    min-width: ${props.minwidth}
  `}
  order: ${props => props.order};
  margin: ${props => props.margin};
  padding: 0 1rem;
  ${media.desktop`
    margin: 0;
    margin-top: ${({ smallmargintop }) => smallmargintop}
    max-width: 35rem;
    order: -1;
    align-items: center;
    justify-content: center;
  `};
`;

export const HalfWidthContaineritem = styled.div`
  width: 50%;
  position: relative;
`;

export const Push = styled.div.attrs(props => ({
  marginleft: props.on && !props.left ? (props.amount ? props.amount : '8rem') : 0,
  marginright: props.on && props.left ? (props.amount ? props.amount : '8rem') : 0
}))`
  margin-left: ${props => props.marginleft};
  margin-right: ${props => props.marginright};

  ${media.desktop`
    margin-left: 0;
    margin-right: 0;
  `};
`;
