import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';

export const IconContainer = styled.div.attrs(props => ({
  background: props.hover ? Colors.ORANGE : Colors.TRANSPARENT,
  size: props.hover ? 1.2 : 1
}))`
  transition: background 0.35s ease-in-out;
  cursor: pointer;
  position: relative;
  background: ${props => props.background};
  position: relative;
  width: 4.083rem;
  height: 4.083rem;
  ${'' /* padding: 1.5rem; */} display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

IconContainer.propTypes = {
  hover: PropTypes.bool
};
