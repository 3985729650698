import styled from 'styled-components/macro';
import { targetBrowser } from 'style/media';
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: calc((16.92rem + (3 * 1.417rem / 2)) * 3.1);
  margin: 0 auto;
  min-height: 10rem;
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;
