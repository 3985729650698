import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNavigation } from 'pages/app/appActions';
import {
  FooterContainer,
  StyledFooter,
  FooterItemContainer,
  FooterItemHeaderLink,
  FooterItem,
  FooterItemColumnWrapper,
  FooterItemColumn,
  FooterItemLink
} from './components';
import { Logo } from 'components/common/Logo';

class Footer extends React.Component {

  componentDidUpdate = (prevProps, prevState) => {
    const { filter } = this.props;
    const { filter: prevFilter } = prevProps;

    if (filter.selected === prevFilter.selected) return null;
    this.props.getNavigation(filter.selected.id);
  };

  getFooterItems = footer => {
    return footer.map((footerItem, index) => {
      if (!footerItem.links.length) return null;

      return (
        <FooterItemContainer key={index}>
          <FooterItem nomarginbottom>
            <FooterItemHeaderLink to={footerItem.headerLink}>
              {footerItem.header}
            </FooterItemHeaderLink>
          </FooterItem>
          {footerItem.links[0].hasTwoColumns ? (
            <FooterItemColumnWrapper>
              <FooterItemColumn>
                {footerItem.links.map((link, index) => {
                  const { leftName, leftLink } = link;

                  return <FooterItemLink key={index} name={leftName} link={leftLink} />;
                })}
              </FooterItemColumn>
              <FooterItemColumn>
                {footerItem.links.map((link, index) => {
                  const { rightName, rightLink } = link;

                  return <FooterItemLink key={index} name={rightName} link={rightLink} />;
                })}
              </FooterItemColumn>
            </FooterItemColumnWrapper>
          ) : (
              footerItem.links.map((link, index) => {
                const { name, link: url } = link;

                return <FooterItemLink key={index} name={name} link={url} />;
              })
            )}
        </FooterItemContainer>
      );
    });
  };
  render() {
    const { footer, app, page } = this.props;
    const { isLoading: appLoading } = app;
    const { loading: pageLoading } = page;
    // if (!footer || pageLoading) return null;
    if (!footer || appLoading || pageLoading) return null;

    return (
      <FooterContainer>
        <StyledFooter>{this.getFooterItems(footer)}</StyledFooter>
        <Logo />
      </FooterContainer>
    );
  }
}

const mapStateToProps = state => ({
  app: {
    isLoading: state.app.isLoading
  },
  page: {
    loading: state.page.loading
  },
  filter: {
    selected: state.filter.selected
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNavigation
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
