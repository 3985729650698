import React from 'react';
import InstructionBlock from 'blocks/instructionblock';
import { FlexContainer } from 'components/flex';
import { InstructionContainerCircle, InstructionContainerCircleText } from 'blocks/instructioncontainerblock';
// import { ButtonText, PrimaryButtonLink } from 'components/button';
// import { colors as Colors } from 'constants/colors';
import { PageContent as PageContainer } from 'pages/components';

class InstructionContainerBlock extends React.Component {


  render() {
    const { instructionBlockCollection } = this.props;
    return (
      <PageContainer>
        <FlexContainer style={{ flexWrap: 'wrap' }} smallAlignContent={'center'} smallpadding="1rem">
          {instructionBlockCollection.map((i, index) => (
            <FlexContainer
              column
              width={'50%'}
              key={i.id}
              smallwidth={'90%'}
              border={'0.5rem solid white'}
            >
              <InstructionContainerCircle>
                <InstructionContainerCircleText>
                  {index + 1}
                </InstructionContainerCircleText>
              </InstructionContainerCircle>
              <InstructionBlock {...i} />
            </FlexContainer>
          ))}

          {/* Removed after feedback, saved the code in case they want it back */}
          {/* {(buttonText != null && buttonLink != null) ?
            <FlexContainer>
              <PrimaryButtonLink
                padding="0"
                backgroundcolor={Colors.WHITE}
                margin="0.5rem 3rem 0.5rem 3rem"
                border={'2px solid' + Colors.ORANGE}
                borderradius="4px"
                to={buttonLink}
                target="_blank">
                <ButtonText color={Colors.BLACK}>
                  {buttonText}
                </ButtonText>
              </PrimaryButtonLink>
            </FlexContainer> : (null)} */}
        </FlexContainer>
      </PageContainer>
    );
  }
}

export default InstructionContainerBlock;
