import React from 'react';
import { CheckboxGroupContext } from './CheckboxGroupContext';

const withCheckboxGroup = Component =>
  React.forwardRef((props, ref) => (
    <CheckboxGroupContext.Consumer>
      {({ checkboxGroupApi, checkboxGroupState }) => (
        <Component
          checkboxGroupApi={checkboxGroupApi}
          checkboxGroupState={checkboxGroupState}
          ref={ref}
          {...props}
        />
      )}
    </CheckboxGroupContext.Consumer>
  ));

export default withCheckboxGroup;
