import styled from 'styled-components/macro';
import { zIndex } from 'constants/settings';

export const FixedCenter = styled.div`
  perspective: 900px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: ${zIndex.ABOVE_MODALS};
`;
