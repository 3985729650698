import styled from 'styled-components/macro';
import { media } from 'style/media';

export const RightContainer = styled.div`
    margin: 1rem 0 1rem 2rem;

    ${media.desktop`
    margin: 0 0 1rem 0;

    `};
`;
