import React from 'react';
import { colors as Colors } from 'constants/colors';
import { SubHeroText, SubHeroHeader, SubHeroTextMobile, SubHeroHeaderMobile } from './components';
import {
  SubHeroBlockContainer,
  SubHeroBlockContainerVideo,
  SubHeroBlockContainerVideoSmall,
  SubHeroBlockContainerMobile,
  SubHeroBlockContentItem,
  SubHeroBlockContentItemMobile,
  SubHeroBlockContent,
  SubHeroBlockContentMobile,
  SubHeroBlockTopImage,
  SubHeroBlockTopImageSubMenu,
  SubHeroBlockTopImageSubMenuMobile,
  LinkButton,
  LinkButtonText
} from './index';
import OPE from 'components/common/EpiOnPageEdit';
import { uberSmall, fillElement, getSelectedValueFromSelectionFactory } from 'utils';
import { LazyLoadImage } from 'features/LazyImage';
import { Hidden } from 'components/containers/OnlyDesktop';
import { Block, BlockInner } from '../components';
import VideoCover from 'react-video-cover';

export default class SubHeroBlock extends React.Component {
  constructor(props) {
    super(props);
    this.hero = React.createRef();
    this.state = { backgroundLoaded: false };
  }

  onBackgroundLoad = () => {
    this.setState({ backgroundLoaded: true });
  };

  onClickScroll = () => {
    let scrollToElement = document.getElementById('formLink');
    if (scrollToElement == null) return null;
    const windowOffset = window.scrollY || window.pageYOffset;

    const { top } = scrollToElement.getBoundingClientRect();
    const scrollY = top;
    window.scroll({
      top: windowOffset + scrollY,
      behavior: 'smooth'
    });
  };
  render() {
    const { backgroundLoaded } = this.state;
    const gradient = {
      direction: 'right',
      color: Colors.WHITE,
      width: '50%'
    };

    const {
      first,
      image,
      video,
      heading,
      text,
      textColor,
      children,
      buttonText,
      buttonLink,
      formLink,
      overlayShadowing,
      blank,
      ...rest
    } = this.props;
    const videoOptions = {
      src: video,
      autoPlay: true,
      loop: true,
      muted: true
    };
    const style = {
      height: '22rem',
      position: 'absolute',
      margin: 'auto',
      zIndex: 0
    };
    const mobileStyle = {
      height: '16rem',
      position: 'absolute',
      margin: 'auto',
      zIndex: 0
    };
    const color = getSelectedValueFromSelectionFactory(textColor);
    if (!this.props.subMenuMobile) {
      return video != null ? (
        <Block className="SubHeroBlock" ref={this.hero} {...rest}>
          <VideoCover style={style} videoOptions={videoOptions} remeasureOnWindowResize />
          <SubHeroBlockContainerVideo>
            <OPE propertyName="image">
              {this.props.blockType === 'SubHeroBlock' ? (
                <SubHeroBlockTopImageSubMenu
                  background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
                />
              ) : (
                <SubHeroBlockTopImage
                  background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
                />
              )}
            </OPE>
            <BlockInner margin="auto 0">
              <SubHeroBlockContentItem>
                <SubHeroBlockContent>
                  <OPE propertyName="heading">
                    <SubHeroHeader color={color}>{heading}</SubHeroHeader>
                  </OPE>
                  <OPE propertyName="text">
                    <SubHeroText color={color}>{text}</SubHeroText>
                  </OPE>
                  {buttonText &&
                    (buttonLink || formLink) &&
                    (formLink ? (
                      <LinkButton
                        onClick={() => {
                          this.onClickScroll();
                        }}>
                        <LinkButtonText>{buttonText}</LinkButtonText>
                      </LinkButton>
                    ) : (
                      <a
                        href={buttonLink}
                        style={{ textDecoration: 'none', width: 'fit-content' }}
                        target={blank ? '_blank' : '_self'}>
                        <LinkButton>
                          <LinkButtonText>{buttonText}</LinkButtonText>
                        </LinkButton>
                      </a>
                    ))}
                </SubHeroBlockContent>
              </SubHeroBlockContentItem>
              <Hidden className="Hidden">
                <LazyLoadImage
                  onLoad={this.onBackgroundLoad}
                  asBackground
                  hideSmall
                  placeholder={uberSmall(image)}
                  src={fillElement(image)}
                />
              </Hidden>
            </BlockInner>
            {children && children}
          </SubHeroBlockContainerVideo>
        </Block>
      ) : (
        <Block className="SubHeroBlock" ref={this.hero} {...rest}>
          <SubHeroBlockContainer
            smallbackground={Colors.WHITE}
            placeholderBg={uberSmall(image)}
            bg={fillElement(image, this.hero.current)}
            gradient={gradient}
            first={first ? 1 : 0}
            color={color}
            opacity={!overlayShadowing && this.props.opacity}>
            <OPE propertyName="image">
              {this.props.blockType === 'SubHeroBlock' ? (
                <SubHeroBlockTopImageSubMenu
                  background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
                />
              ) : (
                <SubHeroBlockTopImage
                  background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
                />
              )}
            </OPE>
            <BlockInner margin="auto 0">
              <SubHeroBlockContentItem>
                <SubHeroBlockContent>
                  <OPE propertyName="heading">
                    <SubHeroHeader color={color}>{heading}</SubHeroHeader>
                  </OPE>
                  <OPE propertyName="text">
                    <SubHeroText color={color}>{text}</SubHeroText>
                  </OPE>
                  {buttonText &&
                    (buttonLink || formLink) &&
                    (formLink ? (
                      <LinkButton
                        onClick={() => {
                          this.onClickScroll();
                        }}>
                        <LinkButtonText>{buttonText}</LinkButtonText>
                      </LinkButton>
                    ) : (
                      <a
                        href={buttonLink}
                        style={{ textDecoration: 'none', width: 'fit-content' }}
                        target={blank ? '_blank' : '_self'}>
                        <LinkButton>
                          <LinkButtonText>{buttonText}</LinkButtonText>
                        </LinkButton>
                      </a>
                    ))}
                </SubHeroBlockContent>
              </SubHeroBlockContentItem>
              <Hidden className="Hidden">
                <LazyLoadImage
                  onLoad={this.onBackgroundLoad}
                  asBackground
                  hideSmall
                  placeholder={uberSmall(image)}
                  src={fillElement(image)}
                />
              </Hidden>
            </BlockInner>
            {children && children}
          </SubHeroBlockContainer>
        </Block>
      );
    } else {
      return video != null ? (
        <Block className="SubHeroBlock" ref={this.hero} {...rest}>
          <VideoCover style={mobileStyle} videoOptions={videoOptions} remeasureOnWindowResize />
          <SubHeroBlockContainerVideoSmall>
            <OPE propertyName="image">
              {/* <SubHeroBlockTopImageSubMenuMobile background={backgroundLoaded ? fillElement(image) : uberSmall(image)} color={color} opacity={this.props.opacity}> */}
              <SubHeroBlockContentItemMobile>
                <SubHeroBlockContentMobile>
                  <OPE propertyName="heading">
                    <SubHeroHeaderMobile color={color}>{heading}</SubHeroHeaderMobile>
                  </OPE>
                  <OPE propertyName="text">
                    <SubHeroTextMobile color={color}>{text}</SubHeroTextMobile>
                  </OPE>
                  {buttonText &&
                    (buttonLink || formLink) &&
                    (formLink ? (
                      <LinkButton
                        onClick={() => {
                          this.onClickScroll();
                        }}>
                        <LinkButtonText>{buttonText}</LinkButtonText>
                      </LinkButton>
                    ) : (
                      <a
                        href={buttonLink}
                        style={{ textDecoration: 'none', width: 'fit-content' }}
                        target={blank ? '_blank' : '_self'}>
                        <LinkButton>
                          <LinkButtonText>{buttonText}</LinkButtonText>
                        </LinkButton>
                      </a>
                    ))}
                </SubHeroBlockContentMobile>
              </SubHeroBlockContentItemMobile>
              <Hidden className="Hidden">
                <LazyLoadImage
                  onLoad={this.onBackgroundLoad}
                  asBackground
                  hideSmall
                  placeholder={uberSmall(image)}
                  src={fillElement(image)}
                />
              </Hidden>

              {/* </SubHeroBlockTopImageSubMenuMobile> */}
            </OPE>
          </SubHeroBlockContainerVideoSmall>
        </Block>
      ) : (
        <Block className="SubHeroBlock" ref={this.hero} {...rest}>
          <SubHeroBlockContainerMobile
            smallbackground={Colors.WHITE}
            placeholderBg={uberSmall(image)}
            bg={fillElement(image, this.hero.current)}
            gradient={gradient}
            first={first ? 1 : 0}
            opacity={!overlayShadowing && this.props.opacity}>
            <OPE propertyName="image">
              <SubHeroBlockTopImageSubMenuMobile
                background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
                color={color}
                opacity={!overlayShadowing ? this.props.opacity : 0}>
                <SubHeroBlockContentItemMobile>
                  <SubHeroBlockContentMobile>
                    <OPE propertyName="heading">
                      <SubHeroHeaderMobile color={color}>{heading}</SubHeroHeaderMobile>
                    </OPE>
                    <OPE propertyName="text">
                      <SubHeroTextMobile color={color}>{text}</SubHeroTextMobile>
                    </OPE>
                    {buttonText &&
                      (buttonLink || formLink) &&
                      (formLink ? (
                        <LinkButton
                          onClick={() => {
                            this.onClickScroll();
                          }}>
                          <LinkButtonText>{buttonText}</LinkButtonText>
                        </LinkButton>
                      ) : (
                        <a
                          href={buttonLink}
                          style={{ textDecoration: 'none', width: 'fit-content' }}
                          target={blank ? '_blank' : '_self'}>
                          <LinkButton>
                            <LinkButtonText>{buttonText}</LinkButtonText>
                          </LinkButton>
                        </a>
                      ))}
                  </SubHeroBlockContentMobile>
                </SubHeroBlockContentItemMobile>
                <Hidden className="Hidden">
                  <LazyLoadImage
                    onLoad={this.onBackgroundLoad}
                    asBackground
                    hideSmall
                    placeholder={uberSmall(image)}
                    src={fillElement(image)}
                  />
                </Hidden>
              </SubHeroBlockTopImageSubMenuMobile>
            </OPE>
          </SubHeroBlockContainerMobile>
        </Block>
      );
    }
  }
}

SubHeroBlock.defaultProps = {
  textColor: Colors.BLACK
};
