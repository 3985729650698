import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const enterDuration = 600;
const exitDuration = 2200;
export default class CookieTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;

    anime({
      targets: node,
      opacity: {
        value: [0, 1],
        duration: enterDuration / 2
      },
      translateY: ['100%', 0],
      duration: enterDuration,
      easing: 'easeOutElastic',
      elasticity: 201
    });
  };

  onExit = (node, index) => {
    anime({
      targets: node,
      opacity: [1, 0],
      translateY: [0, '100%'],
      duration: exitDuration,
      easing: 'easeOutElastic',
      elasticity: 400
      // complete: () => (node.style.display = 'none')
    });
  };

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: enterDuration, exit: exitDuration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onExit={this.onExit}
        mountOnEnter={true}
        unmountOnExit={true}>
        {children}
      </Transition>
    );
  }
}
CookieTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element
};
