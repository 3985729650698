import React, { Component } from 'react';
import { CheckboxGroupContext } from './CheckboxGroupContext';
import { asField } from 'informed';

class InformedCheckboxGroup extends Component {
  get groupContext() {
    return {
      checkboxGroupApi: {
        ...this.props.fieldApi,
        onChange: this.props.onChange,
        onBlur: this.props.onBlur
      },
      checkboxGroupState: this.props.fieldState
    };
  }

  render() {
    return (
      <CheckboxGroupContext.Provider value={this.groupContext}>
        {this.props.children}
      </CheckboxGroupContext.Provider>
    );
  }
}

export default asField(InformedCheckboxGroup);
