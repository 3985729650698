import React from 'react';
import PropTypes from 'prop-types';
import { HtmlComponentWrapper } from './components';
import Parser from 'html-react-parser';
import { ParserOptions } from './parserOptions';
import { connect } from 'react-redux';

class HtmlComponent extends React.Component {
  render() {
    const { html, children, parseroverrides, filter, ...rest } = this.props;
    if (!html) return null;

    /* Replace escape tag '<l�n>' with currently set county */
    let parsedHtml = '';
    if (filter.selected.name) {
      parsedHtml = html.replace(new RegExp('&lt;l&auml;n&gt;', 'g'), filter.selected.name);
    } else {
      /* Fallback */
      parsedHtml = html.replace(new RegExp('&lt;l&auml;n&gt;', 'g'), 'l&auml;net');
    }

    return (
      <HtmlComponentWrapper className="html-component" {...rest}>
        {Parser(parsedHtml, ParserOptions(parseroverrides))}
        {children}
      </HtmlComponentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  currentPage: state.page,
  filter: {
    selected: state.filter.selected,
    reset: state.filter.reset,
    list: state.filter.categoryList
  }
});

export default connect(mapStateToProps)(HtmlComponent);

HtmlComponent.propTypes = {
  html: PropTypes.string,
  style: PropTypes.object,
  parseroverrides: PropTypes.shape({
    p: PropTypes.element
  })
};
