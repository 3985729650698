import styled from 'styled-components/macro';
import fonts from 'style/fonts';
import { PrimaryButton } from 'components/button';
import { media } from 'style/media';

export const JobApplicationBlockButton = styled(PrimaryButton).attrs({as: 'a'})`
  font-size: 16px;
  font-family: ${fonts.LLBrown.REGULAR};
  padding: 0.25rem 1.5rem;
  border: 2px solid ${props => props.color};
  background-color: 'transparent';
  margin: 0.5rem 0.25rem;
  min-height: 40px;
  color: ${props => props.color};
  width: fit-content;
  &:hover,
  :active,
  :focus {
    background-color: ${props => props.color};
    color: ${props => props.hovercolor};
  }
  ${media.mediumtablet`
    width: 90%;
  `};
`;
