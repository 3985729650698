import { FlexContainer } from 'components/flex';
import { colors as Colors } from 'constants/colors';
import { NavigationLink } from 'features/Navigation/components';
import { media } from 'style/media';
import styled, { css } from 'styled-components/macro';
import { shadow } from 'constants/shadows';
import { LazyLoadImageContainer } from 'features/LazyImage';
import { Title5 } from 'components/text/Title';
import fonts from 'style/fonts/fonts';
import { Paragraph } from 'components/text';

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  min-height: 400px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff82;
  z-index: 2;
`;

export const SearchResultLayoutContainer = styled(FlexContainer)`
  flex-direction: column;
  background: ${Colors.WHITE};
  gap: 1rem;
`;

export const SearchResult = styled(NavigationLink)`
  background: ${Colors.GRAY};
  display: flex;
  padding: 20px;
  flex-direction: row;
  align-items: flex-start;
  transition: box-shadow 0.3s ease;
  &:hover,
  &:focus {
    box-shadow: ${shadow(2)};
  }
  ${media.desktop`
    flex-direction: column;
  `};

  ${({ isContact }) =>
    !isContact &&
    css`
      ${media.desktop`
    ${LazyLoadImageContainer} {
      display: none;
    }
  `}
    `}
`;

export const SearchResultDescriptionContainer = styled(FlexContainer)`
  flex-direction: column;
  overflow-x: hidden;
  margin-left: 1rem;
  ${media.desktop`
  margin: 0;
  `};
`;

export const SearchResultContainer = styled(FlexContainer)`
  flex-direction: column;
`;

export const SearchResultTopBar = styled.div`
  display: flex;
  min-height: 40px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;

  ${media.desktop`
  margin-bottom: 20px;
  flex-direction: row;
`};
`;

export const TotalHitsLabel = styled(Title5)`
  && {
    margin: 0;
    font-size: 20px;

    ${media.desktop`
    font-size: 16px;
    `};
  }
`;

export const SearchResultContactFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchResultInnerDiv = styled.div`
  margin-left: 20px;
`;

export const SearchResultPhoneMailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  max-width: 50%;
  margin-left: 1rem;
  ${media.desktop`
      max-width: none;
      justify-content: flex-start;
      margin: 0;
    `};
`;
export const SearchResultTopContainer = styled.div.attrs(props => ({
  justifyContent: props.displayCategory ? 'space-between' : 'flex-end'
}))`
  display: flex;
  justify-content: ${props => props.justifyContent};
`;

export const SearchResultDateContainer = styled(Paragraph)`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  margin: -5px 0 0 0;
`;
export const SearchResultHeader = styled.h3`
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${Colors.BLACK};
  font-size: 26px;
  line-height: 26px;
  font-weight: normal;
  ${media.desktop`
    line-height: 20px;
      font-size: 16px;
    `};

  em {
    font-family: ${fonts.APERCU.WEB};
    font-weight: 700;
    font-style: normal;
  }
`;

export const SearchPageMargin = styled.div`
  margin-bottom: 2rem;
`;
