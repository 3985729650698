import { keyframes, css } from 'styled-components/macro';

const fadeInKeyFrames = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeIn = css`
  ${fadeInKeyFrames} 1s linear;
`;

const fadeOutKeyFrames = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

export const fadeOut = css`
  ${fadeOutKeyFrames} 1s linear;
`;

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */

const scaleInCenterKeyFrames = keyframes`
  0% {
    transform: scale(0.80);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform:  scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
export const scaleIn = css`
  ${scaleInCenterKeyFrames} 0.5s cubic-bezier(0.250,0.460,0.450,0.940) both;
`;

/**
 * ----------------------------------------
 * animation swing
 * ----------------------------------------
 */
const animationSpeed = '.2s';
const swingInKeyFrames = keyframes`
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: .5;
    visibility: visible;
  }
  100% {
    transform: rotateX(0deg)
    transform-origin: top;
    opacity: 1;
    visibility: visible;    
    ${'' /* hegiht:auto;     */}
  }
`;

const swingOutKeyFrames = keyframes`
  0% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
    ${'' /* height:200px; */}
    visibility: visible;   
  }

  95%:{
    ${'' /* top:auto;  */}
  }

  100% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
    ${'' /* height:0; */}
    visibility: hidden;
    
  }

`;

export const swingIn = css`
  ${swingInKeyFrames} ${animationSpeed} cubic-bezier(0.250,0.460,0.450,0.940) both;
`;
export const swingOut = css`
  ${swingOutKeyFrames} ${animationSpeed} cubic-bezier(0.250,0.460,0.450,0.940) both;
`;

/**
 * ----------------------------------------
 * animation jello
 * ----------------------------------------
 */

const jelloKeyframes = keyframes`
0% {
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  opacity: 1;
}
24% {
  opacity: 1;
}
40% {
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
65% {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
82% {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
93% {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
25%,
55%,
75%,
87% {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
100% {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  opacity: 1;
}
`;

export const jello = css`
  ${jelloKeyframes} 0.3s cubic-bezier(0.250,0.460,0.450,0.940) both;
`;
