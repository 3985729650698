import styled from 'styled-components/macro';
import { UnderLineCss } from 'components/UnderlineContainer';
import arrowDown from 'images/arrow-down.svg';
export const ShowMoreButton = styled.button.attrs(props => ({
  arrowUp: props.arrowUp
}))`
  border: none;
  cursor: pointer;
  position: relative;
  background: none;
  overflow: visible;
  &:active,
  &:focus {
    outline: none;
  }

  ${UnderLineCss``};

  &:before {
    content: '';
    background: url(${arrowDown});
    background-size: cover;
    background-position: center;
    height: 1rem;
    width: 1rem;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%)
      ${({ arrowUp }) => (arrowUp ? `translateY(0.1rem) rotate(180deg)` : `translateY(-0.7rem)`)};
    opacity: 0;
    transition: transform 0.25s ease, opacity 0.2s ease-out;
  }

  &:hover {
    &:before {
      transform: translateX(-50%) translateY(-0.3rem)
        ${({ arrowUp }) => arrowUp && `rotate(180deg)`};
      opacity: 1;
    }

    ${UnderLineCss`
      opacity: 0;
    `};
  }
`;
