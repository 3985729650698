import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BlockArea from 'features/BlockArea';
import { BLOCKS } from 'constants/blockTypes';
import { connect } from 'react-redux';

class CountyContentAreaBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.filter.selectedId,
      display: false
    };
  }

  componentDidMount() {
    if (this.props.blockContentArea) {
      this.props.category.forEach(cat => {
        if (cat === this.props.filter.selectedId) {
          this.setState({ display: true });
        }
      });
    }

    if (this.props.isFallback) {
      this.setState({ display: true });
    }
  }

  componentDidUpdate() {
    if (this.props.filter.selectedId !== this.state.filter) {
      this.setState({ filter: this.props.filter.selectedId });
      let newDisplayState = false;

      this.props.category.forEach(cat => {
        if (cat === this.props.filter.selectedId) {
          newDisplayState = true;
        }
      });

      this.setState({ display: newDisplayState });
    }
  }

  render() {
    if (!this.props) return null;
    const { blockContentArea, showMenuBlock, opacity } = this.props;
    let newBlockContentArea = [];
    let addedMenuBlock = false;
    if (blockContentArea !== null) {
      blockContentArea.forEach(block => {
        if (block.blockType !== 'MenuBlock') {
          newBlockContentArea.push(block);
        } else {
          if (showMenuBlock && !addedMenuBlock) {
            newBlockContentArea.push(block);
            addedMenuBlock = true;
          }
        }
      });
    }

    return (
      <div>
        <Fragment>
          <BlockArea
            propertyName="blockArea"
            contentarea={newBlockContentArea}
            opacity={opacity}
            nomargin
          />
        </Fragment>
      </div>
    );
  }
}

CountyContentAreaBlock.propTypes = {
  blockType: PropTypes.oneOf([BLOCKS.COUNTY_CONTENT_AREA_BLOCK])
};

const mapStateToProps = ({ filter }) => ({
  filter: {
    selectedId: filter.selected.id
  },
  forceMode: filter.forceMode
});

export default connect(mapStateToProps)(CountyContentAreaBlock);
