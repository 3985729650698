import { filterActions } from 'redux-ignore';
import { combineReducers } from 'redux';
//import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';

import * as TYPES from './actions';
import navigation from '../features/Navigation/navigationReducer';
import page from 'pages/pageReducer';
import filterarea from '../features/FilterArea/filterAreaReducer';
import cookie from '../features/CookieBar/cookiebarReducer';
import filter from '../features/CountyFilter/countyFilterReducer';
import burgare from '../features/HamburgareMeny/hamburgareReducer';
import pagelistblock from '../blocks/pagelistblock/pageListBlockReducer';
import callistblock from '../blocks/calendarlistblock/calendarlistblockReducer';
import activityblock from '../blocks/activityblock/activityBlockReducer';
import newsblock from '../blocks/newsblock/newsblockReducer';
import contacts from '../blocks/contactlistblock/contactlistblockReducer';
import account from '../features/Account/accountReducer';
import domevents from '../features/DomEvents/domeventsReducer';
import app from 'pages/app/appReducer';
import search from 'features/Search/searchReducer';

// import { routerMiddleware } from 'react-router-redux';

// import { push } from 'react-router-redux';

// import { ConnectedRouter } from 'react-router-redux';

// import { routerReducer as routing } from 'react-router-redux';

export default history =>
  combineReducers({
    //routing: routerReducer,
    router: connectRouter(history),
    burgare: filterActions(burgare, [TYPES.HAMBURGER_TYPES.HAMBURGARE_TOGGLE]),
    navigation,
    page,
    filter,
    filterarea,
    pagelistblock,
    callistblock,
    activityblock,
    app: filterActions(app, action => action.type.match(/app|filter|burgare|page$/)),
    newsblock: filterActions(newsblock, action => action.type.match(/newsblock|filter$/)),
    contacts,
    account,
    domevents,
    cookie,
    search
  });

/*export default  combineReducers({
  //routing: routerReducer,
  router: connectRouter(history),
  burgare: filterActions(burgare, [TYPES.HAMBURGER_TYPES.HAMBURGARE_TOGGLE]),
  navigation,
  page,
  filter,
  filterarea,
  pagelistblock,
  callistblock,
  activityblock,
  app: filterActions(app, action => action.type.match(/app|filter|burgare|page$/)),
  newsblock: filterActions(newsblock, action => action.type.match(/newsblock|filter$/)),
  contacts,
  relatedmaterialblock: filterActions(relatedmaterialblock, action => action.type.match(/relatedmaterialblock|filter$/)),
  account,
  domevents,
  cookie,
  search
});
*/
