import styled from 'styled-components/macro';
// import styled from 'styled-components/macro';
// import PropTypes from 'prop-types';
import { BaseUnderlineContainer } from 'components/UnderlineContainer';
import { colors as Colors } from 'constants/colors';

import fonts from 'style/fonts';

/**
 * RelatedItemText (aside)
 */

const underlineContainer = BaseUnderlineContainer.withComponent('span');
export const RelatedItemTypeHeader = styled(underlineContainer)`
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${Colors.DARKER_GRAY};
  position: relative;
  text-transform: uppercase;
  margin: 0.1rem 0 1rem 0;
  padding-top: 0.5rem;
  font-size: 0.7rem;
  letter-spacing: 0.2px;
  opacity: 0.67;
  text-align: center;

  /* word-break: normal; */
`;

RelatedItemTypeHeader.propTypes = {};
