import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ShowMore } from 'features/ShowMore';
import { Container, RelatedHtmlContainer } from './components';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import { RelatedBlock } from 'features/RelatedBlock';
import { colors as Colors } from 'constants/colors';
import { Title4 } from 'components/text';
import { PageContent } from 'pages/components';
import { fetchRelatedMaterialBlockItems } from '../../api/SearchApi';

class RelatedMaterialBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreActive: false,
      showMoreClicked: false,
      relatedMaterial: []
    };

    this.initialState = { ...this.state };
  }
  componentDidMount() {
    this.fetchRelatedMaterial();
  }

  fetchRelatedMaterial = async (category = null) => {
    const { id, filter } = this.props;
    const relatedResult = await fetchRelatedMaterialBlockItems(
      id,
      filter.selected.id,
      this.props.relatedCategoriesList
    );
    this.setState({ relatedMaterial: relatedResult });
    await this.addStickyToRelated();
  };

  async componentDidUpdate(prevProps, prevState) {
    const { selected: prevSelected, initialized: prevInitialized } = prevProps.filter;
    const { initialized, selected } = this.props.filter;
    if (prevInitialized !== initialized || prevSelected !== selected) {
      this.fetchRelatedMaterial();
    }
  }

  uniqBy = (a, key) => {
    return [...new Map(a.map(x => [key(x), x])).values()];
  };

  addStickyToRelated = () => {
    const { stickiedArticles, externalLinks } = this.props;
    const { relatedMaterial } = this.state;
    if (stickiedArticles == null && !relatedMaterial.length) {
      if (externalLinks && externalLinks.length) {
        this.setState({ relatedMaterial: externalLinks });
      }
      return;
    }
    if (!relatedMaterial.length && stickiedArticles) {
      this.setState({ relatedMaterial: stickiedArticles });
    }
    //Kolla om vi har några relaterade, annars använd bara stickiedArticles
    const tempRelated = relatedMaterial.length ? relatedMaterial : stickiedArticles;

    //Lägg ihop spreadade listor till en unik lista och jämför sedan id för att ta bort duplicates
    //ifall ett av resultaten också finns i stickiedArticles
    const uniqueList = this.uniqBy([...stickiedArticles, ...tempRelated], x => x.id);

    //Lägg till externa i början av listan
    externalLinks && externalLinks.forEach(i => uniqueList.unshift(i));
    this.setState({ relatedMaterial: uniqueList });
  };

  handleOnShowMore = () => {
    this.setState({
      showMoreActive: true,
      showMoreClicked: true
    });
  };

  handleOnShowLess = () => {
    this.setState({ showMoreActive: false, showMoreClicked: false });
  };

  render() {
    const { mainBody, showMoreButton, numberOfResults, numberOfResultsExtended } = this.props;
    const { showMoreActive, showMoreClicked, relatedMaterial } = this.state;
    const isSingle =
      (numberOfResults === 1 || relatedMaterial.length === 1) && showMoreClicked === false;
    if (relatedMaterial === '') return null;
    var maxNumberOfResultsExpanded = 0;
    numberOfResultsExtended === 0
      ? (maxNumberOfResultsExpanded = 999999)
      : (maxNumberOfResultsExpanded = numberOfResultsExtended);

    return (
      <PageContent overflowvisible background={Colors.WHITE}>
        <RelatedHtmlContainer isSingle={isSingle}>
          <HtmlComponentContainer smallalign="left">
            <HtmlComponent html={mainBody} />
          </HtmlComponentContainer>
        </RelatedHtmlContainer>
        <Container>
          {!relatedMaterial.length && <Title4>Inget relaterat material hittades</Title4>}
          {relatedMaterial &&
            relatedMaterial.length &&
            relatedMaterial.map((item, index) => {
              if (showMoreActive) {
                if (index < maxNumberOfResultsExpanded) {
                  return (
                    <RelatedBlock
                      key={index}
                      categories={item.categoryNames}
                      link={item.linkUrl || item.link.to || item.link}
                      image={item.imageUrl || item.listingImage || item.image}
                      header={item.name || item.text || item.header}
                      ingress={item.listingDescription || item.ingress || item.preamble}
                      openin={item.openIn}
                      external={item.blockType === 'RelatedMaterialExternalBlock'}
                    />
                  );
                }
              } else {
                if ((numberOfResults === 1 || relatedMaterial.length === 1) && index === 0) {
                  return (
                    <RelatedBlock
                      single={true}
                      key={index}
                      categories={item.categoryNames}
                      link={item.linkUrl || item.link.to || item.link}
                      image={item.imageUrl || item.listingImage || item.image}
                      header={item.name || item.text || item.header}
                      ingress={item.listingDescription || item.ingress || item.preamble}
                      openin={item.openIn}
                      external={item.blockType === 'RelatedMaterialExternalBlock'}
                    />
                  );
                } else if (index < numberOfResults) {
                  return (
                    <RelatedBlock
                      key={index}
                      categories={item.categoryNames}
                      link={item.linkUrl || item.link.to || item.link}
                      image={item.imageUrl || item.listingImage || item.image}
                      header={item.name || item.text || item.header}
                      ingress={item.listingDescription || item.ingress || item.preamble}
                      openin={item.openIn}
                      external={item.blockType === 'RelatedMaterialExternalBlock'}
                    />
                  );
                }
              }
              return null;
            })}
        </Container>
        {!!showMoreButton && (
          <ShowMore
            text={showMoreButton}
            hide={relatedMaterial.length <= numberOfResults || showMoreClicked}
            onClick={this.handleOnShowMore}
          />
        )}
        {!!showMoreButton && (
          <ShowMore
            text="Visa färre"
            arrowUp
            hide={!showMoreClicked}
            onClick={this.handleOnShowLess}
          />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected,
    categoryList: state.filter.categoryList,
    message: state.filter.message,
    initialized: state.filter.initialized
  }
});

export default connect(
  mapStateToProps,
  null
)(RelatedMaterialBlock);

RelatedMaterialBlock.propTypes = {
  first: PropTypes.bool,
  buttonLink: PropTypes.string,
  background: PropTypes.array
};
