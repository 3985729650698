import styled from 'styled-components/macro';
import { FlexContainer } from 'components/flex';
import { media } from 'style/media';

export const WideVideoContainerNoText = styled(FlexContainer)`
    margin: ${({ pageContentWidth }) => pageContentWidth ? '1rem 0 1.667rem 0' : '0'};
    height: 100%;
    padding-top: 810px;
    max-width: 1440px;
    width: 1440px;
    left: calc(-720px + 50%);
    ${media.extraGiant`
    padding-top: 56.25%;
        width: 100vw;
        max-width: 100vw;
        left: calc(-50vw + 50%)
    `};
`;

export const VideoContainerNoText = styled(FlexContainer)`
    margin: ${({ pageContentWidth }) => pageContentWidth ? '1rem 0 1.667rem 0' : '0'};
    height: 100%;
    padding-top: 56.25%;
    max-width: 100%;
    width: 100%;
`;

export const VideoContainer = styled(FlexContainer)`
    height: 100%;
    padding-top: 56.25%;
`;

