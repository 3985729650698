import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';

/**
 * GradientContainer (div)
 */
export const GradientContainer = styled.div`
  background-image: linear-gradient(
    to ${props => props.direction},
    ${props => (props.color ? props.color : Colors.WHITE)},
    rgba(255, 255, 255, 0)
  );
`;

GradientContainer.propTypes = {
  transparent: PropTypes.bool,
  direction: PropTypes.oneOf(['bottom', 'top', 'right', 'left']).isRequired,
  color: PropTypes.oneOf(Object.values(Colors))
};

export const FadeGradient = css`
  &:after {
    content: ' ';
    right: 0;
    width: 2rem;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      to right,
      ${Colors.TRANSPARENT} 0%,
      ${Colors.GRAY} 80%,
      ${Colors.GRAY} 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0.001) 0%,
      ${Colors.GRAY} 80%,
      ${Colors.GRAY} 100%
    );
    top: 0;
    display: block;
    z-index: ${zIndex.LEVEL_0};
  }
`;
