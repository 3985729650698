import React from 'react';
import { SearchResultLayoutFilterContainer } from './SearchFilterComponents';
import { SearchFilterPill } from './SearchFilterPill';
import { Title4 } from 'components/text/Title';

const SearchFilterDesktop = ({
  clearFilters = () => {},
  filterHitsLabel = '',
  categoryFilterState = [],
  handleFilterClick = () => {},
  filterState = [],
  hitsBeforeFilter = 0
}) => {
  return (
    <SearchResultLayoutFilterContainer>
      <Title4 height="40px" marginTop="24px" marginBottom="52px">
        {filterHitsLabel}
      </Title4>
      <SearchFilterPill
        handleFilterClick={clearFilters}
        item={{ filterName: 'Alla träffar', filterCount: hitsBeforeFilter }}
        selected={!filterState.length}
      />
      {!!categoryFilterState &&
        categoryFilterState.map((item, ix) => (
          <SearchFilterPill
            key={ix}
            handleFilterClick={handleFilterClick}
            item={item}
            selected={filterState.find(x => x.filterId === item.filterId)}
          />
        ))}
    </SearchResultLayoutFilterContainer>
  );
};

export default SearchFilterDesktop;
