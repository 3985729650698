import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ShowMoreContainer, ShowMoreLabel, ShowMoreButton } from './components';
class ShowMore extends PureComponent {
  handleOnClick = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { text, style = {}, arrowUp, ...rest } = this.props;
    return (
      <ShowMoreContainer {...rest} style={style}>
        <ShowMoreButton onClick={this.handleOnClick} arrowUp={arrowUp}>
          <ShowMoreLabel>{text}</ShowMoreLabel>
        </ShowMoreButton>
      </ShowMoreContainer>
    );
  }
}

ShowMore.defaultProps = {
  onClick: () => {},
  text: 'Visa fler'
};

ShowMore.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string
};

export { ShowMore };
