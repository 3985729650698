import React from 'react';
import PropTypes from 'prop-types';
import { threeInRowImage, uberSmall } from 'utils';
import {
  ImageLinkContainer,
  ImageLinkWrapper,
  ImageLinkHeader,
  ImageLinkText,
  ImageLinkContentWrapper
} from './components';

const ImageLink = ({ header, link = '/', ingress, image, show = true, delay = 0 }) => {
  const blank = link.endsWith('pdf' || 'docx' || 'doc' || 'xls' || 'xlsx');
  return (
    <ImageLinkContainer
      showdelay={delay}
      bgdelay={1000}
      show={show}
      placeholderBg={uberSmall(image)}
      bg={threeInRowImage(image)}>
      <ImageLinkWrapper href={link} target={blank && '_blank'}>
        <ImageLinkContentWrapper>
          <ImageLinkHeader>{header}</ImageLinkHeader>
          <ImageLinkText>{ingress}</ImageLinkText>
        </ImageLinkContentWrapper>
      </ImageLinkWrapper>
    </ImageLinkContainer>
  );
};

ImageLink.propTypes = {
  header: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  ingress: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  show: PropTypes.bool,
  delay: PropTypes.number
};

export { ImageLink };
