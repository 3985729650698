export const PDF = 'pdf';
export const JPG = 'jpg';
export const DOCX = 'docx';
export const DOC = 'doc';

export const ExtensionsBlacklist = [
  'asp',
  'aspx',
  'asa',
  'ashx',
  'asmx',
  'bat',
  'chm',
  'class',
  'cmd',
  'com',
  'config',
  'dll',
  'exe',
  'hta',
  'htr',
  'htw',
  'jse',
  'json',
  'lnk',
  'mda',
  'mdb',
  'msc',
  'msh',
  'pif',
  'printer',
  'ps1',
  'ps2',
  'reg',
  'rem',
  'scf',
  'scr',
  'sct',
  'shtm',
  'shtml',
  'soap',
  'stm',
  'svc',
  'url',
  'vb',
  'vbe',
  'vbs',
  'vsix',
  'ws',
  'wsc',
  'wsf',
  'wsh',
  'xamlx',
  'htm',
  'html',
  'js',
  'jar'
];
