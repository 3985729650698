import React, { Component } from 'react';
import { FileNameWrapper, FileNameBox, DeleteButton } from './components';
import PropTypes from 'prop-types';

class FileName extends Component {
  handleDelete = () => {
    this.props.clickHandler(this.props.fileName);
  };

  render() {
    const { color, backgroundcolor, fileName, deletable } = this.props;
    return (
      <FileNameWrapper color={color}>
        <FileNameBox backgroundcolor={backgroundcolor}>
          {fileName}
          {deletable && <DeleteButton onClick={this.handleDelete}>x</DeleteButton>}
        </FileNameBox>
      </FileNameWrapper>
    );
  }
}

export default FileName;

FileName.propTypes = {
  fileName: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  deletable: PropTypes.bool.isRequired,
  color: PropTypes.string,
  backgroundcolor: PropTypes.string
};
