import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { WithFormTracker } from 'features/WithTracker';
import { ExtensionsBlacklist } from 'constants/fileEndings';
import { Block, CenteredBlockInner } from 'blocks/components';
import { ErrorMessage } from 'components/form';
import { HiddenInput, InputWrapper } from 'components/input';
import { FlexContainer } from 'components/flex';
import { ButtonText, SecondaryButton, SubmitButton } from 'components/button';
import { Title2, MainBody, FormFeedbackMessage, AlternativeLabel } from 'components/text';
import { CollapsingArrow } from 'components/images/ArrowDown';
import { HtmlComponent } from 'features/HtmlComponent';

import {
  validateEmail,
  validateMinLength,
  validateAtLeastOneChoice,
  getAntiforgeryToken
} from '../../utils';
import { formPropertyIsRequired, LocalStorage, getSelectedValueFromSelectionFactory } from 'utils';
import { Loader } from 'components/common/Loader';
import ErrorHandler from '../../utils/ErrorHandler';
import update from 'immutability-helper';
import { submitToEpiForms } from '../../api/FormService';
import Informed, {
  AntiForgeryInput,
  TextInput,
  TextAreaInput,
  Radio,
  ValueCheckbox,
  RadioGroup,
  CheckboxGroup
} from 'components/form';
import serialize from 'form-serialize';
import { UnorderedList, NormalizedListItem } from 'components/common';
import { FileName } from '../FileName';
import { Flex, Box } from 'components/grid';
import EpiFormHelper from './EpiFormHelper';
import { sizes } from 'style/media';
import { ga } from 'react-ga';

const INPUT_ELEMENTS = {
  TEXT_INPUTS: ['TextboxElementBlock', 'CustomTextboxElementBlock'],
  TEXTAREA_INPUTS: ['TextareaElementBlock', 'CustomTextareaElementBlock'],
  FILE_INPUTS: ['FileUploadElementBlock', 'CustomFileUploadElementBlock'],
  CHOICE_INPUTS: ['ChoiceElementBlock', 'CustomChoiceElementBlock'],
  GDPR_INPUT: ['AutoConsentElementBlock', 'CustomAutoConsentElementBlock'],
  HIDDEN_CLIENTID: ['UserIdElement', 'UserIdElement'],
  STEP_BUTTON: ['FormStepBlock', 'CustomStepElement'],
  SUBMIT_BUTTON: ['SubmitButtonElementBlock']
};

const VALIDATORS = {
  REQUIRED: 'EPiServer.Forms.Implementation.Validation.RequiredValidator',
  EMAIL: 'EPiServer.Forms.Implementation.Validation.EmailValidator'
};

const SEPARATORS = {
  CHOICE: '---'
};

class EpiForm extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this._storage = new LocalStorage();
    const { elementsArea: elements = [], allowMultipleSubmission, id } = props || {};
    let allElements = elements || [];
    this.EpiFormsHelper = undefined;
    this.InformedApi = undefined;
    this.form = React.createRef();
    this.submitButton = undefined;
    this.inputRef = React.createRef();
    this.clientIdInputRef = React.createRef();
    const textInputs = [].concat(
      allElements.filter(x => INPUT_ELEMENTS.TEXT_INPUTS.includes(x.blockType))
    );
    const textAreaInputs = [].concat(
      allElements.filter(x => INPUT_ELEMENTS.TEXTAREA_INPUTS.includes(x.blockType))
    );
    const choiceInputs = [].concat(
      allElements.filter(x => INPUT_ELEMENTS.CHOICE_INPUTS.includes(x.blockType))
    );
    const gdprInputs = [].concat(
      allElements.filter(x => INPUT_ELEMENTS.GDPR_INPUT.includes(x.blockType))
    );
    const clientId = [].concat(
      allElements.filter(x => INPUT_ELEMENTS.HIDDEN_CLIENTID.includes(x.blockType))
    );
    const stepButton = [].concat(
      allElements.filter(x => INPUT_ELEMENTS.STEP_BUTTON.includes(x.blockType))
    );
    const choiceCheckboxInputs = [].concat(choiceInputs.filter(x => x.allowMultiSelect));
    const choiceRadioInputs = [].concat(choiceInputs.filter(x => !x.allowMultiSelect));

    //put all predifined values from the epiform elements into the same array
    //checkbox and radio need correct property names: (id, predefinedValues)
    this.predefinedValuesFromEpi = []
      .concat(textInputs.filter(x => x.predefinedValue))
      .concat(gdprInputs.filter(x => x.predefinedValue))
      .concat(textAreaInputs.filter(x => x.predefinedValue))
      .concat(stepButton.filter(x => x.predefinedValue))
      .concat(clientId.filter(x => x.predefinedValue))
      .concat(
        choiceCheckboxInputs
          .filter(x => x.items.precheckeditems.length)
          .map(({ id, items }) => {
            return {
              id,
              predefinedValue: items.precheckeditems.map(({ value }) => {
                return value;
              })
            };
          })
      )
      .concat(
        choiceRadioInputs
          .filter(x => x.items.precheckeditems.length)
          .map(x => {
            return { id: x.id, predefinedValue: x.items.precheckeditems[0].value };
          })
      );
    //the intialValues object is used by Informed to populate elements
    this.initialValues = this.predefinedValuesFromEpi.reduce((accumulator, currentValue) => {
      const { predefinedValue, id: elementId } = currentValue;
      return {
        ...accumulator,
        [this.generateUidForFormElement(elementId)]: predefinedValue
      };
    }, {});
    this.initialValues = {
      ...this.initialValues,
      __RequestVerificationToken: getAntiforgeryToken()
    };
    this.state = {
      formId: id,
      allElements: allElements,
      apiAvailable: false,
      invalidateMinutes: 30,
      submittable: false,
      allowMultipleSubmission: allowMultipleSubmission,
      cookieName: `FORM_SENT_${id}`,
      clientId: '',
      clientIdUid: '',
      stepBtnRendered: false,
      stepBtnActive: false,
      fileInput:
        //check if fileinputs exist, in case of yes: take only the first entry.
        !allElements.filter(x => INPUT_ELEMENTS.FILE_INPUTS.includes(x.blockType)).length
          ? []
          : []
            .concat(allElements.filter(x => INPUT_ELEMENTS.FILE_INPUTS.includes(x.blockType)))
            .filter(x => x)
            .map(item => {
              const { id, label, validators, ...rest } = item;
              return {
                id: id,
                value: '',
                label: label,
                validators: validators,
                isRequired: formPropertyIsRequired(validators),
                isValid: formPropertyIsRequired(validators) ? false : true,
                message: '',
                ...rest
              };
            })
            .slice(0, 1),
      submitButtons: []
        .concat(allElements.filter(x => INPUT_ELEMENTS.SUBMIT_BUTTON.includes(x.blockType)))
        .filter(x => x)
        .map(button => {
          const { id, label, redirectToPage } = button;
          return { id: id, label: label, redirectToPage: redirectToPage };
        }),
      isLoading: false,
      result: {
        success: false,
        hasBeenSent: false,
        message: []
      },
      fileList: []
    };

  }

  componentDidMount() {
    const { guid } = this.props;
    this.EpiFormsHelper = new EpiFormHelper({
      guid: guid
    });
    this.validateText();
  }

  componentDidUpdate(prevProps, prevState) {
    let that = this;
    const { clientId } = this.state;
    if (prevState === this.state && prevProps === this.props) return;
    ga('create', 'UA-6253532-4', 'auto');
    ga(function (tracker) {
      if (that.clientIdInputRef === undefined || clientId.length) return;
      that.setState({ clientId: tracker.get('clientId') });
    });
  }

  hasAlreadyPosted() {
    const { allowMultipleSubmission } = this.props;
    const { cookieName, invalidateMinutes } = this.state;

    if (allowMultipleSubmission) return false;
    const cookie = this._storage.getJSON(cookieName);
    if (!cookie) return false;
    const { time } = cookie;
    if (!time) return false;
    const timeSinceLastPost = (new Date() - new Date(time)) / 1000;
    if (timeSinceLastPost > invalidateMinutes * 60) return false;
    return true;
  }

  getErrorMessage() {
    if (this.hasAlreadyPosted()) {
      return 'Du har redan svarat på detta formulär.';
    }
  }

  validateFiles(fileList) {
    const { fileInput: stateFileInput } = this.state;
    if (!stateFileInput || !stateFileInput.length) return;
    if (!fileList || !fileList.length) return;
    let isValid = true;
    let message = '';

    if (fileList.some(f => ExtensionsBlacklist.includes(f.name.split('.').pop()))) {
      message = 'Otillåten filtyp';
      isValid = false;
    }
    if (fileList.some(f => f.size > 4000000)) {
      message = 'En fil får max vara 4MB stor';
      isValid = false;
    }
    if (fileList.length > 3) {
      message = 'Max 3 filer';
      isValid = false;
    }

    if (isValid) {
      this.setState({
        fileInput: update(stateFileInput, {
          0: { isValid: { $set: isValid }, message: { $set: message } }
        })
      });
    } else {
      //no need to set validate again here, because the filelist will not change.
      this.setState({
        fileInput: update(stateFileInput, {
          0: { message: { $set: message } }
        })
      });
    }

    return isValid;
  }

  validateFileInputNotEmpty(fileList) {
    const { fileInput: stateFileInput } = this.state;
    if (!stateFileInput || !stateFileInput.length) return;
    const input = stateFileInput[0];
    const { isRequired } = input;
    let isValid = true;
    let message = '';

    if (isRequired && !fileList.length) {
      message = 'Filuppladdning är obligatoriskt';
      isValid = false;
      this.setState({
        fileInput: update(stateFileInput, {
          0: { isValid: { $set: isValid }, message: { $set: message } }
        })
      });
    }
  }

  validateText(value, validators = '') {
    if (!validators) return null;
    const validatorsArray = validators.split('|||') || [];
    let message = null;
    if (validatorsArray.includes(VALIDATORS.REQUIRED)) {
      message = validateMinLength(value, 0);
    }
    if (validatorsArray.includes(VALIDATORS.EMAIL)) {
      message = validateEmail(value);
    }
    return message;
  }

  validateChoice(value, validators = '') {
    if (!validators) return null;
    const validatorsArray = validators.split('|||') || [];
    let message = null;
    if (validatorsArray.includes(VALIDATORS.REQUIRED)) {
      message = validateAtLeastOneChoice(value);
    }
    return message;
  }

  handleFileInputChange() {
    const { files } = this.inputRef.current;
    if (!files.length) return;
    const { fileList: stateFileList } = this.state;
    const newFileList = [...stateFileList, ...files];
    if (!this.validateFiles(newFileList)) return;
    this.setState(
      {
        fileList: newFileList
      },
      () => this.validateFileInputNotEmpty(this.state.fileList)
    );
  }

  handleFileDelete = fileName => {
    if (this.state.result.success) return;
    const { fileList: stateFileList } = this.state;
    const newFileList = stateFileList.filter(file => file.name !== fileName);
    this.setState(
      {
        fileList: newFileList
      },
      () => this.validateFileInputNotEmpty(this.state.fileList)
    );
  };

  handleSubmit = async e => {
    const { values } = this.InformedApi.getState();
    const { currentLanguage, page } = this.props;
    const { clientIdUid, clientId } = this.state;
    const { id: pageId } = page;
    e.preventDefault();

    this.setState({ isLoading: true });

    const { fileList } = this.state;

    if (fileList && fileList.length) {
      values[this.inputRef.current.name] = fileList.map(file => {
        return { name: file.name, file: file };
      });
    }
    let valuesWithClientId = { ...values, [clientIdUid]: clientId };

    const formData = this.EpiFormsHelper.getFormData(valuesWithClientId, currentLanguage, pageId);

    const { IsSuccess, message } = await submitToEpiForms(formData, this.props.guid);
    this.setState(prevState => ({
      isLoading: false,
      result: { ...prevState.result, success: IsSuccess }
    }));
    this.handlePostSubmit(IsSuccess, message);
  };

  handlePostSubmit(success, message = '') {

    const {
      redirectToPage,
      id,
      title,
      onFormSuccess = () => { },
      formType = [],
      currentCategory: { name } = {}
    } = this.props;
    const { cookieName, formState } = this.state;

    if (!success) {
      ErrorHandler.LogErrorSilently(`Form ${id} was not sent`, {
        formId: id,
        formName: title,
        message: message,
        data: serialize(this.form.current, { hash: true })
      });
    }

    if (success && redirectToPage) {
      window.location.replace(redirectToPage);
    }

    if (success) {
      //.....mmmmmmmmmmmmmmmmmmmmmmm

      const mainNav = document.getElementById('main-navigation');
      const active = mainNav ? mainNav.querySelectorAll('.nav-item-active') : [];
      const last = [...Array.from(active)].pop() || {};
      const arrayValues = Object.values(formState.values).filter(
        value => value.constructor === [].constructor
      );
      const flatted = (arrayValues || [[]]).flat();
      var reduced = flatted.reduce((result, item) => {
        result[`${last.innerText}_${item}`] = item;
        return result;
      }, {}); //watch out the empty {}, which is passed as "result"

      onFormSuccess({
        event: getSelectedValueFromSelectionFactory(formType),
        formName: getSelectedValueFromSelectionFactory(formType, 'text'),
        categoryName: last.innerText,
        county: name,
        subCategoryName: (document.querySelector('h1') || {}).textContent,
        ...(reduced || {})
      });

      this.setState({ result: { hasBeenSent: true, success: success } }, () => this.scrollRef.current.scrollIntoView({ behavior: 'smooth' }));

      const time = new Date();
      this._storage.set(cookieName, { submitted: true, time: time });

    } else {
      const submitFailedMessage = [
        'Något gick fel. Formuläret kunde tyvärr inte skickas. Är alla fält korrekt ifyllda?'
      ];
      this.setState({ result: { message: submitFailedMessage } });
    }
  }

  generateUidForFormElement = id => {
    return `__field_${id}`;
  };

  generateUidForChoice = (id, value) => {
    return `__field_${id}${SEPARATORS.CHOICE}${value}`; ///--- is used as an identifiable separator in the submit-function
  };

  handleFormStateChange = (formState = {}) => {
    const { id } = this.props;
    const { invalid } = formState;

    if (!invalid) {
      this._storage.set(`epiForm_${id}`, formState);
    }
    this.setState({ formState: formState });
  };

  isValid = field => {
    const isValid =
      this.InformedApi.fieldExists(field) && !this.InformedApi.getError(field) ? true : false;
    return isValid;
  };

  getValidationErrorMessage = field => {
    return this.InformedApi.fieldExists(field) &&
      this.InformedApi.getTouched(field) &&
      this.InformedApi.getError(field)
      ? this.InformedApi.getError(field)
      : undefined;
  };

  handleStepButton = () => {
    this.setState({ stepBtnRendered: false, stepBtnActive: true });
  }

  renderSwitch = (element, formId, formApi) => {

    switch (true) {
      case INPUT_ELEMENTS.TEXT_INPUTS.includes(element.blockType): {
        const { id, placeHolder, validators, label, stepped } = element;
        const uid = this.generateUidForFormElement(id);
        return (
          !stepped &&
          <TextInput
            key={formId + '-' + uid}
            id={formId + '-' + uid}
            field={uid}
            validateOnBlur
            label={label}
            isvalid={this.isValid(uid)}
            touched={formApi.getTouched(uid)}
            validate={value => this.validateText(value, validators)}
            message={this.getValidationErrorMessage(uid)}
            name={uid}
            title={this.props.description}
            autoComplete="off"
            type="text"
            // hidden={stepBtnRendered}
            placeholder={placeHolder}
            alwaysmarginbottom
          />
        );
      }
      case INPUT_ELEMENTS.TEXTAREA_INPUTS.includes(element.blockType): {
        const { id, placeHolder, validators, label } = element;
        const uid = this.generateUidForFormElement(id);
        return (
          !element.stepped &&
          <TextAreaInput
            key={formId + '-' + uid}
            id={formId + '-' + uid}
            field={uid}
            validateOnBlur
            label={label}
            isvalid={this.isValid(uid)}
            touched={formApi.getTouched(uid)}
            validate={value => this.validateText(value, validators)}
            message={this.getValidationErrorMessage(uid)}
            name={uid}
            title={this.props.description}
            autoComplete="off"
            placeholder={placeHolder}
            height="5.6rem"
            alwaysmarginbottom
          />
        );
      }
      case INPUT_ELEMENTS.CHOICE_INPUTS.includes(element.blockType) && element.allowMultiSelect: {
        const { id, validators, label, items } = element;
        const uid = this.generateUidForFormElement(id);
        return (
          !element.stepped &&
          <FlexContainer column key={id} margin="0.5rem 0 1rem 0 ">
            <AlternativeLabel>{label}</AlternativeLabel>
            <CheckboxGroup
              field={uid}
              nomarginbottom
              isvalid={this.isValid(uid)}
              touched={formApi.getTouched(uid)}
              message={this.getValidationErrorMessage(uid)}
              validate={value => this.validateChoice(value, validators)}
              validateOnChange
              validateOnBlur>
              {items.optionitems.map(({ caption, value }, index) => {
                const choiceUidIndex = uid + `[${index}]`;
                return (
                  <ValueCheckbox
                    key={formId + '-' + choiceUidIndex}
                    id={formId + '-' + choiceUidIndex}
                    field={uid}
                    label={caption}
                    valueToSet={value}
                    round
                  />
                );
              })}
            </CheckboxGroup>
          </FlexContainer>
        );
      }
      case INPUT_ELEMENTS.GDPR_INPUT.includes(element.blockType): {
        const { id, validators, label, items } = element;
        const uid = this.generateUidForFormElement(id);
        return (
          !element.stepped &&
          // (!element.hideCheckBox ?
          <FlexContainer column key={id}>
            <AlternativeLabel>{label}</AlternativeLabel>
            <CheckboxGroup
              field={uid}
              nomarginbottom
              isvalid={this.isValid(uid)}
              touched={formApi.getTouched(uid)}
              message={this.getValidationErrorMessage(uid)}
              validate={value => this.validateChoice(value, validators)}
              validateOnChange
              validateOnBlur>
              {items.optionitems.map(({ caption, value }, index) => {
                const choiceUidIndex = uid + `[${index}]`;
                return (
                  !element.hideCheckBox ?
                    <ValueCheckbox
                      key={formId + '-' + choiceUidIndex}
                      id={formId + '-' + choiceUidIndex}
                      field={uid}
                      label={caption}
                      valueToSet={value}
                      html={true}
                    />
                    :
                    <HtmlComponent html={caption}></HtmlComponent>
                );
              })}
            </CheckboxGroup>
          </FlexContainer>
          // :
          // <>
          //   {items.optionitems.map(({ caption, value }, index) => {
          //     const choiceUidIndex = uid + `[${index}]`;

          //     return (
          //       <ValueCheckbox
          //         key={formId + '-' + choiceUidIndex}
          //         id={formId + '-' + choiceUidIndex}
          //         field={uid}
          //         label={caption}
          //         valueToSet={value}
          //         html={true}
          //       />);
          //   })}
          //   <HtmlComponent html={items.optionitems[0].caption}></HtmlComponent></>)
        );
      }
      case INPUT_ELEMENTS.CHOICE_INPUTS.includes(element.blockType) && !element.allowMultiSelect: {
        const { id, validators, label, items } = element;
        const uid = this.generateUidForFormElement(id);
        return (
          !element.stepped &&
          <FlexContainer column key={id} margin="0.5rem 0 1rem 0 ">
            <AlternativeLabel>{label}</AlternativeLabel>
            <RadioGroup
              field={uid}
              nomarginbottom
              isvalid={this.isValid(uid)}
              touched={formApi.getTouched(uid)}
              message={this.getValidationErrorMessage(uid)}
              validate={value => this.validateChoice(value, validators)}
              validateOnChange
              validateOnBlur>
              {items.optionitems.map(({ caption, value }) => {
                const choiceUid = this.generateUidForChoice(id, value);
                return (
                  <Radio
                    key={formId + '-' + choiceUid}
                    id={formId + '-' + choiceUid}
                    label={caption}
                    value={value}
                  />
                );
              })}
            </RadioGroup>
          </FlexContainer>
        );
      }
      case INPUT_ELEMENTS.HIDDEN_CLIENTID.includes(element.blockType): {
        const { id, validators } = element;
        const { clientId, clientIdUid } = this.state;
        const uid = this.generateUidForFormElement(id);
        if (!clientIdUid.length) this.setState({ clientIdUid: uid });
        return (
          !element.stepped &&
          <>
            <HiddenInput
              key={id}
              field={uid}
              id={formId + '-hiddenClientId'}
              name={uid}
              ref={this.clientIdInputRef}
              touched={formApi.getTouched(uid)}
              isvalid={this.isValid(uid)}
              validate={value => this.validateText(value, validators)}
              message={this.getValidationErrorMessage(uid)}
              value={clientId}
            />
          </>
        )
      }
      case INPUT_ELEMENTS.STEP_BUTTON.includes(element.blockType): {
        const { id } = element;
        const { clientIdUid, stepBtnActive } = this.state;

        const uid = this.generateUidForFormElement(id);
        if (!clientIdUid.length) this.setState({ clientIdUid: uid });
        return (
          !stepBtnActive &&
          <SecondaryButton
            backgroundcolor={Colors.WHITE}
            bordercolor={Colors.ORANGE}
            borderradius="4px"
            hoverbackgroundcolor={Colors.ORANGE}
            width="fit-content"
            onClick={this.handleStepButton}
          >
            <ButtonText color={Colors.BLACK}>
              Fortsätt till nästa steg
            </ButtonText>
            <CollapsingArrow scale={0.5} black collapsed top="6px" nomargin right="0.6rem" />
          </SecondaryButton>
        )
      }
      default:
        return null;
    }
  };

  render() {
    const {
      allElements: stateElements,
      fileInput,
      submitButtons,
      result,
      isLoading,
      fileList,
      formState: stateFormState,
      stepBtnRendered,
      stepBtnActive
    } = this.state;
    const { invalid } = stateFormState || true;
    const { success, hasBeenSent, message } = result;
    const { id: formId, title, description, submitSuccessMessageText } = this.props;
    const sizeMargin = (window.innerWidth >= sizes.desktop ? '-5rem' : '-6rem')
    let stepCheck = stepBtnRendered;

    //for some reason INPUT_ELEMENTS.STEP_BUTTON did not work here, hence the ugly string comparison
    const stepBlockExists = stateElements.filter(x => x.blockType === 'CustomStepElement');

    const stepFilteredElements = stateElements.map(x => {
      if (x.blockType !== 'CustomStepElement' && stepCheck === false) {
        return { ...x, stepped: false };
      }
      if (x.blockType === 'CustomStepElement' && stepBtnActive === false) {
        stepCheck = true;
        return { ...x, stepped: false };
      };
      if (stepCheck === true) {
        return { ...x, stepped: true };
      }
      return { ...x, stepped: false };
    });
    return (
      <Block formpadding marginbottom background={Colors.GRAY} >
        <CenteredBlockInner nopadding wideForm>
          <div id="formLink" ref={this.scrollRef} style={{
            marginTop: sizeMargin, visibility: 'hidden', position: 'absolute'
          }}></div>
          {!hasBeenSent ?
            <Informed
              onChange={this.handleFormStateChange
              }
              getApi={api => (this.InformedApi = api)}
              id="form"
              ref={this.form}
              initialValues={this.initialValues}
              column={true}
              style={{ width: '100%' }}
              justifycontent="flex-start"
              encType="multipart/form-data">
              {({ formState, formApi }) => (
                <React.Fragment>
                  <AntiForgeryInput field="__RequestVerificationToken" />
                  <Title2 margin="1rem 0 1rem 0">{title}</Title2>
                  <MainBody>{description}</MainBody>
                  <FlexContainer margin="1rem 0 1rem 0" column>
                    {stepFilteredElements.map(item => {
                      return this.renderSwitch(item, formId, formApi);
                    })}
                  </FlexContainer>
                  <FlexContainer>
                    {fileInput.map((filebox, index) => {
                      const { label, description, message, id } = filebox;
                      const uid = this.generateUidForFormElement(id);
                      if (!stepBtnActive && stepBlockExists.length) return null;
                      return (
                        <InputWrapper filewrapper key={formId + '-' + index} left displaynone={success}>
                          <HiddenInput
                            id={formId + '-hiddenFileUpload'}
                            name={uid}
                            title={description}
                            type="file"
                            multiple
                            ref={this.inputRef}
                            onChange={e => this.handleFileInputChange(e, index)}
                          />
                          <SecondaryButton
                            htmlFor={formId + '-hiddenFileUpload'}
                            as="label"
                            bordercolor={Colors.BLACK}
                            backgroundcolor={Colors.WHITE}
                            borderradius="6px"
                            hoverbackgroundcolor={Colors.GRAY}
                            margin="0">
                            <ButtonText color={Colors.BLACK}>{label}</ButtonText>
                          </SecondaryButton>
                          {message && (
                            <ErrorMessage left fail>
                              {message}
                            </ErrorMessage>
                          )}
                        </InputWrapper>
                      );
                    })}
                  </FlexContainer>
                  <FlexContainer>
                    {fileList && (
                      <UnorderedList nopadding>
                        {fileList.map((file, index) => {
                          if (!stepBtnActive && stepBlockExists.length) return null;
                          return (
                            <NormalizedListItem key={formId + '-' + index} inlineBlock>
                              <FileName
                                fileName={file.name}
                                clickHandler={this.handleFileDelete}
                                deletable={!success}
                                color={success ? Colors.WHITE : Colors.BLACK}
                                backgroundcolor={success ? Colors.BRAND_DARK : Colors.GRAY}
                                flex
                              />
                            </NormalizedListItem>
                          );
                        })}
                      </UnorderedList>
                    )}
                  </FlexContainer>
                  <FlexContainer>
                    {submitButtons.map((button, index) => {
                      const { label, id } = button;
                      if (!stepBtnActive && stepBlockExists.length) return null;
                      return (
                        <SubmitButton
                          key={formId + '-' + index}
                          id={formId + '-' + id}
                          animateonactive
                          active={!invalid && !fileInput.filter(x => !x.isValid).length}
                          finished={!isLoading && success && hasBeenSent}
                          disabled={invalid || fileInput.filter(x => !x.isValid).length || success}
                          success={success}
                          right

                          bordercolor={Colors.ORANGE}
                          onClick={this.handleSubmit}
                          borderradius="6px"
                          noflex
                          margin="1rem 0 1.5rem auto"
                          disabledcolor={success ? Colors.ORANGE : Colors.LIGHT_GRAY}
                          type="submit">
                          <Loader
                            width="2.5rem"
                            height="3rem"
                            size="contain"
                            absolute
                            loaded={!isLoading}
                          />
                          <ButtonText hide={isLoading} color={success ? Colors.WHITE : Colors.BLACK}>
                            {label}
                          </ButtonText>
                        </SubmitButton>
                      );
                    })}
                  </FlexContainer>
                  <Flex width="100%">
                    <Box width={1 / 1} column>
                      {!success &&
                        message &&
                        message.length > 0 &&
                        message.map((message = '', index) => {
                          if (!message || !message.length) return null;
                          return (
                            <FormFeedbackMessage key={`${message}_${index}`} fail={!success} nomargin>
                              {message}
                            </FormFeedbackMessage>
                          );
                        })}
                    </Box>
                  </Flex>
                </React.Fragment>
              )}
            </Informed>
            :
            <FlexContainer column margin="0 0 1rem" padding="25px 0">
              <HtmlComponent html={submitSuccessMessageText}></HtmlComponent>
            </FlexContainer>
          }
        </CenteredBlockInner>

      </Block>
    );
  }
}

const mapStateToProps = state => ({
  page: {
    loading: state.page.loading,
    id: state.page.currentId
  },
  currentCategory: state.filter.current
});

const ConnectedForm = connect(
  mapStateToProps,
  null
)(EpiForm);

export default WithFormTracker(ConnectedForm);

EpiForm.propTypes = {
  submitSuccessMessageText: PropTypes.string,
  elementsArea: PropTypes.array,
  description: PropTypes.string,
  submitSuccessMessage: PropTypes.string,
  id: PropTypes.number.isRequired,
  redirectToPage: PropTypes.string,
  showSummarizeData: PropTypes.bool,
  title: PropTypes.string,
  allowAnonymousSubmission: PropTypes.bool,
  allowExposingDataFeeds: PropTypes.bool,
  allowMultipleSubmission: PropTypes.bool,
  allowToStoreSubmissionData: PropTypes.bool,
  guid: PropTypes.string.isRequired
};
