import React from 'react';
import { getSelectedValueFromSelectionFactory } from 'utils';
import {
  JobApplicationBlockHeader,
  JobApplicationBlockText,
  JobApplicationBlockButton
} from './components';
import ScrollAnimation from 'react-animate-on-scroll';
import OPE from 'components/common/EpiOnPageEdit';
import { FlexContainer } from 'components/flex';

export const jobApplicationBlock = ({ ...rest }) => {
  const { textColor, backgroundColor, quoteText, quoteHeader, applicationLink, linkText } = rest;
  const txtColor = getSelectedValueFromSelectionFactory(textColor);
  const bgColor = getSelectedValueFromSelectionFactory(backgroundColor);
  return (
    <FlexContainer
      margin="0 0 1.667rem 0"
      justifycontent={'center'}
      smallrow
      style={{ backgroundColor: bgColor }}
      overflow="hidden">
      <ScrollAnimation animateIn="fadeInUp">
        <FlexContainer
          justifycontent="center"
          alignItems="center"
          smallAlignContent="center"
          column
          overflow="hidden"
          padding="0 0 1rem"
          smallpadding="0 0 1rem">
          <OPE propertyName="quoteHeader">
            <JobApplicationBlockHeader color={txtColor} center>
              {quoteHeader}
            </JobApplicationBlockHeader>
          </OPE>
          <OPE propertyName="quoteText">
            <JobApplicationBlockText color={txtColor} center>
              {quoteText}
            </JobApplicationBlockText>
          </OPE>
          <OPE propertyName="buttonLink">
            <JobApplicationBlockButton
              color={txtColor}
              hovercolor={bgColor}
              backgroundcolor="transparent"
              href={applicationLink}
              target="_blank">
              {linkText ? linkText : 'Spontanansökan'}
            </JobApplicationBlockButton>
          </OPE>
        </FlexContainer>
      </ScrollAnimation>
    </FlexContainer>
  );
};
