import React from 'react';
import { customStyles, DropdownContainer, SearchDropdown } from './SearchSortDropdownStyles';

import { Option } from './Option';
import { Control } from './Control';
import { DropdownIndicator } from './DropdownIndicator';

const SearchSortDropdown = props => {
  const { handleDropdownChange, options } = props;
  return (
    <DropdownContainer>
      <SearchDropdown
        {...props}
        isSearchable={false}
        onChange={e => handleDropdownChange(e)}
        styles={customStyles}
        ignoreAccents={false}
        defaultValue={options[0]}
        components={{ Option, Control, DropdownIndicator }}
      />
    </DropdownContainer>
  );
};

export default SearchSortDropdown;
