import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

const Gradient = styled.div`
  &:before {
    display: ${props => (props.gradient ? 'block' : 'none')};
    content: '';
    width: ${props => (props.gradient ? props.gradient.width : '50%')};
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-image: linear-gradient(
      to ${props => (props.gradient ? props.gradient.direction : '')},
      ${props => (props.gradient ? props.gradient.color : Colors.WHITE)},
      rgba(255, 255, 255, 0)
    );
    background-blend-mode: multiply;
  }
`;

/**
 * ArticleBlockContainer (div)
 */

export const PrioritizedArticleBlockContainer = styled(Gradient).attrs(props => ({
  margin: props.center ? '0 auto' : '0',
  padding: props.nopadding ? 0 : '2rem 2rem 2rem 2rem',
  paddingtop: props.nopadding ? 0 : '2rem'
}))`
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingtop};
  display: flex;
  flex-direction: row;
  position: relative;
  max-height: 17.5rem;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  z-index: 0;
  max-width: ${props => props.maxwidth};
  margin: ${props => props.margin};

  ${media.desktop`
    background: ${Colors.WHITE};  
    padding-top:0;
    max-height: none;
    flex-direction: column;
  `};
`;

PrioritizedArticleBlockContainer.propTypes = {
  first: PropTypes.bool.isRequired,
  gradient: PropTypes.shape({
    direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
    color: PropTypes.oneOf(Object.values(Colors)),
    width: PropTypes.string
  }),
  nopadding: PropTypes.bool
};
