import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const enterDuration = 700;
const exitDuration = 200;
export default class PopIn extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    node.style.display = 'flex';

    anime({
      targets: node,
      translateY: [20, 0],
      opacity: [0, 1],
      duration: enterDuration,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  onExit = (node, index) => {
    anime({
      targets: node,
      opacity: [1, 0],
      translateY: [0, 20],
      duration: exitDuration,
      easing: 'easeOutQuad',
      elasticity: 0,
      complete: () => (node.style.display = 'none')
    });
  };

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: enterDuration, exit: exitDuration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onExit={this.onExit}
        mountOnEnter={true}
        unmountOnExit={false}>
        {children}
      </Transition>
    );
  }
}
PopIn.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element
};
