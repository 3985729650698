import React, { Component } from 'react';
import { Navigation } from 'features/Navigation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getMenuItems } from 'features/Navigation/navigationActions';
import { setPreviousPage } from './appActions';
import { AppContainer } from './AppContainer';
import { AppBasePage } from './AppBasePage';
import { ModalPortalDestination } from 'features/Modal';
import { MainContainer } from './MainContainer';
import { Footer } from 'features/Footer';
import HamburgareMeny from 'features/HamburgareMeny';
import Login from 'features/Account/Login';
import { ErrorBoundary } from 'features/ErrorBoundary';
import { isEmpty } from '../../utils';
import PageRoutes from '../PageRoutes/PageRoutes';
import { setSelectedFilter } from 'features/CountyFilter';
import { toggleModal } from 'features/CountyFilter/countyFilterActions';
import { resetFilter } from 'features/FilterArea';
import { toggle } from 'features/HamburgareMeny';
import { HamburgareIcon } from 'components/button';
import { validate } from 'features/Account/accountActions';
import { setUpDomEvents } from 'features/DomEvents/domeventsActions';
import CookieBar from 'features/CookieBar';
import { PAGES } from 'constants/pageTypes';
import ModalCountyFilter from 'features/ModalCountyFilter';
import { push } from 'connected-react-router';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { currentPageId: undefined };
  }

  componentDidMount() {
    const { validate, setUpDomEvents, location = {} } = this.props;
    const { pathname: currentPage } = location;
    validate();
    setUpDomEvents();
    setPreviousPage(currentPage);
  }

  onPageChange = currentPathName => {
    const { resetFilter } = this.props;
    //Temporary fix for pages that scrolled weirdly, but we don't want to destroy the scroll for servicepage.
    if (currentPathName.length && !currentPathName.includes('tjanster')) window.scrollTo(0, 0);
    setPreviousPage(currentPathName);
    resetFilter();
  };

  fetchFooterItems = () => {
    const { selected, getMenuItems } = this.props;
    getMenuItems(selected);
  };

  componentDidUpdate(prevProps) {
    const { app, currentid, push, location: nextLocation = {}, page } = this.props;
    const { location = {} } = prevProps;
    const { pathname: currentPathName } = location;
    const { pathname: nextPathname } = nextLocation;
    const { routes } = app;

    const currentRoute = routes.find(data => data.id === currentid);

    if (currentPathName !== nextPathname) {
      this.onPageChange(currentPathName);
    }

    if (prevProps.app === app) return null;

    if (!routes) return;
    if (!currentRoute) return;

    if (currentid === this.state.currentPageId) return;
    if (page.current === null || page.prevPage === null) return;
    push(`${currentRoute.url[0]}${window.location.search}`);
    this.setState({ currentPageId: currentid });
    this.fetchFooterItems();
  }

  setInitialCategory() {
    const { setSelectedFilter, app, filter } = this.props;
    setSelectedFilter(!isEmpty(app.counties) ? app.counties[0] : filter.categoryList[0]);
  }

  veryClick = expanded => {
    this.props.toggle(!expanded);
    this.props.toggleModal();
  };

  render() {
    const { isLoading: loading, settings = {}, footer } = this.props.app;
    const { cookie } = settings;
    const { burgare, filter, page } = this.props;
    const { selected, isModalOpen } = filter;
    const { expanded } = burgare;
    const is404 = page.current && page.current.pageType === PAGES.NOT_FOUND_PAGE;
    return (
      <ErrorBoundary>
        <AppContainer>
          <React.Fragment>
            <ModalCountyFilter />
            <ModalPortalDestination />
            <Login selectedCounty={selected.id} />
            <Navigation is404={is404} />
            <HamburgareIcon
              onClick={() => this.veryClick(expanded)}
              expanded={expanded}
              hideOnDesktop
              isModalOpen={isModalOpen}
            />
            <HamburgareMeny />
            <MainContainer>
              <AppBasePage loading={loading}>
                <Route path="/" component={PageRoutes} />
              </AppBasePage>
            </MainContainer>
            <Footer footer={footer} />
            <CookieBar {...cookie} />
            {/* </React.StrictMode> */}
          </React.Fragment>
        </AppContainer>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({ page, app, filter, burgare, router, navigation }) => ({
  page: {
    loading: page.loading,
    current: page.page,
    prevPage: page.previousPage
  },
  app: {
    routes: app.routes,
    sideNav: app.sideNav,
    mainNav: app.mainNav,
    hamburgerNav: app.hamburgerNav,
    footer: app.footer,
    counties: app.counties,
    pageNotFound: app.pageNotFound,
    isLoading: app.isLoading,
    settings: app.settings,
    previousPage: app.previousPage
  },
  filter: {
    counties: filter.categoryList,
    selected: filter.selected,
    isModalOpen: filter.isModalOpen
  },
  burgare: {
    expanded: burgare.expanded
  },
  location: router.location,
  isSearchActive: navigation.isSearchActive
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMenuItems,
      push,
      setSelectedFilter,
      toggle,
      validate,
      setUpDomEvents,
      resetFilter,
      setPreviousPage,
      toggleModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
