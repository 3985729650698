import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login, logout, validate, setLoginFormVisibility } from '../accountActions';
import { Input } from 'components/input';
import { Title2, MainBody } from 'components/text';
import { SubmitButton } from 'components/button';
import { Link } from 'components/link';

import { FlexContainer } from 'components/flex';
import { Form } from 'components/form';
import { ButtonText } from 'components/button';
import { colors as Colors } from 'constants/colors';
import { LoginMessage } from './LoginMessage';
import { Loader } from 'components/common/Loader';
import { FormWrapper } from './FormWrapper';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userValue: '', passwordValue: '', isLoginView: true };
    this.loginForm = {};
  }

  componentDidMount() {
    const { account } = this.props;
    const { user } = account;
    if (!user) return;
    this.setState({ userValue: user });
  }

  handleUsernameChange = e => {
    const { value } = e.target;
    this.setState({ userValue: value });
  };

  handlePasswordChange = e => {
    const { value } = e.target;
    this.setState({ passwordValue: value });
  };

  handleSubmit = async e => {
    const { login } = this.props;
    const { userValue, passwordValue } = this.state;
    e.preventDefault();
    const result = await login(userValue, passwordValue);
    const { success } = result;
    if (!success) {
      this.setState({ passwordValue: '', userValue: '' });
    }
  };
  //TODO: bryt ut denna och requestlogin till en egen återanvändningsbar komponent

  render() {
    if (!this.props) return null;
    const { passwordValue, userValue } = this.state;
    const { account, onChangeView } = this.props;
    const { message, isLoading, isAuthenticated, success } = account;
    return (
      <FormWrapper>
        <Title2>Logga in</Title2>
        <Form column justifycontent="flex-start" onSubmit={this.handleSubmit}>
          <MainBody>
            Är du medlem i Fastighetsägarna men saknar inloggningsuppgifter?
            <Link href="#" onClick={e => e.preventDefault && onChangeView()}>
              Skapa inloggningsuppgifter
            </Link>
          </MainBody>
          <Input
            autocomplete="off"
            value={userValue}
            type="text"
            onChange={this.handleUsernameChange}
            placeholder="Användarnamn"
          />
          <Input
            autocomplete="off"
            value={passwordValue}
            type="password"
            onChange={this.handlePasswordChange}
            placeholder="Lösenord"
          />
          <FlexContainer column alignItems="center">
            <LoginMessage fail={!success} nomargin>
              {((!userValue && !passwordValue) || (userValue && message)) && message}
            </LoginMessage>
            <SubmitButton
              active={userValue && passwordValue}
              type="submit"
              right
              bordercolor={Colors.ORANGE}>
              <Loader width="2.5rem" height="100%" size={'contain'} absolute loaded={!isLoading} />
              <ButtonText large hide={isLoading || isAuthenticated} color={Colors.BLACK}>
                Logga in
              </ButtonText>
            </SubmitButton>
          </FlexContainer>
        </Form>
      </FormWrapper>
    );
  }
}

const mapStateToProps = state => ({
  account: {
    user: state.account.user,
    isAuthenticated: state.account.isAuthenticated,
    showLoginForm: state.account.showLoginForm,
    message: state.account.message,
    isLoading: state.account.isLoading,
    success: state.account.success
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      logout,
      validate,
      setLoginFormVisibility
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

LoginForm.propTypes = {
  login: PropTypes.func
};
