import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';

export const FileNameBox = styled.span.attrs(props => ({
  backgroundcolor: props.backgroundcolor ? props.backgroundcolor : Colors.GRAY
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.7em;
  cursor: default;
  position: relative;
  padding: 0.5417rem;
  background-color: ${props => props.backgroundcolor};
`;
