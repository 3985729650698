import styled from 'styled-components/macro';

export const FilterAreaContainer = styled.ul`
  display: flex;
  width: 100%;
  appereance: none;
  padding: 0;
  margin: 0 0 1rem 0;
  list-style-image: none;
  flex-wrap: wrap;
`;
