import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { PDF, JPG, DOCX, DOC } from 'constants/fileEndings';
const WithLinkTracker = (WrappedComponent, options = {}) => {
  const FILE_TYPES = [PDF, JPG, DOCX, DOC];
  const SELLER_EMAILS = [
    'patrik.levy@fastighetsagarna.se',
    'patrik.drysen@fastighetsagarna.se',
    'monika.malmsten@fastighetsagarna.se'
  ];

  const HOC = class extends Component {
    handleMailLink = (mail = '') => {
      const email = mail.split(':').pop();
      if (!SELLER_EMAILS.includes(email)) return;

      ReactGA.event({
        category: 'Kontakt - Säljare',
        action: 'Klick på en säljares e-post.',
        label: window.location.href,
        value: 'Säljare'
      });
    };

    handlePdfClick = (href = '', fileType = '') => {
      ReactGA.event({
        category: 'Ladda ner',
        action: `Klick på länk för en ${fileType}-fil.`,
        label: window.location.href,
        value: href
      });
    };

    handleOnClick = e => {
      const { href } = this.props;
      if (!href) return;
      if (href.match(/^mailto:/i)) {
        this.handleMailLink(href);
        return;
      }
      const fileType = href.split('.').pop() || '';
      if (fileType && FILE_TYPES.includes(fileType)) {
        this.handlePdfClick(href, fileType);
        return;
      }
      ReactGA.outboundLink(
        {
          label: href
        },
        () => {}
      );
    };
    render() {
      return <WrappedComponent {...this.props} onMouseUp={e => this.handleOnClick(this.props)} />;
    }
  };

  return HOC;
};

export default WithLinkTracker;
