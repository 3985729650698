import styled from 'styled-components/macro';
import React from 'react';
import { media } from 'style/media';
import { colors as Colors } from 'constants/colors';
import { zIndex } from '../../constants/settings';
import PropTypes from 'prop-types';

const getSettings = (scale = 1.5) => {
  return {
    width: `${12 * scale}px`,
    doubleWidth: `${12 * 2}px`,
    halfWidth: `${12 * 1}px`,
    right: `${-9 * scale}px`,
    left: `${-9 * scale}px`,
    top: `${9 * scale}px`,
    height: `${4 * scale}px`
  };
};

const move = (collapsed, hover) => {
  if (collapsed && hover) {
    return '.1em';
  }
  if (collapsed && !hover) return '-3px';
  return 0;
};

export const ArrowContainer = styled.span.attrs(props => ({
  top: props.top !== undefined ? props.top : move(props.collapsed, props.hover),
  smallLeft: props.smallLeft ? props.smallLeft : '',
  expandedTop: props.expandedTop
}))`
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  z-index: ${zIndex.ABOVE_MODALS};
  margin-left: ${({ nomargin }) => (nomargin ? 0 : '0.5em')};
  top: ${props => props.collapsed ? props.top : props.expandedTop};
  width: ${getSettings().doubleWidth};
  height: ${getSettings().doubleWidth};
  right: ${({ right }) => right && right};
  cursor: ${props => (props.show ? 'pointer' : 'default')};
  transform: scale(${({ scale }) => scale});
  &:hover {
    transform: scale(${({ scale }) => scale * 1.1});
  }
  ${media.desktop`
  left: ${props => props.smallLeft};
  `};
`;

ArrowContainer.propTypes = {
  onClick: PropTypes.func,
  collapsed: PropTypes.bool,
  hover: PropTypes.bool
};

export const ArrowBeforeAfter = styled.div`
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    right: ${getSettings().right};
    width: ${getSettings().width};
    height: ${getSettings().height};
    background: ${({ white, black }) =>
    white ? Colors.WHITE : black ? Colors.BLACK : Colors.ORANGE};
    border-radius: 2px;
    transition: all 0.5s ease-in-out;
  }
`;

export const ArrowLeft = styled(ArrowBeforeAfter)`
  &:before {
    transform: rotateZ(45deg);
    left: 0;
    top: 9px;
  }
  &:after {
    transform: rotateZ(-45deg);
    left: 0;
  }
`;

export const ArrowRight = styled(ArrowBeforeAfter)`
  &:before {
    transform: rotateZ(-45deg);
    right: 0;
    top: 9px;
  }
  &:after {
    transform: rotateZ(45deg);
    right: 0;
  }
`;

const Arrow = styled(ArrowBeforeAfter)`
  cursor: ${props => (props.show ? 'pointer' : 'default')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
`;

const ArrowUpper = styled(Arrow).attrs(props => ({
  rotation: props.collapsed ? 45 : -45,
  top: props.collapsed ? 0 : '-5px'
}))`
  &:before {
    top: ${props => props.top};
    transform: rotate(${props => -props.rotation}deg);
  }

  &:after {
    top: ${props => props.top};
    left: ${getSettings().left};
    transform: rotate(${props => props.rotation}deg);
  }
`;

export const CollapsingArrow = ({ collapsed, white = false, black = false, ...props }) => {
  return (
    <ArrowContainer collapsed={collapsed} {...props}>
      <ArrowUpper show collapsed={collapsed} white={white} black={black} />
    </ArrowContainer>
  );
};

CollapsingArrow.propTypes = {
  //collapsed -> up, else -> down.
  collapsed: PropTypes.bool
};
