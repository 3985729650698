import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { shadow, SHADOW_TYPES } from '../../../constants/shadows';
import { zIndex } from '../../../constants/settings';

export const PrioritizedArticleBlockContentItem = styled.div.attrs(props => ({
  width: props.width ? props.width : '50%'
}))`
  flex-direction: ${props => props.flexdirection || 'row'};
  width: ${props => props.width};
  position: relative;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => (props.shadowType ? shadow(props.shadowType) : '')};
  z-index: ${props => (props.zIndex ? props.zIndex : zIndex.LEVEL_0)};
  background: ${props => (props.backgorundcolor ? props.backgorundcolor : Colors.GRAY)};
  ${media.desktop`
    width:100%;
    order: ${props => props.mediaSmallOrder};    
    shadow: none;
    padding: 2rem auto; 
    flex-direction: column;
    justify-content:center;
    align-items: center;
  `};
  display: flex;
`;

PrioritizedArticleBlockContentItem.propTypes = {
  mediaSmallOrder: PropTypes.number,
  flexdirection: PropTypes.oneOf(['row', 'column']),
  shadowType: PropTypes.oneOf(Object.values(SHADOW_TYPES)),
  zIndex: PropTypes.number,
  left: PropTypes.bool,
  right: PropTypes.bool,
  backgorundcolor: PropTypes.oneOf(Object.values(Colors))
};
