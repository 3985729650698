import styled from 'styled-components/macro';
import { shadow, SHADOW_TYPES } from 'constants/shadows';
import { colors as Colors } from 'constants/colors';
import { zIndex, settings } from 'constants/settings';
import { media } from 'style/media';

export const HamburgareContainer = styled.nav`
    // transition: transform .3s ease-in;
  position: fixed;
  display: block;
  background: ${Colors.WHITE};
  width: 15rem;
  max-width: 15rem;
  right: 0;
  box-shadow: ${shadow(SHADOW_TYPES.THREE)};
  z-index: ${zIndex.MODALS};
  height: 110vh;
  max-height: 110vh;
  padding: ${settings.MENU_HEIGHT} 0 ${settings.MENU_HEIGHT} 0;
  overflow-y: scroll;
  // transform: translateX(${props => props.translatex});
  ${media.desktop`
    width: 100%;
    max-width: 100%;
    top: -4rem;
  `};
`;
