import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  HamburgareContainer,
  HamburgareWrapper,
  HamburgareItem,
  HamburgareItemWrapper,
  HamburgareItemHeader,
  HamburgareItemExpander,
  HamburgareTransition,
  HamburgarePerspective
} from './components';
import { SecondaryButton, ButtonText } from 'components/button';
import { PAGES } from 'constants/pageTypes';
import { colors as Colors } from 'constants/colors';
import { logout, setLoginFormVisibility } from 'features/Account/accountActions';
import { toggle } from './hamburgareActions';
import { Link } from 'components/link';
import { FlexContainer } from 'components/flex';
import { FilterByCounty } from 'utils';

class HamburgareMeny extends Component {
  constructor(props) {
    super(props);
    const {
      app: { hamburgerNav, rootCountyId },
      filter: { selectedId, countyCategories }
    } = props;

    this.state = {
      expanded: [],
      show: false,
      navItems: FilterByCounty(hamburgerNav, selectedId, rootCountyId, countyCategories)
    };

    this.toggleItem = this.toggleItem.bind(this);
    this.times = 0;
  }

  toggleItem(id) {
    const isExpanded = this.state.expanded.includes(id);
    if (isExpanded) {
      this.setState({ expanded: this.state.expanded.filter(x => x !== id) });
    } else {
      this.setState({ expanded: [...this.state.expanded, id] });
    }
  }

  componentDidUpdate(prevProps) {
    const { app: nextApp, filter: nextFilter } = this.props;
    const { hamburgerNav: nextHamburgerNav, rootCountyId: nextRootCountyId } = nextApp;
    const { selectedId: nextSelectedId, countyCategories: nextCountyCategories } = nextFilter;
    const { app, filter } = prevProps;
    const { hamburgerNav } = app;
    const { selectedId } = filter;

    if (nextHamburgerNav !== hamburgerNav || selectedId !== nextSelectedId) {
      const filteredHamburgerNav = FilterByCounty(
        nextHamburgerNav,
        nextSelectedId,
        nextRootCountyId,
        nextCountyCategories
      );
      this.setState({ navItems: filteredHamburgerNav });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      hamburgerExpanded,
      filter,
      app: { hamburgerNav }
    } = this.props;
    const {
      hamburgerExpanded: nextHamburgerExpanded,
      filter: nextFilter,
      app: { hamburgerNav: nextHamburgerNav }
    } = nextProps;
    const { expanded, navItems } = this.state;
    const { expanded: nextExpanded, navItems: nextNavItems } = nextState;
    const { selectedId } = filter;
    const { selectedId: nextSelectedId } = nextFilter;
    if (expanded !== nextExpanded) return true;
    if (hamburgerExpanded !== nextHamburgerExpanded) return true;
    if (navItems !== nextNavItems) return true;
    if (selectedId !== nextSelectedId) return true;
    if (hamburgerNav !== nextHamburgerNav) return true;
    return false;
  }

  handleItemClick() {
    if (window.innerWidth > 992) return;
    this.props.toggle(false);
  }

  handleHeaderClick(e, item) {
    const { id, pageType } = item;
    const { target } = e;
    const { tagName } = target;
    if (pageType === PAGES.FOLDER || tagName === 'BUTTON') {
      e.preventDefault();
      this.toggleItem(id);
    } else {
      if (window.innerWidth > 992) return;
      this.props.toggle(false);
    }
  }

  getHamburgerItems = (nav, parent) => {
    if (!nav) return null;
    const { router } = this.props;

    const { location } = router;

    const items = nav.map(item => {
      const { childNavigationLinks: children, id, url, treeDepth } = item;
      const { expanded } = this.state;
      const isExpanded = expanded.includes(id);
      const shouldRenderChildren = !parent || expanded.includes(parent);
      let activeClassName = url[0] === '/' && location.pathname !== '/' ? '' : 'nav-item-active';

      if (children.length && item.treeDepth !== 0) {
        return (
          <HamburgareItemWrapper expanded={true} key={url[0]}>
            <HamburgareItemHeader
              activeClassName={activeClassName}
              onClick={e => this.handleHeaderClick(e, item)}
              {...item}
              key={id}>
              <HamburgareItemExpander expanded={isExpanded} onClick={() => this.toggleItem(id)} />
            </HamburgareItemHeader>
            <HamburgareItemWrapper
              expanded={isExpanded}
              className={`wrapparen ${parent ? parent : ''}`}>
              {shouldRenderChildren && this.getHamburgerItems(children, id)}
            </HamburgareItemWrapper>
          </HamburgareItemWrapper>
        );
      }

      if (treeDepth > 1) {
        return <HamburgareItem onClick={() => this.handleItemClick()} {...item} key={url[0]} />;
      }

      return (
        <HamburgareItemHeader
          bold
          activeClassName={activeClassName}
          onClick={e => this.handleHeaderClick(e, item)}
          {...item}
          key={url[0]}
        />
      );
    });

    return items;
  };

  render() {
    const { hamburgerExpanded, logout, account } = this.props;
    const { isAuthenticated, isAdmin } = account;
    const { navItems } = this.state;
    return (
      <HamburgarePerspective show={hamburgerExpanded}>
        <HamburgareTransition in={hamburgerExpanded}>
          <HamburgareContainer>
            <HamburgareWrapper>
              {hamburgerExpanded && this.getHamburgerItems(navItems)}
              <FlexContainer
                column
                alignItems="center"
                justifycontent="center"
                smallAlignContent="center">
                {isAuthenticated &&
                  isAdmin && [
                    <SecondaryButton
                      key="editor_button"
                      onClick={() => logout()}
                      bordercolor={Colors.ORANGE}
                      backgroundcolor={Colors.WHITE}>
                      <ButtonText color={Colors.ORANGE} large weight="bold">
                        Logga ut
                      </ButtonText>
                    </SecondaryButton>,
                    <Link key="editor_link" external href="/episerver">
                      Gå till redigerarläget
                    </Link>
                  ]}
              </FlexContainer>
            </HamburgareWrapper>
          </HamburgareContainer>
        </HamburgareTransition>
      </HamburgarePerspective>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    lastId: state.filter.last.id,
    selectedId: state.filter.selected.id,
    countyCategories: state.filter.categoryList
  },
  app: {
    hamburgerNav: state.app.hamburgerNav,
    rootCountyId: state.app.settings.rootCounty
  },
  hamburgerExpanded: state.burgare.expanded,
  account: {
    isAuthenticated: state.account.isAuthenticated,
    isAdmin: state.account.isAdmin
  },
  router: state.router
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logout, setLoginFormVisibility, toggle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HamburgareMeny);
