import { colors as Colors } from 'constants/colors';
import React from 'react';
import { components } from 'react-select';
import { fonts } from 'style/fonts';
import styled from 'styled-components/macro';
import { media } from 'style/media';

const LocalControl = styled(components.Control)`
  &&& {
    transition: all 0.3s ease;
    font-family: ${fonts.APERCU.WEB};
    font-size: 20px;
    border-color: ${({ isFocused }) => (isFocused ? Colors.ORANGE : 'transparent')};
    box-shadow: none;

    .StyledSelect__indicator-separator {
      display: none;
    }
    .StyledSelect__indicators {
      svg {
        color: ${Colors.ORANGE};
      }
    }
    &:hover {
      cursor: pointer;
    }

    ${media.desktop`
        font-size: 16px;
    `};
  }
`;

export const Control = props => {
  return <LocalControl {...props}>{props.children}</LocalControl>;
};
