import styled from 'styled-components/macro';
import { media } from 'style/media';
import { zIndex } from '../../constants/settings';

export const HeroBlockContentItem = styled.div.attrs(props => ({
  paddingtop: props.paddingtop ? props.paddingtop : 0,
}))`
  flex-direction: column;
  width: 100%;
  padding-top: ${props => props.paddingtop};
  position: relative;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  z-index: ${zIndex.LEVEL_0};
  ${media.desktop`
    width:100%;
    align-items: center;
    
  `};
`;

export const HeroBlockContentItemSmall = styled(HeroBlockContentItem)`
visibility: visible;
${media.desktop`
visibility: hidden;
padding: 0;
`};
`;
