export const validateEmail = (email = '') => {
  const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !expression.test(email) ? 'Ogiltlig e-postadress.' : null;
};

export const validateMinLength = (value, length = 0) => {
  return !value || value.length < length
    ? length === 0
      ? 'Fältet är obligatoriskt'
      : `Fältet måste vara minst ${length} antal tecken.`
    : null;
};

export const validateMaxLength = (value, field, length = 5) => {
  return value && value.length > length
    ? `Fältet ${field ? field : ''} får inte ha fler än ${length} antal tecken.`
    : null;
};

export const validateAtLeastOneChoice = value => {
  return !value || value.length === 0 ? 'Fältet är obligatoriskt' : null;
};
