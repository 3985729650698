import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { lazyload } from 'react-lazyload';
import { FlexContainer } from 'components/flex';
import { MediumText, ContactText } from 'components/text';
import { InlineLinkWithIcon, InlineWithIcon } from 'components/link';
import { ContactImage } from './ContactImage';
import { ContactTitle } from './ContactTitle';
import { ContactUnderlineContainer } from './ContactUnderlineContainer';
import { uberSmall, largePortrait } from '../../utils';
import { LazyLoadImage } from 'features/LazyImage';
import { UnderlineContainerText } from 'components/UnderlineContainer';
import { colors as Colors } from 'constants/colors';
import iconEnvelope from 'images/iconEnvelope.svg';
import iconQuestion from 'images/iconQuestion.svg';
import { HighlightBackground } from 'components/text';

@lazyload({
  once: true,
  offset: 100
})
class Contact extends React.Component {

  constructor(props){
    super(props);
  this.state = {
    showEmail: false
  }
}
  render() {
    const {
      email,
      imageUrl,
      phone,
      highlight = '',
      name,
      linkUrl,
      title,
      nomargin,
      nolinks,
      localOffice
    } = this.props;
    
    const clickToReveal = (e) => {
      e.preventDefault();
      this.setState({showEmail: true})
    }

    return (
      <FlexContainer
        smallAlignContent="center"
        margin={nomargin ? '0' : '0 0 1.3rem 0'}
        alignItems="center">
        <FlexContainer
          width="100%"
          smallAlignContent="center"
          marginleft="1.5rem"
          justifycontent="flex-start"
          alignItems="center">
          <FlexContainer
            padding="0 0 0 0"
            smallmargin="0 0 1rem 0"
            smallrow
            row
            width="100%"
            flex="1"
            alignItems="center">
            <FlexContainer padding="0 0 0 0" column width="30%" flex="1" alignItems="flex-start">
              <ContactImage href={linkUrl} asLink={!nolinks}>
                <LazyLoadImage
                  offset={0}
                  placeholder={uberSmall(imageUrl)}
                  src={largePortrait(imageUrl)}
                />
              </ContactImage>
            </FlexContainer>
            <FlexContainer
              padding="0 0 0 0"
              column
              width="70%"
              flex="3"
              alignItems="flex-start"
              justifycontent="flex-end">
              <ContactUnderlineContainer
                padding="0"
                margin="0 0 0.5rem 0"
                position="bottom"
                color={Colors.ORANGE}>
                <ContactTitle asLink={!nolinks} href={linkUrl} breakword>
                  <Highlighter
                    background
                    highlightTag={HighlightBackground}
                    searchWords={highlight.split(' ')}
                    autoEscape={true}
                    textToHighlight={name || ''}
                  />
                </ContactTitle>
              </ContactUnderlineContainer>
              <UnderlineContainerText padding="0.4rem 0.1rem 0 0">
                <Highlighter
                  background
                  highlightTag={HighlightBackground}
                  searchWords={highlight.split(' ')}
                  autoEscape={true}
                  textToHighlight={title || ''}
                />
              </UnderlineContainerText>
              <UnderlineContainerText padding="0.1rem 0.1rem 0 0">
                <Highlighter
                  background
                  highlightTag={HighlightBackground}
                  searchWords={highlight.split(' ')}
                  autoEscape={true}
                  textToHighlight={localOffice || ''}
                />
              </UnderlineContainerText>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer
            padding="0 0 0 1rem"
            smallpadding="0 0 0 0"
            column
            alignItems="flex-start"
            width="50%"
            flex="1">
            <MediumText nomargin small spacing>
              {nolinks && (
                <InlineWithIcon icon={iconEnvelope} href={`tel:${phone}`}>
                  <ContactText>Telefon:</ContactText>
                  {` ${phone}`}
                </InlineWithIcon>
              )}
              {!nolinks && (
                <InlineLinkWithIcon icon={iconEnvelope} href={`tel:${phone}`}>
                  <ContactText>Telefon:</ContactText>
                  {` ${phone}`}
                </InlineLinkWithIcon>
              )}
            </MediumText>
            <MediumText nomargin small spacing>
              {nolinks && (
                <InlineWithIcon onClick={clickToReveal} icon={iconQuestion} href={!this.state.showEmail ? null : `mailto:${email}`} >
                  <ContactText>E-post:</ContactText>
                  {!this.state.showEmail ? "Klicka för att visa e-post" : email}
                </InlineWithIcon>
              )}
              {!nolinks && (
                <InlineLinkWithIcon onClick={clickToReveal} icon={iconQuestion} href={!this.state.showEmail ? null : `mailto:${email}`} >
                  <ContactText>E-post:</ContactText>
                  {!this.state.showEmail ? "Klicka för att visa e-post" : email}
                </InlineLinkWithIcon>
              )}
            </MediumText>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

Contact.protoTypes = {
  email: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  localOffice: PropTypes.string,
  phone: PropTypes.string,
  linkUrl: PropTypes.string,
  title: PropTypes.string,
  highlight: PropTypes.string
};

export default Contact;
