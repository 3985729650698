import * as TYPES from './appTypes';

const initialState = {
    sideNav: [],
    mainNav: [],
    hamburgerNav: [],
    footer: [],
    serviceNav: [],
    routes: [],
    isLoading: false,
    currentId: undefined,
    counties: [],
    pageNotFound: [],
    search: '/',
    settings: {},
    previousPage: '',
    oidcRoutes: {},
    authTexts: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TYPES.APP_FETCH: {
            return {
                ...state,
                isLoading: true
            };
        }
        case TYPES.APP_FETCHED: {
            return {
                ...state,
                isLoading: false,
                routes: action.routes,
                counties: action.counties,
                pageNotFound: action.pageNotFound,
                search: action.search,
                settings: action.settings,
                oidcRoutes: action.oidcRoutes,
                authTexts: action.authTexts,
            };
        }
        case TYPES.APP_SET_CURRENT_ID: {
            return {
                ...state,
                currentId: action.id
            };
        }
        case TYPES.FETCHED_ROUTE_BY_URL: {
            return {
                ...state,
                routes: action.route ? [...state.routes, action.route] : state.routes
            };
        }
        case TYPES.SET_PREVIOUS_PAGE: {
            return {
                ...state,
                previousPage: action.url
            };
        }
        case TYPES.NAVIGATION_FETCH: {
            return { ...state, isLoading: true };
        }
        case TYPES.NAVIGATION_FETCHED: {
            return {
                ...state,
                sideNav: action.nav.side,
                mainNav: action.nav.main,
                hamburgerNav: action.nav.hamburger,
                footer: action.nav.footer,
                serviceNav: action.nav.services,
                isLoading: false
            };
        }
        default:
            return state;
    }
};
