import domToReact from 'html-react-parser/lib/dom-to-react';
import React from 'react';
import * as TAGS from './TAGS';
import { ErrorHandler, addEndingIfNeeded, uberSmall } from '../../utils';
import { HtmlImage } from './components';
import { BlockComponents } from 'constants/blockComponents';
//components
import {
  MainBody,
  Ingress,
  Quote,
  Quote2,
  Quote3,
  Quote4,
  Quote5,
  Title,
  Title2,
  Title3,
  Title4,
  ImageText
} from 'components/text';
import { UnorderedList, ListItem, OrderedList } from 'components/common/List';
import { LazyLoadImage, ImageDescriptor } from '../LazyImage';
import { Decision } from 'components/decision';
import { Link, StyledTinyMCELink } from 'components/link';
import IFrame from 'features/IFrame';

let localOverrides = {};

const getProps = props => {
  if (!props) return;
  const { style, class: klass, ...rest } = props;

  return { ...rest, className: klass };
};

const renderChildren = (domNode, options = {}) => {
  return domToReact(domNode.children, ParserOptions(localOverrides));
};

const renderParagraph = (domNode, component) => {
  return React.createElement(component || MainBody, {
    nomargin: domNode.children.some(({ name }) => name === 'img') ? true : false,
    block: true,
    children: renderChildren(domNode),
    fontsize14onsmall: true,
    ...getProps(domNode.attribs)
  });
};

const renderIngress = domNode => {
  return <Ingress {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Ingress>;
};

const renderDiv = domNode => {
  const { attribs } = domNode;

  const props = getProps(attribs);
  const { class: klass, ...rest } = props;
  return (
    <div className={klass} {...rest}>
      {renderChildren(domNode)}
    </div>
  );
};

const renderIElement = domNode => {
  return <i>{renderChildren(domNode)}</i>;
};

const renderQuote = domNode => {
  return <Quote {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Quote>;
};

const renderQuote2 = domNode => {
  return <Quote2 {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Quote2>;
};

const renderQuote3 = domNode => {
  return <Quote3 {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Quote3>;
};
const renderQuote4 = domNode => {
  return <Quote4 {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Quote4>;
};
const renderQuote5 = domNode => {
  return <Quote5 {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Quote5>;
};

const renderDecision = domNode => {
  return <Decision {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Decision>;
};

const renderSpan = domNode => {
  return <span>{renderChildren(domNode)}</span>;
};

const renderImageText = domNode => {
  return <ImageText {...getProps(domNode.attribs)}>{renderChildren(domNode)}</ImageText>;
};
const renderStyledTinyMCELink = domNode => {
  const { href, ...rest } = getProps(domNode.attribs);
  return (
    <StyledTinyMCELink href={href} {...rest}>
      {renderChildren(domNode)}
    </StyledTinyMCELink>
  );
};
const renderIFrame = domNode => {
  const { attribs } = domNode;
  const styleProp = attribs.style && attribs.style;
  if (styleProp) {
    const regex = /([\w-]*)\s*:\s*([^;]*)/g;
    var match,
      properties = {};
    while ((match = regex.exec(styleProp))) properties[match[1]] = match[2].trim();
    attribs.style = properties;
  }
  const { src: url } = attribs;
  return (
    <IFrame url={url} {...attribs}>
      {renderChildren(domNode)}
    </IFrame>
  );
};

const renderHeader = domNode => {
  switch (domNode.name) {
    case TAGS.H1: {
      return <Title {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Title>;
    }
    case TAGS.H2: {
      return <Title2 {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Title2>;
    }
    case TAGS.H3: {
      return (
        <Title3 capitalize {...getProps(domNode.attribs)}>
          {renderChildren(domNode)}
        </Title3>
      );
    }
    case TAGS.H4: {
      return <Title4 {...getProps(domNode.attribs)}>{renderChildren(domNode)}</Title4>;
    }
    default:
      return domToReact(domNode);
  }
};

const renderList = domNode => {
  switch (domNode.name) {
    case TAGS.UL: {
      return (
        <UnorderedList {...getProps(domNode.attribs)}>{renderChildren(domNode)}</UnorderedList>
      );
    }
    case TAGS.OL: {
      return <OrderedList {...getProps(domNode.attribs)}>{renderChildren(domNode)}</OrderedList>;
    }
    case TAGS.LI: {
      return <ListItem>{renderChildren(domNode)}</ListItem>;
    }
    default:
      return domToReact(domNode);
  }
};

const renderLink = domNode => {
  const { href, onlyforauthenticatedvisitors, ...rest } = getProps(domNode.attribs);
  return (
    <Link href={href} onlyForAuthenticatedVisitors={onlyforauthenticatedvisitors} {...rest}>
      {renderChildren(domNode)}
    </Link>
  );
};

const renderImage = domNode => {
  let { title, width: inWidth, height: inHeight, class: klass = '', ...rest } = getProps(
    domNode.attribs
  );
  // const height = addEndingIfNeeded(inHeight, 'px', (text, ending) => !text.endsWith(ending));
  // If xhtmlString images start acting weird, remove maxwidth in the lazyloadimage below and add this const again
  const width = addEndingIfNeeded(inWidth, 'px', (text, ending) => !text.endsWith(ending));

  return (
    <HtmlImage left={klass.includes('left')} right={klass.includes('right')} title={title}>
      <LazyLoadImage
        nolazy
        placeholder={uberSmall(rest.src)}
        {...rest}
        width={width || '100%'}
        height={'auto'}
        maxwidth={'100%'}
      />
      <ImageDescriptor hide={!title}>{title}</ImageDescriptor>
    </HtmlImage>
  );
};

const renderStrong = domNode => {
  return <strong> {renderChildren(domNode)}</strong>;
};

const renderBlock = domNode => {
  const { attribs = {} } = domNode;
  const { data } = attribs;
  try {
    const dataJson = JSON.parse(data);
    const { blockType } = dataJson;
    const props = {
      ...dataJson,
      nopadding: true,
      children: renderChildren(domNode)
    };
    return React.createElement(BlockComponents[blockType], props);
  } catch (ex) {
    ErrorHandler.LogErrorSilently(`Error when rendering block in XHTML property.`, ex, {
      data: data
    });
    return renderChildren(domNode);
  }
};

export const ParserOptions = (overrides = {}) => {
  localOverrides = overrides;
  return {
    replace: domNode => {
      const classAttrib = domNode.attribs && domNode.attribs.class;

      if (classAttrib !== undefined) {
        switch (classAttrib) {
          case 'ingress':
            return renderIngress(domNode);
          case 'quote':
            return renderQuote(domNode);
          case 'quote_2':
            return renderQuote2(domNode);
          case 'quote_3':
            return renderQuote3(domNode);
          case 'quote_4':
            return renderQuote4(domNode);
          case 'quote_5':
            return renderQuote5(domNode);
          case 'remiss-decision':
            return renderDecision(domNode);
          case 'image_text':
            return renderImageText(domNode);
          case 'styled-link':
            return renderStyledTinyMCELink(domNode);
          default:
        }
      }
      switch (domNode.name) {
        case TAGS.P: {
          return renderParagraph(domNode, localOverrides.p);
        }
        case TAGS.SPAN: {
          return renderSpan(domNode);
        }
        case TAGS.H1: {
          return renderHeader(domNode);
        }
        case TAGS.H2: {
          return renderHeader(domNode);
        }
        case TAGS.H3: {
          return renderHeader(domNode);
        }
        case TAGS.H4: {
          return renderHeader(domNode);
        }
        case TAGS.UL: {
          return renderList(domNode);
        }
        case TAGS.LI: {
          return renderList(domNode);
        }
        case TAGS.OL: {
          return renderList(domNode);
        }
        case TAGS.A: {
          return renderLink(domNode);
        }
        case TAGS.STRONG: {
          return renderStrong(domNode);
        }
        case TAGS.EM: {
          return renderStrong(domNode);
        }
        case TAGS.B: {
          return renderStrong(domNode);
        }
        case TAGS.IMG: {
          return renderImage(domNode);
        }
        case TAGS.IFRAME: {
          return renderIFrame(domNode);
        }
        case TAGS.I: {
          return renderIElement(domNode);
        }
        case TAGS.DIV: {
          const { attribs = {} } = domNode;
          const { blocktype } = attribs;

          if (!blocktype) return renderDiv(domNode);

          return renderBlock(domNode);
        }
        default: {
          const { name } = domNode;
          if (name !== undefined && name !== TAGS.BR) {
            ErrorHandler.LogWarningSilently(
              `Unsupported element rendered in HtmlEditor of type, rendering it as is:  ${name}`
            );
          }

          return domToReact(domNode);
        }
      }
    }
  };
};
