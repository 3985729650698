import styled from 'styled-components/macro';
// import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const RentCalculationBlockInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem 0 0;
  align-items: flex-end;
  ${media.desktop`
  flex-direction: column;
  align-items: flex-start;
  `};
  ${media.mediumtablet`
  margin: 1rem 0;
  `};
`;
