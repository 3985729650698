import * as TYPES from './searchTypes';

const initialState = {
  shouldSearch: false,
  latestQuery: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SEARCH_SHOULD_SEARCH:
      return {
        ...state,
        shouldSearch: action.bool
      };
    case TYPES.SEARCH_SET_LATEST_QUERY:
      return {
        ...state,
        latestQuery: action.query
      };
    default:
      return state;
  }
};
