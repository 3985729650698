export function calculateRent(input){
 const newList = input.yearList.map(i => {
        i.t = T(i.year);
        i.u = U(i.year);
        i.v = V(i.year);
        i.w = Math.round(W(i.year) > 0 ? Math.round(W(i.year)) : 0);
        i.x = Math.round(X(i.year));
        i.y = Y(i.year);
        i.z = Math.round(Z(i.year));
        i.aa = Math.round(AA(i.year));
        i.c = Math.round(C(i.year)) > 0 ? Math.round(C(i.year)) : 0;
        i.d = Math.round(D(i.year)) > 0 ? Math.round(D(i.year)) : 0;
        return i;
    });
        
  function getOctIndex(year) {
     const row = input.yearList.filter(x => x.year === year)[0];
     return row !== (null || undefined) && row.octIndex ? row.octIndex : 0;
   };

    //Pass in the year and the Column-name which contains the needed value
 function yearListFilter(year, val) {
    const row = input.yearList.filter(x => x.year === year)[0];
    return row !== (null || undefined) && row[val] ? row[val] : 0;
  };

  //H9 is a hidden value in the excel that I don't quite understand what it represents, but it is still used
  function getH9() {
    return parseFloat(input.firstRecount) > 0
      ? parseFloat(input.firstRecount)
      : parseFloat(input.firstYear) + 1;
  };
 
   // =OM($D$8=A51,B51,0)
   function T(year) {
     return parseFloat(input.baseNumber) === year ? getOctIndex(year) : 0;
   };
 
   // =OM(U51>0,OM(ABS(B52-U51)<3,U51,B52),OM(T51>0,OM(T51<B52,OM(ABS(B52-T51)<3,T51,B52),T51),0))
   function U(year) {
     return yearListFilter(year - 1, 'u') > 0
       ? Math.abs(getOctIndex(year) - yearListFilter(year - 1, 'u')) < 3
         ? yearListFilter(year - 1, 'u')
         : getOctIndex(year)
       : yearListFilter(year - 1, 't') > 0
       ? yearListFilter(year - 1, 't') < getOctIndex(year)
         ? Math.abs(getOctIndex(year) - yearListFilter(year - 1, 't')) < 3
           ? yearListFilter(year - 1, 't')
           : getOctIndex(year)
         : yearListFilter(year - 1, 't')
       : 0;
   };
 
   // =OM(T51+U51>0,OM(B52<$T$12,$T$12,B52),0)
   function V (year) {
     return yearListFilter(year - 1, 't') + yearListFilter(year - 1, 'u') > 0
       ? getOctIndex(year) < yearListFilter(parseFloat(input.baseNumber), 't')
         ? getOctIndex(parseFloat(input.baseNumber))
         : getOctIndex(year)
       : 0;
   };
 
   // =OM(A52<$G$5,0,OM(A52<$H$9,0,OM($D$8+$D$9=0,0,OM(V51>0,(V51-$T$12)/$T$12*$D$6,0))))
   function W (year) {
     const Tsum = getOctIndex(parseFloat(input.baseNumber));
     return year < parseFloat(input.firstYear)
       ? 0
       : year < getH9()
       ? 0
       : parseFloat(input.baseNumber) + parseFloat(input.specifiedIndex) === 0
       ? 0
       : yearListFilter(year - 1, 'v') > 0
       ? ((yearListFilter(year - 1, 'v') - Tsum) / Tsum) * parseFloat(input.baseRent)
       : 0;
   };
 
   // =OM(A52<$G$5,0,$D$5+W52)
   function X(year) {
     return year < parseFloat(input.firstYear)
       ? 0
       : parseFloat(input.rentAmount) + yearListFilter(year, 'w');
   };
 
   // =OM(A52<$G$5,0,OM(B52<$D$9,$D$9,B52))
   function Y(year) {
     return year < parseFloat(input.firstYear)
       ? 0
       : getOctIndex(year) < parseFloat(input.specifiedIndex)
       ? parseFloat(input.specifiedIndex)
       : getOctIndex(year);
   };
 
   // =OM($D$8+$D$9=0,0,OM(A20<$G$5,0,OM(A20<$H$9,0,OM(Y19<$D$9,0,OM(Y19>0,(Y19-$D$9)/$D$9*$D$6,0)))))
   function Z(year) {
     return parseFloat(input.baseNumber) + parseFloat(input.specifiedIndex) === 0
       ? 0
       : year < parseFloat(input.firstYear)
       ? 0
       : year < getH9()
       ? 0
       : yearListFilter(year - 1, 'y') < parseFloat(input.specifiedIndex)
       ? 0
       : yearListFilter(year - 1, 'y') > 0 && input.specifiedIndex !== 0
       ? ((yearListFilter(year - 1, 'y') - parseFloat(input.specifiedIndex)) /
           parseFloat(input.specifiedIndex)) *
         parseFloat(input.baseRent)
       : 0;
   };
 
   // =OM(A22<$G$5,0,$D$5+Z22)
   function AA(year) {
     return year < parseFloat(input.firstYear)
       ? 0
       : parseFloat(input.rentAmount) + yearListFilter(year, 'z');
   };
 
   // =OM($D$9=0,W20,Z20)
   function C(year) {
     return parseFloat(input.specifiedIndex) === 0
       ? yearListFilter(year, 'w')
       : yearListFilter(year, 'z');
   };
 
   // =OM($D$9=0,X20,AA20)
   function D(year) {
     return parseFloat(input.specifiedIndex) === 0
       ? yearListFilter(year, 'x')
       : yearListFilter(year, 'aa');
   };
   return newList;
 }
