import React from 'react';
import Collapsible from 'features/Collapsible';
import CollapsibleDoubleBlock from '../collapsibledoubleblock';
import { HtmlComponent } from 'features/HtmlComponent';

class CollapsibleBlock extends React.Component {

    render() {
        const { header, mainBody, doubleBlockContainer } = this.props;
        return (
            <Collapsible title={header}>
                <HtmlComponent html={mainBody} />
                {(doubleBlockContainer != null) && doubleBlockContainer.map((block, i, { length }) => {
                    const lastOne = (i === (length - 1));
                    return (
                        <CollapsibleDoubleBlock {...block} key={i} lastOne={lastOne} />
                    )

                }
                )}
            </Collapsible>
        )
    }
}

export default CollapsibleBlock;