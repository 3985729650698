export const FILTER_SET_CURRENT = 'filter/FILTER_SET_CURRENT';
export const FILTER_RESET = 'filter/FILTER_RESET';
export const FILTER_CURRENT = 'filter/FILTER_CURRENT';
export const FILTER_SELECTED = 'filter/FILTER_SELECTED';
export const FILTER_SET_SELECTED = 'filter/FILTER_SET_SELECTED';
export const FILTER_EXPAND_COLLAPSE = 'filter/FILTER_EXPAND_COLLAPSE';
export const FILTER_HOVER_START = 'filter/FILTER_HOVER_START';
export const FILTER_HOVER_END = 'filter/FILTER_HOVER_END';
export const FILTER_HEADER_HOVER = 'filter/FILTER_HEADER_HOVER';
export const FILTER_ERROR = 'filter/FILTER_ERROR';
export const FILTER_ANIMATION_END = 'filter/FILTER_ANIMATION_END';
export const FILTER_ANIMATION_START = 'filter/FILTER_ANIMATION_START';
export const FILTER_REQUESTED_STARTED = 'filter/REQUESTED_STARTED';
export const FILTER_SET_MESSAGE = 'filter/SET_MESSAGE';
export const FILTER_REQUESTED_FINISH = 'filter/REQUESTED_FINISH';
export const FILTER_COOKIE = 'filter/FILTER_COOKIE';
export const FILTER_TOGGLE_MODAL = 'filter/FILTER_TOGGLE_MODAL';
export const FILTER_QUERY_COOKIE = 'filter/FILTER_QUERY_COOKIE';
