import styled from 'styled-components/macro';
import { MediumText } from 'components/text';
import { colors as Colors } from 'constants/colors';
import arrowImage from 'images/arrow.min.svg';
export const AutoCompleteItem = styled(MediumText)`
  padding: 0 0 0 0.5rem;
  cursor: pointer;
  height: 30px;
  margin: 0;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  transition: background 0.3s ease-in-out;
  border-bottom: 1px ${Colors.BORDER} solid;

  &:after {
    transition: background-position 0.34s ease;
    content: '';
    width: 2rem;
    position: absolute;
    height: inherit;
    right: 0;
    background-image: url(${arrowImage});
    background-position: center;
    background-position-x: 50%;
    background-size: 1rem;
    background-repeat: no-repeat;
  }

  &:hover {
    background: ${Colors.GRAY};

    &:after {
      background-position-x: 60%;
    }
  }

  ${({ isActive }) => (isActive ? `background: ${Colors.GRAY}` : '')}
`;
