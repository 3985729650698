import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonContainer, Icon, IconContainer, Text, IconButtonLink } from './components';

class IconButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  onMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseleave = () => {
    this.setState({ hover: false });
  };

  handleClick = () => {
    const { clickCallback } = this.props;
    clickCallback && clickCallback();
  };

  render() {
    if (!this.props) return null;
    const { imageUrl, text, selected, asLink, linkUrl, neverHover, specialIcon = true } = this.props;
    const { hover } = this.state;
    const highlighted = hover || (!asLink && selected);
    const component = asLink ? (
      <IconButtonLink
        onMouseEnter={neverHover ? null : this.onMouseEnter}
        onMouseLeave={neverHover ? null : this.handleMouseleave}
        to={linkUrl}
        className="IconButtonLink">
        <IconContainer hover={neverHover ? null : highlighted}>
          <Icon hover={neverHover ? null : highlighted} src={imageUrl} />
        </IconContainer>
        <Text hover={neverHover ? null : highlighted}>{text}</Text>
      </IconButtonLink>
    ) : (
        <IconButtonContainer
          onMouseEnter={neverHover ? null : this.onMouseEnter}
          onMouseLeave={neverHover ? null : this.handleMouseleave}
          onClick={this.handleClick}
          className="IconButton">
          <IconContainer hover={neverHover ? null : highlighted}>
            <Icon hover={neverHover ? null : highlighted} src={imageUrl} />
          </IconContainer>
          {specialIcon && <Text hover={neverHover ? null : highlighted}>{text}</Text>}

        </IconButtonContainer>
      );

    return component;
  }
}

export default IconButton;

IconButton.propTypes = {
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  text: PropTypes.string,
  clickCallback: PropTypes.func,
  index: PropTypes.number,
  asLink: PropTypes.bool
};
