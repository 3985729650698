import React from 'react';
import NukaCarousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import { CarouselContainer, LeftArrow, RightArrow, CarouselArrowContainer } from './index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from '../../utils';

// eslint-disable-next-line
import styles from './carousel.css';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nSlidesToShow: 7,
      currentIndex: 0
    };
    this.timeout = null;
    this.carousel = null;
  }

  componentDidUpdate(prevProps) {
    const { filter: prevFilter } = prevProps;
    const { filter } = this.props;

    if (isEmpty(filter.selected)) return null;
    if (filter.selected.id !== prevFilter.selected.id) {
      this.setState({ currentIndex: 0 });
      this.carousel.goToSlide(0);
    }
  }

  // window.resize callback function
  updateDimensions = () => {
    if (!this.carousel) return null;
    let numberOfItems = Math.floor(window.innerWidth / 250);
    numberOfItems = numberOfItems < 1 ? 1 : numberOfItems;
    numberOfItems = numberOfItems > 7 ? 7 : numberOfItems;
    this.carousel.goToSlide(0);

    this.setState({ nSlidesToShow: numberOfItems, currentIndex: 0 });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    clearTimeout(this.timeout);
  }

  onResize = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.updateDimensions, 100);
  };

  componentDidMount() {
    // window.resize event listener
    window.addEventListener('resize', this.onResize);

    this.updateDimensions();
  }

  showRightArrow = () => {
    return this.state.currentIndex < this.props.children.length - this.state.nSlidesToShow;
  };

  showLeftArrow = () => {
    return this.state.currentIndex > 0;
  };

  slideToPrevious = () => {
    if (this.carousel.state.currentSlide === 0) return null;

    this.carousel.previousSlide();
  };

  slideToNext = () => {
    if (this.carousel.state.currentSlide >= this.props.children.length - this.state.nSlidesToShow)
      return;

    this.carousel.nextSlide();
  };

  render() {
    const { nomarginbottom, children } = this.props;

    return (
      <CarouselContainer className="Carousel" marginbottom={nomarginbottom ? '0px' : undefined}>
        <NukaCarousel
          afterSlide={event => this.setState({ currentIndex: event })}
          ref={carousel => (this.carousel = carousel)}
          cellAlign="left"
          heightMode="max"
          // slideWidth={0.95}
          renderCenterLeftControls={({ previousSlide }) => {
            return (
              <CarouselArrowContainer
                visible={this.showLeftArrow()}
                onClick={this.slideToPrevious}
                left>
                <LeftArrow show={this.showLeftArrow()} />
              </CarouselArrowContainer>
            );
          }}
          renderCenterRightControls={({ nextSlide }) => {
            return (
              <CarouselArrowContainer
                visible={this.showRightArrow()}
                onClick={this.slideToNext}
                right>
                <RightArrow show={this.showRightArrow()} />
              </CarouselArrowContainer>
            );
          }}
          renderBottomCenterControls={({ previousSlide }) => null}
          easing="easeExpIn"
          speed={300}
          framePadding=".5rem"
          cellSpacing={30}
          slidesToShow={this.state.nSlidesToShow}>
          {children}
        </NukaCarousel>
      </CarouselContainer>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Carousel);

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};
