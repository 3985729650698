import { Ingress, Title2, Title4, Paragraph } from 'components/text';
import styled from 'styled-components/macro';
import fonts from 'style/fonts';
import { media } from 'style/media';

export const JobListBlockHeading = styled(Title2)`
  text-align: center;
`;

export const JobListBlockIngress = styled(Ingress)`
  text-align: center;
  max-width: 700px;
`;

export const JobListBlockSmallHeader = styled(Title4)`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  margin-left: 1rem;
  width: 50%;
  ${media.desktop`
  width: auto;
  margin-left: 0;
  margin-bottom: 0.5rem;
  `};
`;

export const JobListBlockParagraph = styled(Paragraph)`
  font-family: ${props => (props.big ? fonts.APERCU.WEB : fonts.APERCU.REGULAR)};
  padding: 0 0.75rem;
`;

export const JobListBlockParagraphWrapper = styled(Paragraph)`
  display: flex;
  width: 25%;
  margin: 0;
  span {
    width: 0;
    margin-right: 8px;

    &:before {
      height: 20px;
      width: 20px;
    }
  }

  ${media.desktop`
  margin: 0;
    width: auto;

    span {
    width: 0;
    margin-right: 8px;
    &:before {
      height: 16px;
      width: 16px;
    }
  }
  `};
`;
