import React, { useRef } from 'react';
import { SearchFilterPillContainer, SearchFilterPillLabel } from './SearchFilterComponents';

export const SearchFilterPill = ({
  selected = false,
  handleFilterClick = () => {},
  item = {},
  inverted = false
}) => {
  const ref = useRef(null);
  const handleClick = () => {
    if (selected) ref.current.blur();
    handleFilterClick(item);
  };

  return (
    <SearchFilterPillContainer
      ref={ref}
      disabled={!item.filterCount}
      {...{ inverted, selected }}
      onClick={() => handleClick()}>
      <SearchFilterPillLabel>
        {item.filterName} ({item.filterCount})
      </SearchFilterPillLabel>
    </SearchFilterPillContainer>
  );
};
