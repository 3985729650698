import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { media } from 'style/media';

/**
 * OnlySmall (div)
 */
export const OnlySmall = styled.div.attrs(props => ({
  flex: props.flex,
  margin: props.margin
}))`
  display: none;
  ${media.desktop`
    display: ${props => props.flex ? 'flex' : 'block'};
    justify-content: ${props => props.flex ? 'center' : 'left'};
    margin-top: ${props => props.margin};
  `};
`;

OnlySmall.propTypes = {
  bypass: PropTypes.bool
};

//inte klar
export const MediaQ = styled.div.attrs(props => ({
  displaysmall: props.hidesmall ? 'none' : 'block'
}))`
  ${media.desktop`
    display: block;
  `};
`;
