import React, { PureComponent } from 'react';
import Card from './Card';

class DynamicFeatureBlock extends PureComponent {
    render() {

    const getBackgroundClass = function(props) {
      const selectedBGColorName =
        (props.backgroundColor.find(item => item.selected === true) || {}).text || 'default';

      switch (selectedBGColorName) {
        case 'Citat-grå':
          return 'dynamic-feature-block--dark-grey';
        case 'Mellangrå':
          return 'dynamic-feature-block--medium-grey';
        case 'Ljusgrå':
          return 'dynamic-feature-block--light-grey';
        case 'Vit':
          return 'dynamic-feature-block--white';
        default:
          return 'dynamic-feature-block--dark-grey';
      }
    };

    const { blockHeading, blockPreamble } = this.props;
    const cards = this.props.dynamicBlockContainer;

    return (
      <div className={`dynamic-feature-block ${getBackgroundClass(this.props)}`}>
        <div className="container--large">
          {blockHeading && <h3 className="h3">{blockHeading}</h3>}
          {blockPreamble && <p className="p p--lead">{blockPreamble}</p>}
            </div>
            {cards && cards.length === 1 && cards[0].cardImage === null ? (
                <div
                    className={`container--large dynamic-feature-block__inner-container dynamic-feature-block__inner-container_only-text--${cards.length}`}>
                    {cards.map((card, index) => (
                        <Card
                            flip={false}
                            key={index}
                            imgURL={card.cardImage}
                            texteditor={card.cardPreamble}
                            buttonURL={`${card.pageToGetDataFrom ? card.pageToGetDataFrom : card.manualLink}${card.shouldScrollToForm ? '#formLink' : ''
                                }`}
                            shouldScrollToForm={card.shouldScrollToForm}
                            ButtonText={card.cardButtonText}
                            numberOfCards={cards.length}
                        />
                    ))}
                </div>
            ) : cards && cards.length >= 1 && (
                <div
                    className={`container--large dynamic-feature-block__inner-container dynamic-feature-block__inner-container--${cards.length}`}>
                    {cards.map((card, index) => (
                        <Card
                            flip={false}
                            key={index}
                            imgURL={card.cardImage}
                            texteditor={card.cardPreamble}
                            buttonURL={`${card.pageToGetDataFrom ? card.pageToGetDataFrom : card.manualLink}${card.shouldScrollToForm ? '#formLink' : ''
                                }`}
                            ButtonText={card.cardButtonText}
                            numberOfCards={cards.length}
                            shouldScrollToForm={card.shouldScrollToForm}
                            pictureRightSide={card.flipPictureOnSingleCard}
                        />
                    ))}
                </div>
            ) }
      </div>
    );
  }
}

export default DynamicFeatureBlock;
