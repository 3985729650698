import styled from 'styled-components/macro';

import { settings as Settings } from 'constants/settings';
import { PageContent } from 'pages/components';
import { media } from 'style/media';

const menuHeight = `calc(${Settings.MENU_HEIGHT})`;

/**
 * TopImageContainer
 */
export const TopImageContainer = styled(PageContent)`
  display: block;
  padding-top: ${menuHeight};
  padding: 0;
  position: relative;
  display: block;
  overflow: hidden;
  min-height: 16.71rem;
  // max-height: 12rem;
  ${media.desktop`
    padding: ${props => props.paddingtop} 0 0 0;
    /* height: 100%; */
    min-height: 0;
  `};
`;

/**
 * TopImageContainer
 */
export const TopImageWrapper = styled.div``;
