import styled from 'styled-components/macro';
import { OnlySmall } from 'components/containers/OnlySmall';
import { colors as Colors } from 'constants/colors';

export const ParallaxBlockImage = styled(OnlySmall).attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  filter: props.blur ? 'blur(5px)' : 'blur(0)'
}))`
  transition: background-image 8s ease-in-out, filter 1s ease-in-out;
  filter: ${props => props.filter};
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 10rem;
`;

export const ParallaxBlockImageMobile = styled(OnlySmall).attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  filter: props.blur ? 'blur(5px)' : 'blur(0)'
}))`
  display: table !important;
  transition: background-image 8s ease-in-out, filter 1s ease-in-out;
  filter: ${props => props.filter};
  background: ${props => props.color === Colors.WHITE
    ? props => `linear-gradient(to right, rgba(0,0,0,${props.opacity}), rgba(0,0,0,${props.opacity})), url(${props.background})`
    : props => props.color === Colors.TOTALLY_BLACK
      ? props => `linear-gradient(to right, rgba(255,255,255,${props.opacity}), rgba(255,255,255,${props.opacity})), url(${props.background})`
      : props => `linear-gradient(to right, rgba(0,0,0,${props.opacity}), rgba(0,0,0,${props.opacity})), url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 24rem;
`;