import styled from 'styled-components/macro';
import fonts from 'style/fonts';

export const HtmlComponentWrapper = styled.div.attrs(props => ({
  width: props.width ? props.width : 'initial'
}))`
  display: block;
  width: ${props => props.width};
  font-family: ${fonts.LLBrown.REGULAR};
  font-size: calc(16px + 0.02vw);
  line-height: 1.083rem;
  ${({ css }) => (css ? css : '')};
`;
