import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { targetBrowser } from 'style/media';

/**
 * RelatedItemContainer (div)
 */
export const RelatedItemContainer = styled.div.attrs(props => ({
  underlinebg: props.usehovereffect ? Colors.ORANGE : Colors.TRANSPARENT,
  background: props.usebackground ? Colors.TRANSPARENT_GRAY : Colors.TRANSPARENT,
  width: props.square ? '6rem' : props.horizontal ? '9.083rem' : '100%',
  height: props.square ? '6.5rem' : props.horizontal ? '14.08rem' : 'unset',
  marginbottom: !props.horizontal && !props.square ? '1rem' : 0,
  locked: props.locked,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  word-break: break-all;
  width: ${props => props.width};
  max-width: ${props => props.width};
  min-width: ${props => props.width};
  min-height: ${props => props.height};
  margin: 0 auto;
  margin-bottom: ${props => props.marginbottom};
  overflow: visible;
  background: ${props => props.background};
  justify-content: flex-start;
  &:before {
    transition: width 0.2s ease-in-out;
    content: '';
    width: 0;
    position: absolute;
    bottom: 0;
    background: ${props => props.underlinebg};
    left: 0;
  }
  &:hover {
    &:before {
      width: 100%;
      height: 3px;
    }
  }
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;

RelatedItemContainer.propTypes = {
  isimage: PropTypes.bool,
  minwidth: PropTypes.string,
  minheight: PropTypes.string
};
