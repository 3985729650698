import React from 'react';
import { FooterItem, StyledFooterItemLink } from './';

const FooterItemLink = props => {
  const { name, link } = props;
  const isInternal = link && link.startsWith('/');

  return (
    <FooterItem>
      {link ? (
        isInternal ? (
          <StyledFooterItemLink to={link}>{name}</StyledFooterItemLink>
        ) : (
          <StyledFooterItemLink external={true} to={link}>
            {name}
          </StyledFooterItemLink>
        )
      ) : (
        <StyledFooterItemLink as="span" nounderline>
          {name}
        </StyledFooterItemLink>
      )}
    </FooterItem>
  );
};

export default FooterItemLink;
