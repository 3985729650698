import React from 'react';
import { ArrowLinkWrapper, ArrowLinkWrapperAsLink, NoArrowLinkWrapper, ArrowLinkWrapperCOP, ArrowLinkTitle } from './components';
import PropTypes from 'prop-types';
import { FlexContainer } from 'components/flex';
import { Width } from 'components/containers';

import { SlideInLeft } from 'components/Animations';
import { LockOrangeCircle } from 'features/Account/components';
import WithAuthentication from 'features/WithAuthentication';

class ArrowLink extends React.Component {
  render() {
    const {
      to,
      children,
      name,
      left,
      center,
      secondary,
      oneline,
      buttonInsteadOfArrow,
      showLogin,
      pageTypeHeader,
      fileType,
      openIn,
      useFullWidthImage,
      locked,
      onlyForAuthenticatedVisitors,
      dispatch,
      contactOfficePage,
      ...rest
    } = this.props;
    const childrenItems = (
      <FlexContainer style={{ zIndex: left ? 100 : 1 }} alignItems="center" smallAlignContent="center" smallrow>
        {locked && (
          <FlexContainer width='initial' smallwidth='initial' margin='0 0 0 0.5rem'>
            <LockOrangeCircle />
          </FlexContainer>
        )}
        <Width forceoneline={oneline ? 1 : 0}>
          <ArrowLinkTitle
            white={secondary}
            spaceWrap
            medium
            inline
            margin={`auto auto auto ${left ? '3.333rem' : locked ? '0.5rem' : '1rem'}`}>
            {name}
          </ArrowLinkTitle>
        </Width>
      </FlexContainer>
    );

    return (
      <SlideInLeft key={to}>
        {to && buttonInsteadOfArrow ? (
          <NoArrowLinkWrapper
            secondary={secondary ? 1 : 0}
            to={to}
            center={center}
            left={left}
            {...rest}>
            {name && childrenItems}
            {children}
          </NoArrowLinkWrapper>
        ) : to && contactOfficePage ? (
          <ArrowLinkWrapperCOP
            to={to}
            {...rest}>
          </ArrowLinkWrapperCOP>
        ) : to ? (
          <ArrowLinkWrapper
            secondary={secondary ? 1 : 0}
            to={to}
            center={center}
            left={left}
            {...rest}>
            {name && childrenItems}
            {children}
          </ArrowLinkWrapper>
        ) : (
                <ArrowLinkWrapperAsLink
                  secondary={secondary ? 1 : 0}
                  center={center}
                  left={left}
                  {...rest}>
                  {name && childrenItems}
                  {children}
                </ArrowLinkWrapperAsLink>
              )}
      </SlideInLeft>
    );
  }
}
ArrowLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.any
};

export default WithAuthentication(ArrowLink);
