import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';

export const UnderlineAfter = styled.div.attrs(props => ({
  hover: props.hover ? '100%' : '0px'
}))`
  &:after {
    content: '';
    margin: 0.2rem auto auto auto;
    display: block;
    width: ${props => props.hover};
    background: transparent;
    transition: width 0.3s ease, background-color 0.5s ease;
    content: '';
    height: 2px;
    bottom: -0.3em;
    background: ${Colors.ORANGE};
    margin: 0.2rem auto auto auto;
  }
`;

UnderlineAfter.propTypes = {
  hover: PropTypes.bool
};
