import * as TYPES from './cookiebarTypes';
import { CookieHandler as CookieManager } from '../../utils';
const CookieHandler = new CookieManager(true);

const cookie = CookieHandler.getJSON(TYPES.COOKIEBAR_COOKIE);

const initialState = {
  accepted: cookie ? cookie.accepted : false,
  set: cookie ? cookie.set : false,
  clear: cookie ? cookie.clear : false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.COOKIEBAR_COOKIE_SET: {
      const { accepted } = action;
      return {
        ...state,
        accepted: accepted,
        set: true,
        clear: false
      };
    }
    case TYPES.COOKIEBAR_COOKIE_CLEARALL: {
      return {
        ...state,
        accepted: false,
        set: false,
        clear: true
      }
    }
    default:
      return state;
  }
};
