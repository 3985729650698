import store from '../store/store';

export const getSelectedFromSelectionProperty = (type = [], key = 'text') => {
  const { app } = store.getState();
  const { settings } = app;
  const { emptyPageCategory } = settings;
  if (type.length === 0) return '';
  const selected = type.find(x => x.selected);
  if (!selected) return '';
  const { value } = selected;

  if (parseInt(value, 10) === emptyPageCategory) return undefined;
  if (selected.hasOwnProperty(key)) return selected[key];
  return '';
};

export const getSelectedValueFromSelectionFactory = (values = [], key = 'value') => {
  const selected = values.find(x => x.selected);
  if (!selected) return '';

  if (selected.hasOwnProperty(key)) return selected[key];
  return '';
};

export const formPropertyIsRequired = (validator = '') => {
  if (validator === null) return false;
  return validator.includes('EPiServer.Forms.Implementation.Validation.RequiredValidator');
};

export const formPropertyIsEmail = (validator = '') => {
  if (validator === null) return false;
  return validator.includes('EPiServer.Forms.Implementation.Validation.EmailValidator');
};
