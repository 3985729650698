import styled from 'styled-components/macro';
import { media } from 'style/media';

/**
 * NavigationItem (div)
 */
export const NavigationItem = styled.span.attrs(() => ({
  as: 'span'
}))`
  display: flex;
  padding-left: 0.45rem;
  &:nth-of-type(2) {
    margin-left: 2rem;
    height: 100%;
    width: 100%;
    margin-right: 3rem;
    ${media.giant`
      margin-left: 1.5rem;
      margin-right: 2.5rem;
    `}
    ${media.biggerdesktop`
      margin-left: 1rem;
      margin-right: 1rem;
    `}
    ${media.bigdesktop`
      margin-left: 0.25rem;
      margin-right: 0.5rem;
    `}
  }
  ${media.desktop`
    display: ${({ largeonly }) => (largeonly ? 'none' : 'block')};
  `};
`;

export const SecondNavigationItem = styled(NavigationItem)`
margin-left: 0;
height: 100%;
width: 100%;
margin-right: 3rem;
padding: 0;
${media.giant`
  margin-left: 1.5rem;
  margin-right: 2.5rem;
`}
${media.biggerdesktop`
  margin-left: 1rem;
  margin-right: 1rem;
`}
${media.bigdesktop`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
`}
`;
