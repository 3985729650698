import React from 'react';
import { Paragraph } from 'components/text/Text';
import { PaginationArrow, PaginationContainer } from './SearchPaginationComponents';

const SearchPagination = ({
  skip = 0,
  forwardClick = () => {},
  backwardClick = () => {},
  totalHits = 0
}) => {
  const currPage = skip === 0 ? 1 : Math.floor(skip / 10) * 1 + 1;
  const maxPage = totalHits < 10 ? 1 : Math.ceil(totalHits / 10) * 1;
  const backPossible = currPage !== 1;
  const forwardPossible = currPage !== maxPage;

  return (
    <PaginationContainer>
      <PaginationArrow
        disabled={!backPossible}
        onClick={() => backPossible && backwardClick()}
        title="Visa föregående resultat"
      />
      <Paragraph margin="0">
        Sida {currPage} av {maxPage}
      </Paragraph>
      <PaginationArrow
        right
        disabled={!forwardPossible}
        onClick={() => forwardPossible && forwardClick()}
        title="Visa nästa resultat"
      />
    </PaginationContainer>
  );
};

export default SearchPagination;
