import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { media } from 'style/media';

/**
 * OnlyDesktop (div)
 */
export const CountyFilterDesktop = styled.div`
  overflow: hidden;
  display: block;
  height: 335px;
  ${media.desktop`
    display: none;
  `};
`;

CountyFilterDesktop.propTypes = {
  bypass: PropTypes.bool
};

/**
 * OnlyDesktop (div)
 */
export const CountyFilterMobile = styled.div`
  display: none;
  ${media.desktop`
    display: block;
  `};
`;

CountyFilterMobile.propTypes = {
  bypass: PropTypes.bool
};