import styled from 'styled-components/macro';
import { FlexContainer } from 'components/flex';
import { targetBrowser } from 'style/media';
export const ListBlockItemContent = styled(FlexContainer).attrs(props => ({
  afterdisplay: props.fade ? 'block' : 'none',
  marginleft: props.hasimage ? '1rem' : 0
}))`
  margin: 0 0 0 ${props => props.marginleft};
  flex-direction: column;
  overflow: hidden;
  ${targetBrowser.IE10ANDHIGHER`
    width: 85%;
    
  `};
`;
