import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { BasicImage } from 'components/images/Image';
import { media } from 'style/media';
import React from 'react';

const ImageContainer = styled.div`
  width: 100%;
  background-color: ${Colors.WHITE};
  padding: 1rem;
  height: 130px;
  min-height: 130px;
  ${media.mediumtablet`
    padding: 1rem 3rem;
  `};
`;

const LocalImage = styled(BasicImage)`
  top: 50%;
  height: auto;
  max-height: 83px;
  object-fit: contain;
  transform: translateY(-50%);
  ${media.mediumtablet`
    max-height:90px;
  `};
`;

export const PuffListBlockItemImage = ({ url }) => {
  return (
    <ImageContainer>
      {/* <ImageWidth> */}
      <LocalImage src={url} loaded fadein />
      {/* </ImageWidth> */}
    </ImageContainer>
  );
};
