import styled from 'styled-components/macro';
import ArrowImage from 'images/arrow.min.svg';
import { FlexContainer } from 'components/flex/FlexContainer';

export const CategoryPillArrow = styled.span`
  background: no-repeat url(${ArrowImage}) center;
  transform: translateX(0px);
  transition: transform 0.3s;
  min-width: 40px;
  min-height: 20px;
  && {
    width: auto;
  }
`;

export const StyledArrowLink = styled(FlexContainer)`
  height: 100%;
  padding: 1rem;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'unset')};
  margin: 0;
  flex-direction: row;
  align-items: center;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    text-decoration: underline;

    ${CategoryPillArrow} {
      transform: translateX(10px);
    }
  }
`;
