import React from 'react';
import PropTypes from 'prop-types';
import { CalendarListBlockItem, CalendarListBlockItemContainer } from './components';
import { UnderlineContainer } from 'components/UnderlineContainer';
import { colors as Colors } from 'constants/colors';
import { Title2 } from 'components/text';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchItems } from './calendarlistblockActions';
import { isEmpty } from '../../utils';
import { TransitionGroup } from 'react-transition-group';
import { TransitionFromRight } from 'features/Transitions';
import BlockArea from 'features/BlockArea';

import { Block, CenteredBlockInner } from '../components';

class CalendarListBlock extends React.Component {
  componentDidMount() {
    this.fetchBlockItems();
  }

  shouldFetchNewItems = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { filter, calblock } = this.props;
    if (isEmpty(filter.selected)) return false;

    if (filter.selected.id !== prevFilter.selected.id) return true;

    if (calblock.lastUpdatedCategory !== filter.selected.id) return true;
    return false;
  };

  componentDidUpdate(prevProps) {
    if (this.shouldFetchNewItems(prevProps)) {
      this.fetchBlockItems();
    }
  }

  fetchBlockItems() {
    const { id, filter, fetchItems } = this.props;
    if (id === undefined) return;
    const { selected } = filter;
    fetchItems(id, selected.id);
  }

  childFactory(child) {
    return React.cloneElement(child, {});
  }

  shouldRenderYear = (array, index) => {
    if (index === 0) return true;
    return array[index].year !== array[index - 1].year;
  };

  getListItems = () => {
    const { items: linkItems } = this.props.calblock;
    if (!linkItems) return null;
    return linkItems.map((yearItem, index, array) => {
      return (
        <TransitionFromRight index={index} transitionkey={index} key={index}>
          <div style={{ marginBottom: '2rem' }}>
            <CalendarListBlockItemContainer key={index}>
              {this.shouldRenderYear(array, index) && (
                <UnderlineContainer position="bottom" color={Colors.ORANGE}>
                  {yearItem.year}
                </UnderlineContainer>
              )}
              <Title2 transform="capitalize" large>
                {yearItem.month}
              </Title2>
              <TransitionGroup>
                {yearItem.items.map((link, index) => {
                  const key = `${yearItem.year}${yearItem.month}${link.linkUrl}`;
                  return (
                    <TransitionFromRight index={index} transitionkey={key} key={key}>
                      <div>
                        <CalendarListBlockItem {...link} />
                      </div>
                    </TransitionFromRight>
                  );
                })}
              </TransitionGroup>
            </CalendarListBlockItemContainer>
          </div>
        </TransitionFromRight>
      );
    });
  };

  render() {
    const empty = this.props.calblock.error.hasError;
    const { filterContentArea } = this.props;
    return (
      <Block>
        <CenteredBlockInner>
          <BlockArea propertyName="filterContentArea" contentarea={filterContentArea} />
          {empty && 'inga träffar'}
          <TransitionGroup>{!empty && this.getListItems()}</TransitionGroup>
        </CenteredBlockInner>
      </Block>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected
  },
  calblock: {
    items: state.callistblock.items,
    isLoading: state.callistblock.isLoading,
    error: state.callistblock.error,
    lastUpdatedCategory: state.callistblock.lastUpdatedCategory
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchItems }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarListBlock);

CalendarListBlock.propTypes = {
  id: PropTypes.number,
  filterContentArea: PropTypes.object,
  //deprecated
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      month: PropTypes.string,
      items: PropTypes.array
    })
  )
};
