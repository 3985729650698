import React from 'react';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { HeroText, HeroHeader, HeroScrollTo } from './components';
import { PrimaryButtonLink, ButtonText } from 'components/button';
import { connect } from 'react-redux';
import {
  HeroBlockContainer,
  HeroBlockContentItem,
  HeroBlockContent,
  HeroBlockTopImage,
  HeroBlockContainerVideo,
  HeroBlockContentItemSmall,
  HeroBlockContainerVideoSmall,
  OuterHeroContainer
} from './index';
import OPE from 'components/common/EpiOnPageEdit';
import { uberSmall, fillElement, getSelectedValueFromSelectionFactory } from 'utils';
import { LazyLoadImage } from 'features/LazyImage';
import { Hidden } from 'components/containers/OnlyDesktop';
import { Block, BlockInner } from '../components';
import VideoCover from 'react-video-cover';
class HeroBlock extends React.Component {
  constructor(props) {
    super(props);
    this.heroVideo = React.createRef();
    this.heroImage = React.createRef();
    this.state = { backgroundLoaded: false };
  }

  onBackgroundLoad = () => {
    this.setState({ backgroundLoaded: true });
  };

  render() {
    const { backgroundLoaded } = this.state;
    const gradient = {
      direction: 'right',
      color: Colors.WHITE,
      width: '50%'
    };

    const {
      first,
      image,
      heading,
      text,
      video,
      buttonLink,
      buttonText,
      textColor,
      children,
      isModalOpen,
      ...rest
    } = this.props;
    const color = getSelectedValueFromSelectionFactory(textColor);
    const videoOptions = {
      src: video,
      autoPlay: true,
      loop: true,
      muted: true
    };
    const style = {
      position: 'absolute',
      margin: 'auto',
      zIndex: 0
    };

    return video != null ? (
      <OuterHeroContainer className="OuterHeroContainer" ref={this.heroVideo}>
        <Block className="HeroBlockVideo" {...rest}>
          <HeroBlockContainerVideo>
            <VideoCover style={style} videoOptions={videoOptions} remeasureOnWindowResize />
            <BlockInner padding="10px">
              <HeroBlockContentItemSmall paddingtop={'2rem'}>
                <HeroBlockContent>
                  <OPE propertyName="heading">
                    <HeroHeader color={color}>{heading}</HeroHeader>
                  </OPE>
                  <OPE propertyName="text">
                    <HeroText color={color}>{text}</HeroText>
                  </OPE>
                  <OPE propertyName="buttonLink">
                    {buttonLink && (
                      <PrimaryButtonLink shadow={2} to={buttonLink}>
                        <ButtonText large weight="bold">
                          {buttonText}
                        </ButtonText>
                      </PrimaryButtonLink>
                    )}
                  </OPE>
                </HeroBlockContent>
              </HeroBlockContentItemSmall>
            </BlockInner>
            {children && children}
          </HeroBlockContainerVideo>
        </Block>
        <HeroBlockContainerVideoSmall>
          <BlockInner padding="10px">
            <HeroBlockContentItem paddingtop={'2rem'}>
              <HeroBlockContent>
                <HeroHeader color={color}>{heading}</HeroHeader>
                <HeroText color={color}>{text}</HeroText>
                {buttonLink && (
                  <PrimaryButtonLink shadow={2} to={buttonLink}>
                    <ButtonText large weight="bold">
                      {buttonText}
                    </ButtonText>
                  </PrimaryButtonLink>
                )}
              </HeroBlockContent>
            </HeroBlockContentItem>
          </BlockInner>
          {children && children}
        </HeroBlockContainerVideoSmall>
      </OuterHeroContainer>
    ) : (
      <Block className="HeroBlock" ref={this.heroImage} {...rest}>
        <HeroBlockContainer
          smallbackground={Colors.WHITE}
          placeholderBg={uberSmall(image)}
          bg={fillElement(image, this.heroImage.current)}
          gradient={gradient}
          first={first ? 1 : 0}
          ismodalopen={isModalOpen.toString()}>
          <OPE propertyName="image">
            <HeroBlockTopImage
              background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
            />
          </OPE>
          <BlockInner padding="10px">
            <HeroBlockContentItem>
              <HeroBlockContent>
                <OPE propertyName="heading">
                  <HeroHeader color={color}>{heading}</HeroHeader>
                </OPE>
                <OPE propertyName="text">
                  <HeroText color={color}>{text}</HeroText>
                </OPE>
                <OPE propertyName="buttonLink">
                  {buttonLink && (
                    <PrimaryButtonLink shadow={2} to={buttonLink}>
                      <ButtonText large weight="bold">
                        {buttonText}
                      </ButtonText>
                    </PrimaryButtonLink>
                  )}
                </OPE>
              </HeroBlockContent>
            </HeroBlockContentItem>
            <Hidden className="Hidden">
              <LazyLoadImage
                onLoad={this.onBackgroundLoad}
                asBackground
                hideSmall
                placeholder={uberSmall(image)}
                src={fillElement(image)}
              />
            </Hidden>
          </BlockInner>
          {children && children}

          <HeroScrollTo scrollto="bottom" next={false} element={this.heroImage.current} />
        </HeroBlockContainer>
      </Block>
    );
  }
}

HeroBlock.defaultProps = {
  textColor: Colors.BLACK
};
HeroBlock.propTypes = {
  //contentId of the block. Will maybe be used later to fetch block from epi.
  id: PropTypes.number,
  //First block on page. Will add extra padding-top.
  first: PropTypes.bool,
  //Link button
  buttonLink: PropTypes.string.isRequired,
  //Text for button
  buttonText: PropTypes.string.isRequired,
  //block heading
  heading: PropTypes.string.isRequired,
  //Link to image
  image: PropTypes.string,
  //Text taht displays below heading
  text: PropTypes.string.isRequired,
  //Text taht displays below heading
  textColor: PropTypes.string
};

const mapStateToProps = ({ filter }) => ({
  isModalOpen: filter.isModalOpen
});

export default connect(mapStateToProps)(HeroBlock);
