import React from 'react';
import { fillElement } from 'utils';
import { SlideInLeft } from 'components/Animations';
import {
  JobListBlockLargeContainer,
  JobListBlockImageContainerLarge,
  JobListBlockTextContainer,
  JobListBlockInfoContainer,
  JobListBlockInfoButton,
  JobListBlockParagraph
} from './components';
import { LazyLoadImage } from 'features/LazyImage';
import { Title3, Paragraph } from 'components/text';
import format from 'date-fns/format';

const getDate = date => {
  if (!date) return null;
  const formattedDate = format(new Date(date) - 1, 'YYYY-MM-DD');
  return formattedDate.includes('9999') ? 'Löpande' : formattedDate;
};

export const JobListBlockItemsLarge = ({ jobList }) => {
  if (!jobList.length) return null;
  return jobList.map((item, index) => {
    const date = getDate(item.endDate);
    return (
      <SlideInLeft>
        <JobListBlockLargeContainer key={index}>
          <JobListBlockImageContainerLarge href={item.linkUrl}>
            <LazyLoadImage center={false} onload={true} src={fillElement(item.imageUrl)} />
          </JobListBlockImageContainerLarge>
          <JobListBlockTextContainer>
            <Title3 capitalize margin="0">
              {item.header}
            </Title3>
            <Paragraph>{item.text}</Paragraph>
          </JobListBlockTextContainer>
          <JobListBlockInfoContainer>
            <JobListBlockParagraph big fontSizeProp="16px" nomargin weight="600">
              Ansök senast:
            </JobListBlockParagraph>
            <JobListBlockParagraph fontSizeProp="16px" margin="0 0 0.5rem 0">
              {date}
            </JobListBlockParagraph>
            <JobListBlockParagraph big fontSizeProp="16px" nomargin weight="600">
              Plats:
            </JobListBlockParagraph>
            <JobListBlockParagraph fontSizeProp="16px" margin="0 0 0.5rem 0">
              {item.location}
            </JobListBlockParagraph>
            <JobListBlockParagraph big fontSizeProp="16px" nomargin weight="600">
              Bolag:
            </JobListBlockParagraph>
            <JobListBlockParagraph fontSizeProp="16px" margin="0 0 0.5rem 0">
              {item.department}
            </JobListBlockParagraph>
            <JobListBlockInfoButton to={item.linkUrl}>Läs mer</JobListBlockInfoButton>
          </JobListBlockInfoContainer>
        </JobListBlockLargeContainer>
      </SlideInLeft>
    );
  });
};
