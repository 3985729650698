import styled from 'styled-components/macro';
import { Title4 } from 'components/text';
import { colors as Colors } from 'constants/colors';
import { UnderLineCss } from 'components/UnderlineContainer';
import fonts from 'style/fonts/fonts';
import { targetBrowser } from 'style/media';

export const ImageLinkHeader = styled(Title4).attrs(() => ({
  fontSize: '1.167rem',
  color: Colors.WHITE
}))`
  margin: 0;
  text-align: center;
  position: absolute;
  font-family: ${fonts.LLBrown.BOLD};
  width: 100%;
  max-width: 100%;
  bottom: 50%;

  ${UnderLineCss`
  `};

  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 1 100%;
    transform: translateX(-50%);
  `};
`;
