import React from 'react';
import PropTypes from 'prop-types';
import { LinkListItem } from './components';
export default class LinkList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { titleWidth: '100%' };
    this.currentLargest = 0;
  }

  handleMaxWidth(w) {
    if (parseFloat(w) > parseFloat(this.currentLargest)) {
      this.currentLargest = w;
      this.setState({ titleWidth: w });
    }
  }

  render() {
    const { data = [] } = this.props;
    if (!data.length) return null;
    const resultData = data.map((item, index) => {
      return (
        <LinkListItem
          // titleWidth={this.state.titleWidth}
          // maxWidthSet={w => this.handleMaxWidth(w)}
          index={index}
          key={index}
          {...item}
        />
      );
    });

    return <React.Fragment>{resultData}</React.Fragment>;
  }
}

LinkList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      subPages: PropTypes.arrayOf(
        PropTypes.shape({
          imageUrl: PropTypes.string,
          ingress: PropTypes.string,
          name: PropTypes.string,
          date: PropTypes.string,
          linkUrl: PropTypes.string,
          documentType: PropTypes.string,
          tag: PropTypes.string,
          text: PropTypes.string,
          useFullWidthImage: PropTypes.bool,
          fileType: PropTypes.string
        })
      )
    })
  )
};
