import styled from 'styled-components/macro';
import { Title } from 'components/text';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
export const HeroHeader = styled(Title).attrs(props => ({
  centerTextSmall: true,
  large: true,
  min28onmobile: true
}))`
  color: ${({ color }) => color || Colors.BLACK};
  margin-bottom: ${props => (props.large ? '0.8333rem' : '1rem')};
  ${media.desktop`
    color: ${Colors.BLACK};
    text-align: ${props => (props.centerTextSmall ? 'center' : 'left')};
  `};
`;
