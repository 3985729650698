import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';

export const LineLoader = styled.div.attrs(props => ({
  width: props.progress === 0 || props.progress === 100 ? 0 : `${props.progress}%`,
  opacity: props.progress === 0 || props.progress === 100 ? 0 : 1
}))`
  transition: width 0.35s ease-in, opacity 0.05s ease-in;
  height: 1px;
  position: relative;
  opacity: ${props => props.opacity};
  bottom: 0;
  background: ${Colors.ORANGE};
  width: ${props => props.width};
`;

LineLoader.propTypes = {
  progress: PropTypes.number
};
