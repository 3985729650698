import styled from 'styled-components/macro';
import { media } from 'style/media';

export const HeroBlockContent = styled.div`
  padding: 5rem 0 9rem calc(6.688rem - 0.8333rem);
  max-width: calc(22.83rem + 7.083rem - 0.8333rem);

  @media (max-height: 800px) {
    padding-top: 2.5rem;
  }

  ${media.giant`
    width:100%;
  `};
  ${media.desktop`
    padding:0;
    display: flex;
    flex-direction: column;
    align-items: center;
`};
`;
