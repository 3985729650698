import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { AlternativeLabel } from 'components/text';
import fonts from 'style/fonts';
import { Input } from 'components/input';
export const RentCalculationBlockInputContainer = styled.div.attrs(props => ({
  paddingBottom: props.showValidation ? 0 : '1.5rem'
}))`
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  padding: 0 0.5rem;
  padding-bottom: ${props => props.paddingBottom};
  ${media.desktop`
  flex-basis: 100%;
  width: 100%;
  margin: 1rem 0 0;
  padding:0;
  `};
  &:first-of-type {
    padding-left: 0;
    ${media.desktop`
    padding:0;`};
  }
  &:last-of-type {
    /* padding-right: 0; */
    padding-left: 0;
    ${media.desktop`
    padding:0;`};
  }
`;

export const RentCalculationBlockInputLabel = styled(AlternativeLabel)`
  height: auto;
  font-size: 18px;
  margin-bottom: 0.2rem;
  position: relative;
`;

export const RentCalculationBlockInput = styled(Input).attrs(props => ({
  borderColor: props.validationBorder ? Colors.VALIDATION_ERROR : Colors.BLACK
}))`
  background-color: ${Colors.WHITE};
  border: 1px solid ${props => props.borderColor};
  width: 100%;
  height: 1.7rem;
  border-radius: 0;
  ${media.desktop`
  height: 2.5rem;
`};
`;

export const RentCalculationBlockValidation = styled.div`
  width: auto;
  position: relative;
  bottom: 0;
  height: 1.35rem;
  margin-top: 0.15rem;
  font-size: 16px;
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${Colors.VALIDATION_ERROR};
`;

export const RentCalculationBlockToolTipIcon = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  border-radius: 50px;
  margin-left: 0.3rem;
  text-align: center;
  background-color: ${Colors.TOOLTIP_ORANGE};
  color: ${Colors.WHITE};
`;

export const RentCalculationBlockToolTipBox = styled.div`
  color: ${Colors.BLACK};
  display: block;
  width: auto;
  padding: 0.5rem;
  text-align: start;
  font-size: 14px;
  font-family: ${fonts.APERCU.REGULAR};
  z-index: 1;
  background-color: ${Colors.WHITE};
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
  border: solid 0.5px #d8d8d8;
  border-radius: 3px;
  position: absolute;
  bottom: 25px;

  ${media.mediumtablet`
  position:relative;
  box-shadow: none;
  margin: 0.2rem 0;
  bottom: 0;
  `};
`;
