import * as TYPES from './pageTypes';

const initialState = {
  page: null,
  previousPage: null,
  loading: false,
  error: false,
  meta: {},
  title: '',
  currentId: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PAGE_FETCH:
      return {
        ...state,
        loading: true
      };
    case TYPES.PAGE_FETCHED:
      return {
        ...state,
        page: action.page,
        previousPage: action.previousPage,
        loading: false,
        currentId: action.id
      };
    case TYPES.PAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
