import { fetchJobAdPages } from '../../api/SearchApi';
import { isEmpty } from '../../utils';
import React, { useState, useEffect } from 'react';
import { FlexContainer } from 'components/flex';
import { PageContent } from 'pages/components';
import { JobListBlockHeading, JobListBlockIngress } from './components';
import OPE from 'components/common/EpiOnPageEdit';
import { JobListBlockItemsLarge } from './JobListBlockItemsLarge';
import { JobListBlockItemsSmall } from './JobListBlockItemsSmall';
import { Loader } from 'components/common/Loader';

export const jobListBlock = ({ heading, preamble }) => {
  const [jobList, setJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const items = await fetchJobAdPages();

      if (!isEmpty(items)) {
        setJobList(items);
      } else {
        setJobList([]);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <PageContent>
      <FlexContainer alignItems="center" column>
        <OPE propertyName="heading">
          {heading && <JobListBlockHeading>{heading}</JobListBlockHeading>}
        </OPE>
        <OPE propertyName="preamble">
          {preamble && <JobListBlockIngress>{preamble}</JobListBlockIngress>}
        </OPE>
        {isLoading ? (
          <Loader key={'loader_animation'} loaded={!isLoading} />
        ) : jobList.length > 5 ? (
          <JobListBlockItemsSmall jobList={jobList} />
        ) : (
          <JobListBlockItemsLarge jobList={jobList} />
        )}
      </FlexContainer>
    </PageContent>
  );
};
