import React from 'react';
import CardCarouselBlockItem from 'blocks/cardcarouselblockitem';
import Slider from 'react-slick';
import { SliderContainer } from './SliderContainer';
import { PageContent } from 'pages/components';

// import '../../../node_modules/slick-carousel/slick/slick.css';
// import '../../../node_modules/slick-carousel/slick/slick-theme.css';
//These imports where removed and the CDN was placed in the Head.cshtml file in the backend.
//Somehow the webpack could not fetch these files while building to integration.
//Webpack should perhaps be looked at to fix it, but unfortunately there was not enough time right now.

import { Title2 } from 'components/text';

class CardCarouselBlock extends React.Component {
  render() {
    const { carouselBlockItemArea } = this.props;
    if (carouselBlockItemArea == null || !carouselBlockItemArea.length) return null;

    const settings = {
      className: 'center',
      centerMode: true,
      centerPadding: '190px',
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            centerPadding: '140px'
          }
        },
        {
          breakpoint: 815,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            centerPadding: '50px',
            nextArrow: false,
            prevArrow: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '50px',
            nextArrow: false,
            prevArrow: false
          }
        }
      ]
    };
    return (
      <SliderContainer>
        <PageContent>
          <Title2 fontSize={'1.5rem'}>{this.props.header}</Title2>
        </PageContent>
        <Slider style={{ width: '100%' }} {...settings}>
          {carouselBlockItemArea.map((elem, key) => (
            <CardCarouselBlockItem key={key} {...elem} />
          ))}
        </Slider>
      </SliderContainer>
    );
  }
}

export default CardCarouselBlock;
