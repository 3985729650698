import * as TYPES from './newsblockTypes';
import { fetchNewsBlockItems } from '../../api/SearchApi';
import { LocalStorage, isEmpty } from '../../utils';

const Storage = new LocalStorage();

export const fetchNewsItems = (id, category) => {
  return async dispatch => {
    dispatch({ type: TYPES.NEWS_BLOCK_FETCH, category });
    try {
      const news = await fetchNewsBlockItems(id, category);

      if (!isEmpty(news)) {
        const cookie = Storage.getJSON(TYPES.NEWS_BLOCK_COOKIE);
        Storage.set(TYPES.NEWS_BLOCK_COOKIE, {
          ...cookie,
          news: news,
          lastHitCategory: category
        });

        dispatch({ type: TYPES.NEWS_BLOCK_FETCHED, news, lastHitCategory: category });
      } else {
        dispatch({ type: TYPES.NEWS_BLOCK_ERROR, message: 'Inga träffar för ' });
      }
    } catch (e) {
      throw e;
    }
  };
};
