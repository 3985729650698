import React from 'react';
// import RevokeCookies from '../../features/CookieBar/components/RevokeCookies';
import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { fonts } from 'style/fonts';
import { media } from 'style/media';
import { Title4, Paragraph } from 'components/text';
import { UnorderedList, NormalizedListItem } from 'components/common';
import { UnderLineCss } from 'components/UnderlineContainer';

const CookieBlockTitleWrapper = styled.div`
  background-color: ${Colors.DARK_GAY};
  padding: 0.3rem 1rem;
`;
const CookieBlockTitle = styled(Title4)`
  margin: 0;
`;
const CookieListHeader = styled(Paragraph)`
  flex-basis: auto;
  font-weight: bold;
  margin: 0 0.5rem 0 0;
  position: relative;
  font-size: 14px;

  ${UnderLineCss`
      width: 100%;
  `};

  ${media.tablet`
      padding-left: 10px;
      min-width: 100px;
      max-width: 100px;
      &:after {
          left: 0;
          width: 2px;
          height: 100%;
          top: 0;
      }
  `};
`;
const CookieBlockWrapper = styled.div`
  font-family: ${fonts.LLBrown.REGULAR};
  width: 100%;
  background-color: ${Colors.GRAY};
`;
const CookieListValue = styled(Paragraph)`
  margin: 0;
  font-size: 14px;
`;
const CookieDescription = styled(Paragraph)`
  margin: 0;
  padding: 1rem 1rem 0 1rem;
  font-size: 14px;
`;
const CookieBlockList = styled(UnorderedList)`
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: flex-start;
  ${media.tablet`
      flex-direction: column;
  `};
`;
const CookieBlockListItem = styled(NormalizedListItem)`
  flex-direction: row;
  margin-left: 2rem;
  align-items: baseline;
  flex-basis: 20%;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    flex-basis: auto;
  }
  ${media.tablet`
      margin-left: 0;
  `};
`;

export default class CookieBlock extends React.Component {
  render() {
    const { category: categories, name, description, expire, owner } = this.props;
    const category = categories.filter(x => x.selected);

    return (
      <CookieBlockWrapper>
        <CookieBlockTitleWrapper>
          <CookieBlockTitle black>{name}</CookieBlockTitle>
        </CookieBlockTitleWrapper>
        {description !== null && description.length > 0 && (
          <CookieDescription>{description}</CookieDescription>
        )}
        <CookieBlockList>
          <CookieBlockListItem flex>
            <CookieListHeader>Ägare:</CookieListHeader>
            <CookieListValue nomargin>{owner}</CookieListValue>
          </CookieBlockListItem>
          {category.length > 0 && (
            <CookieBlockListItem flex>
              <CookieListHeader>Kategori:</CookieListHeader>
              <CookieListValue nomargin>{category[0].text}</CookieListValue>
            </CookieBlockListItem>
          )}
          <CookieBlockListItem flex>
            <CookieListHeader>Utgår:</CookieListHeader>
            <CookieListValue nomargin>{expire}</CookieListValue>
          </CookieBlockListItem>
        </CookieBlockList>
      </CookieBlockWrapper>
    );
  }
}
