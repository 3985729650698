import styled from 'styled-components/macro';
import { media } from 'style/media';

export const HtmlComponentContainer = styled.div.attrs(props => ({
  margin: props.margin ? props.margin : '2rem 0 0 0',
  margintop: props.margintop ? props.margintop : '2rem',
  maxwidth: props.maxwidth ? props.maxwidth : '27.92rem',
  smallmargin: props.smallmargin !== undefined ? props.smallmargin : props.margin,
  smallalign: props.smallalign
}))`
  max-width: ${props => props.maxwidth};
  width: 100%;
  position: relative;
  &&& {
    * {
      text-align: ${({ forceCenter }) => forceCenter && 'center'};
    }
  }
  margin: ${props => props.margin};
  ${props => (!props.margin ? `margin-top: ${props => props.margintop}` : null)};
  ${media.desktop`
    margin: ${props => props.smallmargin};
    text-align: ${props => props.smallalign};
  `};
  p {
  }

  ${({ preview }) =>
    preview &&
    `
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -100%;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
      width: 300%;
      height: 10rem;
    }
  `}
`;
