import * as TYPES from './accountTypes';

const initialState = {
  isLoading: false,
  user: '',
  isAuthenticated: false,
  message: '',
  showLoginForm: false,
  success: true,
  isAdmin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACCOUNT_LOGIN: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.ACCOUNT_LOGGED_IN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.loggedInUser,
        isAuthenticated: action.isAuthenticated,
        message: action.message,
        success: true,
        isAdmin: action.isAdmin
      };
    }
    case TYPES.ACCOUNT_LOGGED_OUT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: '',
        isAuthenticated: false,
        message: action.message,
        success: true,
        isAdmin: false
      };
    }
    case TYPES.ACCOUNT_LOGOUT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.ACCOUNT_LOGGED_IN_FAIL: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: action.isAuthenticated,
        message: action.message,
        success: false
      };
    }
    case TYPES.ACCOUNT_LOGGED_OUT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: action.isAuthenticated,
        message: action.message,
        success: false
      };
    }
    case TYPES.ACCOUNT_VALIDATE_SUCCESS: {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        message: action.message,
        success: true,
        isAdmin: action.isAdmin
      };
    }
    case TYPES.ACCOUNT_SHOW_LOGIN_FORM: {
      return {
        ...state,
        showLoginForm: action.show
      };
    }
    case TYPES.ACCOUNT_CREATE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.ACCOUNT_CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        message: action.message,
        user: action.user,
        success: true
      };
    }
    case TYPES.ACCOUNT_CREATE_FAIL: {
      return {
        ...state,
        isLoading: false,
        success: false
      };
    }
    default:
      return state;
  }
};
