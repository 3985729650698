import styled from 'styled-components/macro';
import { media } from 'style/media';

export const ContactImage = styled.a.attrs(({ asLink }) => ({ as: asLink ? 'a' : 'span' }))`
  max-height: 4rem;
  max-width: 3.58rem;
  position: relative;
  min-height: 4rem;
  min-width: 3.58rem;

  height: 4rem;
  width: 3.58rem;
  ${media.desktop`
    width:80%;
    height:auto;
    max-height: none;
    max-width: none;
  `};
`;
