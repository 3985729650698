import styled from 'styled-components/macro';
import { media } from 'style/media';
import { zIndex } from '../../constants/settings';

export const SubHeroBlockContentItem = styled.div`
  padding-top: 1rem;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  z-index: ${zIndex.LEVEL_0};
  ${media.desktop`
    width:100%;
    align-items: center;
    
  `};
`;

export const SubHeroBlockContentItemMobile = styled.div`
  display: table;
  table-layout: fixed;
  height: 15rem;
  width: 100%;
  z-index: ${zIndex.LEVEL_0};
`;
