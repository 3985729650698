import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const IFrame = class extends PureComponent {
  constructor(props) {
    super(props);
    this.iframe = undefined;
    this.ref = React.createRef();
  }

  handleOnLoad = () => {
    const { onLoad, autosize } = this.props;
    const { current: iframe } = this.ref;

    if (onLoad) onLoad(iframe);
    if (autosize) {
      this.iFrameHeight(iframe);
    }
  };

  iFrameHeight = iframe => {
    try {
      const height = iframe.contentWindow.document.body.scrollHeight;

      iframe.height = height;
    } catch (ex) {
      iframe.style.height = '900px';
    }
  };

  render() {
    const {
      url,
      src,
      allowFullScreen,
      position,
      display,
      height,
      width,
      styles,
      id,
      className,
      name,
      children,
      frameborder,
      allowtransparency,
      onLoad,
      ...rest
    } = this.props;
    const props = {
      frameBorder: frameborder,
      allowtransparency: allowtransparency || 'false',
      src: src || url,
      target: '_parent',
      allowFullScreen: allowFullScreen || false,
      style: Object.assign(
        {},
        {
          position: position || 'relative',
          display: display || 'block',
          height: height || '100%',
          width: width || '100%'
        },
        styles || {}
      ),
      height: height || '100%',
      name: name || '',
      width: width || '100%',
      onLoad: this.handleOnLoad,
      ...rest
    };

    return (
      <React.Fragment>
        {React.createElement(
          'iframe',
          Object.assign(
            props,
            id ? { id: id, ref: this.ref } : {},
            className ? { className: className } : {}
          )
        )}
        {children && children}
      </React.Fragment>
    );
  }
};
IFrame.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  position: PropTypes.string,
  display: PropTypes.string,
  name: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.object,
  allowFullScreen: PropTypes.bool,
  onLoad: PropTypes.func
};
export default IFrame;
