import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import fonts from 'style/fonts';
import { colors as Colors } from 'constants/colors';
import { targetBrowser } from 'style/media';
export const UnderlineContainerText = styled.span.attrs(props => ({
  color: props.color ? props.color : Colors.BLACK,
  textalign: props.center ? 'center' : 'left',
  font: props.font ? props.font : fonts.LLBrown.REGULAR,
  opacity: props.opacity ? props.opacity : '0.59',
  padding: props.padding ? props.padding : '0.1rem 0.1rem 0 0'
}))`
  opacity: ${props => props.opacity};
  color: ${props => props.color};
  font-size: 11px;
  font-family: ${props => props.font};
  text-transform: uppercase;
  letter-spacing: 1.1px;
  text-align: ${props => props.textalign};
  padding: ${props => props.padding};
  word-break: break-word;
  ${targetBrowser.IE10ANDHIGHER`
    display: inline-table;
  `};
`;

UnderlineContainerText.propTypes = {
  font: PropTypes.oneOf(Object.values(fonts.APERCU).concat(Object.values(fonts.BROWN_PRO)))
};
