import React from 'react';
import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { NavigationLink } from 'features/Navigation';
import { fonts } from 'style/fonts';
import { LinkWrapper } from 'components/link';

export const HamburgareItemExpander = styled.button.attrs(props => ({
  color: props.expanded ? Colors.ORANGE : Colors.BRAND,
  beforetransform: props.expanded ? 'rotateZ(133deg)' : 'rotateZ(45deg)'
}))`
  margin-left: auto;
  margin-right: 1rem;
  apperance: none;
  background: none;
  cursor: pointer;
  border: none;
  &:before {
    transition: transform 0.2s ease-in-out;
    position: relative;
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-right: 0.18em solid ${props => props.color};
    border-top: 0.18em solid ${props => props.color};
    transform: ${props => props.beforetransform};
    margin-right: 0.5em;
  }

  &:focus {
    outline: none;
  }
`;

export const HamburgareItemWrapper = styled.ul.attrs(props => ({
  opacity: props.expanded ? 'block' : 'none'
}))`
  display: ${props => props.opacity};
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const StyledHamburgareItem = styled.li.attrs(props => ({
  paddingleft: props.indent > 1 ? `calc(1rem + ${props.indent * 0.5}rem)` : '1rem',
  background: props.background ? props.background : Colors.TRANSPARENT,
  fontSize: props.indent > 1 ? `calc(1em / ${1 + props.indent * 0.1})` : '1em'
}))`
  background: ${props => props.background};
  font-family: ${fonts.APERCU.WEB};
  font-size: ${props => props.fontSize};
  display: block;
  padding: 0.5em 0em 0.5em ${props => props.paddingleft};
  border-bottom: solid 1px ${Colors.DARK_GAY};
  &:hover {
  }
`;

export const StyledHamburgareItemHeader = styled(StyledHamburgareItem)`
  display: flex;
  align-items: center;
`;

export const HamburgareItemHeader = ({
  isTopNode,
  children,
  name,
  url,
  treeDepth,
  childNavigationLinks,
  pageType,
  onClick,
  id,
  activeClassName,
  ...rest
}) => {
  const filteredChildName = name.replace("$", "");

  return (
    <LinkWrapper href={url[0]}>
      <StyledHamburgareItemHeader onClick={e => onClick && onClick(e)} {...rest} indent={treeDepth}>
        <NavigationLink
          dropdown
          activeClassName={activeClassName}
          onClick={e => onClick && onClick(e)}
          font={treeDepth <= 1 ? fonts.APERCU.WEB : fonts.APERCU.REGULAR}
          color={Colors.BLACK}
          to={url[0]}>
          {filteredChildName}
        </NavigationLink>
        {children}
      </StyledHamburgareItemHeader>
    </LinkWrapper>
  );
};

export const HamburgareItem = ({
  isTopNode,
  children,
  name,
  url,
  treeDepth,
  childNavigationLinks,
  pageType,
  activeClassName,
  ...rest
}) => {
  const filteredChildName = name.replace("$", "");

  return (
    <LinkWrapper href={url[0]}>
      <StyledHamburgareItem {...rest} indent={treeDepth}>
        <NavigationLink dropdown="true" color={Colors.BLACK} to={url[0]} font={fonts.APERCU.REGULAR}>
          {filteredChildName}
        </NavigationLink>
        {children}
      </StyledHamburgareItem>
    </LinkWrapper>
  );
};
