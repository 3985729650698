import React from 'react';
import PropTypes from 'prop-types';
import { FilterArea } from 'features/FilterArea';
import { Block, BlockInner } from '../components';

export default class FilterAreaBlock extends React.Component {
  render() {
    const { filterAreaRootNode, nopadding, ...rest } = this.props;
    return (
      <Block>
        <BlockInner nopadding={nopadding}>
          <FilterArea {...rest} rootcategory={filterAreaRootNode} />
        </BlockInner>
      </Block>
    );
  }
}
FilterAreaBlock.propTypes = {
  nopadding: PropTypes.bool,
  filterAreaRootNode: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.string
    })
  )
};
