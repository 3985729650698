import React from 'react';
import PropTypes from 'prop-types';
const styles = {
  root: { backgroundColor: '#1f4662', color: '#fff', fontSize: '12px' },
  header: {
    backgroundColor: '#193549',
    padding: '5px 10px',
    fontFamily: 'monospace',
    color: '#ffc600'
  },
  pre: {
    display: 'block',
    padding: '10px 30px',
    margin: '0',
    overflow: 'scroll',
    whiteSpace: 'pre-wrap'
  }
};

export default class DebugPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      data: this.tryGetJson(props.data)
    };
  }

  tryGetJson = json => {
    try {
      return { json: JSON.parse(json), isJson: true };
    } catch (e) {
      return { json: json, isJson: false };
    }
  };

  toggle = () => {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div style={styles.root}>
        <div style={styles.header} onClick={this.toggle}>
          <strong>Debug</strong>
        </div>
        {this.state.show ? (
          <pre style={styles.pre}>{JSON.stringify(data.json, null, 2)}</pre>
        ) : null}
      </div>
    );
  }
}

DebugPrint.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
