import React from 'react';
import {
  CardCarouselBlockItemContainer,
  CardCarouselBlockItemContent,
  CustomCarouselLink
} from './CardCarouselBlockItemContainer';
import { Title4, Paragraph } from 'components/text';
import { colors as Colors } from 'constants/colors';
import { UnderlineContainer } from 'components/UnderlineContainer';

class CardCarouselBlockItem extends React.Component {
  render() {
    return (
        <CustomCarouselLink href={this.props.carouselLink} target={this.props.targetBlank && '_blank'}>
        <CardCarouselBlockItemContainer image={this.props.image} padding>
          <CardCarouselBlockItemContent>
            <Title4 color={Colors.WHITE} transform={'uppercase'} center centerTextSmall>
              {this.props.header}
              <UnderlineContainer
                width={'60%'}
                color={Colors.ORANGE}
                style={{ marginTop: '0.5rem' }}
                center
                left={'50%'}
                smallLeft={'38%'}
              />
            </Title4>

            <Paragraph
              color={Colors.WHITE}
              center
              centerTextSmall
              smallest
              lineheight={'1.4rem'}
              style={{ paddingTop: '0.5rem' }}>
              {this.props.carouselText}
            </Paragraph>
          </CardCarouselBlockItemContent>
        </CardCarouselBlockItemContainer>
      </CustomCarouselLink>
    );
  }
}

export default CardCarouselBlockItem;

// fontSize={'0.8rem'}
