import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { HtmlComponent } from 'features/HtmlComponent';
import { MainBody } from 'components/text';
import {
  ListBlockItemContainer,
  ListBlockItemDate,
  ListBlockItemLink,
  ListBlockItemContent
} from './index';
import { Date } from 'features/Date';
import { LoginToReadMore } from 'features/Account';
import { setLoginFormVisibility } from 'features/Account/accountActions';
import RelatedItem from 'features/Related/RelatedItem';
import { colors as Colors } from 'constants/colors';
import { FileTypes } from '../../../constants/fileTypes';

class ListBlockItem extends React.Component {
  constructor(props) {
    super(props);

    this.container = null;
    this.imageelement = null;
  }

  getHeight() {
    if (!this.container) return null;
  }

  getLink = (url, documentType, name) => {
    switch (documentType) {
      case 'dokument':
        return (
          <ListBlockItemLink external={true} target="_blank" to={url}>
            {name}
          </ListBlockItemLink>
        );
      case 'externallink':
        return (
          <ListBlockItemLink external={true} target="_blank" to={url}>
            {name}
          </ListBlockItemLink>
        );
      default:
        return (
          <ListBlockItemLink external={false} to={url}>
            {name}
          </ListBlockItemLink>
        );
    }
  };

  render() {
    const {
      imageUrl,
      ingress,
      name,
      fileType,
      documentType,
      useFullWidthImage,
      linkUrl,
      date,
      showLogin,
      html,
      account,
      children,
      altText,
      hideDate
    } = this.props;
    const { isAuthenticated } = account;
    return (
      <ListBlockItemContainer
        ref={container => (this.container = container)}
        justifycontent="center">
        <div>
          {imageUrl && (
            <RelatedItem
              ref={imageelement => (this.imageelement = imageelement)}
              hidefiletype={!FileTypes.DOCUMENT.includes(documentType)}
              square
              hidetype
              documentType={documentType}
              fileType={fileType}
              useFullWidthImage={useFullWidthImage}
              tag={fileType}
              altText={altText}
              imageUrl={imageUrl}
              linkUrl={linkUrl}>
              {/* <LoginToReadMore hide={!showLogin} /> */}
            </RelatedItem>
          )}
        </div>
        <ListBlockItemContent
          className="ListBlockItemContent"
          hasimage={imageUrl}
          fade={showLogin && !isAuthenticated}>
          <LoginToReadMore faded hide={!showLogin} />
          {!hideDate && <Date wrapper={<ListBlockItemDate />} date={date} />}
          {this.getLink(linkUrl, documentType, name)}
          {html && <HtmlComponent style={{}} html={html} />}
          {!html && (
            <MainBody color={Colors.TEXT_GRAY} weight={300} nomargin>
              <Truncate lines={hideDate ? 4 : 3} trimWhitespace={true}>
                {ingress}
              </Truncate>
              {children && children}
            </MainBody>
          )}
        </ListBlockItemContent>
      </ListBlockItemContainer>
    );
  }
}

const mapStateToProps = state => ({
  account: {
    user: state.account.user,
    isAuthenticated: state.account.isAuthenticated,
    showLoginForm: state.account.showLoginForm
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({ setLoginFormVisibility }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListBlockItem);

ListBlockItem.propTypes = {
  imageUrl: PropTypes.string,
  ingress: PropTypes.string,
  name: PropTypes.string.isRequired,
  tag: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['dokument', 'pagelink', 'image']),
  fileType: PropTypes.string
};
