import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import Select from 'react-select';
import { fonts } from 'style/fonts';
import styled from 'styled-components/macro';

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-basis: 50%;
  width: 100%;
  justify-content: flex-end;
`;

export const SearchDropdown = styled(Select).attrs(() => ({
  className: 'StyledSelect',
  classNamePrefix: 'StyledSelect'
}))`
  width: 100%;
  max-width: 250px;
`;

export const customStyles = {
  container: () => {
    const styles = {
      background: Colors.WHITE,
      border: 0,
      cursor: 'pointer'
    };
    return styles;
  },
  indicatorsContainer: (provided, state) => {
    const styles = {
      ...provided,
      cursor: 'pointer'
    };
    return styles;
  },
  indicatorSeparator: (provided, state) => {
    const styles = {
      ...provided,
      background: 'red'
    };
    return styles;
  },
  placeholder: (provided = {}) => {
    const styles = {
      ...provided,
      color: Colors.BLACK
    };

    return styles;
  },

  noOptionsMessage: provided => {
    const styles = {
      ...provided,
      fontFamily: fonts.APERCU.WEB,
      fontSize: '.75rem',
      background: Colors.ERROR,
      color: Colors.WHITE
    };

    return styles;
  },
  input: (provided, state) => {
    const styles = {
      fontFamily: fonts.APERCU.WEB,
      color: Colors.ORANGE,
      width: '100%'
    };

    return styles;
  },
  valueContainer: (provided, test) => {
    const styles = {
      ...provided,
      height: '100%',
      color: Colors.ORANGE,
      padding: 0,
      '.StyledSelect__placeholder': {
        color: Colors.ORANGE
      }
    };
    return styles;
  },
  singleValue: ({ color, ...provided }, { isDisabled, ...rest }) => {
    const styles = {
      ...provided,
      paddingBottom: '1px',
      marginLeft: '1em'
    };

    return styles;
  },
  menu: (provided = {}) => {
    const styles = {
      ...provided,
      color: Colors.BLACK,
      width: '100%',
      maxWidth: '250px',
      top: 'auto',
      right: 0,
      borderRadius: 0,
      boxShadow: '0 0 0 1px hsl(0deg 0% 0%), 0 4px 11px hsl(0deg 0% 0% / 10%);'
    };

    return styles;
  },
  menuList: (provided = {}) => {
    const styles = {
      ...provided,
      padding: 0,
      color: Colors.BLACK,
      zIndex: zIndex.HIGHEST,
      '.StyledSelect__option': {
        color: Colors.BLACK,
        padding: '10px 20px'
      }
    };

    return styles;
  }
};
