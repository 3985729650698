import styled from 'styled-components/macro';

export const SubHeroBlockContent = styled.div`
  padding-left: 2rem;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
`;

export const SubHeroBlockContentMobile = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 0.75rem 1rem 0.75rem 1rem;
  max-width: 30rem;
`;
