import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { isInternalUrl } from '../../utils';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import fonts from 'style/fonts';
import { WithLinkTracker } from 'features/WithTracker';
import Polymorph from 'features/Polymorph';
import { bindActionCreators } from 'redux';
import WithAuthentication from 'features/WithAuthentication';
import { Lock } from 'features/Account/components/Lock';
import { setSelectedFilter } from 'features/CountyFilter/countyFilterActions';

const UnstyledLink = styled(Polymorph).attrs(() => ({
  as: 'a'
}))``;

export const StyledLink = styled.a.attrs(props => ({
  display: props.flex ? 'flex' : 'inline-block',
  as: props.as || 'a'
}))`
  color: ${({ color }) => (color ? color : Colors.ORANGE)};
  text-decoration: none;
  display: ${props => props.display};
  ${props => (props.flex ? 'flex-direction: column' : '')};
  ${props => (props.flex ? 'align-items: center' : '')};
  font-family: ${fonts.LLBrown.REGULAR};
  margin: ${({ margin }) => (margin ? margin : '0')};
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const UnstyledInternalLinkWrapper = styled.a`
  text-decoration: none;
  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`;

export const BreadcrumbLink = styled(StyledLink).attrs(props => ({
  color: props.color ? props.color : Colors.BLACK
}))`
  color: ${props => props.color};
  font-size: 0.7em;
  &:hover,
  &:focus {
    color: ${props => LightenDarkenColor(props.color, 75)};
    &:after {
      color: ${props => props.color};
    }
  }
  &:not(:last-child) {
    padding-right: 1em;
    &:after {
      text-decoration: none;
      overflow: hidden;
      content: '/';
      height: 1em;
      width: 1em;
      position: absolute;
      text-align: center;
    }
  }
`;

export const BreadcrumbNoLink = styled(BreadcrumbLink).attrs(props => ({
  color: props.color ? props.color : Colors.BLACK
}))`
  color: ${props => props.color};
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.color};
  }
`;

export const BreadcrumbLinkWrapper = ({ children, as, ...props }) => {
  const type = as ? as : NavLink;
  return type === NavLink ? (
    <BreadcrumbLink as={type} {...props}>
      {children}
    </BreadcrumbLink>
  ) : (
    <BreadcrumbNoLink as={type} {...props}>
      {children}
    </BreadcrumbNoLink>
  );
};

export const InternalLinkWrapper = styled(UnstyledInternalLinkWrapper).attrs(() => ({
  as: NavLink
}))``;

export const InternalLink = styled(StyledLink).attrs(() => ({ as: NavLink }))``;

InternalLink.propTypes = {
  to: PropTypes.string.isRequired
};

export const ExternalLink = StyledLink.withComponent(WithLinkTracker(StyledLink));
export const UnstyledExternalLink = WithLinkTracker(UnstyledLink);

export const LinkWrapper = ({ href, children, external, className, as, ...rest }) => {
  if (isInternalUrl(href) && !external) {
    return (
      <InternalLinkWrapper {...rest} className={className} to={href} as={NavLink}>
        {children}
      </InternalLinkWrapper>
    );
  }
  return (
    <UnstyledInternalLinkWrapper {...rest} className={className} href={href} as="a">
      {children}
    </UnstyledInternalLinkWrapper>
  );
};

const Link = ({
  href,
  internal,
  children,
  flex,
  external,
  as,
  dispatch,
  onlyForAuthenticatedVisitors,
  locked,
  staticContext,
  ...rest
}) => {
  const renderContent = () => {
    return (
      <React.Fragment>
        {locked && (
          <Lock lockColor="orange" display="inline-block" size="1em" margin="0 0.25em 0 0" />
        )}
        {children}
      </React.Fragment>
    );
  };
  const handleClick = href => {
    const { categoryList } = rest.filter;
    const newRegion = categoryList.filter(
      x =>
        x.name.toLowerCase().replace(/\s+/g, '') ===
        decodeURIComponent(href.split('=')[1].toLowerCase())
    );
    let checkRegionArray = newRegion.id === undefined ? newRegion[0] : newRegion;
    setSelectedFilter(checkRegionArray);
  };

  if ((isInternalUrl(href) || internal) && !external && rest.target !== '_blank') {
    const { target, ...restProps } = rest;
    if (href.includes('?region=')) {
      return (
        <InternalLink onClick={() => handleClick(href)} to={href} as={NavLink}>
          {renderContent()}
        </InternalLink>
      );
    }
    return (
      <InternalLink {...restProps} flex={flex ? 1 : 0} to={href} as={NavLink}>
        {renderContent()}
      </InternalLink>
    );
  }
  return (
    <ExternalLink {...rest} flex={flex ? 1 : 0} href={href}>
      {renderContent()}
    </ExternalLink>
  );
};

const mapStateToProps = state => ({
  app: {
    routes: state.app.routes
  },
  filter: {
    selected: state.filter.selected,
    categoryList: state.filter.categoryList
  }
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedFilter
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WithAuthentication(Link)));
