import React from 'react';
import styled from 'styled-components/macro';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import { media } from 'style/media';

export const TinyMceLinkStyling = styled.a`
  color: ${Colors.WHITE};
  background-color: ${Colors.ORANGE};
  padding: 0.3rem 1rem;
  transition: all 0.2s ease-in-out;
  margin: 0.2rem 0.3rem;
  text-decoration: none;
  &:hover {
    background-color: ${LightenDarkenColor(Colors.ORANGE, -15)};
  }

  &:first-of-type {
    margin-left: 0;
  }

  ${media.mediumtablet`
  &:first-of-type {
      margin: 0.2rem 0.3rem;
  }
  `}
`;

export const StyledTinyMCELink = ({ href, ...rest }) => {
  const cleanHref = decodeURIComponent(href).replace('amp;', '');
  return <TinyMceLinkStyling href={cleanHref} isStyledTinyMCE {...rest} />;
};
