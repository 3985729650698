import styled from 'styled-components/macro';
import React from 'react';
import { PrimaryButton, SecondaryButton } from './index';
import { NavLink } from 'react-router-dom';
import DOMHelper from '../../utils/DOMHelper';
import { isInternalUrl } from '../../utils';
import fonts from 'style/fonts';

/**
 * ButtonLink (a) extends react-router-dom Link
 */
const Link = PrimaryButton.withComponent(NavLink);

export const StyledButtonLink = styled(Link).attrs(() => ({}))`
  font-family: ${fonts.LLBrown.REGULAR};
  line-height: 1.3em;
  font-weight: bold;
  letter-spacing: 0.05em;
`;

export const StyledButtonExternalLink = styled(Link).attrs(() => ({
  as: 'a'
}))`
  font-family: ${fonts.LLBrown.REGULAR};
  line-height: 1.3em;
  font-weight: bold;
  letter-spacing: 0.05em;
`;

const StyledExternalLink = StyledButtonLink.withComponent('a');

/**
 *In epi mode the component is rendered without a router.
 * We therefore must export Button and not StyledBouttonLink which uses NavLink
 * @param {*} props
 */
export const PrimaryButtonLink = props => {
  const { to: href } = props;
  return !DOMHelper.inEpiEditMode() ? (
    isInternalUrl(href) ? (
      <StyledButtonLink {...props} />
    ) : (
        <StyledExternalLink as={StyledButtonExternalLink} href={href} {...props} />
      )
  ) : (
      <PrimaryButton {...props} />
    );
};

const SecondaryLink = styled(SecondaryButton).attrs(props => ({
  ...props,
  as: NavLink
}))``;
export const StyledSecondaryButtonLink = styled(SecondaryLink).attrs(props => ({ ...props }))``;

const StyledSecondaryExternalButtonLink = styled(StyledSecondaryButtonLink).attrs(props => ({
  as: 'a',
  ...props
}))``;

/**
 *In epi mode the component is rendered without a router.
 * We therefore must export Button and not StyledBouttonLink which uses NavLink
 * @param {*} props
 */
export const SecondaryButtonLink = props => {
  const { to: href } = props;
  return !DOMHelper.inEpiEditMode() ? (
    isInternalUrl(href) ? (
      <StyledSecondaryButtonLink {...props} />
    ) : (
        <StyledSecondaryExternalButtonLink href={href} {...props} />
      )
  ) : (
      <PrimaryButton {...props} />
    );
};
