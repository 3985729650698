import styled from 'styled-components/macro';
import React from 'react';

/*
 * EventContainer (div)
 */

const StyledContactListBlockGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

class ContactListBlockGroup extends React.Component {
  render() {
    return <StyledContactListBlockGroup>{this.props.children}</StyledContactListBlockGroup>;
  }
}

export default ContactListBlockGroup;
