import React from 'react';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { Message } from 'components/message';
// import { ListBlockItem } from './components';
import {
  PuffListBlockTopContainer,
  PuffListBlockCategoryContainer,
  PuffListBlockCategoryTitle,
  PuffListBlockItem,
  PuffListBlockFlexContainer,
  PuffListBlockCountyContainer
} from './components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from '../../utils';
import { Block } from '../components';
import BlockArea from 'features/BlockArea';
import { fetchPuffListBlockItems } from '../../api/SearchApi';
import { Loader } from 'components/common/Loader';
import { PageContent } from 'pages/components';
// import { SlideInTop } from 'components/Animations';

class PuffListBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      items: [],
      error: {
        hasError: false,
        message: ''
      },
      first: true
    };
  }

  componentDidMount() {
    const { filter: countyFilter } = this.props;
    const { selected: selectedCounty } = countyFilter;
    if (isEmpty(selectedCounty)) return false;
    this.fetchPuffListBlockItems();
  }

  fetchPuffListBlockItems = async () => {
    const { id, filter, filterarea } = this.props;
    if (id === undefined) return;
    const { selected } = filter;
    const { selectedFilters } = filterarea;
    const result = await fetchPuffListBlockItems(id, selected.id, selectedFilters);
    if (isEmpty(result)) {
      this.setState({
        error: { hasError: true, message: 'Inga träffar' },
        items: [],
        first: false,
        isLoading: false
      });
    } else {
      this.setState({
        items: result,
        error: {
          hasError: false,
          message: ''
        },
        isLoading: false,
        first: false
      });
    }
  };

  receivedNewCountyFilter = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { first } = this.state;
    const { filter } = this.props;
    if (isEmpty(filter.selected)) return false;

    if (filter.selected.id !== prevFilter.selected.id || first) return true;
    return false;
  };

  receivedNewCategoryfilter = prevProps => {
    const { filterarea: prevFilterarea } = prevProps;
    const { filterarea } = this.props;
    if (prevFilterarea.selectedFilters !== filterarea.selectedFilters) return true;
  };

  componentDidUpdate(prevProps) {
    if (this.receivedNewCountyFilter(prevProps) || this.receivedNewCategoryfilter(prevProps)) {
      this.setState({ first: false, isLoading: true });

      this.fetchPuffListBlockItems();
    }
  }

  render() {
    const { filterContentArea, heading } = this.props;
    const { error, isLoading, items } = this.state;
    const AreaFilterBlock = filterContentArea.filter(x => x.blockType === 'AreaFilterBlock');
    const CountyFilterBlock = filterContentArea.filter(x => x.blockType === 'CountyFilterBlock');
    return (
      <Block background={Colors.GRAY} smallbackground={Colors.WHITE}>
        <PageContent bottommargin>
          <PuffListBlockTopContainer>
            <PuffListBlockCategoryContainer areafilter={AreaFilterBlock.length !== 0}>
              {heading && <PuffListBlockCategoryTitle>{heading}</PuffListBlockCategoryTitle>}
              <BlockArea nopadding propertyName="filterContentArea" contentarea={AreaFilterBlock} />
            </PuffListBlockCategoryContainer>
            <PuffListBlockCountyContainer>
              <BlockArea
                nopadding
                propertyName="filterContentArea"
                contentarea={CountyFilterBlock}
              />
            </PuffListBlockCountyContainer>
          </PuffListBlockTopContainer>
          <Loader loaded={!isLoading} />
          <PuffListBlockFlexContainer>
            <Message relative visible={error.hasError} key="error">{`${error.message}`}</Message>
            {!error.hasError &&
              !isLoading &&
              items.map((comp, index) => {
                return (
                  //   <SlideInTop delay={`${index / 10}s`} key={index}>
                  <PuffListBlockItem {...comp} key={index} index={index} />
                  // </SlideInTop> */}
                );
              })}
          </PuffListBlockFlexContainer>
        </PageContent>
      </Block>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected
  },
  filterarea: {
    selectedFilters: state.filterarea.selectedFilters
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PuffListBlock);

PuffListBlock.propTypes = {
  //Block contentLinkID
  id: PropTypes.number,
  header: PropTypes.string
};
