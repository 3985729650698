import React, { Fragment } from 'react';
import OPE from 'components/common/EpiOnPageEdit';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import { FlexContainerCentered } from 'components/flex';
import { sizes } from 'style/media';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LinkButton, LinkButtonText, PageContent } from './MenuBlockContainer';
import { CountyButtons } from 'pages/servicepage/components/CountyButtons';

class MenuBlock extends React.Component {
  onClickScroll = () => {
    let scrollToElement = document.getElementById('formLink');
    if (scrollToElement == null) return null;
    const windowOffset = window.scrollY || window.pageYOffset;

    const { top } = scrollToElement.getBoundingClientRect();
    const scrollY = top;
    window.scroll({
      top: windowOffset + scrollY,
      behavior: 'smooth'
    });
  };

  render() {
    const { mainBody, buttonText, buttonLink, formLink, countyLinks, categoryList } = this.props;
    if (window.innerWidth >= sizes.desktop) {
      return (
        <Fragment>
          <FlexContainerCentered alignItems="flex-start" marginbottom="2.5em">
            <PageContent
              className="PageContentClass"
              hasrelated={false}
              style={{ paddingTop: '1rem' }}>
              <OPE propertyName="mainBody" floating={false}>
                <HtmlComponentContainer
                  smallmargin="0rem 0rem 0rem 0rem"
                  margin="0rem 0rem 0rem 0rem"
                  maxwidth="700px">
                  <HtmlComponent html={mainBody} />
                </HtmlComponentContainer>
              </OPE>
              <CountyButtons countyLinks={countyLinks} categoryList={categoryList} />

              {buttonText &&
                (buttonLink || formLink) &&
                (formLink ? (
                  <LinkButton
                    onClick={() => {
                      this.onClickScroll();
                    }}>
                    <LinkButtonText>{buttonText}</LinkButtonText>
                  </LinkButton>
                ) : (
                  <a href={buttonLink} style={{ textDecoration: 'none', width: 'fit-content' }}>
                    <LinkButton>
                      <LinkButtonText>{buttonText}</LinkButtonText>
                    </LinkButton>
                  </a>
                ))}
            </PageContent>
          </FlexContainerCentered>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <FlexContainerCentered alignItems="flex-start">
            <PageContent
              className="PageContentClass"
              hasrelated={false}
              style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
              <OPE propertyName="mainBody" floating={false}>
                <HtmlComponentContainer
                  smallmargin="0rem 0rem 0rem 0rem"
                  margin="0rem 0rem 0rem 0rem"
                  maxwidth="700px">
                  <HtmlComponent html={mainBody} />
                </HtmlComponentContainer>
              </OPE>
              <CountyButtons countyLinks={countyLinks} categoryList={categoryList} />
              {buttonText &&
                (buttonLink || formLink) &&
                (formLink ? (
                  <LinkButton
                    onClick={() => {
                      this.onClickScroll();
                    }}>
                    <LinkButtonText>{buttonText}</LinkButtonText>
                  </LinkButton>
                ) : (
                  <a href={buttonLink} style={{ textDecoration: 'none', width: 'fit-content' }}>
                    <LinkButton>
                      <LinkButtonText>{buttonText}</LinkButtonText>
                    </LinkButton>
                  </a>
                ))}
            </PageContent>
          </FlexContainerCentered>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = ({ filter }) => ({
  selected: filter.selected,
  categoryList: filter.categoryList
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuBlock);
