import React from 'react';
import {
  RentCalculationTableWrapper,
  RentCalculationTableHeader,
  RentCalculationTableRow,
  RentCalculationTableHeaderRow,
  RentCalculationTableData,
  RentCalculationMobileTableWrapper,
  RentCalculationMobileTableItemContainer,
  RentCalculationMobileTableItem,
  RentCalculationMobileTableItemHeader,
  RentCalculationMobileTableItemText
} from './RentCalculationTableComponents';

class RentCalculationTable extends React.Component {
  render() {
    const displayList = this.props.yearList && this.props.yearList.slice().reverse();
    return (
      <>
        <RentCalculationTableWrapper>
          <tbody>
            <RentCalculationTableHeaderRow>
              <RentCalculationTableHeader>År</RentCalculationTableHeader>
              <RentCalculationTableHeader>KPI-oktober</RentCalculationTableHeader>
              <RentCalculationTableHeader>Hyrestillägg</RentCalculationTableHeader>
              <RentCalculationTableHeader>Sammanlagd hyra</RentCalculationTableHeader>
            </RentCalculationTableHeaderRow>
            {displayList.map(i => {
              return (
                <RentCalculationTableRow key={'table_row_' + i.year}>
                  <RentCalculationTableData>{i.year}</RentCalculationTableData>
                  <RentCalculationTableData>
                    {i.octIndex > 0 ? i.octIndex.toString().replace(/\./g, ',') : ''}
                  </RentCalculationTableData>
                  <RentCalculationTableData>{i.c} kr</RentCalculationTableData>
                  <RentCalculationTableData>{i.d} kr</RentCalculationTableData>
                </RentCalculationTableRow>
              );
            })}
          </tbody>
        </RentCalculationTableWrapper>
        <RentCalculationMobileTableWrapper>
          {displayList.map(i => {
            return (
              <RentCalculationMobileTableItemContainer key={'table_item_mobile_' + i.year}>
                <RentCalculationMobileTableItem>
                  <RentCalculationMobileTableItemHeader>År</RentCalculationMobileTableItemHeader>
                  <RentCalculationMobileTableItemText>{i.year}</RentCalculationMobileTableItemText>
                </RentCalculationMobileTableItem>
                <RentCalculationMobileTableItem>
                  {' '}
                  <RentCalculationMobileTableItemHeader>
                    KPI-oktober
                  </RentCalculationMobileTableItemHeader>
                  <RentCalculationMobileTableItemText>
                    {i.octIndex.toString().replace(/\./g, ',')}
                  </RentCalculationMobileTableItemText>
                </RentCalculationMobileTableItem>
                <RentCalculationMobileTableItem>
                  {' '}
                  <RentCalculationMobileTableItemHeader>
                    Hyrestillägg
                  </RentCalculationMobileTableItemHeader>
                  <RentCalculationMobileTableItemText orange>
                    {i.c} kr
                  </RentCalculationMobileTableItemText>{' '}
                </RentCalculationMobileTableItem>
                <RentCalculationMobileTableItem>
                  {' '}
                  <RentCalculationMobileTableItemHeader>
                    Sammanlagd hyra
                  </RentCalculationMobileTableItemHeader>
                  <RentCalculationMobileTableItemText orange>
                    {i.d} kr
                  </RentCalculationMobileTableItemText>
                </RentCalculationMobileTableItem>
              </RentCalculationMobileTableItemContainer>
            );
          })}
        </RentCalculationMobileTableWrapper>
      </>
    );
  }
}

export default RentCalculationTable;
