import React, { forwardRef } from 'react';
import { fillElement } from 'utils';
import {
  SearchHeroBlockImage,
  PreambleText,
  HeroTitle
} from './components/SearchHeroBlockComponents';
import OPE from 'components/common/EpiOnPageEdit';

const SearchHeroBlock = forwardRef(
  ({ imageUrl = '', preamble = '', header = '', children }, passedRef) => {
    return (
      <SearchHeroBlockImage ref={passedRef} background={fillElement(imageUrl)}>
        <OPE propertyName="header">
          <HeroTitle>{header}</HeroTitle>
        </OPE>
        <OPE propertyName="preamble">
          <PreambleText>{preamble}</PreambleText>
        </OPE>
        {children}
      </SearchHeroBlockImage>
    );
  }
);

export default SearchHeroBlock;
