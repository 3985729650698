import styled from 'styled-components/macro';
import { ThemeProvider } from 'styled-components/macro';
import { footer } from 'constants/settings';
import { Theme } from 'constants/theme';

// import PropTypes from 'prop-types';

/**
 * App (div)
 */
export const AppContainer = styled.div.attrs(props => ({
  className: props.className,
  theme: Theme,
  as: ThemeProvider
}))`
  height: 100%;
  position: relative;
  margin: 0;
  min-height: 100vh;
  /* padding-bottom: ${footer.HEIGHT}; */
  /* perspective: 20rem; */
`;
