import styled from 'styled-components/macro';
import React from 'react';
import { colors as Colors } from 'constants/colors';
import fonts from 'style/fonts/fonts';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
export const StyledListBlockItemLink = styled.h2`
  font-family: ${fonts.LLBrown.REGULAR};
  margin: 0 0 0.1rem 0;
  padding: 0;
  font-size: 0.9883em;
  line-height: 1.36em;
  text-decoration: none;
  color: ${Colors.TOTALLY_BLACK};
`;

const RealStyledListBlockItemLink = StyledListBlockItemLink.withComponent(NavLink);
const StyledExternalListBlockItemLink = StyledListBlockItemLink.withComponent('a');

export const ListBlockItemLink = ({ external, to, ...props }) => {
  if (external) {
    return <StyledExternalListBlockItemLink href={to} {...props} />;
  }
  return <RealStyledListBlockItemLink to={to} {...props} />;
};

ListBlockItemLink.propTypes = {
  to: PropTypes.string.isRequired
};
