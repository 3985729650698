import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login, create as createUser, setLoginFormVisibility } from '../accountActions';
import { Input } from 'components/input';
import { Title2, MainBody } from 'components/text';
import { Link } from 'components/link';
import { FlexContainer } from 'components/flex';
import { Form } from 'components/form';
import { ButtonText, SubmitButton } from 'components/button';
import { colors as Colors } from 'constants/colors';
import { LoginMessage } from './LoginMessage';
import { Loader } from 'components/common/Loader';
import { FormWrapper } from './FormWrapper';
class RequestLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      nameValue: '',
      isLoginView: true,
      phoneValue: '',
      lastNameValue: '',
      passwordValue: '',
      memberNumberValue: ''
    };
    this.loginForm = {};
  }

  handleEmailChange = e => {
    const { value } = e.target;
    this.setState({ emailValue: value });
  };

  handleNameChange = e => {
    const { value } = e.target;
    this.setState({ nameValue: value });
  };

  handleLastNameChange = e => {
    const { value } = e.target;
    this.setState({ lastNameValue: value });
  };

  handlePhoneChange = e => {
    const { value } = e.target;
    this.setState({ phoneValue: value });
  };

  handlePasswordChange = e => {
    const { value } = e.target;
    this.setState({ passwordValue: value });
  };
  handleMemberNumberChange = e => {
    const { value } = e.target;
    this.setState({ memberNumberValue: value });
  };

  handleSubmit = async e => {
    const {
      emailValue,
      nameValue,
      phoneValue,
      lastNameValue,
      passwordValue,
      memberNumberValue
    } = this.state;
    const { onChangeView, createUser } = this.props;
    e.preventDefault();
    const mainResult = await createUser({
      emailValue,
      nameValue,
      phoneValue,
      lastNameValue,
      passwordValue,
      memberNumberValue
    });
    const { success, message } = mainResult;
    this.setState({ passwordValue: '', message: message });

    if (!success) return;
    onChangeView();
  };

  render() {
    if (!this.props) return null;
    const {
      nameValue,
      emailValue,
      phoneValue,
      lastNameValue,
      message,
      passwordValue,
      memberNumberValue
    } = this.state;
    const { account, onChangeView } = this.props;
    const { isLoading, isAuthenticated, success } = account;
    const activeButton = emailValue && nameValue && lastNameValue && phoneValue && passwordValue;
    return (
      <FormWrapper>
        <Title2>Skapa ett konto!</Title2>
        <MainBody>
          Skriv in informationen nedan för att skapa ett konto.
          <Link href="#" onClick={e => e.preventDefault && onChangeView()}>
            Tillbaka till logga in
          </Link>
        </MainBody>
        <Form column justifycontent="flex-start" onSubmit={this.handleSubmit}>
          <Input
            value={nameValue}
            type="text"
            onChange={this.handleNameChange}
            placeholder="Förnamn"
          />
          <Input
            value={lastNameValue}
            type="text"
            onChange={this.handleLastNameChange}
            placeholder="Efternamn"
          />
          <Input
            value={emailValue}
            type="text"
            onChange={this.handleEmailChange}
            placeholder="E-post"
          />
          <Input
            value={phoneValue}
            type="number"
            onChange={this.handlePhoneChange}
            placeholder="Telefon"
          />
          <Input
            value={memberNumberValue}
            type="text"
            onChange={this.handleMemberNumberChange}
            placeholder="Medlemsnummer"
          />
          <Input
            value={passwordValue}
            type="password"
            onChange={this.handlePasswordChange}
            placeholder="Lösenord"
          />
          <FlexContainer alignItems="center">
            <LoginMessage fail={!success} nomargin>
              {message && message}
            </LoginMessage>
            <SubmitButton
              disabled={!activeButton}
              active={activeButton}
              finished={!isLoading && success}
              type="submit"
              success={success}
              right
              bordercolor={Colors.ORANGE}>
              <Loader width="2.5rem" height="3rem" size={'contain'} absolute loaded={!isLoading} />
              <ButtonText large hide={isLoading || isAuthenticated} color={Colors.BLACK}>
                Ansök
              </ButtonText>
            </SubmitButton>
          </FlexContainer>
        </Form>
      </FormWrapper>
    );
  }
}

const mapStateToProps = state => ({
  account: {
    user: state.account.user,
    isAuthenticated: state.account.isAuthenticated,
    showLoginForm: state.account.showLoginForm,
    message: state.account.message,
    isLoading: state.account.isLoading,
    success: state.account.success
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      setLoginFormVisibility,
      createUser
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestLoginForm);

RequestLoginForm.propTypes = {
  login: PropTypes.func
};
