import styled from 'styled-components/macro';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import PropTypes from 'prop-types';
import fonts from 'style/fonts/fonts';

/**
 * ListItem (div)
 */
export const CountyFilterListItem = styled.li`
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out;
  color: ${Colors.DARKER_GRAY};
  font-size: 22px;
  position: relative;
  cursor: pointer;
  font-family: ${fonts.LLBrown.BOLD};
  padding: 0.5rem;
  min-width: 20%;
  display: block;
  &:hover {
    color: ${Colors.TOTALLY_BLACK};
  }
`;

const Underline = styled.span.attrs(props => ({
  color: props.selected ? Colors.ORANGE : Colors.TRANSPARENT,
  width: props.selected ? '100%' : '80%'
}))`
  &:before {
    transition: background 0.2s ease-in, width 0.3s ease-out;
    content: '';
    position: absolute;
    background: ${props => props.color};
    width: ${props => props.width};
    height: 2px;
    bottom: -0.4rem;
    transform: translateX(-50%);
    left: 50%;
  }

  &:hover:before {
    background: ${LightenDarkenColor(Colors.DARKER_GRAY, 50)};
    width: 100%;
  }
  &:active:before {
    background: ${Colors.ORANGE};
  }
`;

/**
 * ListItem (div)
 */
export const CountyFilterListItemSpan = styled(Underline).attrs(props => ({
  color: props.selected ? Colors.ORANGE : 'inherit'
}))`
  color: ${props => props.color};
  position: relative;
  display: inline-block;
`;

CountyFilterListItem.propTypes = {
  selected: PropTypes.bool
};
