import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import fonts, { fontSize } from 'style/fonts';

/**
 * ButtonText (span)
 */
export const ButtonText = styled.span.attrs(props => ({
  fontSize: props.search ? '1rem' : props.large ? '0.6667rem' : fontSize(15, 18),
  color: props.color ? props.color : Colors.WHITE,
  display: 'inline-block',
  opacity: props.hide ? 0 : 1,
  forcedFontSize: props.forcedFontSize ? fontSize(...props.forcedFontSize) : undefined,
  forcedLineHeight: props.forcedLineHeight
}))`
  font-family: ${fonts.LLBrown.REGULAR};
  color: ${props => props.color};
  font-weight: ${props => (props.weight ? props.weight : 'normal')};
  padding: 0 1rem 0 1rem;
  font-size: ${({ forcedFontSize, fontSize }) => (forcedFontSize ? forcedFontSize : fontSize)};
  line-height: ${({ forcedLineHeight }) => (forcedLineHeight ? forcedLineHeight : '2em')};
  display: ${props => props.display};
  opacity: ${props => props.opacity};
  transition: filter 0.3s ease-out;
  ${({ blur }) => (blur ? 'filter: blur(2px);' : '')};
  &:hover {
    cursor: pointer;
  }
`;
