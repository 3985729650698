import { Box as RebassBox, Flex as RebassFlex } from '@rebass/grid';
import styled from 'styled-components/macro';
import { targetBrowser } from 'style/media';

export const Flex = styled(RebassFlex)`
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;

export const Box = styled(RebassBox).attrs(props => ({
  margin: props.margin ? props.margin : 'inherit'
}))`
  margin: ${props => props.margin};
`;
