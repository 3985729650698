import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { settings as Settings } from 'constants/settings';
import { media } from 'style/media';

const Gradient = styled.div`
  &:before {
    display: ${props => (props.gradient ? 'block' : 'none')};
    content: '';
    width: ${props => (props.gradient ? props.gradient.width : '50%')};
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-image: linear-gradient(
      to ${props => (props.gradient ? props.gradient.direction : '')},
      ${props => (props.gradient ? props.gradient.color : Colors.WHITE)},
      rgba(255, 255, 255, 0)
    );
    background-blend-mode: multiply;
  }
`;

/**
 * QuestionBlockContainer (div)
 */
const menuHeight = `calc(${Settings.MENU_HEIGHT})`;

export const LinkTextBlockContainer = styled(Gradient).attrs(props => ({
  maxwidth: props.limitwidth ? '60rem' : '100%',
  margin: props.center ? '0 auto' : '0',
  padding: props.nopadding ? 0 : '2rem 2rem 2rem 2rem',
  paddingtop: props.first ? menuHeight : props.nopadding ? 0 : '2rem',
  direction: props.column ? 'column' : 'row'
}))`
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingtop};
  display: flex;
  flex-direction: ${props => props.direction};
  position: relative;
  background: ${props => props.background};
  height: 100%;
  width: 100%;
  justify-content: center;
  background-position: center;
  background-size: cover;
  z-index: 0;
  max-width: ${props => props.maxwidth};
  margin: ${props => props.margin};

  ${media.desktop`
    background: ${Colors.WHITE};  
    padding-top:0;
    max-height: none;
    flex-direction: column;
  `};
`;

LinkTextBlockContainer.propTypes = {
  limitwidth: PropTypes.bool,
  center: PropTypes.bool,
  background: PropTypes.string,
  first: PropTypes.bool.isRequired,
  gradient: PropTypes.shape({
    direction: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
    color: PropTypes.oneOf(Object.values(Colors)),
    width: PropTypes.string
  }),
  maxHeighT: PropTypes.bool
};
