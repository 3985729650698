import * as TYPES from './activityBlockTypes';
import { LocalStorage } from '../../utils';

const Storage = new LocalStorage();

const cookie = Storage.getJSON(TYPES.ACTIVITY_COOKIE);
const initialState = {
  isLoading: false,
  events: [],
  previousEvents: [],
  lastHitCategory: 2,
  error: {
    hasError: false,
    message: ''
  }
};

if (!cookie) {
  Storage.set(TYPES.ACTIVITY_COOKIE, initialState);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTIVITY_BLOCK_FETCH: {
      return {
        ...state,
        isLoading: true,
        lastUpdatedCategory: action.category
      };
    }
    case TYPES.ACTIVITY_BLOCK_FETCHED: {
      return {
        ...state,
        isLoading: false,
        events: action.events,
        previousEvents: state.events,
        lastHitCategory: action.lastHitCategory,
        error: { hasError: false, message: '' }
      };
    }
    case TYPES.ACTIVITY_BLOCK_ERROR: {
      return {
        ...state,
        isLoading: false,
        events: [],
        error: { hasError: true, message: action.message }
      };
    }
    default:
      return state;
  }
};
