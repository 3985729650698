import React from 'react';
import CollapsibleBlock from 'blocks/collapsibleblock';
import { PageContent } from 'pages/components';
import { HtmlComponent } from 'features/HtmlComponent';


class CollapsibleContainerBlock extends React.Component {

    render() {
        const { contentArea, mainBody } = this.props;
        return (
            <PageContent column bottommargin>
                <HtmlComponent html={mainBody} />
                <br />
                {contentArea.map((block, index) => {
                    return <CollapsibleBlock {...block} key={index} />;
                })}
            </PageContent>
        )
    }
}

export default CollapsibleContainerBlock;