import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 350;
const exitDuration = 170;
export default class AutoCompleteTrancition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    anime({
      targets: node,
      opacity: [0.8, 1],
      rotateX: [-35, 0],
      translateY: [-5, 0],
      duration: duration,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  onExit = (node, index) => {
    if (!node) return null;

    anime({
      targets: node,
      opacity: [1, 0],
      rotateX: [0, -80],
      translateY: [0, -5],
      duration: exitDuration,
      easing: 'easeOutElastic',
      elasticity: 100
    });
  };

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: duration, exit: exitDuration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onExit={this.onExit}
        mountOnEnter={false}
        unmountOnExit={true}>
        {children}
      </Transition>
    );
  }
}
AutoCompleteTrancition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  children: PropTypes.element
};
