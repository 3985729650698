export { Title, Title2, Title3, Title4 } from './Title';

export {
  Paragraph,
  Ingress,
  Span,
  SmallText,
  ContactOfficePageSmallText,
  LargeText,
  CenteredParagraph,
  Quote,
  Quote2,
  Quote3,
  Quote4,
  Quote5,
  Strong,
  ImageText,
  HighlightText,
  HighlightBackground,
  MediumText,
  MainIntro,
  MainBody,
  Label,
  AlternativeLabel,
  FormFeedbackMessage,
  NeedToLoginText,
  ContactText,
} from './Text';
