import React from 'react';
import { ModalContainer, CloseModal, ModalWrapper } from './components';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });

  };

  closeModal = () => {
    const { onClickClose = () => {} } = this.props;
    onClickClose();
  };

  render() {
  
    const { children, isModalOpen, hideClose = false, modalClose = false } = this.props;
    return (
      <ModalContainer isOpen={isModalOpen}>
        <ModalWrapper isOpen={isModalOpen} modalClose={modalClose}>      
          {children}
          {!hideClose && <CloseModal onClick={this.closeModal}>Stäng</CloseModal>}
        </ModalWrapper>
      </ModalContainer>
    );
  }
}

export default Modal;
