import React from 'react';
import { HtmlComponent } from 'features/HtmlComponent';
import { LeftContainer, RightContainer, CollapsibleDoubleBlockContainer } from './components'


class CollapsibleDoubleBlock extends React.Component {

    render() {
        const { leftColumnBody, rightColumnBody, lastOne } = this.props;
        return (
            <CollapsibleDoubleBlockContainer lastOne={lastOne}>
                <LeftContainer>
                    <HtmlComponent html={leftColumnBody}></HtmlComponent>
                </LeftContainer>
                <RightContainer>
                    <HtmlComponent html={rightColumnBody}></HtmlComponent>
                </RightContainer>
            </CollapsibleDoubleBlockContainer>
        )
    }
}


export default CollapsibleDoubleBlock;