import { createPortal } from 'react-dom';
import React from 'react';
const id = 'modal-portal';
const ModalPortal = ({ children }) => {
  const element = document.getElementById(id);
  return createPortal(children, element);
};

const ModalPortalDestination = () => <div id={id} />;

export { ModalPortal, ModalPortalDestination };
