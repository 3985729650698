import CategoryTag from 'features/CategoryTag';
import React from 'react';
import { fillElement } from 'utils';
import { Paragraph } from 'components/text/Text';
import { Title4 } from 'components/text/Title';
import { LazyLoadImage } from 'features/LazyImage';
import {
  SearchResult,
  SearchResultDescriptionContainer,
  SearchResultDateContainer,
  SearchResultTopContainer
} from './SearchResultLayoutComponents';
import { SlideInLeft } from 'components/Animations';

export const SearchResultItem = ({
  url = '',
  imageUrl = '',
  tag = '',
  description = '',
  text = '',
  isDocument = false,
  published = '',
  simpleVersion = false,
  ingress = '',
  linkUrl = ''
}) => {
  const displayCategory = !!tag && tag !== 'Root';
  return (
    <SlideInLeft>
      <SearchResult external={isDocument} nounderline to={simpleVersion ? linkUrl : url}>
        {!simpleVersion && (
          <LazyLoadImage
            onload={true}
            fit={tag === 'Dokument' ? 'contain' : 'cover'}
            src={fillElement(imageUrl)}
            maxwidth="6rem"
            maxheight="6rem"
            minheight="6rem"
          />
        )}
        <SearchResultDescriptionContainer column>
          <SearchResultTopContainer displayCategory={displayCategory}>
            {displayCategory && <CategoryTag category={tag} />}
            {displayCategory && (
              <Paragraph nomargin style={{ fontSize: '14px' }}>
                {published}
              </Paragraph>
            )}
            {!displayCategory && <SearchResultDateContainer>{published}</SearchResultDateContainer>}
          </SearchResultTopContainer>
          <Title4 marginTop="14px">{text}</Title4>
          <Paragraph>{!simpleVersion ? description : ingress}</Paragraph>
        </SearchResultDescriptionContainer>
      </SearchResult>
    </SlideInLeft>
  );
};
