import styled from 'styled-components/macro';
import { targetBrowser } from 'style/media';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: ${Colors.WHITE};
  width: 100%;
  /* max-width: calc((16.92rem + (3 * 1.417rem / 2)) * 3.1); */
  gap: 0.5rem;
  padding: 0.5rem 0;
  margin: 0 auto;
  min-height: 10rem;
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
  ${media.extraGiant`
    justify-content:center;
  `};
`;

export const RelatedHtmlContainer = styled.div`
  margin-left: 0.2rem;
`;
