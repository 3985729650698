import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';

// import OPE from 'components/common/EpiOnPageEdit';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { UnderlineContainer } from 'components/UnderlineContainer';
import OPE from 'components/common/EpiOnPageEdit';
import { Title2, Paragraph } from 'components/text';
import CarouselBlock from 'blocks/carouselblock';
import { Transition } from 'react-transition-group';
import { CoolTransition } from 'features/Transitions';
import { BasePage } from 'pages/basepage';
import { IconButton } from 'features/IconButton';
import { colors as Colors } from 'constants/colors';
import { isEmpty, getSelectedFromSelectionProperty } from '../../utils';
import { FlexContainerCentered, FlexContainer } from 'components/flex';
import BlockArea from 'features/BlockArea';
import { TagLabel } from 'features/Tag/Tag';
import { FilterAreaContainer } from 'features/FilterArea/';
import { fetchItems, changeActivePage } from './pageListBlockActions';
import { Block, BlockInner, BlockHeader } from '../components';
import { Message } from 'components/message';

const enterDuration = 300;
const exitDuration = 600;
class PageListBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasClicked: false };
  }

  componentDidMount() {
    this.fetchListItems();
  }

  shouldFetchNewItems = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { filter, pagelistblock, ignoreFilters } = this.props;
    if (ignoreFilters) return false;
    if (isEmpty(filter.selected)) return false;
    if (filter.selected.id !== prevFilter.selected.id) return true;
    if (pagelistblock.lastUpdatedCategory !== filter.selected.id) return true;
    return false;
  };

  componentDidUpdate(prevProps) {
    if (this.shouldFetchNewItems(prevProps)) {
      this.fetchListItems();
    }
  }

  handleIconClick = (id, index) => {
    const { currentPageId } = this.props.pagelistblock;
    const { changeActivePage } = this.props;
    if (currentPageId === id) return null;
    this.setState({ hasClicked: true });
    changeActivePage(id, index);
  };

  clearProps = (node, resetProgress) => {
    node.style.position = 'relative';
  };

  onExit = (node, index) => {
    if (!node) return null;
    anime({
      duration: exitDuration,
      targets: node,
      translateY: [0, '5rem'],
      opacity: [1, 0],
      easing: 'easeOutQuad'
    });
  };

  onEnter = node => {
    anime({
      targets: node,
      duration: 0,
      opacity: 0
    });
  };

  onEntered = (node, index) => {
    if (!node) return null;
    anime({
      duration: enterDuration,
      targets: node,
      translateY: ['-5rem', 0],
      delay: target => index * 100,
      elasticity: (target, totalTargets) => {
        return 200 + index * 50;
      },
      easing: 'easeOutElastic',
      opacity: [0, 1],
      completed: this.clearProps(node, true)
    });
  };

  getItemsAsIconButtons = () => {
    const { items, selectedIndex, isLoading, error } = this.props.pagelistblock;

    const { asLinks, asIcons } = this.props;
    if (error.hasError) {
      return [
        <Message key={0} relative visible={error.hasError}>
          {/* {`${error.message} ${filter.selected.name}`} */}
          Inga resultat
        </Message>
      ];
    }

    if (!asIcons) {
      return (
        <FilterAreaContainer>
          {items.map((item, index) => {
            return (
              <TagLabel
                selected={selectedIndex === index}
                key={`${index}_${item.pageId}`}
                onClick={() => this.handleIconClick(item.pageId, index)}>
                {item.text}
              </TagLabel>
            );
          })}
        </FilterAreaContainer>
      );
    }
    return items.map((item, index) => {
      return (
        <Transition
          key={index}
          appear={true}
          exit={true}
          timeout={500}
          mountOnEnter={true}
          in={!isLoading}
          onEnter={(node, isAppearing) => this.onEnter(node, index)}
          onEntered={(node, isAppearing) => this.onEntered(node, index)}
          onExit={node => this.onExit(node, index)}>
          <IconButton
            asLink={asLinks}
            selected={selectedIndex === index}
            clickCallback={() => this.handleIconClick(item.pageId, index)}
            {...item}
          />
        </Transition>
      );
    });
  };

  fetchListItems() {
    const { id, filter, fetchItems, ignoreFilters } = this.props;
    if (id === undefined) return;
    const { selected, list } = filter;

    fetchItems(id, ignoreFilters ? list[0] && list[0].id : selected.id);
  }

  render() {
    if (!this.props) return null;
    const { pagelistblock } = this.props;
    const { currentPageId, isLoading, error, items, lastPageId } = pagelistblock;
    if (!items) return null;
    const { hasClicked } = this.state;

    const {
      asLinks,
      heading,
      pageTypeCategory,
      mainIntro,
      filterContentArea,
      asIcons
    } = this.props;

    const reverse =
      items.findIndex(x => x.pageId === currentPageId) <
      items.findIndex(x => x.pageId === lastPageId);
    return (
      <div>
        {!asIcons && (
          <Block background={Colors.TRANSPARENT}>
            <BlockInner>
              <FlexContainerCentered
                maxwidthsmall
                maxwidth="calc(36.25rem - 2.76666rem)"
                alignItems="flex-start">
                {this.getItemsAsIconButtons()}
              </FlexContainerCentered>
              <FlexContainerCentered maxwidthsmall maxwidth="calc(36.25rem - 2.76666rem)">
                {heading && (
                  <OPE propertyName="heading">
                    <BlockHeader centerTextSmall>{heading}</BlockHeader>
                  </OPE>
                )}
              </FlexContainerCentered>
            </BlockInner>
          </Block>
        )}
        {asIcons && (
          <Block background={Colors.GRAY}>
            <BlockInner>
              <FlexContainerCentered maxwidth="36.25rem" alignItems="center" column>
                {getSelectedFromSelectionProperty(pageTypeCategory) && (
                  <UnderlineContainer position="bottom" center color={Colors.ORANGE}>
                    <OPE propertyName="pageTypeCategory">
                      <Title2 centerTextSmall uppercase small center>
                        {getSelectedFromSelectionProperty(pageTypeCategory)}
                      </Title2>
                    </OPE>
                  </UnderlineContainer>
                )}
                {heading && (
                  <OPE propertyName="heading">
                    <Title2 margin="0 0 0.88rem 0" large centerTextSmall>
                      {heading}
                    </Title2>
                  </OPE>
                )}
                {mainIntro && (
                  <OPE propertyName="mainIntro">
                    <Paragraph centerTextSmall nomargin center>
                      {mainIntro}
                    </Paragraph>
                  </OPE>
                )}
              </FlexContainerCentered>
              <BlockArea
                nopadding
                propertyName="filterContentArea"
                contentarea={filterContentArea}
              />
              {!error.hasErrors && (
                <CarouselBlock
                  nopadding
                  nominheight
                  loading={isLoading}
                  nomarginbottom
                  first={false}
                  heading={''}>
                  {this.getItemsAsIconButtons()}
                </CarouselBlock>
              )}
            </BlockInner>
          </Block>
        )}
        <FlexContainer width="100%" justifycontent="center">
          {!asLinks &&
            items.map((item, index) => {
              return (
                <CoolTransition
                  reverse={reverse}
                  key={item.pageId}
                  transitionkey={item.pageId}
                  in={currentPageId === item.pageId}>
                  <div>
                    <BasePage
                      useTopPadding={false}
                      shouldUpdateState={false}
                      width="100vw"
                      onLoadedComplete={this.handlePageLoaded}
                      pageprops={item.page}
                      scrollTo={hasClicked && item.pageId}
                      pagetype={item.page.pageType}
                      id={item.pageId}
                    />
                  </div>
                </CoolTransition>
              );
            })}
        </FlexContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentPage: state.page,
  filter: {
    selected: state.filter.selected,
    reset: state.filter.reset,
    list: state.filter.categoryList
  },
  pagelistblock: {
    isLoading: state.pagelistblock.isLoading,
    items: state.pagelistblock.items,
    hasErrors: state.pagelistblock.hasErrors,
    lastPageId: state.pagelistblock.lastPageId,
    selectedIndex: state.pagelistblock.selectedIndex,
    currentPageId: state.pagelistblock.currentPageId,
    error: state.pagelistblock.error,
    lastUpdatedCategory: state.pagelistblock.lastUpdatedCategory
  },
  app: {
    counties: state.app.counties
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchItems, changeActivePage }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageListBlock);

PageListBlock.propTypes = {
  //id of the block
  id: PropTypes.number,
  findPageTypes: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  ignoreFilters: PropTypes.bool
};
