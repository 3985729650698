import React from 'react';
import withCheckboxGroup from './withCheckboxGroup';

const InformedValueCheckbox = ({ checkboxGroupApi, checkboxGroupState, valueToSet, ...props }) => {
  const { value: groupValue = [] } = checkboxGroupState;
  const { setValue, setTouched, onChange: groupOnChange, onBlur: groupOnBlur } = checkboxGroupApi;

  const getNewValue = (current = [], newValue) => {
    if (current.some(x => x === newValue)) {
      return current.filter(x => x !== newValue);
    }
    return [...groupValue, valueToSet];
  };

  const { value, onChange, onBlur, field, initialValue, forwardedRef, ...rest } = props;
  return (
    <input
      {...rest}
      name={field}
      ref={forwardedRef}
      checked={groupValue.some(v => v === valueToSet)}
      onChange={e => {
        setValue(getNewValue(groupValue, valueToSet));
        if (onChange) {
          onChange(e);
        }
        if (groupOnChange) {
          groupOnChange(e);
        }
      }}
      onBlur={e => {
        setTouched();
        if (onBlur) {
          onBlur(e);
        }
        if (groupOnBlur) {
          groupOnBlur(e);
        }
      }}
      type="checkbox"
    />
  );
};

export default withCheckboxGroup(InformedValueCheckbox);
