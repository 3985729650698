import styled from 'styled-components/macro';
import { media } from 'style/media';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import fonts from 'style/fonts';

export const InstructionContainerCircle = styled.div`
    position: absolute;
    left: -7px;
    top: 23px;
    background: ${Colors.ORANGE};
    width: 45px;
    height: 45px;
    border-radius: 45px;
    z-index:${zIndex.LEVEL_3};
    ${media.desktop`
        width: 38px;
        height: 38px;
        border-radius: 38px;
        left: -7px;
        top: 19px;
    `};
    ${media.smalltablet`
        width: 30px;
        height: 30px;
        border-radius: 30px;
        left: 1px;
        left: -3px;
        top: 19px;
    `};
    ${media.phone`
        left: -11px;
        top: 18px;
    `};
`;

export const InstructionContainerCircleText = styled.div`
    margin: 0;
    font-family: ${fonts.LLBrown.REGULAR};
    color: ${Colors.WHITE};
    justify-content: center;
    font-weight: bold;
    text-align: center;
    height: 100%;
    width: auto;
    line-height: 43px;
    font-size: 1.15rem;
    ${media.desktop`
        line-height: 37px;
    `};
    ${media.smalltablet`
    width: 100%;
    height: auto;
    line-height: 29px;
`};
`;