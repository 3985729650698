import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const exitDuration = 700;
const enterDuration = 425;
export default class CountyFilterTransition extends React.Component {
    onEnter = (node, isAppearing, index, mobile) => {
        if (!node) return null;
        const height = node.getBoundingClientRect().height;
        anime({
            targets: node,
            height: ['0', height + 'px'],
            opacity: {
                value: [0.5, 1],
                delay: enterDuration / 10,
                easing: 'easeOutElastic',
                elasticity: 10
            },
            elasticity: 0,
            easing: 'easeOutQuad',
            duration: enterDuration
        });
    };

    onExit = (node, index) => {
        if (!node) return null;
        anime({
            targets: node,
            height: ['318px', '0'],
            duration: exitDuration,
            easing: 'easeOutElastic',
            elasticity: 10
        });
    };

    render() {
        const { children, transitionkey, in: inProp } = this.props;
        return (
            <Transition
                appear={true}
                enter={true}
                exit={true}
                in={inProp}
                timeout={{ enter: 0, exit: exitDuration }}
                key={transitionkey}
                onEnter={this.onEnter}
                onExit={this.onExit}
                mountOnEnter={true}
                unmountOnExit={false}>
                {children}
            </Transition>
        );
    }
}
CountyFilterTransition.propTypes = {
    transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.element
};
