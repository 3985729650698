import styled from 'styled-components/macro';
import fonts from 'style/fonts';
import { PrimaryButtonLink } from 'components/button';
import { media } from 'style/media';
import { colors as Colors } from 'constants/colors';

export const JobListBlockInfoButton = styled(PrimaryButtonLink)`
  font-size: 16px;
  font-family: ${fonts.LLBrown.REGULAR};
  padding: 0.25rem 1.5rem;
  border: none;
  background-color: 'transparent';
  margin: 0;
  min-height: 40px;
  background-color: ${Colors.ORANGE};
  color: ${Colors.WHITE};
  border: 1px solid ${Colors.ORANGE};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  :active,
  :focus {
    background-color: ${Colors.WHITE};
    color: ${Colors.ORANGE};
    border: 1px solid ${Colors.ORANGE};
  }
  ${media.desktop`
    height: 50px;
  `};
`;
