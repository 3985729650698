const FilterByCounty = (list, catId, rootCatId, countyCategories) => {
  if (!list || !list.length || !catId) return list;

  return filterByCountyRecursive(list, catId, countyCategories);
};

const filterByCountyRecursive = (list, catId, countyCategories) => {

  if (!list || !list.length) return [];
  if (!countyCategories || !countyCategories.length) return list;
  return list.reduce((accumulator, currentVal) => {
    const { categories, childNavigationLinks } = currentVal;
    if (categories.includes(catId) || currentVal.isExternalLink )
      accumulator.push({
        ...currentVal,
        childNavigationLinks: filterByCountyRecursive(childNavigationLinks, catId, countyCategories)
      });

    return accumulator;
  }, []);
};

export default FilterByCounty;
