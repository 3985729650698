export const SHADOW_TYPES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  RIGHT: 6,
  SIX: 7,
  ORANGE: 8
};

export function shadow(level = 1) {
  switch (level) {
    case SHADOW_TYPES.ONE:
      return '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
    case SHADOW_TYPES.TWO:
      return '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)';
    case SHADOW_TYPES.THREE:
      return '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)';
    case SHADOW_TYPES.FOUR:
      return '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
    case SHADOW_TYPES.FIVE:
      return '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)';
    case SHADOW_TYPES.RIGHT:
      return '11px 0 40px -2px rgba(0, 0, 0, 0.24)';
    case SHADOW_TYPES.ORANGE:
      return '0 1px 3px rgba(249, 132, 18, .12), 0 1px 2px rgba(249, 132, 18, .24)';
    case SHADOW_TYPES.SIX:
      return '0 19px 38px rgba(0,0,0,0.66), 0 15px 12px rgba(0,0,0,0.77)';
    default:
      break;
  }
}
