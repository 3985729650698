import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  padding: 1rem 0 1rem 0;
`;

const IconLink = IconButtonContainer.withComponent(NavLink);
export const IconButtonLink = styled(IconLink)`
  text-decoration: none;
`;
