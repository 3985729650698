import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'components/common/EpiOnPageEdit';
import { Title3 } from 'components/text';
import { Block, DoubleContainer } from '../components';
import { FlexContainer } from 'components/flex';
import { LazyLoadImage } from 'features/LazyImage';
import { Link } from 'components/link';
const imageWidth = '3rem';
const textMargin = '1rem 0 .5rem 0';
class IconListBlock extends React.PureComponent {
  componentDidMount() {}

  render() {
    const {
      faqIcon,
      questionsAndAnswersLink,
      questionsAndAnswersText,
      prenumerationIcon,
      penumerationLink,
      prenumerationText
    } = this.props;
    return (
      <Block wide>
        <DoubleContainer noextramargin>
          <FlexContainer alignItems="baseline" justifycontent="center">
            <FlexContainer
              margin="0 0 1rem 0"
              smallwidth="100%"
              width="30%"
              alignItems="center"
              justifycontent="center"
              column>
              <OPE propertyName="questionsAndAnswersLink">
                <Link flex href={questionsAndAnswersLink}>
                  {faqIcon && <LazyLoadImage center src={faqIcon} width={imageWidth} />}
                  <Title3 margin={textMargin}>{questionsAndAnswersText}</Title3>
                </Link>
              </OPE>
            </FlexContainer>
            <FlexContainer smallwdth="100%" width="30%" alignItems="center" column>
              <OPE propertyName="penumerationLink">
                <Link flex href={penumerationLink}>
                  {prenumerationIcon && (
                    <LazyLoadImage center src={prenumerationIcon} width={imageWidth} />
                  )}
                  <Title3 margin={textMargin}>{prenumerationText}</Title3>
                </Link>
              </OPE>
            </FlexContainer>
          </FlexContainer>
        </DoubleContainer>
      </Block>
    );
  }
}

export default IconListBlock;

IconListBlock.propTypes = {
  first: PropTypes.bool,
  penumerationLink: PropTypes.string,
  pernumerationIcon: PropTypes.string,
  questionsAndAnswersLink: PropTypes.string,
  faqIcon: PropTypes.string
};
