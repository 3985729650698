import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class QuoteBlock extends React.Component {
  render() {
    const getBackgroundClass = function(backgroundColor) {
      const selectedItem = backgroundColor.find(item => item.selected === true) || {};

      switch (selectedItem.text) {
        case 'Vit':
          return 'quote-block--white';
        case 'Ljusgrå':
          return 'quote-block--light-grey';
        case 'Mellangrå':
          return 'quote-block--medium-grey';
        default:
          return 'quote-block--black';
      }
    };

    const name = this.props.name ? `"${this.props.name}"` : null;

    return (
      <div
        className={`h4 quote-block ${getBackgroundClass(this.props.backgroundColor || [])}`}
        smallrow>
        <ScrollAnimation animateIn="fadeInUp">
          <div className="quote-block__quote" style={{ '--data-name': name }}>
            {this.props.quote}
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default QuoteBlock;
