import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import { media } from 'style/media';
import { LazyBackground } from 'features/LazyImage';
import { Link } from 'components/link';
import { MediumText } from 'components/text';
import ArrowImage from 'images/arrow-white.min.svg';

export const ParallaxBlockContainer = styled(LazyBackground).attrs(() => ({
  bgdelay: 300
}))`
  margin-bottom: 1rem !important;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 22rem;
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  ${media.desktop`
    background-image: none !important;
    background-color: ${Colors.WHITE};
    padding-top: 0;
    min-height: 0;
    flex-direction: column;
    height: auto;

    .lazy-load-bg__real {
      display: none;
    }

    .lazy-load-bg__temp {
      display: none;
    }

    .lazy-load-bg__temp--color {
      display: none;
    }
  `};
`;

export const ParallaxBlockContainerMobile = styled(LazyBackground).attrs(() => ({
  bgdelay: 300
}))`
  margin-bottom: 1rem !important;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 24rem;
  z-index: ${zIndex.LEVEL_0};
  margin: 0;
  background-image: none !important;
  background-color: ${Colors.WHITE};
  padding-top: 0;
  min-height: 0;
  flex-direction: column;
  height: auto;

  .lazy-load-bg__real {
    display: none;
  }

  .lazy-load-bg__temp {
    display: none;
  }

  .lazy-load-bg__temp--color {
    display: none;
  }
`;

export const LinkButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.ORANGE};
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
`;

export const LinkButtonMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.ORANGE};
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
`;

export const LinkButtonText = styled(MediumText)`
  font-size: 0.7rem;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;
  color: white;
`;

export const LinkButtonTextMobile = styled(MediumText)`
  font-size: 0.9rem;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;
  color: white;
`;

export const CustomLink = styled(Link)`
  text-decoration: none !important;
  :hover {
    cursor: pointer;
  }
`;

export const ArrowRight = styled.div`
  background: no-repeat url(${ArrowImage});
  padding: 0;
  margin-left: 0.25rem;
  margin-top: 0.1rem;
  background-size: 1rem;
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  min-height: 1rem;
  max-height: 1rem;
  color: white;
`;

export const ArrowRightMobile = styled.div`
  background: no-repeat url(${ArrowImage});
  padding: 0;
  margin-left: 0.25rem;
  margin-top: 0.1rem;
  background-size: 1.2rem;
  height: 1.2rem;
  width: 1.2rem;
  min-width: 1.2rem;
  max-width: 1.2rem;
  min-height: 1.2rem;
  max-height: 1.2rem;
  color: white;
`;