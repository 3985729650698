import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import logoImage from 'images/logo.svg';
import { Picture } from 'components/images/Image';
import { media } from 'style/media';

/**
 * Logo (div)
 */
export const StyledLogo = styled.img.attrs(props => ({
  left: props.countyPicker ? '10%' : '',
  top: props.countyPicker ? '-1rem' : '',
  height: props.countyPicker ? '2rem' : '',
}))`
  position: relative;
  margin: ${props => (props.center ? '0 auto' : 0)};
  left: ${props => props.left};
  top: ${props => props.top};
  height: ${props => props.height};

  ${media.phone`
    height: 2rem;
  `}
`;

export const Logo = props => {
  let combinedProps = {
    ...props,
    src: logoImage,
    alt: props.alt || 'Fastighetsägarnas logotyp'
  };

  return (
    <Picture center>
      <StyledLogo {...combinedProps} />
    </Picture>
  );
};

Logo.propTypes = {
  alt: PropTypes.string
};
