import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { MainBody } from '../text';
import { media } from 'style/media';
import xIcon from 'images/cancel.svg';

export const ChoiceLabel = styled(MainBody).attrs(props => ({
  as: 'label',
  round: props.round ? '50%' : '0%',
  dimension: props.round ? '1rem' : '1.5rem',
  roundtop: props.round,
  padding: props.html ? '0 0 0 2rem' : '0 2rem'
}))`
  flex: 1;
  justify-content: flex-start;
  width: ${({ width }) => (width ? width : 'auto')};
  padding-top: ${props => (props.roundtop ? '' : '1rem')};
  padding: ${props => props.padding};
  cursor: pointer;
  position: relative;
  line-height: 20px;
  ${media.desktop`
  line-height: 20px;
  `};
  ${media.tablet`
  line-height: 18px;
  `};
  &:before {
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.34s ease-in;
    content: '';
    background: ${Colors.WHITE};
    position: absolute;
    width: ${props => props.dimension};
    left: 0;
    top: ${props => (props.html ? '0' : props.roundtop ? '-10%' : '-0.15rem')};
    height: ${props => props.dimension};
    border: solid 1px ${Colors.BLACK};
    border-radius: ${props => props.round};
    box-shadow: ${props => props.round === 1 && 'inset 0px 0px 0 4px #FFF'};
    ${media.mediumtablet`
        top: ${props => props.roundtop && '0'};
      `};
  }

  &:hover {
    &:before {
    }
  }
  &:last-of-type {
    margin-bottom: 1.3em;
  }

  &:not(:first-of-type) {
    /* margin:0 0 1.3em 2.625rem; */
  }
`;

export const Radio = styled.input.attrs(props => ({
  type: props.type ? props.type : 'radio'
}))`
  width: 0;
  height: 0;
  opacity: 0;
  &::before + ${ChoiceLabel} {
    box-shadow: inset 0px 0px 0 4px #fff;
  }

  position: absolute;
  &:checked + ${ChoiceLabel} {
    &:before {
      background: ${Colors.ORANGE};
      background-size: cover;
      border: solid 1px ${Colors.BLACK};
      box-shadow: inset 0px 0px 0 4px #fff;
    }
  }
  &:hover + ${ChoiceLabel} {
    &:before {
      background: ${Colors.ORANGE};
      background-size: cover;
      border: solid 1px ${Colors.BLACK};
      box-shadow: inset 0px 0px 0 4px #fff;
    }
  }
`;

export const Checkbox = styled(Radio).attrs(props => ({
  type: 'checkbox'
}))`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  /* box-shadow: inset 0px 0px 0 4px #FFF; */

  &:checked + ${ChoiceLabel} {
    &:before {
      background: url(${xIcon});
      background-size: cover;
      border: solid 1px ${Colors.BLACK};
      /* box-shadow: inset 0px 0px 0 4px #FFF; */
    }
  }
`;

export const RadioGroup = styled.div.attrs(props => ({
  nomarginbottom: props.nomarginbottom ? '0' : '1rem'
}))`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 50%;

  align-content: flex-start;
  justify-content: flex-start;
  /* flex-grow: 1; */
  margin-top: 0.5rem;
  margin-bottom: ${props => props.nomarginbottom};
  ${media.mediumtablet`
    flex-basis: 50%;
    flex-grow: 1;
  `};
`;
