import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import fonts from 'style/fonts/fonts';
import ArrowImage from 'images/arrow.min.svg';

export const CalendarListBlockItemLink = styled(NavLink)`
  font-family: ${fonts.LLBrown.REGULAR};
  background: ${Colors.GRAY};
  margin-bottom: 0.4rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${Colors.BLACK};
  &:after {
    transition: transform 0.25s ease-in-out;
    content: '';
    background: no-repeat red url(${ArrowImage}) center;
    width: 50px;
    height: 100%;
    position: absolute;
    right: 0;
    padding-right: 1rem;
    top: 0;
  }
  &:hover {
    &:after {
      transform: translateX(0.3rem);
    }
  }
`;

CalendarListBlockItemLink.propTypes = {
  to: PropTypes.string.isRequired
};
