import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { colors as Colors } from 'constants/colors';
export const Text = styled.span.attrs(props => ({
  blur: props.blur,
  opacity: props.direction,
  color: props.color ? props.color : Colors.ORANGE
}))`
  color: ${props => props.color};
  display: inline-block;
  filter: blur(${props => props.blur}px);
  opacity: ${props => props.opacity};
  transition: opacity ${props => props.animationlength}ms ease-in,
    filter ${props => props.animationlength / 10}ms ease-in;
`;

Text.propTypes = {
  blur: PropTypes.number,
  direction: PropTypes.number,
  color: PropTypes.oneOf(Object.values(Colors)),
  animationlength: PropTypes.number
};
