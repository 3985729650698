import * as TYPES from './navigationTypes';

const initialState = {
  navItems: {
    main: [],
    side: [],
    hamburger: []
  },
  loading: false,
  error: false,
  isSearchActive: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NAVIGATION_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case TYPES.NAVIGATION:
      return {
        ...state,
        navItems: Object.assign({}, action.value),
        loading: false,
        error: false
      };
    case TYPES.NAVIGATION_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case TYPES.NAVIGATION_TOGGLE_SEARCH:
      return {
        ...state,
        isSearchActive: !state.isSearchActive
      };
    default:
      return state;
  }
};
