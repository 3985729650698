import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { zIndex } from 'constants/settings';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getRouteByUrl } from '../app/appActions';
//Page types
import { BasePage } from '../basepage';
import NotFoundPage from '../notfoundpage';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';

import { Transition } from 'react-transition-group';

import anime from 'animejs';

const enterDuration = 200;
const exitDuration = 100;

class PageRoutes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { routes: null, loadedId: null, lastLoadedId: null, requestedUrl: '' };
  }

  componentDidUpdate = prevProps => {
    const { app: prevApp } = prevProps;
    const { app, location } = this.props;
    const { routes: appRoutes, pageNotFound } = app;

    if (app.routes === prevApp.routes) return null;
    const routes = appRoutes.map((route, index) => {
      const { url, id, pageType } = route;
      return url.map((linkUrl, i) => {
        return (
          <Route
            key={`${linkUrl}_${i}`}
            exact
            path={linkUrl}
            render={props => this.getPageType(pageType, id, linkUrl)}
          />
        );
      });
    });

    routes.push([
      <Route
        key={'404_page'}
        path="*"
        render={route =>
          this.getPageType(pageNotFound.pageType, pageNotFound.id, pageNotFound.url[0])
        }
      />
    ]);

    this.setState({
      routes: routes,
      requestedUrl: !this.state.requestedUrl ? location.pathname : this.state.requestedUrl
    });
  };

  handlePageLoaded(id) {
    const { app, filter } = this.props;
    const { selected } = filter;
    const { routes: appRoutes } = app;
    this.setState({ lastLoadedId: this.state.loadedId, loadedId: id });
    const routeObj = appRoutes.filter(item => {
      return item.url[0] === window.location.pathname;
    });

    if (routeObj.length && !routeObj[0].hideCountyInUrl) {
      const county = queryString.parse(window.location.search);
      if (
        (Object.keys(county).length === 0 || (county.region && county.region !== selected.name)) &&
        selected.id !== 2
      ) {
        window.history.replaceState('', '', `?region=${selected.name.replace(/\s+/g, '')}`);
      } else if (selected.id === 2 && routeObj[0].pageType !== 'SearchPage') {
        window.history.replaceState('', '', `${routeObj[0].url[0]}`);
      } else {
        window.history.replaceState('', '', '');
      }
    } else if (routeObj.length && routeObj[0].hideCountyInUrl) {
      window.history.replaceState('', '', `${routeObj[0].url[0]}`);
    }
  }

  getPageType = (type, id, link = '') => {
    const { loadedId, requestedUrl } = this.state;
    return (
      <Transition
        key={link}
        exit={true}
        enter={true}
        in={loadedId === id}
        appear={true}
        timeout={{ exit: exitDuration, enter: enterDuration }}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        onExited={this.onExiting}
        mountOnEnter={false}
        unmountOnExit={false}>
        <div key={link}>
          <BasePage
            key={id}
            first={true}
            onLoadedComplete={() => this.handlePageLoaded(id)}
            id={id}
            pagetype={type}
            requestedUrl={requestedUrl}
          />
        </div>
      </Transition>
    );
  };

  clearProps = node => {
    node.style.position = 'relative';
  };

  onEnter = (node, isAppearing) => {
    if (!node) return null;
    node.style.zIndex = zIndex.ABOVE_MODALS;
    node.style.background = 'white';
    node.style.opacity = 0;

    anime({
      duration: enterDuration,
      delay: 0,
      targets: node,
      translateZ: ['-.5rem', 0],
      opacity: [0.7, 1],
      elasticity: 1000,
      easing: 'easeInOutExpo',
      completed: this.clearProps(node)
    });
  };

  onEntering = (node, isAppearing) => {};

  onEntered = (node, isAppearing) => {};

  onExit = node => {
    if (!node) return null;
    node.style.position = 'fixed';
    node.style.top = 0;
    node.style.zIndex = zIndex.LEVEL_0;

    anime({
      duration: exitDuration,
      targets: node,
      opacity: {
        value: [1, 0],
        duration: exitDuration
      },
      scale: [1, 0.97],
      elasticity: 0,
      filter: 'blur(3px)',
      easing: 'easeInOutBack',
      complete: () => {
        this.clearProps(node);
      }
    });
  };

  onExiting = node => {};
  onExited = node => {};

  render() {
    const { location } = this.props;

    return (
      <Switch location={location}>
        {this.state.routes}
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  app: {
    routes: state.app.routes,
    pageNotFound: state.app.pageNotFound
  },
  page: {
    loading: state.page.loading,
    id: state.page.currentId
  },
  filter: {
    selected: state.filter.selected
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({ getRouteByUrl }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageRoutes);

PageRoutes.propTypes = {
  location: PropTypes.object.isRequired,
  pagetype: PropTypes.oneOf([
    'StandardPage',
    'StartPage',
    'CalendarPage',
    'TjanstePage',
    'RemissPage'
  ])
};
