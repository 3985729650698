import styled from 'styled-components/macro';
import { media } from 'style/media';

/**
 * CountyFilterHeaderContainer (div)
 */
export const CountyFilterHeaderContainer = styled.div.attrs(props => ({
  justification: props.center ? 'center' : 'flex-start',
  align: props.ispuffblockfilter ? 'flex-start' : 'center'
}))`
  display: flex;
  flex-direction: row;
  width: ${props => (props.active ? '100%' : 'auto')};
  position: relative;
  justify-content: ${props => props.justification};
  align-items: ${props => props.align};
  height: 3rem;
  flex-wrap: wrap;
  ${media.desktop`
    padding-left:0.8333rem; 
    padding-right:0.8333rem; 
  `};
`;
