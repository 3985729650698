import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import fonts from 'style/fonts/fonts';
const padding = '0.5rem';
const margin = '1.35rem';
const underlinelength = '2.4rem';

/**
 * Base UnderlineContainer
 */
export const BaseUnderlineContainer = styled.div.attrs(props => ({
  paddingbottom: props.position === 'bottom' ? padding : 0,
  paddingtop: props.position === 'top' ? padding : 0,
  paddingleft: props.position === 'left' ? padding : 0,
  paddingright: props.position === 'right' ? padding : 0,
  marginbottom: props.position === 'bottom' ? margin : 0,
  margintop: props.position === 'top' ? margin : '2rem',
  marginleft: props.position === 'left' ? margin : 0,
  marginright: props.position === 'right' ? margin : 0,
  bottom: props.position === 'top' ? '100%' : 0,
  top: props.position === 'top' ? '0' : '100%',
  textalign: props.centertext ? 'center' : 'left',
  color: props.color || Colors.BLACK,
  underlinemargin: props.underlinemargin ? props.underlinemargin : '0',
  smallunderlinewhensmall: props.smallunderlinewhensmall,
  center: props.center,
  height: props.height ? props.height : '2px',
  alignwhensmall: props.alignwhensmall,
  underlinelength: props.underlinelength ? props.underlinelength : underlinelength,
  countyUnderline: props.countyUnderline
}))`
  &:after {
    position: absolute;
    content: '';
    bottom: ${props => props.bottom};
    top: ${props => props.top};
    left: ${props => (props.left ? props.left : props.center ? '50%' : '0')};
    transform: translateX(${props => (props.center ? '-50%' : '0')});
    height: ${props => props.height};
    width: ${props =>
      props.countyUnderline ? '40rem' : props.width ? props.width : underlinelength};
    background: ${props => props.color};
    margin: ${props => props.underlinemargin};
  }
  ${media.bigdesktop`
  &:after {
    width: ${props => (props.countyUnderline ? '36rem' : underlinelength)}
  }
  `};
  ${media.desktop`   
    &:after {
      transform: translateX(0);
      width: ${({ smallunderlinewhensmall }) =>
        smallunderlinewhensmall ? underlinelength : '90%'};
      ${({ smallunderlinewhensmall, center, alignwhensmall, smallLeft }) =>
        smallLeft
          ? `left: ${smallLeft}`
          : smallunderlinewhensmall
          ? `
        left: ${center || alignwhensmall === 'center' ? `calc(50% - ${underlinelength} / 2)` : '0%'}
      `
          : `
        left: ${center || alignwhensmall === 'center' ? '5%' : '0%'};
      `}
    }
  `}
`;

BaseUnderlineContainer.propTypes = {
  centertext: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(Colors)),
  center: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired
};

/**
 * UnderlineContainer (div)
 */
export const UnderlineContainer = styled(BaseUnderlineContainer).attrs(
  ({
    position,
    margintop,
    center,
    centertext,
    color,
    margin: propsmargin,
    padding: propspadding,
    hide,
    small,
    smallunderlinewhensmall,
    alignwhensmall,
    countyUnderline,
    width
  }) => ({
    paddingbottom: position === 'bottom' ? (propspadding ? propspadding : padding) : 0,
    paddingtop: position === 'top' ? (propspadding ? propspadding : padding) : 0,
    paddingleft: position === 'left' ? (propspadding ? propspadding : padding) : 0,
    paddingright: position === 'right' ? (propspadding ? propspadding : padding) : 0,
    marginbottom: position === 'bottom' ? margin : 0,
    margintop: position === 'top' ? (margintop ? margintop : margin) : '1.2rem',
    marginleft: position === 'left' ? margin : 0,
    marginright: position === 'right' ? margin : 0,
    bottom: position === 'top' ? '100%' : 0,
    top: position === 'top' ? '0' : '100%',
    textalign: centertext ? 'center' : 'left',
    color: color || Colors.BLACK,
    margin: propsmargin ? propsmargin : undefined,
    padding: propspadding ? propspadding : undefined,
    opacity: hide ? 0 : 1,
    fontSize: small ? '11px' : 'inherit',
    smallunderlinewhensmall: smallunderlinewhensmall,
    alignwhensmall: alignwhensmall,
    center: center,
    countyUnderline: countyUnderline,
    width: width
  })
)`
  font-family: ${fonts.LLBrown.REGULAR};
  position: relative;
  padding: ${({ paddingtop, paddingright, paddingbottom, paddingleft }) =>
    `${paddingtop} ${paddingright} ${paddingbottom} ${paddingleft}`};

  margin: ${({ margintop, marginright, marginbottom, marginleft, countyUnderline }) =>
    countyUnderline
      ? `0 0 1rem 30vw`
      : `${margintop} ${marginright} ${marginbottom} ${marginleft}`};
  text-align: ${props => props.textalign};
  min-width: ${underlinelength};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.padding && `padding: ${props.padding}`};
  opacity: ${props => props.opacity};
  font-size: ${props => props.fontSize};
  ${media.huge`

  margin: ${({ margintop, marginright, marginbottom, marginleft, countyUnderline }) =>
    countyUnderline
      ? `0 0 1rem 9rem`
      : `${margintop} ${marginright} ${marginbottom} ${marginleft}`};
  ${props => props.margin && `margin: ${props.margin}`};
  
  `};
  ${media.bigdesktop`

    margin-left: ${(props, marginleft) => (props.countyUnderline ? '7.58rem' : marginleft)}
    margin-top: ${(props, margintop) => (props.countyUnderline ? 0 : margintop)}
    margin-bottom: ${(props, marginbottom) => (props.countyUnderline ? '1rem' : marginbottom)}
  ${props => props.margin && `margin: ${props.margin}`};

  `};
  ${media.desktop`
    text-align: ${({ alignwhensmall, textalign }) => (alignwhensmall ? alignwhensmall : textalign)}
    width: ${props => (props.width ? props.width : `100%`)};
  ${props => props.margin && `margin: ${props.margin}`};

  `}
`;

UnderlineContainer.propTypes = {
  centertext: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(Colors)),
  center: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
};

//    ${props => props.smallLeft ? `left: ${props.smallLeft}` : `` }
