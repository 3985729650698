const push = (event = '', additionalKeys = {}) => {
  const payload = {
    event,
    ...additionalKeys
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(payload);
};

export default push;
