import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components/macro';

const LocalDropdownIndicator = styled(components.DropdownIndicator)`
  svg {
    transition: 0.2s ease all;
  }
  ${({ menuIsOpen }) =>
    menuIsOpen &&
    css`
      svg {
        transform: rotateX(180deg);
      }
    `}
`;

export const DropdownIndicator = props => {
  const { menuIsOpen } = props.selectProps;
  return <LocalDropdownIndicator {...props} menuIsOpen={menuIsOpen} />;
};
