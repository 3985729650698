import queryString from 'query-string';
import { LocalStorage } from 'utils';
const Storage = new LocalStorage();
const COOKIE_KEY = 'SUPPORTS_WEB_P';

const supportsWebp = () => {
  const doSupportWebP = Storage.getJSON(COOKIE_KEY);
  if (doSupportWebP !== undefined) {
    return doSupportWebP;
  }
  const canvas = typeof document === 'object' ? document.createElement('canvas') : {};
  canvas.width = canvas.height = 1;
  const result = canvas.toDataURL
    ? canvas.toDataURL('image/webp').indexOf('image/webp') === 5
    : false;
  Storage.set(COOKIE_KEY, result);

  return result;
};

const getFormat = imageUrl => {
  if (supportsWebp()) {
    return {
      format: 'webp'
    };
  }

  return {};
};

const centerCrop = {
  mode: 'crop',
  center: '0.50,0.50'
};

const standardQuality = {
  quality: 75
};

export const fillElement = (imageUrl, element) => {
  if (!element || !imageUrl)
    return `${imageUrl}?${queryString.stringify({
      ...getFormat(),
      ...centerCrop,
      ...standardQuality
    })}`;

  if (imageUrl.endsWith('.svg')) return imageUrl;

  const { width, height } = element.getBoundingClientRect();

  return `${imageUrl}?${queryString.stringify({
    ...standardQuality,
    ...getFormat(),
    ...centerCrop,
    width: width,
    height: height
  })}`;
};

export const uberSmall = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;
  return `${imageUrl}?${queryString.stringify({
    quality: 20,
    width: 150,
    sigma: 1.5,
    threshold: 10,
    ...centerCrop,
    ...getFormat()
  })}`;
};

export const threeInRowImage = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;

  return `${imageUrl}?${queryString.stringify({
    width: 406,
    height: 228,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const relatedMaterialImage = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;

  return `${imageUrl}?${queryString.stringify({
    width: 415,
    height: 276,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const relatedMaterialMultipleImage = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;

  return `${imageUrl}?${queryString.stringify({
    width: 330,
    heightratio: 0.68,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const portrait = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;

  return `${imageUrl}?${queryString.stringify({
    width: 140,
    height: 156,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const largePortrait = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;

  return `${imageUrl}?${queryString.stringify({
    width: 280,
    height: 312,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const thumbnail = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;
  return `${imageUrl}?${queryString.stringify({
    width: 350,
    height: 350,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const related = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;
  return `${imageUrl}?${queryString.stringify({
    // height: 185,
    // width: 170,
    ...centerCrop,
    ...standardQuality,
    ...getFormat()
  })}`;
};

export const reFormat = imageUrl => {
  if (!imageUrl) return;
  if (imageUrl.endsWith('.svg')) return imageUrl;
  return `${imageUrl}?${queryString.stringify({
    ...standardQuality,
    ...getFormat()
  })}`;
};
