import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 700;
export default class TransitionFromRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inState: true };
  }

  componentDidUpdate(prevProps) {
    if (this.props.in !== prevProps.in) {
      this.setState({ inState: this.props.in });
    }
  }

  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    node.style.height = 'auto';
    anime({
      targets: node,
      translateX: ['-1rem', 0],
      opacity: [0.8, 1],
      duration: duration,
      delay: (el, i, l) => {
        return this.props.index * 100;
      },
      elasticity: 100,
      easing: 'easeOutElastic'
    });
  };

  onEntering = (node, isAppearing, index) => {};

  onEntered = (node, isAppearing, index) => {};

  setHeight = ({ node, height = 0, duration = 0 }) => {
    anime({
      targets: node,
      duration: duration,
      height: height,
      elasticity: 0,
      easing: 'easeInOutCirc'
    });
  };

  onExit = (node, index) => {
    if (!node) return null;
    anime({
      targets: node,
      opacity: [1, 0],
      duration: duration,
      easing: 'easeOutElastic',
      complete: this.setHeight({ node, duration: 600 })
    });
  };

  onExiting = (node, index) => {};
  onExited = node => {};

  render() {
    const { children, transitionkey } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={this.state.inState}
        timeout={{ enter: duration, exit: duration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        mountOnEnter={true}
        unmountOnExit={false}>
        {React.cloneElement(children)}
      </Transition>
    );
  }
}
TransitionFromRight.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.element
};
