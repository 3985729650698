import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

const duration = 700;
export default class FlipTransition extends React.Component {
  onEnter = (node, isAppearing, index) => {
    if (!node) return null;
    anime({
      targets: node,
      opacity: [0.8, 1],
      rotateX: [-1, 0],
      translateY: [25, 0],
      duration: duration,
      easing: 'easeOutElastic',
      delay: () => anime.random(0, 100),
      elasticity: 100
    });
  };

  onExit = (node, index) => {
    if (!node) return null;

    const { height } = node.getBoundingClientRect();
    node.style.overflow = 'hidden';
    anime({
      targets: node,
      translateX: [0, 20],
      opacity: [1, 0],
      height: [`${height}px`, 0],
      duration: duration / 2,
      delay: () => anime.random(0, 100),
      easing: 'easeOutElastic'
    });
  };

  render() {
    const { children, transitionkey, in: inProp } = this.props;
    return (
      <Transition
        appear={true}
        enter={true}
        exit={true}
        in={inProp}
        timeout={{ enter: duration, exit: duration }}
        key={transitionkey}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        mountOnEnter={true}
        unmountOnExit={false}>
        {children}
      </Transition>
    );
  }
}
FlipTransition.propTypes = {
  transitionkey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  children: PropTypes.element
};
