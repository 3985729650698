import styled from 'styled-components/macro';
import { media } from 'style/media';

export const JobListBlockImageContainerLarge = styled.div`
  max-width: 30%;

  ${media.desktop`
    max-width: none;
  `};
`;
