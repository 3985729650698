import styled from 'styled-components/macro';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { zIndex } from '../../constants/settings';
import { UnstyledExternalLink } from 'components/link';
import PropTypes from 'prop-types';
/**
 * RelatedItemLink (NavLink)
 */
const BaseRelatedItemLink = styled.img`
  flex: 1;
  width: 3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.LEVEL_1};
  left: 0;
`;

const StyledRelatedItemLink = BaseRelatedItemLink.withComponent(NavLink);
const StyledExternalRelatedItemLink = BaseRelatedItemLink.withComponent(UnstyledExternalLink);

export const RelatedItemLink = ({ external, to, ...props }) => {
  if (external) {
    return <StyledExternalRelatedItemLink href={to} {...props} />;
  }
  return <StyledRelatedItemLink to={to} {...props} />;
};

RelatedItemLink.propTypes = {
  target: PropTypes.oneOf(['_blank', '_top', '_self']),
  to: PropTypes.string.isRequired
};
