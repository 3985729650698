import React from 'react';
import { SearchResultItem } from 'components/searchresultitem/SearchResultItem';
import { BlockHeader } from '../components';
import { ContractCollectionItemContainer } from './components/ContractCollectionItemContainer';
import { ContractBlockContainer } from './components/ContractBlockContainer';

const ContractBlock = ({ header, contractCollection }) => {
  return (
    <>
      <ContractBlockContainer>
        <BlockHeader>{header}</BlockHeader>
        <ContractCollectionItemContainer>
          {contractCollection &&
            contractCollection.length &&
            contractCollection.map(item => <SearchResultItem simpleVersion {...item} />)}
        </ContractCollectionItemContainer>
      </ContractBlockContainer>
    </>
  );
};
export default ContractBlock;
