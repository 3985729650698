import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { zIndex } from 'constants/settings';
import { Paragraph } from 'components/text'

const CountyList = styled.ul`
  position: relative;
  top: 0;
  z-index: ${zIndex.LEVEL_4};
  list-style: none;
  list-style-type: none;
  padding: 0 0 1rem 30vw;
  margin: -1px 0; //negative margin to hinder visual bug
  display: flex;
  flex-wrap: wrap;
  background-color: ${Colors.TRANSPARENT};
  flex-direction: column;
  max-height: 7rem;
  max-width: 1000px;
  width: 100%;
  height: 70%;
  min-height: 70%;
${media.huge`
  padding: 0 0 1rem 10.1rem;
  max-width: 700px;
`};
  ${media.bigdesktop`
    padding: 0 0 1rem 8rem;
  `};
  ${media.desktop`
    max-height: 35rem;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
  `};
`;

export const CountyListBelow = styled(CountyList)`
  padding: 0 0 3rem 30vw;
  max-width: none;
  max-height: 0;
  min-height: 0;
  height: auto;
  margin: 0;
  padding-bottom: 3rem;
  ${media.huge`
  padding: 0 0 3rem 10.1rem;
`};
  ${media.bigdesktop`
    padding: 0 0 3rem 8rem;
  `};
`;

export const CountyListTitle = styled(Paragraph).attrs(props => ({
  centerTextSmall: true,
  orange: props.orange,
  black: props.black,
  paddingtop: props.paddingtop,
  paddingbottom: props.paddingbottom,
  small: props.small ? '0.7rem' : '0.9rem'
}))`
  font-size: ${props => props.small};
  padding: 0 0 0 30vw;
  margin: 0;
  position: relative;
  background-color: ${Colors.TRANSPARENT};
  ${({ paddingtop }) => paddingtop && `padding-top: ${paddingtop}`};
  ${({ paddingbottom }) => paddingbottom && `padding-bottom: ${paddingbottom}`};
  ${({ orange }) =>
    orange &&
    `
    color: ${Colors.ORANGE};
  `}
   ${({ black }) =>
    black &&
    `
    color: ${Colors.BLACK};
  `}
  ${media.huge`
    padding: 0 0 0 10rem;
  `};
  ${media.bigdesktop`
    padding: 0 0 0 8rem;
  `};
  ${media.desktop`
    padding: 1.3rem 3.4rem;
  `}
`;

export const CountyListSmallText = styled(Paragraph)`
position: absolute;
margin: 1.1rem 0 0.3em 0.9rem;
font-size: 0.6rem;
${media.desktop`
  display: none;
`};
`;

export const CountyListTitleMobile = styled(CountyListTitle).attrs(props => ({
  mobileTitle: props.mobileTitle ? '1.2rem' : '0.9rem'
}))`
  ${media.desktop`
  text-align: left;
  padding-left: 2.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  top: -0.5rem;
  font-size: ${props => props.mobileTitle};
`}
`;

export default CountyList;
