import React, { Component } from 'react';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';

/* "preamble" is now full "texteditor", "tinymceContent" is "heading" but only for side card. "flip" is alterante layout for side card.  */

class SideCard extends Component {
    // Because text imported into texteditor gets no markup.
    wrapInParagraph(str) {
        if (str.includes('<')) {
            return str;
        } else {
            return `<p>${str}</p>`;
        }
    }
    render() {
        const { tinymceContent, imgURL, texteditor, buttonURL, ButtonText, flip } = this.props;
        const processedText = this.wrapInParagraph(texteditor);

        if (
            buttonURL &&
            buttonURL !== 'null' &&
            buttonURL !== 'null#formLink' &&
            buttonURL !== 'undefined' &&
            buttonURL !== 'undefined#formLink'
        )
            return (
                <a href={buttonURL} className="feature-side-card">
                    {flip && tinymceContent && (
                        <div className="tinymce">
                            <h4>{tinymceContent}</h4>
                        </div>
                    )}
                    {imgURL && <img className="feature-side-card__img" src={imgURL} alt="Bild till kort." />}
                    {!flip && tinymceContent && (
                        <div className="tinymce">
                            <h4>{tinymceContent}</h4>
                        </div>
                    )}
                    {texteditor && (
                        <HtmlComponentContainer
                            smallmargin="0rem 0rem 1rem 0rem"
                            margin="1rem 0rem 1rem 0rem">
                            <HtmlComponent html={processedText} />
                        </HtmlComponentContainer>
                    )}
                    {ButtonText && (
                        <div className="feature-side-card__button button button--primary button--full-width">
                            {ButtonText}
                        </div>
                    )}
                </a>
            );

        return (
            <div className="feature-side-card">
                {flip && tinymceContent && (
                    <div className="tinymce">
                        <h4>{tinymceContent}</h4>
                    </div>
                )}
                {imgURL && <img className="feature-side-card__img" src={imgURL} alt="Bild till kort." />}
                {!flip && tinymceContent && (
                    <div className="tinymce">
                        <h4>{tinymceContent}</h4>
                    </div>
                )}
                {texteditor && <HtmlComponentContainer
                    smallmargin="0rem 0rem 1rem 0rem"
                    margin="1rem 0rem 1rem 0rem">
                    <HtmlComponent html={processedText} />
                </HtmlComponentContainer>}
            </div>
        );
    }
}

export default SideCard;

