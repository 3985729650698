import React from 'react';
import { RentCalculationBlockWrapper } from './components/RentCalculationBlockWrapper';
import { RentCalculationBlockInputWrapper } from './components/RentCalculationBlockInputWrapper';
import { HtmlComponent } from 'features/HtmlComponent';
import FormulaComponents from './components/FormulaComponents';
import { calculateRent } from './components/RentCalculation';
import {
  RentCalculationBlockInputContainer,
  RentCalculationBlockInputLabel,
  RentCalculationBlockInput,
  RentCalculationBlockValidation,
  RentCalculationBlockToolTipIcon,
  RentCalculationBlockToolTipBox
} from './components/RentCalculationBlockInput';

class RentCalculationBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentAmount: '',
      baseRent: '',
      firstYear: '',
      baseNumber: '',
      firstRecount: '',
      specifiedIndex: '',
      octoberIndexArea: '',
      rentAmountShowValidation: false,
      baseRentShowValidation: false,
      firstYearShowValidation: false,
      baseNumberShowValidation: false,
      firstRecountShowValidation: false,
      specifiedIndexShowValidation: false,
      regexp: /^[0-9\b]+$/,
      toolTipList: [],
      yearList: []
    };
  }

  componentDidMount() {
    const {
      rentAmountInfo,
      baseRentInfo,
      firstYearInfo,
      baseNumberInfo,
      firstRecountInfo,
      specifiedIndexInfo
    } = this.props;
    this.clearTable();
    this.setState({
      toolTipList: [
        { text: rentAmountInfo, index: 0, show: false },
        { text: baseRentInfo, index: 1, show: false },
        { text: firstYearInfo, index: 2, show: false },
        { text: baseNumberInfo, index: 3, show: false },
        { text: firstRecountInfo, index: 4, show: false },
        { text: specifiedIndexInfo, index: 5, show: false }
      ],
      octoberIndexArea: this.props.octoberIndexArea
    });
  }

  validateInputValue = (evt, stateName) => {
    const value = evt.target.value.replace(/\s+/g, '');
    if (value === '' || this.state.regexp.test(value)) {
      this.setState({ [stateName]: value, [stateName + 'ShowValidation']: false });
    } else {
      this.setState({
        [stateName + 'ShowValidation']: true,
        [stateName]: value
      });
    }
  };

  toggleTooltip = index => {
    const newList = this.state.toolTipList.map(i => {
      if (i.index === index) i.show = !i.show;
      return i;
    });
    this.setState({ toolTipList: newList });
  };

  toolTipKeyPress = (e, index) => {
    if (e.keyCode === 13) this.toggleTooltip(index);
  };

  setYearList = () => {
    const input = {
      yearList: this.state.yearList,
      baseNumber: this.state.baseNumber === '' ? 0 : this.state.baseNumber,
      firstYear: this.state.firstYear === '' ? 0 : this.state.firstYear,
      rentAmount: this.state.rentAmount === '' ? 0 : this.state.rentAmount,
      baseRent: this.state.baseRent === '' ? 0 : this.state.baseRent,
      specifiedIndex: this.state.specifiedIndex === '' ? 0 : this.state.specifiedIndex,
      firstRecount: this.state.firstRecount === '' ? 0 : this.state.firstRecount
    };
    const newList = calculateRent(input);
    this.setState({ yearList: newList });
    return newList;
  };

  clearTable = () => {
    const defaultList = this.props.octoberIndexArea.map(i => {
      return {
        year: i.year,
        octIndex: i.octIndex,
        c: 0,
        d: 0,
        t: 0,
        u: 0,
        v: 0,
        w: 0,
        x: 0,
        y: 0,
        z: 0,
        aa: 0
      };
    });
    this.clearFields();
    this.setState({ yearList: defaultList });
  };

  clearFields = () => {
    this.setState({
      rentAmount: '',
      baseRent: '',
      firstYear: '',
      baseNumber: '',
      firstRecount: '',
      specifiedIndex: '',
      rentAmountShowValidation: false,
      baseRentShowValidation: false,
      firstYearShowValidation: false,
      baseNumberShowValidation: false,
      firstRecountShowValidation: false,
      specifiedIndexShowValidation: false
    });
  };

  inputKeyPressEvent = e => {
    if (e.keyCode === 13) {
      this.setYearList();
    }
  };

  render() {
    const {
      mainBody,
      rentAmount,
      baseRent,
      firstYear,
      baseNumber,
      //these two were removed per request from customer, if these were to be readded, just uncomment and add them to inputList
      firstRecount,
      // specifiedIndex,
      validationMessage
    } = this.props;
    const { toolTipList } = this.state;
    const inputList = [
      { rentAmount },
      { baseRent },
      { firstYear },
      { baseNumber },
      { firstRecount }
    ];
    const validateMessage = validationMessage
      ? validationMessage
      : 'Du måste ange beloppet i siffror.';
    if (!toolTipList.length) return null;
    return (
      <RentCalculationBlockWrapper>
        <HtmlComponent html={mainBody} />
        <RentCalculationBlockInputWrapper>
          {inputList.map((i, index) => {
            const itemValue = Object.values(i);
            const itemName = Object.keys(i);
            const validationName = itemName + 'ShowValidation';
            return (
              <RentCalculationBlockInputContainer
                showValidation={this.state[validationName]}
                key={index}>
                <RentCalculationBlockInputLabel htmlFor={itemName + '_' + index}>
                  {itemValue}
                  {toolTipList[index].text && (
                    <RentCalculationBlockToolTipIcon
                      tabIndex="0"
                      onMouseEnter={() => this.toggleTooltip(index)}
                      onMouseLeave={() => this.toggleTooltip(index)}
                      onKeyUp={e => this.toolTipKeyPress(e, index)}>
                      !
                    </RentCalculationBlockToolTipIcon>
                  )}
                  {toolTipList[index].show && (
                    <RentCalculationBlockToolTipBox onClick={() => this.toggleTooltip(index)}>
                      {toolTipList[index].text}
                    </RentCalculationBlockToolTipBox>
                  )}
                </RentCalculationBlockInputLabel>
                <RentCalculationBlockInput
                  value={this.state[itemName]}
                  id={itemName + '_' + index}
                  onChange={evt => this.validateInputValue(evt, itemName)}
                  validationBorder={this.state[validationName]}
                  onKeyUp={e => this.inputKeyPressEvent(e)}
                />
                {this.state[validationName] && (
                  <RentCalculationBlockValidation>{validateMessage}</RentCalculationBlockValidation>
                )}
              </RentCalculationBlockInputContainer>
            );
          })}
        </RentCalculationBlockInputWrapper>
        <FormulaComponents
          calculateRent={this.setYearList}
          yearList={this.state.yearList}
          clearTable={this.clearTable}
        />
      </RentCalculationBlockWrapper>
    );
  }
}

export default RentCalculationBlock;
