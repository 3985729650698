import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { setCurrentPage } from '../pageActions';
import { PageWrapper, PageContent } from '../components';
import { LinkList } from 'features/LinkList';
import DefaultLoadable from 'utils/Loadable';
import { settings } from 'constants/settings';
import { PAGES } from 'constants/pageTypes';
import { toggleModal } from 'features/CountyFilter/countyFilterActions';
import { getRouteByUrl } from '../app/appActions';

import {
  setMetaTags as utilsSetMetaTags,
  isElementInViewport,
  getMetaTagContent,
  rem2px
} from '../../utils';
import { isEmpty } from 'utils';

const StandardPage = DefaultLoadable(() =>
  import('../standardpage' /* webpackChunkName: "standardpage" */)
);

const SubMenuPage = DefaultLoadable(() =>
  import('../submenupage' /* webpackChunkName: "submenupage" */)
);

const ServicePage = DefaultLoadable(() =>
  import('../servicepage' /* webpackChunkName: "servicepage" */)
);

const EditorsManualPage = DefaultLoadable(() =>
  import('../editorsmanualpage' /* webpackChunkName: "editorsmanualpage" */)
);

const CourseListPage = DefaultLoadable(() =>
  import('../courselistpage' /* webpackChunkName: "courselistingpage" */)
);

const CourseContainerPage = DefaultLoadable(() =>
  import('../coursecontainerpage' /* webpackChunkName: "coursecontainerpage" */)
);

const JobAdPage = DefaultLoadable(() => import('../jobadpage' /* webpackChunkName: "jobadpage" */));

const UtbildningPage = DefaultLoadable(() =>
  import('../searchpage' /* webpackChunkName: "searchpage" */)
);

const BlockContainerPage = DefaultLoadable(() =>
  import('../blockcontainerpage' /* webpackChunkName: "searchpage" */)
);

const CalendarPage = DefaultLoadable(() =>
  import('../calendarpage' /* webpackChunkName: "calendarpage" */)
);

const TjanstePage = DefaultLoadable(() =>
  import('../tjanstepage' /* webpackChunkName: "tjanstepage" */)
);

const SearchPage = DefaultLoadable(() =>
  import('../searchpage' /* webpackChunkName: "searchpage" */)
);

const OfficePage = DefaultLoadable(() =>
  import('../officepage' /* webpackChunkName: "officepage" */)
);

const ContactOfficePage = DefaultLoadable(() =>
  import('../contactofficepage' /* webpackChunkName: "contactofficepage" */)
);

const StartPage = DefaultLoadable(() => import('../startpage' /* webpackChunkName: "startpage" */));

const WorkbusterPage = DefaultLoadable(() =>
  import('../workbusterpage' /* webpackChunkName: "workbusterpage" */)
);
const RemissPage = DefaultLoadable(() =>
  import('../remisspage' /* webpackChunkName: "remisspage" */)
);

const ContactPage = DefaultLoadable(() =>
  import('../contactpage' /* webpackChunkName: "contactpage" */)
);

const LockedPage = DefaultLoadable(() =>
  import('../lockedpage' /* webpackChunkName: "lockedpage" */)
);

const DocumentPage = DefaultLoadable(() =>
  import('../documentpage' /* webpackChunkName: "documentpage" */)
);

const NotFoundPage = DefaultLoadable(() =>
  import('../notfoundpage' /* webpackChunkName: "notfoundpage" */)
);

class BasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      prevPage: {}
    };
  }

  async componentDidMount() {
    // This cumbusome function was needed because React couldn't find the element to scroll to, likely due to multipe re-renders. This way it tries until it's loaded, up to 100 times.
    const hash = window.location.hash;
    const attemptScrollToHash = (retryCount = 0) => {
      if (hash) {
        const targetElement = document.getElementById(hash.slice(1));

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        } else if (retryCount < 100) {
          setTimeout(() => attemptScrollToHash(retryCount + 1), 100);
        }
      }
    };
    attemptScrollToHash();

    const { id, onLoadedComplete, scrollTo, setCurrentPage } = this.props;
    const page = await setCurrentPage(id);

    if (page && page.pageType === PAGES.XTRACTOR_PAGE) {
      const { url: redirectTo } = page;
      window.location.href = redirectTo;
      return;
    }

    if (onLoadedComplete) onLoadedComplete();
    this.setState({
      page: page,
      prevPage: page
    });

    const { metaTitle = '', metaDescription = '', metaKeyword = '', metaImage = undefined, image } =
      page || {};

    const logoUrl = getMetaTagContent(`property="logo-large"`);

    let backupMetaImage = image
      ? `${window.location.protocol}//${window.location.hostname}${image}`
      : logoUrl;
    utilsSetMetaTags(`property="og:description"`, metaDescription);
    utilsSetMetaTags(`property="og:keywords"`, metaKeyword);
    utilsSetMetaTags(`property="og:title"`, metaTitle);
    utilsSetMetaTags(`property="og:url"`, window.location.href);
    if (page.pageType !== PAGES.JOB_AD_PAGE)
      utilsSetMetaTags(`property="og:image"`, metaImage || backupMetaImage);

    const { anchor } = queryString.parse(window.location.search);

    if (anchor) {
      const element = document.getElementById(`${anchor}`);

      if (!element) return;
      const { top: scrollY } = element.getBoundingClientRect();
      window.scrollTo({
        behaviour: 'smooth',
        top: scrollY - rem2px(settings.MENU_HEIGHT)
      });
      return;
    }
    const element = document.getElementById(scrollTo);
    if (scrollTo && element && element.scrollIntoView) {
      if (!isElementInViewport(element)) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  async componentDidUpdate(prevProps) {
    const { isAuthenticated: prevIsAuthenticated } = prevProps;
    const { isAuthenticated, id, onLoadedComplete, setCurrentPage, pagetype } = this.props;
    const { page: currentPage } = this.state;
    if (prevIsAuthenticated !== isAuthenticated) {
      const page = await setCurrentPage(id);
      if (onLoadedComplete) onLoadedComplete();
      this.setState({ page: page, prevPage: currentPage });
    }
    let servicePageBlockTitle;
    let servicePageBlockDescription;
    if (pagetype === 'ServicePage' && currentPage.blockContentArea) {
      currentPage.blockContentArea.forEach(block => {
        if (!block.isFallback) {
          block.category.forEach(cat => {
            if (cat === this.props.selectedCountyFilter.id) {
              servicePageBlockTitle = block.serviceMetaTitle && block.serviceMetaTitle;
              servicePageBlockDescription =
                block.serviceMetaDescription && block.serviceMetaDescription;
            }
          });
        }
      });
      if (!servicePageBlockDescription && !servicePageBlockTitle) {
        currentPage.blockContentArea.forEach(block => {
          if (block.isFallback) {
            servicePageBlockTitle = block.serviceMetaTitle && block.serviceMetaTitle;
            servicePageBlockDescription =
              block.serviceMetaDescription && block.serviceMetaDescription;
          }
        });
      }
    }
    const { metaTitle = '', metaDescription = '' } = currentPage || {};

    document.title = servicePageBlockTitle ? servicePageBlockTitle : metaTitle;

    let newMetaDescription = servicePageBlockDescription
      ? servicePageBlockDescription
      : metaDescription;
    let newMetaTitle = servicePageBlockTitle ? servicePageBlockTitle : metaTitle;
    utilsSetMetaTags(`property="og:description"`, newMetaDescription);
    utilsSetMetaTags(`property="og:title"`, newMetaTitle);
  }

  getPage = (type, pageProps) => {
    if (!pageProps) return null;
    const { isAuthenticated } = this.props;
    const { listSubPages, onlyForAuthenticatedVisitors, isPreview, ...rest } = pageProps;

    if (!onlyForAuthenticatedVisitors) return this.getPageByType(type, pageProps);
    if (isAuthenticated) return this.getPageByType(type, pageProps);
    if (isPreview) return <StandardPage isPreview={isPreview} {...rest} />;
    return <LockedPage />;
  };

  listSubPages = pageProps => {
    if (!pageProps) return null;
    const { isAuthenticated } = this.props;
    const { listSubPages, onlyForAuthenticatedVisitors } = pageProps;

    if (!listSubPages) return null;
    if (onlyForAuthenticatedVisitors && !isAuthenticated) return null;
    return (
      <div>
        {pageProps.pageType !== PAGES.SERVICE_PAGE && (
          <PageContent className="PageContents">
            <LinkList data={listSubPages} />
          </PageContent>
        )}
      </div>
    );
  };

  getPageByType = (type, pageProps) => {
    if (!pageProps) return null;
    const { listSubPages, ...rest } = pageProps;
    const { pagetype, requestedUrl } = this.props;

    switch (type || pagetype) {
      case PAGES.STANDARD: {
        return <StandardPage {...rest} />;
      }
      case PAGES.NOT_FOUND_PAGE: {
        return <NotFoundPage {...rest} requestedUrl={requestedUrl} />;
      }
      case PAGES.SUB_MENU_PAGE: {
        return <SubMenuPage subPages={listSubPages} {...rest} />;
      }

      case PAGES.SERVICE_PAGE: {
        return <ServicePage subPages={listSubPages} {...rest} />;
      }
      case PAGES.START: {
        return <StartPage {...rest} />;
      }
      case PAGES.CALENDAR: {
        return <CalendarPage {...rest} />;
      }
      case PAGES.BLOCK_CONTAINER_PAGE: {
        return <BlockContainerPage {...rest} />;
      }
      case PAGES.TJANSTE: {
        return <TjanstePage {...rest} />;
      }
      case PAGES.REPORT: {
        return <StandardPage {...rest} />;
      }
      case PAGES.REMISS: {
        return <RemissPage {...rest} />;
      }
      case PAGES.UTBILDNING: {
        return <UtbildningPage {...rest} />;
      }
      case PAGES.SEARCH: {
        return <SearchPage {...rest} />;
      }
      case PAGES.WORKBUSTER: {
        return <WorkbusterPage {...rest} />;
      }
      case PAGES.EDITORS_MANUAL_PAGE: {
        return <EditorsManualPage {...rest} />;
      }
      case PAGES.CONTACT_PAGE: {
        return <ContactPage {...rest} />;
      }
      case PAGES.OFFICE_PAGE: {
        return <OfficePage {...rest} />;
      }
      case PAGES.CONTACT_OFFICE_PAGE: {
        return <ContactOfficePage {...rest} />;
      }
      case PAGES.COURSE_LIST_PAGE: {
        return <CourseListPage {...rest} />;
      }
      case PAGES.COURSE_CONTAINER_PAGE: {
        return <CourseContainerPage {...rest} />;
      }
      case PAGES.JOB_AD_PAGE: {
        return <JobAdPage {...rest} />;
      }
      case PAGES.DOCUMENT_PAGE: {
        return <DocumentPage {...rest} />;
      }
      default:
        break;
    }
  };

  render() {
    let { pagetype, id, width, useTopPadding, page: pageFromRedux, isModalOpen } = this.props;
    const { loading } = pageFromRedux;
    const { page, prevPage } = this.state;
    if (isEmpty(page) && isEmpty(prevPage)) return null;
    const pageToRender = loading ? prevPage : page;
    return pagetype !== PAGES.SUB_MENU_PAGE ? (
      <PageWrapper
        page={useTopPadding === false ? false : pagetype !== PAGES.START || useTopPadding}
        isModalOpen={isModalOpen}
        width={width}
        id={id}>
        {this.getPage(pagetype, pageToRender)}
        {this.listSubPages(pageToRender)}
      </PageWrapper>
    ) : (
      <PageWrapper
        page={useTopPadding === false ? false : pagetype !== PAGES.START || useTopPadding}
        width={width}
        id={id}>
        {this.getPage(pagetype, pageToRender)}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ page, filter, app, account, router: { location } }) => ({
  page: {
    loading: page.loading,
    current: page.page,
    id: page.currentId
  },
  isModalOpen: filter.isModalOpen,
  selectedCountyFilter: filter.selected,
  rootCountyId: app.settings.rootCounty,
  isAuthenticated: account.isAuthenticated,
  pageNotFound: app.pageNotFound,
  location: location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setCurrentPage, toggleModal, getRouteByUrl }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);

BasePage.defaultProps = {
  toggleModal: () => {}
};

BasePage.propTypes = {
  id: PropTypes.number,
  //Should we add extra top margin?
  first: PropTypes.bool,
  // milliseconds to wait before changing the page
  delay: PropTypes.number,
  pagetype: PropTypes.oneOf(Object.values(PAGES)),
  onLoadedComplete: PropTypes.func,
  noscroll: PropTypes.bool
};
