import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import DOMHelper from '../../utils/DOMHelper';
import fonts from 'style/fonts/fonts';
import { zIndex } from 'constants/settings';

const Placeholder = styled.div`
  background-color: #eee;
  background-image: linear-gradient(
      45deg,
      #f9841242 25%,
      transparent 25%,
      transparent 75%,
      #f9841242 75%,
      #f9841242
    ),
    linear-gradient(
      -45deg,
      #f9841242 25%,
      transparent 25%,
      transparent 75%,
      #f9841242 75%,
      #f9841242
    );
  min-height: 2rem;
  background-size: 1em 1em;
  font-family: ${fonts.LLBrown.REGULAR};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-style: italic;
`;

const StyledOPE = styled.div.attrs(props => ({
  name: props.name ? props.name : ''
}))`
  display: block;

  &:before {
    z-index: ${zIndex.ABOVE_MODALS};
    transition: opacity .3s ease-in-out;
    font-family: ${fonts.APERCU.MONO};
    opacity: 0;
    content: "${props => props.name}";
    position: absolute;
    transform: translateY(-1.4rem);
    font-size: 1.2em;
    background: orange;
    padding: 0 .5em;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &:hover {
    &:before {
    }
  }
`;
/**
 * EpiContainer used to enable on page editing
 */
class OPE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastChangedContentData: {}
    };

    this.interval = null;
    this.className = 'has-been-clicked';
  }

  componentDidUpdate = () => {
    // window.epi.publish('beta/domUpdated');
  };

  componentDidMount = () => {
    this.interval = setInterval(this.uglyliReinitializeDojo, 500);

    // window.epi.subscribe('beta/contentSaved', contentData => {
    //   const { successful } = contentData;
    //   if (!successful) return;
    //   console.log(contentData);
    //   this.setState({ lastChangedContentData: contentData });
    //   //window.epi.publish("beta/domUpdated")
    // });
  };
  uglyliReinitializeDojo = () => {
    const selectedInPageTree = window.parent.document.querySelectorAll(
      "[data-dojo-attach-point='rowNode'].dijitTreeRow.dijitInline.dijitTreeRowSelected"
    );
    if (selectedInPageTree && selectedInPageTree.length) {
      const selected = selectedInPageTree[0];
      const hasBeenClicked = selected.classList.contains(this.className);
      if (hasBeenClicked) {
        clearInterval(this.interval);
        return;
      }
      selected.click();
      selected.classList.add(this.className);
    }
  };

  render() {
    const { propertyName, children } = this.props;

    const inEpiMode = DOMHelper.inEpiEditMode();
    if (!inEpiMode) return children || null;
    let items = React.Children.map(children, child => {
      if (!child) return null;
      return React.cloneElement(child);
    });

    return (
      <StyledOPE
        data-epi-property-name={propertyName}
        data-epi-property-render="none"
        data-epi-property-edittype={'floating'}>
        {!children && (
          <Placeholder className="OPE-Placeholder">{`Egenskapen är för närvarande tom.`}</Placeholder>
        )}
        {/* {this.state.lastChangedContentData.properties[0].value} */}
        {items}
      </StyledOPE>
    );
  }
}

export default OPE;

OPE.propTypes = {
  propertyName: PropTypes.string.isRequired,
  floating: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.any
  ])
};
