export const colors = {
  BROWN: '#A2999E',
  BACKGROUND: '#C6C7C4',
  TEXT_GRAY: '#454545',
  GRAY: '#f7f8f9',
  SLIGHTLY_GARY: '#868686',
  DARK_GAY: '#e3e3e3',
  LIGHTER_GRAY: '#d1d1d1',
  MEDIUM_GRAY: '#e4e4e4',
  LIGHT_GRAY: '#979797',
  QUOTE_GRAY: '#27251f',
  DARKER_GRAY: '#353B46',
  GRAYISH_BROWN: '#484848',
  BORDER: '#d0d2d5',
  BLACKER_GRAY: '#3b3b3b',
  TOTALLY_BLACK: '#000',
  BLACK: '#2f2f2f',
  TRANSAPRENT_BLACK: '#000000ab',
  BRAND: '#006892',
  BRAND_DARK: '#006e86',
  BLUE: '#0466d6',
  ERROR: '#f98412', //old error, red, was #f6554a
  MAIN_BODY: '#444444',
  MAIN_INTRO: '#4a4a4a',
  BRAND_LIGHT: '#008087',
  COOKIE_BACKGROUND: '#F7F8F9',
  COOKIE_BORDER: '#D6D6D6',
  SWITCH_OFF_BG: '#F7F7F7',
  SWITCH_OFF_HANDLE: '#717171',
  WHITE: '#FFF',
  TRANSPARENT_WHITE: `rgba(255,255,255,0.90)`,
  LESS_TRANSPARENT_WHITE: `rgba(255,255,255,0.98)`,
  ORANGE: '#f98412',
  TRANSPARENT_ORANGE: 'rgba(249, 132, 18, 0.7)',
  MORE_TRANSPARENT_ORANGE: 'rgba(249, 132, 18, 0.6)',
  SUPER_TRANSPARENT_ORANGE: 'rgba(249, 132, 18, 0.2)',
  TRANSPARENT: 'transparent',
  TRANSPARENT_GRAY: 'rgba(231, 235, 239, 0.35)',
  TRANSPARENT_DARK_GRAY: 'rgba(231, 235, 239, 0.9)',
  TRANSPARENT_LIGHT_GRAY: 'rgba(216, 216, 216, 0.25)',
  VALIDATION_ERROR: '#af0000',
  FOOTER_LINK_GRAY: '#ababab',
  TOOLTIP_ORANGE: '#fc8600',
  RENT_TABLE_GRAY: '#e8e8e8',
  RENT_TABLE_OVERLAY_ORANGE: 'rgba(252, 134, 0, 0.15)'
};

export const LightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};
