import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { zIndex } from 'constants/settings';
import PropTypes from 'prop-types';
import fonts from 'style/fonts/fonts';
import { media, targetBrowser } from 'style/media';

const InitialCountyFilterListContainer = styled.div`
  opacity: 0;
  left: 50%;
  transform: rotateX(-90deg) translateX(-50%);
  transform-origin: 50% top;
`;

/**
 * FilterBlockHeader (div)
 */
export const CountyFilterListContainer = styled(InitialCountyFilterListContainer).attrs(props => ({
  left: props.isPuffBlockFilter ? '0' : '50%'
  // top: props.isPuffBlockFilter ? '1.5rem' : '0'
}))`
  font-family: ${fonts.LLBrown.REGULAR};
  height: auto;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: ${zIndex.FILTER};
  width: 37rem;
  position: absolute;
  max-width: 90vw;
  max-height: 20rem;
  display: flex;
  left: ${props => props.left};

  flex-direction: row;
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
  ${media.desktop`
    max-height: 33rem;
  `};
`;

CountyFilterListContainer.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)),
  active: PropTypes.bool
};
