import React from 'react';
import { Rectangle } from 'components/containers';
import { NeedToLoginText } from 'components/text';
import { Lock, NeedToLoginWrapper } from '../components';
import { Link } from 'components/link';
import { connect } from 'react-redux';
import { RectangleWrapper } from '../../../components/containers';

class NeedToLoginComponent extends React.Component {
  render() {
    const { app, authTexts } = this.props;
    const { loginBoxHeader, loginBoxLinkText } = authTexts;
    const { urlLogin = '' } = app;
    const header = loginBoxHeader ? loginBoxHeader : "Du behöver logga in för att läsa vidare";
    const linkText = loginBoxLinkText ? loginBoxLinkText : "Klicka här för att logga in";

    return (
      <RectangleWrapper>
        <Rectangle>
          <Lock size='2.5rem' />
          <NeedToLoginWrapper>
            <NeedToLoginText>{header}</NeedToLoginText>
            <NeedToLoginText>
              <Link external href={urlLogin}>
                {linkText}
              </Link>
            </NeedToLoginText>
          </NeedToLoginWrapper>
        </Rectangle>
      </RectangleWrapper>
    );
  };
} 

const MapStateToProps = ({ app }) => ({ 
  app: {
    urlLogin: app.oidcRoutes.urlLogin,
  },
  authTexts: {
    loginBoxHeader: app.authTexts.loginBoxHeader,
    loginBoxLinkText: app.authTexts.loginBoxLinkText,
  }
});

export default connect(MapStateToProps)(NeedToLoginComponent);