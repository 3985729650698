import React from 'react';
import PropTypes from 'prop-types';
import {
  PuffListBlockItemContainer,
  PuffListBlockItemBottom,
  PuffListBlockItemBottomHeader,
  PuffListBlockItemBottomText,
  PuffListBlockItemImage
} from './index';

class PuffListBlockItem extends React.Component {
  constructor(props) {
    super(props);

    this.container = null;
    this.imageelement = null;
  }

  getHeight() {
    if (!this.container) return null;
  }

  truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  render() {
    const { imageUrl, ingress, text, linkUrl } = this.props;
    return (
      <PuffListBlockItemContainer to={linkUrl} nounderline>
        <PuffListBlockItemImage url={imageUrl} />
        <PuffListBlockItemBottom>
          <PuffListBlockItemBottomText>
            <PuffListBlockItemBottomHeader fontSizeSmall={'1.2rem'}>
              {text + ' '}
            </PuffListBlockItemBottomHeader>

            {this.truncateString(ingress, 80)}
          </PuffListBlockItemBottomText>
        </PuffListBlockItemBottom>
      </PuffListBlockItemContainer>
    );
  }
}

export default PuffListBlockItem;

PuffListBlockItem.propTypes = {
  imageUrl: PropTypes.string,
  ingress: PropTypes.string,
  name: PropTypes.string.isRequired,
  tag: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['dokument', 'pagelink', 'image']),
  fileType: PropTypes.string
};
