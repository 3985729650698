import React, { useState } from 'react';
import {
  PuffListOuterContainer,
  PuffListHeader,
  PuffListInnerContainer,
  ArrowUp,
  ArrowDown,
  PuffListInnerHeader,
  CountyFilterPuffListContainer
} from './components/CountyFilterPuffListComponents';

export const CountyFilterPuffList = ({ categoryList, onItemSelect, selected }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = county => {
    onItemSelect(county);
    setIsOpen(false);
  };

  return (
    <CountyFilterPuffListContainer>
      <PuffListOuterContainer border onClick={() => togglePanel()}>
        <PuffListInnerContainer>
          <PuffListHeader>Välj län</PuffListHeader>
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </PuffListInnerContainer>
      </PuffListOuterContainer>
      {isOpen &&
        categoryList.map((county, index)=> {
          return (
            <PuffListOuterContainer>
              <PuffListInnerContainer>
                <PuffListInnerHeader
                  key={index}
                  onClick={() => handleSelect(county)}
                  selected={county.id === selected.id}>
                  {county.name}
                </PuffListInnerHeader>
              </PuffListInnerContainer>
            </PuffListOuterContainer>
          );
        })}
    </CountyFilterPuffListContainer>
  );
};

export default CountyFilterPuffList;
