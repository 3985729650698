import styled, { keyframes } from 'styled-components/macro';
import BaseAnimation from './BaseAnimation';

const SlideInCenterAnimation = keyframes`
  0% {
    transform: translateZ(-2em);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
`;

const SlideInTopAnimation = keyframes`
  0% {
    transform: translateZ(-2em) translateY(-2em);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
`;

const SlideInLeftAnimation = keyframes`
  0% {
    transform: translateX(-2rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const BounceInKeyframes = keyframes`

  0% {
    transform: translateY(-15px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-11px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-2px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-1px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }

`;

export const SlideInLeft = styled(BaseAnimation)`
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-name: ${SlideInLeftAnimation};
`;

export const SlideInCenter = styled(BaseAnimation)`
  -webkit-font-smoothing: antialiased;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-name: ${SlideInCenterAnimation};
`;

export const SlideInTop = styled(BaseAnimation)`
  -webkit-font-smoothing: antialiased;
  animation-name: ${SlideInTopAnimation};
`;

export const BounceIn = styled(BaseAnimation)`
  -webkit-font-smoothing: antialiased;
  animation-name: ${BounceInKeyframes};
`;
