import HeroBlock from '../blocks/heroblock';
import ListBlock from '../blocks/listblock';
import PuffListBlock from '../blocks/pufflistblock';
import CalendarListBlock from '../blocks/calendarlistblock';
import PrioritizedArticleBlock from '../blocks/prioritizedarticleblock';
import ActivityBlock from '../blocks/activityblock';
import LinkTextBlock from '../blocks/linktextblock';
import CarouselBlock from '../blocks/carouselblock';
import PageListBlock from '../blocks/pagelistblock';
import LinkListBlock from '../blocks/linklistblock';
import { FilterArea } from '../features/FilterArea';
import NewsBlock from '../blocks/newsblock';
import IconListBlock from '../blocks/iconlistblock';
import ContactListBlock from '../blocks/contactlistblock';
import ContactBlock from '../blocks/contactblock';
import CollapsibleContainerBlock from '../blocks/collapsiblecontainerblock';
import CollapsibleBlock from '../blocks/collapsibleblock';
import CustomAreaBlock from '../blocks/customareablock';
import ContractBlock from '../blocks/contractblock/ContractBlock';
import ContractCategoryBlock from '../blocks/contractcategoryblock';
import EpiForm from '../features/EpiForm';
import FilterBlock from '../blocks/filterblock';
import DualTextBlock from '../blocks/dualtextblock';
import TextBlock from '../blocks/textblock';
import CountyContentAreaBlock from '../blocks/countycontentareablock';
import RelatedMaterialBlock from '../blocks/relatedmaterialblock';
import CollapsibleDoubleBlock from '../blocks/collapsibledoubleblock';
import CookieBlock from '../blocks/cookieblock';
import CookieContainerBlock from '../blocks/cookieblock';
import RentCalculationBlock from '../blocks/rentcalculationblock';
import { JobListBlock } from '../blocks/joblistblock';
import { JobApplicationBlock } from '../blocks/jobapplicationblock';

export const BlockComponents = {
  CountyContentAreaBlock: CountyContentAreaBlock,
  HeroBlock: HeroBlock,
  ListBlock: ListBlock,
  CalendarListBlock: CalendarListBlock,
  PrioritizedArticleBlock: PrioritizedArticleBlock,
  ActivityBlock: ActivityBlock,
  LinkTextBlock: LinkTextBlock,
  CarouselBlock: CarouselBlock,
  PageListBlock: PageListBlock,
  CountyFilterBlock: FilterBlock,
  CollapsibleContainerBlock: CollapsibleContainerBlock,
  CollapsibleBlock: CollapsibleBlock,
  LinkListBlock: LinkListBlock,
  PuffListBlock: PuffListBlock,
  AreaFilterBlock: FilterArea,
  NewsBlock: NewsBlock,
  IconListBlock: IconListBlock,
  ContactListBlock: ContactListBlock,
  ContactBlock: ContactBlock,
  CookieContainerBlock: CookieContainerBlock,
  CookieBlock: CookieBlock,
  CustomAreaBlock: CustomAreaBlock,
  ContractBlock: ContractBlock,
  ContractCategoryBlock: ContractCategoryBlock,
  FormContainerBlock: EpiForm,
  ExtendedFormContainerBlock: EpiForm,
  DualTextBlock: DualTextBlock,
  TextBlock: TextBlock,
  RelatedMaterialBlock: RelatedMaterialBlock,
  CollapsibleDoubleBlock: CollapsibleDoubleBlock,
  RentCalculationBlock: RentCalculationBlock,
  JobListBlock: JobListBlock,
  JobApplicationBlock: JobApplicationBlock
};
