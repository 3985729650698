import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { Title2 } from 'components/text';
import { MediumText } from 'components/text';
import { Link } from 'components/link';

export const TextBlockHeading = styled(Title2)`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.4rem;
`;

export const CustomLink = styled(Link)`
  color: ${Colors.BLACK};
  text-decoration: none !important;
`;

export const LinkButton = styled.div`
  display: inline-block;
  padding: 0.55rem 1.2rem 0.55rem 1.2rem;
  background-color: ${Colors.WHITE};
  border: 2px solid black;
  border-color: ${Colors.ORANGE};

  &:hover {
    background-color: ${Colors.ORANGE};
  }
`;

export const LinkButtonText = styled(MediumText)`
  font-size: 0.6rem;
  text-decoration: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;
  color: black;
  font-weight: bold;
  border-radius: 4px;
  ${LinkButton}:hover & {
    color: white;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const Column = styled.div`
margin: ${({ left }) => left && 'auto 0'};
  flex: 50%;
  height: auto;
  width: 100%;
`;

// export const Media = styled.div.attrs(({ media }) => ({
//   media: media
// }))`
//   background-image: url('${props => props.media}');
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   max-width: 100%;
//   height: auto;
// `;

// export const MediaMobile = styled.div.attrs(({ media }) => ({
//   media: media
// }))`
//   background-image: url('${props => props.media}');
//     position: relative;
//     padding: 0;
//     height: auto;
//     max-width: 100%;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center center;
//     margin-top: 1rem;
// `;

export const PageContentBlock = styled.div`
  max-width: calc(38.25rem - 0.8333rem + 7.083rem);
  width: 100%;
  padding: 0 0.8333rem 0 0.8333rem;
  background: ${Colors.TRANSPARENT};
  position: relative;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1rem .5rem 0 .5rem;
`;

export const PageContentBlockOnlyText = styled.div`
  margin: 0 1rem 0 1rem;
`;