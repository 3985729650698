import styled from 'styled-components/macro';
import React from 'react';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import { media } from 'style/media';
import fonts from 'style/fonts/fonts';

const animationSpeed = '.24s';

const StyledTagItem = styled.li`
  list-style: none;
  display: flex;
`;

export const TagLabel = styled.label.attrs(props => ({
  background: props.selected ? Colors.ORANGE : Colors.DARK_GAY,
  color: props.selected ? Colors.WHITE : Colors.BLACK,
  hoverbackground: props =>
    props.selected ? Colors.ORANGE : LightenDarkenColor(Colors.DARK_GAY, 15)
}))`
  cursor: pointer;
  position: relative;
  background: ${props => props.background};
  color: ${props => props.color};
  font-family: ${fonts.APERCU.WEB};
  font-size: 0.9rem;
  padding: 0.3rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  transition: background ${animationSpeed} ease-out, color ${animationSpeed} ease-out;
  &:hover {
    background: ${props => props.hoverbackground};
  }
`;

const StyledTagInput = styled.input`
  display: none;
  &:checked ~ ${TagLabel} {
    background-color: ${Colors.ORANGE};
    color: ${Colors.WHITE};
  }
`;

export const PuffTagLabel = styled.label.attrs(props => ({
  background: props.selected ? Colors.ORANGE : Colors.WHITE,
  color: props.selected ? Colors.WHITE : Colors.BLACK
}))`
  cursor: pointer;
  position: relative;
  background: ${props => props.background};
  border: 1px solid ${Colors.ORANGE};
  color: ${props => props.color};
  font-family: ${fonts.APERCU.WEB};
  font-size: 16px;
  padding: 0.3rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  transition: background ${animationSpeed} ease-out, color ${animationSpeed} ease-out;
  &:hover {
    background: ${Colors.ORANGE};
    color: ${Colors.WHITE};
  }
  ${media.desktop`
    &:hover {
      background: ${Colors.WHITE};
    color: ${Colors.BLACK};

    }
  `};
`;

const StyledPuffTagInput = styled.input`
  display: none;
  &:checked ~ ${PuffTagLabel} {
    background-color: ${Colors.ORANGE};
    color: ${Colors.WHITE};
  }
`;

export const Tag = ({ name, value, selectCallback, selected, isPuffBlockFilter }) => {
  const id = `filter_checkbox_${value}`;

  const inputKeyPressEvent = e => {
    if (e.keyCode === 13) {
      selectCallback();
    }
  };
  return (
    <StyledTagItem>
      {isPuffBlockFilter ? (
        <>
          <StyledPuffTagInput
            onChange={e => null}
            checked={selected}
            type="checkbox"
            name={name}
            id={id}
            value={value}
          />
          <PuffTagLabel
            onClick={selectCallback}
            htmlFor={id}
            tabIndex={0}
            onKeyUp={e => inputKeyPressEvent(e)}>
            {name}
          </PuffTagLabel>
        </>
      ) : (
        <>
          <StyledTagInput
            onChange={e => null}
            checked={selected}
            type="checkbox"
            name={name}
            id={id}
            value={value}
          />
          <TagLabel onClick={selectCallback} htmlFor={id}>
            {name}
          </TagLabel>
        </>
      )}
    </StyledTagItem>
  );
};
