import React from 'react';
import PropTypes from 'prop-types';
import { ScrollToContainer, ScrollToButton } from './components';
import { settings } from 'constants/settings';
import { rem2px } from 'utils';
import { CollapsingArrow } from 'components/images/ArrowDown';

class ScrollTo extends React.PureComponent {
  static propTypes = {
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    element: PropTypes.element.isRequired,
    next: PropTypes.bool,
    scrollto: PropTypes.oneOf(['top', 'bottom'])
  };

  static defaultProps = {
    next: false,
    scrollto: 'top'
  };

  getElementToScrollTo = () => {
    const { element, next } = this.props;
    if (!element) return null;
    if (!next) return element;
    const nextElement = element.nextElementSibling;

    if (!nextElement) return element;
    return next ? nextElement : element;
  };

  handleOnClick = () => {
    const { scrollto } = this.props;
    const scrollToElement = this.getElementToScrollTo();
    if (!scrollToElement) return null;
    //TODO bryt ut denna. Samma funktion används på flera ställen
    const { top, bottom } = scrollToElement.getBoundingClientRect();
    const scrollY = scrollto === 'top' ? top : bottom;

    const windowOffset = window.scrollY || window.pageYOffset;
    const menuHeight = rem2px(settings.MENU_HEIGHT);

    window.scroll({
      top: windowOffset + scrollY - menuHeight,
      behavior: 'smooth'
    });
  };

  render() {
    const { ...props } = this.props;
    return (
      <ScrollToContainer {...props}>
        <ScrollToButton onClick={this.handleOnClick}>
          <CollapsingArrow show top={0} scale={0.7} white collapsed nomargin />
        </ScrollToButton>
      </ScrollToContainer>
    );
  }
}

export { ScrollTo };
