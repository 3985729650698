import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { Title4 } from 'components/text';
import fonts from 'style/fonts';

export const CollapsibleContainer = styled.div`
        background-color: #f7f8f9;        
    border-bottom: 0.5rem solid ${Colors.WHITE};

`;

export const CollapsibleArrowContainer = styled.div`
    position: absolute;
    right: 2rem;
`;

export const CollapsiblePanelDiv = styled.div.attrs(props => ({
    // marginBottom: props.open ? '0px' : '10px',
}))`
    display: flex;
    max-width: 100%;
    /* margin-bottom: ${props => props.marginBottom}; */
    padding: 0.7rem;
    background-color: #f7f8f9;
    :hover {
        cursor: pointer;
    }
`;

export const CollapsiblePanelChildren = styled.div.attrs(props => ({
    show: props.show ? 'visible' : 'hidden',
}))`
    visibility: ${props => props.show};
    height: fit-content;
    background-color: #f7f8f9;
    padding: 0 2rem 2rem 2rem;
    margin-bottom: 10px;
    ${media.desktop`
    height: fit-content;
        padding: 0rem 1.6rem 1.4rem 1.6rem;
    `};

    
`;

export const CollapsibleTitle = styled(Title4).attrs(props => ({
    color: props.open ? Colors.BLACK : Colors.DARKER_GRAY,
    weight: props.open ? 'bold' : 'normal',
}))`
color: ${props => props.open};
margin-top: 0px;
font-size: 20px;
font-family: ${fonts.APERCU.REGULAR};
font-weight: ${props => props.weight}
`;