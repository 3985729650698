import styled from 'styled-components/macro';
import { settings } from 'constants/settings';

export const FilterCartList = styled.ul`
  perspective: 900px;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: ${settings.MENU_HEIGHT};
  margin: 0;
  /* width: 13rem; */
`;
