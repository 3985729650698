import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { footer } from 'constants/settings';
import { FlexContainer } from 'components/flex';
import { media } from 'style/media';
import { NavigationLink } from 'features/Navigation';
import fonts, { fontSize } from 'style/fonts/';

// import PropTypes from 'prop-types';

/**
 * Footer (div)
 */
export const FooterContainer = styled.div`
  display: block;
  min-height: ${footer.HEIGHT};
  background: ${Colors.GRAY};
  text-align: center;
  padding: 1.5rem 0 4rem 0;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

export const StyledFooter = styled(FlexContainer)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  margin: 2rem 0 3rem 0;
  background: ${Colors.GRAY};
  ${media.desktop`
    text-align: center;
    margin-top: 0;
  `}
`;

export const FooterItemContainer = styled.ul`
  display: flex;
  flex-flow: column;
  margin: 0;
  height: 100%;
  padding: 0 1.5rem;
  text-decoration: none;
  list-style: none;
  ${media.desktop`
    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  `}
`;

export const FooterItem = styled.li`
  height: 100%;
  text-decoration: none;
  margin: 0;
  list-style: none;
`;

export const FooterItemColumnWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  ${media.desktop`
    justify-content: center;
  `}
`;

export const FooterItemColumn = styled.div`
  display: flex;
  flex-flow: column;
  &:not(:first-of-type) {
    margin-left: 2rem;
  }
`;

export const FooterItemHeaderLink = styled(NavigationLink).attrs(props => ({
  noactiveunderline: 1
}))`
  font-size: ${fontSize(16, 20)};
  font-family: ${fonts.APERCU.REGULAR};
  font-weight: 700;
  color: ${Colors.FOOTER_LINK_GRAY};
  margin-bottom: 1rem;
  &:after {
    margin-top: 0.3rem;
  }
  &:hover {
    &:after {
      margin-top: 0.3rem;
    }
  }
  ${media.desktop`
    margin-bottom: 0.5rem;
    &:after{
      margin-top: 0.3rem;
    }
      &:hover{
    &:after{
      margin-top: 0.3rem;
    }
  }
  `}
  ${media.bigdesktop`
    margin-bottom: 0.5rem;
    &:after{
      margin-top: 0.3rem;
    }
      &:hover{
    &:after{
      margin-top: 0.3rem;
    }
  }
  `}
`;

export const StyledFooterItemLink = styled(FooterItemHeaderLink).attrs(props => ({
  lineheight: '0.5em'
}))`
  font-size: ${fontSize(12, 14)};
  margin: 0;
  font-weight: 400;
`;
