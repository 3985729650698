import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { zIndex } from 'constants/settings';
import { colors as Colors } from 'constants/colors';
import ArrowImage from 'images/arrow.min.svg';
import ArrowImageWhite from 'images/arrow-white.min.svg';
import { media } from 'style/media';
import fonts from 'style/fonts/fonts';

const StyledArrowButton = styled.button.attrs(props => ({
  background: `${props.backgroundcolor ? props.backgroundcolor : Colors.WHITE} url(${ArrowImage}) ${
    props.hastext ? '90%' : 'center'
  } no-repeat`,
  color: props.color ? props.color : Colors.BLACK,
  center: props.center ? 1 : 0,
  incornerwhensmall: props.incornerwhensmall
}))`
  background: ${props => props.background};
  position: absolute;
  ${props =>
    !props.center &&
    `
    right: 0;
  `}
  bottom: 0;
  color: ${props => props.color};
  min-width: 3.458rem;
  height: 2.3333rem;
  display: block;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  &:hover {
    background-color: ${Colors.TRANSPARENT_WHITE};
  }
  ${media.desktop`
      ${({ incornerwhensmall }) =>
        !incornerwhensmall &&
        `
        background-color: ${props =>
          props.smallbackgroundcolor ? props.smallbackgroundcolor : Colors.WHITE};
        transition: background-position-x .2s cubic-bezier(.71,.02,.75,.85),
                    background-color .3s ease-in-out;
        width:100%;
        background-position-x: 50%;
        position: ${props => (props.smallposition ? props.smallposition : 'absolute')};
        &:hover{
          background-position-x: 95%;
        }
    `}
  `};
`;

StyledArrowButton.propTypes = {
  backgroundcolor: PropTypes.oneOf(Object.values(Colors)),
  smallbackgroundcolor: PropTypes.oneOf(Object.values(Colors)),
  smallposition: PropTypes.oneOf(['absoulte', 'relative'])
};

export const ArrowButton = StyledArrowButton.withComponent(NavLink);

export const ArrowButtonRight = styled(ArrowButton)`
  ${media.desktop`
    background-position-x: 95%;  
    &:hover{
      background-position-x: 50%;
    }
  `};
`;

const StyledArrowLink = StyledArrowButton.withComponent(NavLink);

export const ArrowLink = styled(StyledArrowLink)`
  font-family: ${fonts.APERCU.WEB};
  position: relative;
  background-color: ${Colors.ORANGE};
  color: white;
  font-size: 1.3em;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0.45rem;
  padding: 0.4rem 0.2rem 0.4rem 1rem;
  &:before {
    background-position-x: 50%;
    display: block;
    transition: background-position-x 0.2s cubic-bezier(0.71, 0.02, 0.75, 0.85);
    background: url(${ArrowImageWhite}) center no-repeat;
    content: ' ';
    top: 0;
    right: 0;
    width: 4rem;
    height: 100%;
    position: absolute;
    z-index: ${zIndex.LEVEL_1};
  }
  &:after {
    content: ' ';
    right: 0;
    width: 5rem;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      to right,
      ${Colors.TRANSPARENT} 0%,
      ${Colors.ORANGE} 30%,
      ${Colors.ORANGE} 100%
    );
    background: -webkit-linear-gradient(
      left,
      ${Colors.TRANSPARENT} 0%,
      ${Colors.ORANGE} 30%,
      ${Colors.ORANGE} 100%
    );
    top: 0;
    display: block;
    z-index: ${zIndex.LEVEL_0};
  }
  ${media.desktop`
    position: relative;
    width:100%;
    background: ${Colors.ORANGE};
  `};
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    background: ${Colors.ORANGE};
    &:before {
      background-position-x: 60%;
    }
  }
`;

export const ExternalArrowLink = ArrowLink.withComponent('a');

export const ArrowButtonText = styled.span.attrs(props => ({
  padding: props.makeroomforarrow ? '0 4rem 0 1rem' : 0,
  apercu: props.apercu,
  fontsize: props.fontsize ? props.fontsize : '1rem',
  smallfontsize: props.smallfontsize
}))`
  font-family: ${props => (props.apercu ? fonts.APERCU.WEB : fonts.LLBrown.REGULAR)};
  color: ${Colors.BLACK};
  font-size: ${props => props.fontsize};
  letter-spacing: 0.3px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-out;
  padding: ${props => props.padding};

  opacity: 1;

  ${media.desktop`
    font-size: ${({ smallfontsize, fontsize }) => (smallfontsize ? smallfontsize : fontsize)};
    padding: 0;
    &:hover {
      opacity: 0;
    }
  `};
`;
