import React from 'react';
import PropTypes from 'prop-types';
import { RelatedItem, RelatedContainer } from './index';
import { UnderlineContainer, UnderlineContainerText } from 'components/UnderlineContainer';
import { colors as Colors } from 'constants/colors';

export default class Related extends React.Component {
  getItems() {
    return this.props.items.map((item, index) => {
      return (
        <RelatedItem
          useFullWidthImage={this.props.useFullWidthImage}
          carousel={this.props.carousel}
          key={index}
          {...item}
        />
      );
    });
  }

  render() {
    const { marginright, header, items, hasimage } = this.props;
    return (
      <RelatedContainer className="RelatedContainer" hasimage={hasimage} marginright={marginright}>
        <UnderlineContainer color={Colors.ORANGE} center centertext position="bottom">
          <UnderlineContainerText> {header || 'Relaterat'}</UnderlineContainerText>
        </UnderlineContainer>
        {items && this.getItems()}
      </RelatedContainer>
    );
  }
}

Related.propTypes = {
  marginright: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      linkUrl: PropTypes.string,
      text: PropTypes.string,
      tag: PropTypes.string,
      type: PropTypes.string
    })
  ).isRequired,
  carousel: PropTypes.bool
};
