import anime from 'animejs';

export const fromBelow = (node, { duration = 1000, elasticity = 100 }) => {
  const timeline = anime.timeline({});

  timeline
    .add({
      targets: node,
      height: [0, '100%'],
      duration: duration / 2,
      easing: 'easeInCirc',
      elasticity: elasticity
    })
    .add({
      targets: node,
      opacity: [0.8, 1],
      translateY: [30, 0],
      duration: duration,
      easing: 'easeOutElastic',
      elasticity: elasticity,
      offset: `-=100`
    });

  timeline.play();
};

export const animateHeight = (node, { to = 0, duration = 333, elasticity = 100 }) =>
  anime({
    targets: node,
    opacity: [1, 0],
    height: `${to}`,
    duration: duration,
    minHeight: 0,
    easing: 'easeInCirc',
    elasticity: elasticity
  });

export const changeColor = (node, { color, duration, elasticity }) => {
  return anime({
    targets: node,
    backgroundColor: color || `#FFF`,
    duration: duration || 1000,
    easing: 'easeOutElastic',
    elasticity: elasticity || 50
  });
};
