/** eslint disable no-unexpected-multiline*/

import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { media, targetBrowser } from 'style/media';
import { colors as Colors } from 'constants/colors';

const flexOptions = [
  'stretch',
  'center',
  'flex-start',
  'flex-end',
  'baseline',
  'initial',
  'space-evenly',
  'space-around',
  'space-between'
];
/**
 * EventContainer (spdivan)
 */
export const FlexContainer = styled.div.attrs(props => ({
  marginright: props.marginright ? '2rem' : 0,
  marginleft: props.marginleft ? props.marginleft : 0,
  flexdirection: props.column ? 'column' : 'row',
  alignment: props.alignItems ? props.alignItems : 'stretch',
  alignself: props.alignself ? props.alignself : '',
  justification: props.justifycontent ? props.justifycontent : 'flex-start',
  flex: props.flex ? props.flex : 'initial',
  maxwidth: props.maxwidth ? props.maxwidth : 'initial',
  minwidth: props.minwidth ? props.minwidth : 'initial',
  maxwidthsmall: props.maxwidthsmall && props.maxwidth ? props.maxwidth : 'none',
  smallwidth: props.smallwidth ? props.smallwidth : '100%',
  perspective: props.perspective ? '900px' : 'initial',
  margin: props.margin ? props.margin : 0,
  smallmargin: props.smallmargin,
  width: props.width ? props.width : '100%',
  padding: props.padding ? props.padding : 0,
  smallpadding: props.smallpadding,
  minheight: props.minheight ? props.minheight : 'unset',
  minheightmobile: props.minheightMobile ? props.minheightMobile : props.minheight,
  zindex: props.zindex ? props.zindex : 'initial',
  smalldirection: props.smallrow ? 'row' : 'column',
  smallaligncontent: props.smallAlignContent,
  backgroundimage: props.backgroundImage ? props.backgroundImage : '',
  overflow: props.overflow ? props.overflow : '',
  flexWrap: props.flexWrap ? 'wrap' : 'nowrap'
}))`
  margin-right: ${props => props.marginright};
  margin-left: ${props => props.marginleft};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  display: flex;
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};
  flex-direction: ${props => props.flexdirection};
  flex-wrap: ${props => props.flexWrap};
  align-items: ${props => props.alignment};
  align-self: ${props => props.alignself};
  justify-content: ${props => props.justification};
  flex: ${props => props.flex};
  max-width: ${props => props.maxwidth};
  min-height: ${props => props.minheight};
  min-width: ${props => props.minwidth};
  background: ${props => props.backgroundimage};
  min-width: 1px;
  width: ${props => props.width};
  position: relative;
  perspective: ${props => props.perspective};
  z-index: ${props => props.zindex};
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `}
  ${media.desktop`
    overflow: ${props => props.overflow};
    flex-direction: ${props => props.smalldirection};
    align-items: ${props => (props.smallaligncontent ? props.smallaligncontent : 'stretch')};
    width: ${props => props.smallwidth};
    max-width: ${props => props.maxwidthsmall};
    margin: ${props => props.smallmargin};
    padding: ${props => props.smallpadding};
    justify-content: ${props => props.justification};
    border: 0;
  `};
  ${media.phone`
    min-height: ${props => props.minheightmobile}
  `};
`;

export const FlexContainerCentered = styled(FlexContainer).attrs(props => ({
  marginbottom: props.marginbottom ? props.marginbottom : '0'
}))`
  margin: 0 auto;
  margin-bottom: ${({ marginbottom }) => marginbottom};
`;

FlexContainer.propTypes = {
  margin: PropTypes.string,
  marginright: PropTypes.string,
  marginleft: PropTypes.string,
  column: PropTypes.bool,
  alignItems: PropTypes.oneOf(flexOptions),
  justifycontent: PropTypes.oneOf(flexOptions),
  smallAlignContent: PropTypes.oneOf(flexOptions),
  background: PropTypes.oneOf(Object.values(Colors))
};
