import RequestService from './RequestService';

const API_BASE_URL = `/api`;
const ACCOUNT = 'account';
const LOGIN = `${API_BASE_URL}/${ACCOUNT}/login`;
const CREATE = `${API_BASE_URL}/${ACCOUNT}/create`;
const LOGOUT = `${API_BASE_URL}/${ACCOUNT}/logout`;
const VALIDATE = `${API_BASE_URL}/${ACCOUNT}/validate`;

/**
 * login
 * @param {*Username} username
 * @param {*Username} password
 */
export async function loginUser(username, password) {
  if (!username || !password) {
    return false;
  }

  try {
    const result = RequestService.postAsync(LOGIN, {
      username: username,
      password: password
    });
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Log in as default user
 */
export async function createUser(user) {
  try {
    const result = RequestService.postAsync(CREATE, user);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * logs out logged in user
 */
export async function logoutUser() {
  try {
    const result = RequestService.postAsync(LOGOUT);
    return result;
  } catch (e) {
    throw e;
  }
}

/**
 * Validates logged in user
 */
export async function validateUser() {
  try {
    const result = RequestService.fetchAsync(VALIDATE);
    return result;
  } catch (e) {
    throw e;
  }
}
