import React from 'react';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import {
  ParallaxBlockContainer,
  ParallaxBlockContainerMobile,
  ParallaxBlockImage,
  ParallaxBlockImageMobile,
  ParallaxBlockContentItem,
  ParallaxBlockContentItemMobile,
  ParallaxBlockContent,
  ParallaxBlockContentMobile
} from './index';
import OPE from 'components/common/EpiOnPageEdit';
import { uberSmall, fillElement, getSelectedValueFromSelectionFactory } from 'utils';
import {
  ParallaxHeader,
  ParallaxHeaderMobile,
  ParallaxMainIntro,
  ParallaxMainIntroMobile
} from './components';
import { LazyLoadImage } from 'features/LazyImage';
import { Hidden } from 'components/containers/OnlyDesktop';
import {
  LinkButton,
  LinkButtonMobile,
  LinkButtonText,
  LinkButtonTextMobile,
  CustomLink
} from './ParallaxBlockContainer';

export default class ParallaxBlock extends React.Component {
  constructor(props) {
    super(props);
    this.hero = React.createRef();
    this.state = { backgroundLoaded: false };
  }

  onBackgroundLoad = () => {
    this.setState({ backgroundLoaded: true });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.updateParallax);
    this.updateParallax();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateParallax);
  }

  // There is a slight mistake in the calculation below. ElementHeight refers to img, which is scaled and therefor isn't the same as the block height. Would be more exact if there was time to rebuild. That probably explains why it's not "160 - 80".
  updateParallax = () => {
    document.querySelectorAll('.parallax-img').forEach(element => {
      // Because The block has different aspect ratio depending on screen width, the scaling factor can be set to just enough.
      element.style.transform = `scale(${parseFloat(
        (2 - (0.4 / 2200) * window.innerWidth).toFixed(2)
      )})`;
      element.parentElement.style.overflow = 'hidden';
      if (this.isElementVisible(element)) {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const halfElementHeight = element.clientHeight / 2;
        const distanceMiddletoTopDecimal =
          1 -
          (element.getBoundingClientRect().bottom - halfElementHeight) /
            (viewportHeight + halfElementHeight * 2);
        const normalizedDistance = Math.min(1, Math.max(0, distanceMiddletoTopDecimal));
        element.style.top = `${normalizedDistance * 240 - 120}%`;
      }
    });
  };

  isElementVisible(element) {
    const topDistanceFromTop = element.getBoundingClientRect().top;
    const bottomDistanceFromTop = element.getBoundingClientRect().bottom;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const topInViewport = topDistanceFromTop >= 0 && topDistanceFromTop <= viewportHeight;
    const bottomInViewport = bottomDistanceFromTop <= viewportHeight && bottomDistanceFromTop >= 0;
    return topInViewport || bottomInViewport;
  }

  render() {
    const { backgroundLoaded } = this.state;
    const {
      image,
      heading,
      mainIntro,
      buttonText,
      buttonLink,
      buttonLinkRef,
      buttonLinkRefTargetBlank,
      targetBlank,
      textColor,
      setOverlay,
      opacity
    } = this.props;
    const color = getSelectedValueFromSelectionFactory(textColor);

    if (!this.props.subMenuMobile) {
      return (
        <ParallaxBlockContainer
          color={color}
          opacity={setOverlay ? opacity : 0}
          smallbackground={Colors.WHITE}
          placeholderBg={uberSmall(image)}
          bg={fillElement(image, this.hero.current)}>
          <OPE propertyName="image">
            <ParallaxBlockImage
              background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
            />
          </OPE>
          <ParallaxBlockContentItem>
            <ParallaxBlockContent>
              <OPE propertyName="heading">
                <ParallaxHeader color={color}>{heading}</ParallaxHeader>
              </OPE>
              <OPE propertyName="mainIntro">
                <ParallaxMainIntro color={color}>{mainIntro}</ParallaxMainIntro>
              </OPE>
              <OPE propertyName="button">
                {buttonText &&
                  buttonLink &&
                  (buttonLinkRefTargetBlank || targetBlank ? (
                    <CustomLink
                      href={buttonLinkRef}
                      target={'_blank'}
                      style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                      <LinkButton>
                        <LinkButtonText>{buttonText}</LinkButtonText>
                      </LinkButton>
                    </CustomLink>
                  ) : (
                    <CustomLink
                      href={buttonLinkRef}
                      style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                      <LinkButton>
                        <LinkButtonText>{buttonText}</LinkButtonText>
                      </LinkButton>
                    </CustomLink>
                  ))}
              </OPE>
            </ParallaxBlockContent>
          </ParallaxBlockContentItem>
          <Hidden className="Hidden">
            <LazyLoadImage
              onLoad={this.onBackgroundLoad}
              asBackground
              hideSmall
              placeholder={uberSmall(image)}
              src={fillElement(image)}
            />
          </Hidden>
        </ParallaxBlockContainer>
      );
    } else {
      return (
        <ParallaxBlockContainerMobile
          color={color}
          opacity={setOverlay ? opacity : 0}
          smallbackground={Colors.WHITE}
          placeholderBg={uberSmall(image)}
          bg={fillElement(image, this.hero.current)}
          style={{ maxHeight: '400px', aspectRatio: '1' }}>
          <OPE propertyName="image">
            <ParallaxBlockContentItemMobile style={{ zIndex: '1' }}>
              <ParallaxBlockContentMobile>
                <OPE propertyName="heading">
                  <ParallaxHeaderMobile color={color}>{heading}</ParallaxHeaderMobile>
                </OPE>
                <OPE propertyName="mainIntro">
                  <ParallaxMainIntroMobile color={color}>{mainIntro}</ParallaxMainIntroMobile>
                </OPE>
                <OPE propertyName="button">
                  {buttonText &&
                    buttonLink &&
                    (buttonLinkRefTargetBlank || targetBlank ? (
                      <CustomLink href={buttonLinkRef} target={'_blank'}>
                        <LinkButtonMobile>
                          <LinkButtonTextMobile>{buttonText}</LinkButtonTextMobile>
                        </LinkButtonMobile>
                      </CustomLink>
                    ) : (
                      <CustomLink href={buttonLinkRef}>
                        <LinkButtonMobile>
                          <LinkButtonTextMobile>{buttonText}</LinkButtonTextMobile>
                        </LinkButtonMobile>
                      </CustomLink>
                    ))}
                </OPE>
              </ParallaxBlockContentMobile>
            </ParallaxBlockContentItemMobile>
            <ParallaxBlockImageMobile
              className="parallax-img"
              style={{ position: 'absolute', height: '100%', width: '100%' }}
              background={backgroundLoaded ? fillElement(image) : uberSmall(image)}
              color={color}
              opacity={setOverlay ? opacity : 0}>
              <Hidden className="Hidden">
                <LazyLoadImage
                  onLoad={this.onBackgroundLoad}
                  asBackground
                  hideSmall
                  placeholder={uberSmall(image)}
                  src={fillElement(image)}
                />
              </Hidden>
            </ParallaxBlockImageMobile>
          </OPE>
        </ParallaxBlockContainerMobile>
      );
    }
  }
}

ParallaxBlock.defaultProps = {
  textColor: Colors.BLACK
};
ParallaxBlock.propTypes = {
  buttonLink: PropTypes.any,
  buttonText: PropTypes.string,
  heading: PropTypes.string.isRequired,
  image: PropTypes.string,
  mainIntro: PropTypes.string,
  textColor: PropTypes.string
};
