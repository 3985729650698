import styled from 'styled-components/macro';
import { media } from 'style/media';

export const ParallaxBlockContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 0 0 0 calc(5.688rem - 0.8333rem);
  max-width: calc(22.83rem + 7.083rem - 0.8333rem);

  @media (max-height: 800px) {
    padding-top: 2.5rem;
  }

  ${media.giant`
    width:100%;
  `};
  ${media.desktop`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

export const ParallaxBlockContentMobile = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 0 1rem 0 1rem;
  max-width: calc(22.83rem + 7.083rem - 0.8333rem);

`;