import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import OPE from 'components/common/EpiOnPageEdit';
import { Title, Ingress } from 'components/text';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TopImageContainer, TopImageWrapper } from 'components/containers';
import { LazyLoadImage, ImageDescriptor } from 'features/LazyImage';
import BlockArea from 'features/BlockArea';
import { FlexContainerCentered } from 'components/flex';
import { uberSmall, reFormat } from '../../utils';
import { PageContent } from 'pages/components';
import { StandardPageContainer } from '../standardpage/components';
import { NeedToLogin } from 'features/Account/Login';

class NotFoundPage extends React.Component {
  componentDidMount() {
    const { history, requestedUrl } = this.props;
    if (requestedUrl && history) {
      history.push(requestedUrl);
    }
  }

  componentDidUpdate() {}

  render() {
    if (!this.props) return null;
    const {
      image,
      mainIntro,
      mainBody,
      firstContentArea,
      header,
      imageTitle,
      children,
      altText,
      style = {},
      isPreview = false
    } = this.props;
    const htmlComponentStyles = {};

    return (
      <Fragment>
        <StandardPageContainer className="NotFoundPage" style={style}>
          <FlexContainerCentered alignItems="flex-start" marginbottom="2.5em">
            <PageContent className="PageContentClass">
              <div
                style={{
                  height: '100%',
                  maxWidth: '100%'
                }}>
                <OPE propertyName="image">
                  {image && (
                    <TopImageContainer>
                      <TopImageWrapper>
                        <LazyLoadImage
                          alt={altText}
                          placeholder={uberSmall(image)}
                          src={reFormat(image)}
                        />
                        {imageTitle && <ImageDescriptor>{imageTitle}</ImageDescriptor>}
                      </TopImageWrapper>
                    </TopImageContainer>
                  )}
                </OPE>
                <OPE propertyName="header">
                  <Title style={{ width: '100%' }} black>
                    {header}
                  </Title>
                </OPE>
                <OPE propertyName="mainIntro" floating={false}>
                  {mainIntro && (
                    <Ingress maxwidth="calc(27.92rem + 5rem)" margin="1rem 0 0 0">
                      {mainIntro}
                    </Ingress>
                  )}
                </OPE>
                <OPE propertyName="mainBody" floating={false}>
                  <HtmlComponentContainer margin="1rem 0 0 0" preview={isPreview ? 1 : 0}>
                    <HtmlComponent style={htmlComponentStyles} html={mainBody} />
                  </HtmlComponentContainer>
                </OPE>
                {isPreview && <NeedToLogin />}
              </div>
            </PageContent>
          </FlexContainerCentered>
          {!isPreview && (
            <BlockArea propertyName="firstContentArea" contentarea={firstContentArea} />
          )}
          <FlexContainerCentered>
            <PageContent>{children}</PageContent>
          </FlexContainerCentered>
        </StandardPageContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({ currentPage: state.page, domevents: state.domevents });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotFoundPage));

NotFoundPage.propTypes = {
  pageType: PropTypes.oneOf(['StandardPage']).isRequired,
  header: PropTypes.string.isRequired,
  mainBody: PropTypes.string,
  listOnStartPage: PropTypes.bool,
  image: PropTypes.string,
  mainIntro: PropTypes.string,
  firstContentArea: PropTypes.array,
  pageTypeCategory: PropTypes.array,
  createdByUser: PropTypes.string
};
