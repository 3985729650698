import React from 'react';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { BLOCKS } from 'constants/blockTypes';
import HtmlComponent from 'features/HtmlComponent';
import { Block, CenteredBlockInner } from '../components';
import { CustomAreaBlockContainer } from './components';
class CustomAreaBlock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      background: Colors.TRANSPARENT_LIGHT_GRAY
    };
  }
  //Antingen från kontaktsida eller editerbar text
  render() {
    const { background } = this.state;
    const { mainBody, nopadding } = this.props;
    if (!mainBody) return null;
    return (
      <Block smallpadding={!nopadding} className="CustomAreaBlock">
        <CenteredBlockInner border background={background}>
          <CustomAreaBlockContainer color={Colors.MAIN_INTRO}>
            <HtmlComponent html={mainBody} />
          </CustomAreaBlockContainer>
        </CenteredBlockInner>
      </Block>
    );
  }
}

export default CustomAreaBlock;

CustomAreaBlock.propTypes = {
  text: PropTypes.string.isRequired,
  blockType: PropTypes.oneOf([BLOCKS.CUSTOMAREA_BLOCK])
};
