import React from 'react';
import { Paragraph } from 'components/text/Text';
import { CategoryPillArrow } from './StyledArrowLink';
import { StyledArrowLink } from './StyledArrowLink';

export const ArrowLink = ({ text, ...styles }) => (
  <StyledArrowLink smallrow flexdirection="row" height="100%" {...styles} as={Paragraph}>
    <span> {text}</span>
    <CategoryPillArrow />
  </StyledArrowLink>
);
