import * as TYPES from './activityBlockTypes';
import { fetchActivityBlockItems } from '../../api/SearchApi';
import { LocalStorage, isEmpty } from 'utils';

const Storage = new LocalStorage();

export const fetchItems = (id, category) => {
  return async dispatch => {
    dispatch({ type: TYPES.ACTIVITY_BLOCK_FETCH, category });
    try {
      const events = await fetchActivityBlockItems(id, category);

      if (!isEmpty(events)) {
        const cookie = Storage.getJSON(TYPES.ACTIVITY_COOKIE);
        Storage.set(TYPES.ACTIVITY_COOKIE, {
          ...cookie,
          events: events,
          lastHitCategory: category
        });

        dispatch({ type: TYPES.ACTIVITY_BLOCK_FETCHED, events, lastHitCategory: category });
      } else {
        dispatch({ type: TYPES.ACTIVITY_BLOCK_ERROR, message: 'Inga träffar för ' });
      }
    } catch (e) {
      throw e;
    }
  };
};
