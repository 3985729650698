import { ErrorHandler } from '../utils';
import { getAntiforgeryToken } from '../utils';

class RequestService {
  // async function
  async fetchAsync(url, cache = 'no-cache') {
    ErrorHandler.ConsoleLog(`%c Fetching ${url}`, 'background: #f98412; color: #FFF');
    // let error = Error();
    try {
      let response = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        xhrFields: {
          withCredentials: true
        },
        cache: cache,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'max-age=0'
        }
      });
      if (response.ok) {
        let json = await response.json();
        const isString = json.constructor === ''.constructor;
        if (isString) {
          ErrorHandler.LogErrorSilently(`Response from ${url} was a string. Expected JSON.`);
          return JSON.parse(json);
        }
        ErrorHandler.ConsoleLog(`👍 %c${url}`, 'background: #bafa55; color: #9455fa', json);

        return json;
      } else if (response.status === 404) {
        return {};
      }
      const json = await response.json();

      ErrorHandler.LogErrorSilently(`response was not 'OK'. Was:`, json);
      return json;
    } catch (e) {
      let error = new Error(e);
      ErrorHandler.LogError(error);
      ErrorHandler.ConsoleLog(error);
      return {};
    }
  }

  async postAsync(url, payload = {}, credentials = true) {
    ErrorHandler.ConsoleLog(`%c Posting ${url} `, 'background: #222; color: #4eabe2', payload);
    let error = Error();
    try {
      let response = await fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        xhrFields: {
          withCredentials: credentials
        },
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (response.ok) {
        let json = await response.json();
        return json;
      }
      if (response.status === 401) {
        let json = await response.json();
        return json;
      }

      if (response.status === 400) {
        let json = await response.json();
        return json;
      }
      const json = await response.json();
      return json;
    } catch (e) {
      error = new Error(e);
      ErrorHandler.LogError(error);
      return this.errorObject(error, `Error when making request.`);
    }
  }

  //file upload functionality
  async postFormAsync(url, payload = new FormData()) {
    let error = Error();
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          antiForgeryToken: getAntiforgeryToken()
        },
        credentials: 'include',
        body: payload
      });
      if (response.ok) {
        let json = await response.json();
        return json;
      }
      if (response.status === 401) {
        let json = await response.json();
        return json;
      }

      if (response.status === 400) {
        let json = await response.json();
        return json;
      }
      const json = await response.json();
      return json;
    } catch (e) {
      error = new Error(e);
      ErrorHandler.LogError(error);
      return this.errorObject(error, `Error when making request.`);
    }
  }
  errorObject = (error = new Error(), message = 'no message') => {
    return {
      result: false,
      hasError: true,
      error,
      message,
      success: false
    };
  };
}

export default new RequestService();
