import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { settings } from 'constants/settings';
import PropTypes from 'prop-types';
import { media } from 'style/media';
const menuHeight = settings.MENU_HEIGHT;
const extraGiant = 1440;
/**
 * PageContent
 */
export const PageContent = styled.div.attrs(props => ({}))`
  @media (max-width: ${extraGiant}px) {
    max-width: ${({ hasrelated }) => hasrelated ? 'calc(36.25rem - 0.8333rem + 7.083rem)' : 'calc(42.25rem - 0.8333rem)'};
  }
  @media (min-width: ${extraGiant}px) {
    max-width: ${({ hasrelated }) => hasrelated ? 'calc(36.25rem - 0.8333rem + 7.083rem)' : 'calc(46.25rem - 0.8333rem)'};
  }
  width: 100%;
  padding: 0 0.8333rem 0 0.8333rem;
  padding-top: ${({ first, extrapaddingtop }) =>
    first ? (extrapaddingtop ? `calc(${menuHeight} + 3rem)` : menuHeight) : 0};
  background: ${Colors.TRANSPARENT};
  position: relative;
  margin: 0 auto ${({ bottommargin }) => (bottommargin ? '1.667rem' : 0)} auto;
  margin-right: ${({ marginright }) => (marginright ? marginright : 'auto')};
  display: flex;
  flex-direction: ${({ hasrelated }) => (hasrelated ? 'row' : 'column')};
  ${media.desktop`
    padding: ${({ first, extrapaddingtop }) =>
      first ? (extrapaddingtop ? `calc(${menuHeight} + 3rem)` : menuHeight) : 0} 1rem 0 1rem;
  `};
`;

PageContent.propTypes = {
  marginright: PropTypes.string
};
