import { fetchApp, fetchNavigation, fetchRouteByUrl } from '../../api/ContentApi';
import { isEmpty } from 'utils';
import * as TYPES from './appTypes';

export const getApp = () => {
  let error = new Error();
  return async dispatch => {
    dispatch({ type: TYPES.APP_FETCH });
    try {
      const app = await fetchApp();
      dispatch({ type: TYPES.APP_FETCHED, ...app });
    } catch (e) {
      error = new Error(e);
      throw error;
    }
  };
};
export const setPreviousPage = (url = '/') => {
  return dispatch => {
    dispatch({ type: TYPES.SET_PREVIOUS_PAGE, url });
  };
};

export const getNavigation = catid => {
  let error = new Error();
  return async dispatch => {
    dispatch({ type: TYPES.NAVIGATION_FETCH });
    try {
      const nav = await fetchNavigation(catid);
      dispatch({ type: TYPES.NAVIGATION_FETCHED, nav });
    } catch (e) {
      error = new Error(e);
      throw error;
    }
  };
};

export const getRouteByUrl = url => {
  let error = new Error();
  return async dispatch => {
    dispatch({ type: TYPES.FETCH_ROUTE_BY_URL });
    try {
      const result = await fetchRouteByUrl(url);
      const route = !isEmpty(result) ? result : undefined;

      dispatch({ type: TYPES.FETCHED_ROUTE_BY_URL, route });
    } catch (e) {
      error = new Error(e);
      throw error;
    }
  };
};

export const setCurrentPageId = (id = undefined) => {
  let error = new Error();
  if (id === undefined) {
    error = new Error('id is undefined');
    throw error;
  }

  return dispatch => {
    dispatch({ type: TYPES.APP_SET_CURRENT_ID, id });
  };
};
