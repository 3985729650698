import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';

export const FileNameWrapper = styled.div.attrs(props => ({
  color: props.color ? props.color : Colors.LIGHT_GRAY
}))`
  color: ${props => props.color};
  margin: 0.5em 0.5em 0 0;
  display: flex;
  justify-content: center;
  user-select: none;
  transition: all 0.4s ease-in-out;
`;
