import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const JobListBlockSmallButton = styled.div`
  display: none;
  ${media.desktop`
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  margin-top: 20px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: ${Colors.ORANGE};
  color: ${Colors.WHITE};
    display: flex;
  `};
`;
