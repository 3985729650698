import * as TYPES from './newsblockTypes';
import { LocalStorage } from '../../utils';

const Storage = new LocalStorage();

const cookie = Storage.getJSON(TYPES.NEWS_BLOCK_COOKIE);
const initialState = {
  isLoading: false,
  news: cookie ? cookie.news : [],
  lastHitCategory: cookie ? cookie.lastHitCategory : 0,
  error: {
    hasError: false,
    message: ''
  }
};

if (!cookie) {
  Storage.set(TYPES.NEWS_BLOCK_COOKIE, initialState);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NEWS_BLOCK_FETCH: {
      return {
        ...state,
        isLoading: true,
        lastUpdatedCategory: action.category
      };
    }
    case TYPES.NEWS_BLOCK_FETCHED: {
      return {
        ...state,
        isLoading: false,
        news: action.news,
        lastHitCategory: action.lastHitCategory,
        error: { hasError: false, message: '' }
      };
    }
    case TYPES.NEWS_BLOCK_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: { hasError: true, message: action.message }
      };
    }
    default:
      return state;
  }
};
