import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { zIndex } from '../../../../constants/settings';
import { media, targetBrowser } from 'style/media';

/**
 * EventItem (spdivan)
 */
export const EventItem = styled.div`
  transition: backdrop-filter 0.5s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  border: ${Colors.WHITE} solid 5px;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(2px);
  padding: 2rem 1.125rem 4rem 1.125rem;
  z-index: ${zIndex.LEVEL_1};
  min-width: 10rem;
  max-width: 28rem;
  width: 95%;
  margin: 1rem 0.5rem 3rem 0.5rem;
  &:first-of-type {
    margin-left: 3rem;
  }
  &:last-of-type {
    margin-right: 3rem;
  }
  min-height: 18rem;

  &:hover {
    backdrop-filter: blur(10px);
  }
  ${media.desktop`
    margin: 1rem 1.5rem 2rem 1.5rem;
    &:first-of-type {
      margin-left: 1.5rem;
    }
    &:last-of-type {
      margin-right: 1.5rem;
    }    
  `};
  ${targetBrowser.IE10ANDHIGHER`
    flex: 0 0 auto;
  `};
`;
