import * as TYPES from './contactlistblockTypes';
import { fetchContacts as apiFetchContacts } from '../../api/SearchApi';
import { isEmpty } from '../../utils';

export const fetchContacts = (id, county, categories, filter, fromParent = false) => {
  return async dispatch => {
    dispatch({ type: TYPES.CONTACT_LIST_BLOCK_ITEMS_FETCH, county });
    try {
      const contacts = await apiFetchContacts(id, county, categories, filter, fromParent);

      if (!isEmpty(contacts)) {
        dispatch({ type: TYPES.CONTACT_LIST_BLOCK_ITEMS_FETCHED, contacts });
      } else {
        dispatch({ type: TYPES.CONTACT_LIST_BLOCK_ERROR, message: 'Hittar inga kontakter i ' });
      }
    } catch (e) {
      throw e;
    }
  };
};
export const filterContacts = () => {
  return dispatch => {
    dispatch({ type: TYPES.CONTACT_LIST_BLOCK_START_SET_LOADING, loading: true });
    dispatch({ type: TYPES.CONTACT_LIST_BLOCK_FILTER });
  };
};

export const setFilterValue = (filter = '') => {
  return dispatch => {
    dispatch({ type: TYPES.CONTACT_LIST_BLOCK_START_SET_LOADING, loading: true });
    dispatch({ type: TYPES.CONTACT_LIST_BLOCK_SET_FILTER, filter });
  };
};
