import styled from 'styled-components/macro';
import { media } from 'style/media';
import { zIndex } from '../../../constants/settings';

export const CountyFilterWrapper = styled.div.attrs(props => ({
  display: props.ispuffblockfilter ? 'block' : 'none'
}))`
  position: relative;
  perspective: 900px;
  z-index: ${zIndex.FILTER};
  max-width: 100%;
  ${media.desktop`
  display: ${props => props.display};
  `};
`;
