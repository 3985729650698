import * as TYPES from './contactlistblockTypes';

const initialState = {
  isLoading: false,
  contacts: [],
  progress: 0,
  lastUpdatedCategory: undefined,
  error: {
    hasError: false,
    message: ''
  },
  filterValue: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CONTACT_LIST_BLOCK_ITEMS_FETCH: {
      return {
        ...state,
        isLoading: true,
        progress: 25,
        lastUpdatedCategory: action.county,
        error: { hasError: false, message: '' }
      };
    }
    case TYPES.CONTACT_LIST_BLOCK_ITEMS_FETCHED: {
      return {
        ...state,
        isLoading: false,
        contacts: action.contacts,
        error: { hasError: false, message: '' },
        progress: 100
      };
    }
    case TYPES.CONTACT_LIST_BLOCK_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: { hasError: true, message: action.message }
      };
    }
    case TYPES.CONTACT_LIST_BLOCK_FILTER: {
      return {
        ...state,
        isLoading: false
      };
    }
    case TYPES.CONTACT_LIST_BLOCK_START_SET_LOADING: {
      return {
        ...state,
        isLoading: action.loading
      };
    }
    case TYPES.CONTACT_LIST_BLOCK_SET_FILTER: {
      return {
        ...state,
        filterValue: action.filter
      };
    }
    default:
      return state;
  }
};
