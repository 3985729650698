import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

/**
 * BlockAreaContainer (div)
 */
export const BlockAreaContainer = styled.div.attrs(props => ({
  background: props.background ? props.background : Colors.TRANSPARENT,
  paddingleft: props.pushright ? '5rem' : 0,
  nomargin: props.nomargin
}))`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  padding-left: ${props => props.paddingleft};
  margin-bottom: ${({ nomargin }) => (nomargin ? 0 : '1.23rem')};
  ${media.desktop`
    padding-left: 0;
  `};
`;

BlockAreaContainer.propTypes = {
  column: PropTypes.bool
};
