import React from 'react';
import PropTypes from 'prop-types';
import { Title2, Paragraph } from 'components/text';
import { LinkTextBlockContainer } from './components';
import { Fade } from 'components/common/Fade';
import { FlexContainer } from 'components/flex';
import { ArrowLink, ExternalArrowLink } from 'components/button';
import { isInternalUrl } from '../../utils';

export default class LinkTextBlock extends React.Component {
  render() {
    const { links, header, text, first } = this.props;

    const questionLinks = (links || []).map(({ linkUrl, text }, index) => {
      if (isInternalUrl(linkUrl)) {
        return (
          <ArrowLink to={linkUrl} key={`${linkUrl}_${index}`}>
            {text}
          </ArrowLink>
        );
      }
      return (
        <ExternalArrowLink href={linkUrl} key={`${linkUrl}_${index}`}>
          {text}
        </ExternalArrowLink>
      );
    });

    return (
      <Fade>
        <LinkTextBlockContainer center first={first}>
          <FlexContainer maxwidth="48.75rem" alignItems="center" justifycontent="space-around">
            <FlexContainer
              maxwidth="30%"
              flex={1}
              alignItems="center"
              justifycontent="center"
              column>
              <Title2 centerTextSmall nopadding center large>
                {header}
              </Title2>
              <Paragraph maxwidth="15rem" centerTextSmall padding center>
                {text}
              </Paragraph>
            </FlexContainer>
            <FlexContainer maxwidth="60%" smallwidth="100%" flex={1} justifycontent="center" column>
              {questionLinks}
            </FlexContainer>
          </FlexContainer>
        </LinkTextBlockContainer>
      </Fade>
    );
  }
}
LinkTextBlock.propTypes = {
  first: PropTypes.bool,
  blockType: PropTypes.oneOf(['LinkTextBlock']).isRequired,
  id: PropTypes.number,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({ linkUrl: PropTypes.string.isRequired, text: PropTypes.string.isRequired })
      .isRequired
  )
};
