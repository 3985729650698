import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { HighlightBackground } from 'components/text';
import { FlexContainer } from 'components/flex';
import Highlighter from 'react-highlight-words';
import { AutoCompleteContainer, AutoCompleteItem } from './components';
import { fetchAutocomplete } from 'api/SearchApi';
import { ErrorHandler } from 'utils';

const AutoComplete = ({
  highlight = '',
  onItemSelect = () => {},
  setFocusOnInput = () => {},
  css
}) => {
  const [hits, setHits] = useState([]);
  const [isAutocompleteVisible, setAutoCompleteVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [currentSelected, setCurrentSelected] = useState('');
  const wrapper = useRef(null);
  const [currentActive, setActiveIndex] = useState(-1);

  const handleKeyDown = e => {
    const { key } = e;
    if (key === 'ArrowDown' && currentActive < hits.length - 1) {
      e.preventDefault();
      const next = currentActive + 1;
      setActiveIndex(next);
    } else if (key === 'ArrowUp' && currentActive >= 0) {
      e.preventDefault();

      const next = currentActive - 1;
      setActiveIndex(next);
    } else if (key === 'Enter') {
      setCurrentSelected(currentActive);
    }
  };

  useEffect(() => {
    if (isAutocompleteVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isAutocompleteVisible, hits, currentActive]);

  useEffect(() => {
    try {
      if (wrapper.current && hits.length && currentActive > -1) {
        wrapper.current.children[currentActive].focus({ preventScroll: true });
      } else {
        setFocusOnInput();
        setActiveIndex(-1);
      }
    } catch (e) {
      ErrorHandler.LogErrorSilently('could not set focus', e);
    }
  }, [currentActive]);

  useEffect(() => {
    if (isAutocompleteVisible) {
      onItemSelect(hits[currentSelected]);
      setAutoCompleteVisible(false);
    }
  }, [currentSelected]);

  useEffect(() => {
    const getAutocomplete = async () => {
      const result = (await fetchAutocomplete(highlight)) || [];
      setHits(result);

      const hasInput = highlight.length > 0;
      const hasSuggestions = result.length > 0;
      const isSameAsInput = result.length === 1 && result[0] === highlight;
      if (hasInput && hasSuggestions && !isSameAsInput) {
        setAutoCompleteVisible(true);
      } else {
        setAutoCompleteVisible(false);
      }
    };
    if (isMounted) {
      getAutocomplete();
    }
    setActiveIndex(-1);
  }, [highlight]);

  useEffect(() => {
    setIsMounted(true);
    setAutoCompleteVisible(false);
  }, []);

  return (
    <AutoCompleteContainer visible={isAutocompleteVisible} style={css}>
      <FlexContainer column ref={wrapper}>
        {(hits || []).map((text, index) => {
          return (
            <AutoCompleteItem
              isActive={currentActive === index}
              tabIndex={0}
              key={`${text}_${index}`}
              onMouseDown={() => {
                onItemSelect(text);
                setAutoCompleteVisible(false);
              }}>
              <Highlighter
                highlightTag={HighlightBackground}
                searchWords={highlight.split(' ')}
                autoEscape={true}
                textToHighlight={text || ''}
              />
            </AutoCompleteItem>
          );
        })}
      </FlexContainer>
    </AutoCompleteContainer>
  );
};

export default AutoComplete;

AutoComplete.propTypes = {
  items: PropTypes.array,
  onItemSelect: PropTypes.func
};
