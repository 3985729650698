import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';
import { NavigationLink } from 'features/Navigation/components';

export const PuffListBlockItemContainer = styled(NavigationLink)`
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  border: solid 1px ${Colors.COOKIE_BORDER};
  margin: 0 0.5rem 0.5rem 0;
  width: calc(25% - 15px);
  position: relative;
  justify-content: flex-end;
  min-height: 200px;
  align-items: flex-end;
  background-color: ${Colors.WHITE};
  ${media.desktop`
  margin: 0 0.5rem 0.5rem 0;
  width: 43%;
  `};
  ${media.smalltablet`
  margin: 0 0 1rem 0;
  width: 100%;
  `};

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 7px ${Colors.COOKIE_BORDER};
  }
`;
