import styled from 'styled-components/macro';
import { Title4 } from 'components/text';
import { media } from 'style/media';

export const PuffListBlockTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${media.desktop`
    flex-direction: column-reverse;
  `};
`;

export const PuffListBlockCategoryContainer = styled.div.attrs(props => ({
  hideSmall: props.areafilter ? 'block' : 'none'
}))`
  display: block;
  width: 50%;
  margin: 2rem 0;
  ${media.desktop`
  display: ${props => props.hideSmall};

    width: 100%;
`};
`;

export const PuffListBlockCategoryTitle = styled(Title4)`
  margin: 0 0 0.8rem 0;
  letter-spacing: -0.1px;
`;

export const PuffListBlockFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  ${media.smalltablet`
    flex-direction: column;
    `}
`;
