import React from 'react';
import PropTypes from 'prop-types';
import { ArrowButtonRight, ArrowButtonText } from 'components/button';
import { ActivityBlockContainer, Event, EventsContainer } from './components';
import OPE from 'components/common/EpiOnPageEdit';
import { LazyLoadImage } from 'features/LazyImage';
import { colors as Colors } from 'constants/colors';
import { UnderlineContainer, UnderlineContainerText } from 'components/UnderlineContainer';
import { FlexContainer } from 'components/flex';
import { uberSmall, getSelectedFromSelectionProperty, reFormat } from '../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchItems } from './activityBlockActions';
import { setMessage } from 'features/CountyFilter/countyFilterActions';
import { ChangeNameTransition } from 'features/Transitions';
import BlockArea from 'features/BlockArea';
import { Hidden } from 'components/containers/OnlyDesktop';
import { Message } from 'components/message';
class ActivityBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { backgroundLoaded: false };
  }

  onBackgroundLoad = () => {
    this.setState({ backgroundLoaded: true });
  };

  componentDidMount = async () => {
    const { lastHitCategory } = this.props.activityblock;
    const { selected = {} } = this.props.filter;
    const { id = lastHitCategory || 0 } = selected;

    this.fetchEvents(id);
  };

  getEvents = () => {
    const { activityblock } = this.props;
    if (!activityblock) return;

    const { events, isLoading } = activityblock;
    if (!events) return;

    return activityblock.events.map((event, index) => {
      return (
        <ChangeNameTransition
          animateOutComplete={id => this.handleAnimateOutDone(id)}
          in={!isLoading}
          key={event.linkUrl}
          transitionkey={event.linkUrl}>
          <Event key={index} {...event} />
        </ChangeNameTransition>
      );
    });
  };

  handleAnimateOutDone = (id = '') => {};

  componentDidUpdate(prevProps) {
    if (prevProps.filter.selected !== this.props.filter.selected) {
      this.fetchEvents();
    }
  }

  fetchEvents = async (category = null) => {
    const { id, filter, fetchItems } = this.props;
    fetchItems(id, category || filter.selected.id || 0);
  };

  render() {
    const {
      background,
      buttonLink,
      filterContentArea,
      pageTypeCategory,
      first,
      buttonText,
      activityblock,
      filter
    } = this.props;
    const { hasError, message, isLoading } = activityblock;
    const { selected } = filter;
    const { name } = selected || {};
    const { backgroundLoaded } = this.state;
    let gradient = {
      direction: 'bottom',
      color: Colors.BLACK,
      width: '100%'
    };
    const pageTypeCat = getSelectedFromSelectionProperty(pageTypeCategory);
    return (
      <ActivityBlockContainer
        className="ActivityBlock"
        column
        first={first}
        gradient={gradient}
        background={backgroundLoaded ? background : uberSmall(background)}>
        <FlexContainer
          minheight="25rem"
          padding="0 0 3rem 0"
          justifycontent="center"
          smallAlignContent="center"
          column>
          <UnderlineContainer
            hide={!pageTypeCat}
            position="bottom"
            center={1}
            centertext
            color={Colors.ORANGE}
            underlinemargin={'0.625rem 0 0 0'}>
            <OPE propertyName="heading">
              <UnderlineContainerText center color={Colors.WHITE}>
                {pageTypeCat}
              </UnderlineContainerText>
            </OPE>
          </UnderlineContainer>
          <BlockArea nopadding propertyName="filterContentArea" contentarea={filterContentArea} />

          <EventsContainer
            perspective
            justifycontent="center"
            align-items="center"
            smallAlignContent="center">
            <Message
              relative
              visible={hasError && !isLoading}
              key="error">{`${message} ${name}`}</Message>
            {this.getEvents()}
          </EventsContainer>
          <OPE propertyName="buttonLink">
            <ArrowButtonRight
              hastext={buttonText}
              smallbackgroundcolor={Colors.GRAY}
              backgroundcolor={Colors.GRAY}
              to={buttonLink}>
              <ArrowButtonText makeroomforarrow apercu fontsize="0.75rem" smallfontsize="16px">
                {buttonText}
              </ArrowButtonText>
            </ArrowButtonRight>
          </OPE>

          <Hidden className="Hidden">
            <LazyLoadImage
              onLoad={this.onBackgroundLoad}
              asBackground
              hideSmall
              placeholder={uberSmall(background)}
              src={reFormat(background)}
            />
          </Hidden>
        </FlexContainer>
      </ActivityBlockContainer>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ fetchItems, setMessage }, dispatch);

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected,
    categoryList: state.filter.categoryList,
    message: state.filter.message
  },
  activityblock: {
    events: state.activityblock.events,
    hasError: state.activityblock.error.hasError,
    message: state.activityblock.error.message,
    isLoading: state.activityblock.isLoading,
    lastHitCategory: state.activityblock.lastHitCategory,
    previousEvents: state.activityblock.previousEvents
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityBlock);

ActivityBlock.propTypes = {
  first: PropTypes.bool,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  background: PropTypes.string
};
