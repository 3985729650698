import styled from 'styled-components/macro';
import { media } from 'style/media';
import { Title2, Paragraph } from 'components/text';
import fonts from 'style/fonts/fonts';

export const JobApplicationBlockHeader = styled(Title2).attrs(props => ({
  color: props.color
}))`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-family: ${fonts.BROWN_PRO};
  margin: 2rem auto 0;
  font-size: 1.5rem;
  letter-spacing: 0px;
  padding: 20px 0;
  text-align: center;
  line-height: 1.4rem;
  max-width: 800px;
  color: ${props => props.color};
  ${media.desktop`
    flex-direction: row;
    padding: 0.5rem 1rem;    
    text-align: center;
  `};
  ${media.smalltablet`
  line-height: 1.6rem;
  `};
`;

export const JobApplicationBlockText = styled(Paragraph).attrs(props => ({
  color: props.color
}))`
  color: ${props => props.color};
  margin: 0 auto;
  max-width: 800px;
  font-weight: normal;
  text-align: center;
  padding: 1rem;
  ${media.desktop`
  text-align: center;
    padding: 1rem;
  `};
  ${media.smalltablet`
  text-align: center;
    padding: 1rem;
  `};
`;
