import styled from 'styled-components/macro';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import fonts from 'style/fonts/fonts';

import React from 'react';
import PropTypes from 'prop-types';
export const RadioContainer = styled.ul.attrs(props => ({
  alignitems: props.center ? 'center' : 'stretch'
}))`
  display: flex;
  width: 100%;
  appereance: none;
  align-items: ${props => props.alignitems};
  padding: 0;
  margin: 0;
  list-style-image: none;
`;

const StyledRadioItem = styled.li`
  list-style: none;
  display: flex;
`;
const StyledRadioButtonLabel = styled.label`
  padding: 0.3rem;
  background: ${Colors.TRANSPARENT};
  color: ${Colors.BLACK};
  border: 1px solid ${Colors.DARK_GAY};
  font-family: ${fonts.APERCU.WEB};
  &:hover {
    background: ${LightenDarkenColor(Colors.DARK_GAY, 15)};
  }
`;

const StyledRadioInput = styled.input`
  display: none;
  &:checked ~ ${StyledRadioButtonLabel} {
    background-color: ${Colors.BLUE};
    color: ${Colors.WHITE};
  }
`;

export const RadioItem = ({ name, value, selectCallback, selected = false }) => {
  const id = `radio_${value}`;
  return (
    <StyledRadioItem>
      <StyledRadioInput defaultChecked={selected} type="radio" name={name} id={id} value={value} />
      <StyledRadioButtonLabel onClick={selectCallback} htmlFor={id}>
        {value}
      </StyledRadioButtonLabel>
    </StyledRadioItem>
  );
};

RadioItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  selectCallback: PropTypes.func
};
