import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
export const ScrollToButton = styled.button`
  border: none;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: ${colors.TRANSPARENT_ORANGE};
  padding: 0;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.98);
  }
`;
