export const ACCOUNT_LOGIN = 'account/ACCOUNT_LOGIN';
export const ACCOUNT_LOGGED_IN_SUCCESS = 'account/ACCOUNT_LOGGED_IN_SUCCESS';
export const ACCOUNT_LOGGED_IN_FAIL = 'account/ACCOUNT_LOGGED_IN_FAIL';

export const ACCOUNT_CREATE = 'account/ACCOUNT_CREATE';
export const ACCOUNT_CREATE_SUCCESS = 'account/ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAIL = 'account/ACCOUNT_CREATE_FAIL';

export const ACCOUNT_LOGOUT = 'account/ACCOUT_LOGOUT';
export const ACCOUNT_LOGGED_OUT_SUCCESS = 'account/ACCOUNT_LOGGED_OUT_SUCCESS';
export const ACCOUNT_LOGGED_OUT_FAIL = 'account/ACCOUNT_LOGGED_OUT_FAIL';

export const ACCOUNT_VALIDATE = 'account/ACCOUNT_VALIDATE';
export const ACCOUNT_VALIDATE_SUCCESS = 'account/ACCOUNT_VALIDATE_SUCCESS';

export const ACCOUNT_SHOW_LOGIN_FORM = 'account/ACCOUNT_SHOW_LOGIN_FORM';
export const ACCOUNT_HIDE_LOGIN_FORM = 'account/ACCOUNT_HIDE_LOGIN_FORM';

export const ACCOUNT_LOGIN_SETTINGS_PAGE_FETCHING = 'account/ACCOUNT_LOGIN_SETTINGS_PAGE_FETCHING';
export const ACCOUNT_LOGIN_SETTINGS_PAGE_FETCHED = 'account/ACCOUNT_LOGIN_SETTINGS_PAGE_FETCHED';
