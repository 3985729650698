import React from 'react';
import { fillElement } from 'utils';
import { SlideInLeft } from 'components/Animations';
import {
  JobListBlockSmallContainer,
  JobListBlockSmallHeader,
  JobListBlockParagraphWrapper,
  JobListBlockSmallButton,
  JobListBlockSmallImageContainer
} from './components';
import { LazyLoadImage } from 'features/LazyImage';
import format from 'date-fns/format';
import iconLocation from 'images/iconLocation.svg';
import calendar from 'images/calendar-orange.svg';
import { InlineWithIcon } from 'components/link';

const getDate = date => {
  if (!date) return null;
  const formattedDate = format(new Date(date) - 1, 'YYYY-MM-DD');
  return formattedDate.includes('9999') ? 'Löpande' : formattedDate;
};

export const JobListBlockItemsSmall = ({ jobList }) => {
  if (!jobList.length) return null;

  return jobList.map((item, index) => {
    const date = getDate(item.endDate);

    return (
      <SlideInLeft key={index}>
        <JobListBlockSmallContainer to={item.linkUrl}>
          <JobListBlockSmallImageContainer>
            <LazyLoadImage onload={true} src={fillElement(item.imageUrl)} />
          </JobListBlockSmallImageContainer>
          <JobListBlockSmallHeader>{item.header}</JobListBlockSmallHeader>
          <JobListBlockParagraphWrapper>
            <InlineWithIcon icon={iconLocation} />
            {item.location}
          </JobListBlockParagraphWrapper>
          <JobListBlockParagraphWrapper>
            <InlineWithIcon icon={calendar} />
            Ansök senast: {date}
          </JobListBlockParagraphWrapper>
          <JobListBlockSmallButton>Ansök nu</JobListBlockSmallButton>
        </JobListBlockSmallContainer>
      </SlideInLeft>
    );
  });
};
