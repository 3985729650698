export const P = 'p';
export const UL = 'ul';
export const OL = 'ol';
export const LI = 'li';
export const SPAN = 'span';
export const IMG = 'img';
export const A = 'a';
export const H1 = 'h1';
export const H2 = 'h2';
export const H3 = 'h3';
export const H4 = 'h4';
export const NO_BREAKING_SPACE = '&nbsp;';
export const BR = 'br';
export const B = 'b';
export const STRONG = 'strong';
export const EM = 'em';
export const IFRAME = 'iframe';
export const DIV = 'div';
export const I = 'i';
