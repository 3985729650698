import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { ArrowRight } from 'components/images/ArrowDown';

export const CarouselArrowContainer = styled.button.attrs(props => ({
  opacity: props.visible ? 1 : 0
}))`
  outline: 0;
  height: 2rem;
  width: 2rem;
  position: absolute;
  transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${props => props.opacity};
  background: none;
  border: 0;
  top: 50%;
  left: ${props => (props.left ? '-2rem' : 'auto')};
  right: ${props => (!props.left ? '-2rem' : 'auto')};
  transform: translateY(-50%);
  height: 100%;
  &:hover {
    cursor: pointer;
    background: white;
  }
`;

CarouselArrowContainer.propTypes = {
  onClick: PropTypes.func,
  left: PropTypes.bool
};

/**
 * RightArrow (button)
 */
export const RightArrow = styled(ArrowRight)`
  transition: all 0.5s ease-in-out;
  left: ${props => (props.show ? '50%' : '150%')};
  position: absolute;
  top: 50%;
  transform: translateY(50%) translateX(50%) rotateZ(180deg);

  opacity: ${props => (props.show ? 1 : 0)};
  &:before {
    transform: ${props => (props.show ? 'rotateZ(-45deg)' : 'rotateZ(0)')};
    right: 0;
    top: ${props => (props.show ? '-9px' : '0')};
  }
  &:after {
    transform: ${props => (props.show ? 'rotateZ(45deg)' : 'rotateZ(0)')};
    right: 0;
  }
`;

RightArrow.propTypes = {
  show: PropTypes.bool
};

/**
 * LeftArrow (button)
 */

export const LeftArrow = styled(RightArrow)`
  left: ${props => (props.show ? '50%' : '-50%')};

  top: 50%;
  transform: translateY(50%) translateX(50%) rotateZ(0);
`;
