import styled from 'styled-components/macro';
import { Paragraph } from 'components/text';
import { colors as Colors } from 'constants/colors';
import { media } from 'style/media';

export const HeroText = styled(Paragraph).attrs(props => ({
  centerTextSmall: true,
  small: true
}))`
  font-size: 0.8333rem;
  line-height: 1.4rem;
  margin: 0 0 1.25rem 0;
  color: ${({ color }) => color || Colors.BLACK};

  ${media.desktop`
    color: ${Colors.BLACK};
    font-size: max(1rem, calc(13px + 0.3vw));
    opacity: 0.6;
  `};
`;
