import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forceCheck } from 'react-lazyload';
import debounce from 'lodash.debounce';
import { FlexContainer } from 'components/flex';
import { isEmpty, getSearchParams } from '../../utils';
import { Block, CenteredBlockInner, BlockHeader } from '../components';
import { PageContent } from 'pages/components';
import { Title3 } from 'components/text';
import BlockArea from 'features/BlockArea';
import { fetchContacts, filterContacts, setFilterValue } from './contactlistblockActions';
import Contact from 'features/Contact';
import { Message } from 'components/message';
import { RadioItem, RadioContainer } from 'components/common/Radio';
import { ContactListBlockGroup } from './components';
import { lazyload } from 'react-lazyload';

import { FilterInput } from 'components/input';
const SORT_TYPES = {
  LETTER: 'BY_LETTER',
  REGION: 'BY_REGION',
  OFFICE: 'LOCAL_OFFICE'
};

@lazyload({
  height: 200,
  once: true,
  offset: 100
})
class ContactListBlock extends React.Component {
  constructor(props) {
    super(props);

    this.filtercontacts = debounce(this.filtercontacts, 1000);
    this.state = { sortBy: SORT_TYPES.LETTER };
  }

  componentDidMount() {
    this.fetchBlockItems();
    const filterParam = getSearchParams(window.location.href, 'filter');
    this.props.setFilterValue(filterParam || '');
  }

  receivedNewCountyFilter = prevProps => {
    const { filter: prevFilter } = prevProps;
    const { filter } = this.props;
    if (isEmpty(filter.selected)) return false;
    if (filter.selected.id !== prevFilter.selected.id) return true;
    return false;
  };

  receivedNewCategoryfilter = prevProps => {
    const { filterarea: prevFilterarea } = prevProps;
    const { filterarea } = this.props;
    if (prevFilterarea.selectedFilters !== filterarea.selectedFilters) return true;
    return false;
  };

  componentDidUpdate(prevProps, prevState) {
    const { contacts } = this.props;
    const { contacts: prevContacts } = prevProps;
    const { filterValue } = contacts;
    const { filterValue: prevFilterValue } = prevContacts;
    const { sortBy } = this.state;
    const { sortBy: prevSortBy } = prevState;
    if (filterValue !== prevFilterValue) {
      forceCheck();
      this.filtercontacts(filterValue);
    }

    if (
      this.receivedNewCountyFilter(prevProps) ||
      this.receivedNewCategoryfilter(prevProps) ||
      sortBy !== prevSortBy
    ) {
      this.fetchBlockItems();
    }
  }

  fetchBlockItems() {
    const { id, parentPageId, filter, filterarea, fetchContacts } = this.props;
    if (id === undefined && parentPageId === undefined) return;
    const { selected } = filter;
    if (isEmpty(selected)) return false;
    const { selectedFilters } = filterarea;
    const { sortBy } = this.state;
    fetchContacts(
      id || parentPageId,
      selected.id,
      selectedFilters,
      sortBy,
      parentPageId && !id ? true : false
    );
  }

  handleSort = (sortBy = SORT_TYPES.LETTERS) => {
    this.setState({ sortBy: sortBy });
  };

  getSortedContactList = () => {
    const { contactlist = [], filterValue, error, isLoading } = this.props.contacts;
    if (error.hasError || isLoading) return null;

    return contactlist.map(({ key, data }) => {
      const filteredData = filterValue ? this.filtercontactsbyvalue(data, filterValue) : data;
      if (filterValue && !filteredData.length) return null;
      return (
        <ContactListBlockGroup key={key}>
          <Title3 uppercase large margin="0 0 .8em 0">
            {key}
          </Title3>
          {filteredData.map((contact, index) => {
            return <Contact highlight={filterValue} key={index} {...contact} />;
          })}
        </ContactListBlockGroup>
      );
    });
  };

  filtercontactsbyvalue = (array, filter) => {
    return array.filter(
      c =>
        c.name.toLowerCase().includes(filter.toLowerCase()) ||
        c.title.toLowerCase().includes(filter.toLowerCase()) ||
        c.localOffice.toLowerCase().includes(filter.toLowerCase())
    );
  };

  filtercontacts() {
    const { filterContacts } = this.props;
    filterContacts();
  }

  handleInputChange = e => {
    const { value: query } = e.target;
    const { setFilterValue } = this.props;
    setFilterValue(query);
  };

  render() {
    const { filterContentArea, contacts, filterarea, filter, heading, radio = true } = this.props;
    const { selected } = filter;
    const { selectedFilters } = filterarea;
    const { error, filterValue, isLoading } = contacts;
    return (
      <Block>
        <PageContent>
          {heading && <BlockHeader>{heading}</BlockHeader>}

          <BlockArea nopadding propertyName="filterContentArea" contentarea={filterContentArea} />
          <RadioContainer center>
            {radio ? (
              <>
                <RadioItem
                  selectCallback={() => this.handleSort(SORT_TYPES.LETTER)}
                  name="contact_sort"
                  value="A-ö"
                  selected={true}
                />
                <RadioItem
                  selectCallback={() => this.handleSort(SORT_TYPES.OFFICE)}
                  name="contact_sort"
                  value="Kontor"
                />
              </>
            ) : (
              <></>
            )}
            <FilterInput
              radio={radio}
              handleClear={() => this.props.setFilterValue()}
              loading={isLoading}
              active={filterValue.length}
              ontop
              value={filterValue}
              type="text"
              onChange={this.handleInputChange}
              placeholder="Filtrera"
            />
          </RadioContainer>
        </PageContent>

        <CenteredBlockInner overflowvisible>
          <FlexContainer alignItems="initial" column>
            <Message relative visible={error.hasError}>
              {radio ? error.message + selected.name : 'Inga kontakter hittades för detta kontor'}
              {selectedFilters.length !== 0 &&
                selectedFilters.reduce((prevVal, currVal, idx) => {
                  return idx === selectedFilters.length - 1 && idx !== 0
                    ? `${prevVal} eller ${currVal.name}`
                    : `${prevVal} ${currVal.name}`;
                }, ' med filtret')}
            </Message>
            {this.getSortedContactList()}
          </FlexContainer>
        </CenteredBlockInner>
      </Block>
    );
  }
}

ContactListBlock.propTypes = {
  parentPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blockType: PropTypes.oneOf(['ContactListBlock']),
  filter: PropTypes.objectOf({
    selected: PropTypes.object,
    categoryList: PropTypes.array
  }),
  contacts: PropTypes.objectOf({
    contactlist: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        localOffice: PropTypes.string,
        phone: PropTypes.string,
        linkUrl: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    error: PropTypes.shape({
      hasError: PropTypes.bool,
      message: PropTypes.string
    }).isRequired
  }),
  fetchContacts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filter: {
    selected: state.filter.selected
  },
  filterarea: {
    selectedFilters: state.filterarea.selectedFilters
  },
  contacts: {
    contactlist: state.contacts.contacts,
    error: state.contacts.error,
    isLoading: state.contacts.isLoading,
    filterValue: state.contacts.filterValue
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchContacts, filterContacts, setFilterValue }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactListBlock);
