import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';

export const JobListBlockInfoContainer = styled.div`
  min-width: 250px;
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  border: 10px solid ${Colors.GRAY};
  padding-top: 0.5rem;
`;
