import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const Width = styled.span.attrs(props => ({
  width: props.width ? props.width : 'auto',
  maxwidth: props.width ? props.width : 'none',
  overflow: props.forceoneline ? 'hidden' : 'visible',
  whitespace: props.forceoneline ? 'nowrap' : 'wrap'
}))`
  display: inline;
  width: ${props => props.width};
  min-width: ${props => props.width};
  max-width: ${props => props.maxwidth};
  overflow-x: ${props => props.overflow};
  white-space: ${props => props.whitespace};
`;

Width.propTypes = {
  forceoneline: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};
