import React from 'react';
import SideCard from './SideCard';

class SideCardBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  calculateTopValue() {
    if (this.state.screenWidth > 1550) {
      const firstContent =
        document.querySelector('[class^="FlexContainer"]') ||
        document.querySelector('[class^="ServiceBlockContainer"]');
      const sideCardBlock = document.querySelector('.side-card-block');

      if (firstContent && sideCardBlock) {
        const firstContentPosition = firstContent.getBoundingClientRect().top + window.scrollY;
        return `${Math.floor(firstContentPosition)}px`;
      }
    }
    return '0px';
  }

  render() {
    const topValue = this.calculateTopValue();

    return (
      <div className="side-card-block" style={{ top: topValue }}>
        <div className={`container--medium  dynamic-side-feature-block__inner-container--1`}>
                <SideCard
            flip={true}
            tinymceContent={this.props.heading}
            imgURL={this.props.image}
            texteditor={this.props.preamble}
            buttonURL={`${
              this.props.pageToGetDataFrom ? this.props.pageToGetDataFrom : this.props.manualLink
            }${this.props.shouldScrollToForm ? '#formLink' : ''}`}
            ButtonText={this.props.buttonText}
          />
        </div>
      </div>
    );
  }
}

export default SideCardBlock;
