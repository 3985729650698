import styled from 'styled-components/macro';
import { colors as Colors } from 'constants/colors';
import chevron from 'images/chevron.svg';
import { zIndex } from 'constants/settings';
import { MediumText } from 'components/text';
import { media } from 'style/media';

export const CountyFilterPuffListContainer = styled.div`
  display: none;
  ${media.desktop`
  display: block
  `};
`;

export const PuffListOuterContainer = styled.div.attrs(props => ({
  border: props.border ? `2px solid ${Colors.ORANGE}` : `1px solid ${Colors.COOKIE_BORDER}`
}))`
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-left: 1rem;
  height: 4rem;
  background-color: ${Colors.GRAY};
  border-bottom: ${props => props.border};
  :hover {
    cursor: pointer;
  }
`;

export const PuffListHeader = styled(MediumText)`
  margin: 0;
  font-size: 1.25rem;
  float: left;
  letter-spacing: 0;
`;
export const PuffListInnerContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const ArrowUp = styled.div`
  position: absolute;
  float: right;
  background: url(${chevron});
  z-index: ${zIndex.ABOVE_MODALS + 2};
  border: none;
  margin-top: 0.3rem !important;
  background-size: 1em;
  height: 1em;
  width: 1em;
  right: 1em;
`;

export const ArrowDown = styled.div`
  position: absolute;
  float: right;
  background: url(${chevron});
  transform: rotate(180deg);
  z-index: ${zIndex.ABOVE_MODALS + 2};
  border: none;
  margin-top: 0.3rem !important;
  background-size: 1em;
  height: 1em;
  width: 1em;
  right: 1em;
`;

export const PuffListInnerHeader = styled(MediumText)`
  margin: 0;
  font-size: 1rem;
  font-weight: 200;
  float: left;
  letter-spacing: 0;
  color: ${props => (props.selected ? Colors.ORANGE : Colors.BLACK)};
  :hover {
    cursor: pointer;
  }
`;
