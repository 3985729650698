import styled from 'styled-components/macro';
import { Title4 } from 'components/text';

export const ContactTitle = styled(Title4).attrs(({ asLink }) => ({ as: asLink ? 'a' : 'h3' }))`
  text-decoration: none;
  text-transform: uppercase;
    margin-top: 0;
    &:hover {
    text-decoration: underline;
  }
`;
