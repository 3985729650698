import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import { propTypes } from 'constants/propTypes';
import { shake } from 'style/animations';
import { ButtonText } from './ButtonText';
import loaderImage from 'images/loader.white.min.svg';
import searchIconWhite from 'images/search-icon-white.svg';
import { zIndex } from 'constants/settings';
import { media } from 'style/media';

/**
 * PrimaryButton (button)
 */
export const PrimaryButton = styled.button.attrs(props => ({
  display: props.display ? props.display : 'inline-block',
  marginright: props.left ? 'auto' : 0,
  marginleft: props.right ? 'auto' : 0,
  margin: props.nomargin
    ? 0
    : props.margin
    ? props.margin
    : `0.5rem ${props.left ? 'auto' : 0} 0.5rem ${props.right ? 'auto' : 0}`,
  padding: props.padding
    ? props.padding
    : props.wide
    ? '0.1rem 1rem 0.1rem 1rem'
    : '0.4rem 0 0.4rem 0',
  type: props.type ? props.type : 'button',
  backgroundcolor: props.backgroundcolor ? props.backgroundcolor : Colors.ORANGE,
  disabledcolor: props.disabledcolor ? props.disabledcolor : Colors.LIGHT_GRAY,
  scale: props.scale ? props.scale : '1',
  width: props.width,
  props: props.border,
  borderradius: props.borderradius
}))`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: ${props => props.backgroundcolor};
  text-decoration: none;
  display: ${props => props.display};
  backface-visibility: hidden;
  transform: scale(${props => props.scale}) translateZ(0);
  margin-right: ${props => props.marginright};
  margin-left: ${props => props.marginleft};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: center;
  overflow: initial;
  border-radius: ${props => props.borderradius};
  width: ${({ width }) => (width ? width : 'initial')};
  border: ${props => (props.border ? props.border : 'none')};
  &:hover {
    background-color: ${LightenDarkenColor(props => props.backgroundcolor, -15)};
  }
  &:active {
    transform: scale(${props => props.scale - 0.01});
  }

  &:disabled {
    background: ${props => props.disabledcolor};

    cursor: not-allowed;
  }
`;

export const SearchButton = styled(PrimaryButton)`
  margin: 0;
  padding: 0;
  padding-left: 5em;
  padding-right: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  overflow: hidden;

  ${media.desktop`
  padding-left: 4em;
  padding-right: 3em;
  `};
  &:after,
  &:before {
    transition: opacity 0.23s ease-in-out, transform 0.25s ease-in-out;
    content: '';
    display: block;
    margin-right: 0.3em;
    position: absolute;
    left: 1.8em;
    width: 1.8em;
    height: 1.8em;
    background-size: contain;
    background-repeat: no-repeat;
    transform-origin: center center;
    background-position: center;
    z-index: ${zIndex.ABOVE_MODALS};

    ${media.desktop`
    left: 1.5em;
    width: 1.5em;
    height: 1.5em;
    `};
  }

  &:after {
    width: 1.5em;
    height: 1.5em;
    background-image: url(${loaderImage});
    opacity: ${({ isSearching }) => (isSearching ? 1 : 0)};
    transform: translateX(${({ isSearching }) => (isSearching ? 0 : '-3rem')});
  }

  &:before {
    opacity: ${({ isSearching }) => (isSearching ? 0 : 1)};
    background-image: url(${searchIconWhite});
    transform: translateX(${({ isSearching }) => (isSearching ? '-3rem' : 0)});
  }

  span {
    padding: 0;
  }
`;

export const SecondaryButton = styled(PrimaryButton).attrs(props => ({
  bordercolor: props.bordercolor ? props.bordercolor : Colors.WHITE,
  displaynone: props.displaynone ? 'none' : 'table',
  bg: props.backgroundcolor ? props.backgroundcolor : 'transparent',
  hoverbackgroundcolor: props.hoverbackgroundcolor
    ? props.hoverbackgroundcolor
    : props.backgroundcolor,
  borderradius: props.borderradius,
  position: props.position,
  left: props.left,
  bottom: props.bottom,
  mediumleft: props.mediumleft,
  hovercolor: props.hovercolor ? props.hovercolor : Colors.BLACK,
  color: props.color ? props.color : Colors.BLACK,
  smallwidth: props.smallwidth ? props.smallwidth : 'initial'
}))`
  border: 2px ${props => props.bordercolor} solid;
  background: ${props => props.bg};
  color: ${props => props.color};
  padding: 0;
  display: ${props => props.displaynone};
  position: ${props => props.position};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  transition: all 0.2s ease-in-out;

  text-align: center;
  ${media.mediumtablet`
    left: ${props => props.mediumleft};
    width: ${props => props.smallwidth};
  `};
  ${props =>
    props.borderradius &&
    `
    border-radius: ${props.borderradius};
  `}
  &:hover {
    background-color: ${props => props.hoverbackgroundcolor};
    color: ${props => props.hovercolor};
    && * {
      color: ${props => props.hovercolor};

      &:before,
      &:after {
        background: ${props => props.hovercolor};
      }
    }
  }
`;

SecondaryButton.propTypes = {
  bordercolor: PropTypes.string
};

PrimaryButton.propTypes = {
  weight: PropTypes.string,
  display: PropTypes.oneOf(propTypes.DISPLAY)
};

const getButtonColor = props => {
  const { success, active, fail, finished } = props;
  if (!active) {
    return Colors.ORANGE;
  } else if (success && finished) {
    return Colors.ORANGE;
  } else if (!success && finished) {
    return Colors.ERROR;
  } else if (fail) {
    return Colors.ERROR;
  } else {
    return Colors.ORANGE;
  }
};
const getBorderColor = props => {
  const { active } = props;
  if (!active) {
    return Colors.BLACK;
  }
  return Colors.ORANGE;
};
const getTextColor = props => {
  const { success, active } = props;
  if (!success || !active) {
    return Colors.WHITE;
  }
  return Colors.WHITE;
};

const shakeAnimation = props =>
  css`
    ${props.active && props.animateonactive
      ? css`
          ${shake} 0.6s cubic-bezier(.36,.07,.19,.97) both;
        `
      : 'none'}
  `;

export const SubmitButton = styled(SecondaryButton).attrs(props => ({
  borderradius: props.borderradius ? props.borderradius : 0,
  width: 'auto',
  height: 'auto',
  color: getButtonColor(props),
  bordercolor: getBorderColor(props),
  type: ({ type }) => (type ? type : 'submit'),
  noflex: props.noflex ? '' : '1 0 auto;'
}))`
  font-weight: normal;
  background: ${props => getButtonColor(props)};
  position: relative;
  transition: all 0.4s ease-in-out;
  height: ${props => props.height};
  width: ${props => props.width};
  border-color: ${props => props.bordercolor};
  border-radius: ${props => props.borderradius};
  animation: ${shakeAnimation};
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  flex: ${props => props.noflex};
  perspective: 1000px;
  &:hover {
    background-color: ${props => LightenDarkenColor(getButtonColor(props), -15)};
  }

  ${ButtonText} {
    color: ${props => getTextColor(props)};
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const PrimarySubmitButton = styled(PrimaryButton).attrs(props => ({
  width: '100%',
  height: 'auto',
  color: getButtonColor(props),
  bordercolor: getBorderColor(props)
}))`
  height: ${props => props.height};
  width: ${props => props.width};

  ${ButtonText} {
    color: ${Colors.WHITE};
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const SmallButton = styled(PrimaryButton)`
  padding: 0 0.5rem 0 0.5rem;
`;
