import React from 'react';
import styled from 'styled-components/macro';
import { HtmlComponent } from 'features/HtmlComponent';
import {
  Form as Informed,
  Text,
  TextArea,
  RadioGroup as InformedRadioGroup,
  Radio as InformedRadio,
  Checkbox as InformedCheckbox,
  Scope
} from 'informed';

import { ErrorMessage } from './ErrorMessage';
import {
  Input,
  Radio as RadioInput,
  Checkbox as CheckboxInput,
  RadioGroup as RadioGroupElement,
  ChoiceLabel,
  InputWrapper
} from 'components/input';
import { AlternativeLabel } from 'components/text';
import { Form } from './Form';
import { InformedValueCheckbox, InformedCheckboxGroup, GdprWrapper } from './Custom';

const StyledTextInput = Input.withComponent(Text);
export const TextInput = ({
  isvalid = true,
  touched,
  message,
  label,
  alwaysmarginbottom,
  nocapitalize,
  ...rest
}) => {
  const isInvalid = !isvalid || !touched;
  return (
    <InputWrapper left isinvalid={isInvalid ? 1 : 0} alwaysmarginbottom>
      {label && <AlternativeLabel nocapitalize>{label}</AlternativeLabel>}
      <StyledTextInput isinvalid={isvalid && !message ? 0 : 1} {...rest} />
      {!isvalid && message && <ErrorMessage fail={!isvalid ? 1 : 0}>{message}</ErrorMessage>}
    </InputWrapper>
  );
};

export const AntiForgeryInput = styled(Text).attrs(props => ({
  type: 'hidden'
}))``;

const StyledTextAreaInput = Input.withComponent(TextArea);
export const TextAreaInput = ({
  isvalid = true,
  touched,
  message,
  label,
  height,
  alwaysmarginbottom,
  nocapitalize,
  ...rest
}) => {
  return (
    <InputWrapper left isvalid={isvalid && touched ? 1 : 0} alwaysmarginbottom>
      {label && <AlternativeLabel nocapitalize>{label}</AlternativeLabel>}
      <StyledTextAreaInput height={height} isvalid={isvalid && !message ? 1 : 0} {...rest} />
      {!isvalid && message && <ErrorMessage fail={!isvalid ? 1 : 0}>{message}</ErrorMessage>}
    </InputWrapper>
  );
};

const StyledRadioInput = RadioInput.withComponent(InformedRadio);
export const Radio = ({ label, id, checked, notround, ...rest }) => {
  return (
    <React.Fragment>
      <StyledRadioInput id={id} {...rest} checked={checked ? 1 : 0} />
      <ChoiceLabel htmlFor={id} round={notround ? 0 : 1}>
        {label}
      </ChoiceLabel>
    </React.Fragment>
  );
};

const StyledCheckboxInput = CheckboxInput.withComponent(InformedCheckbox);
export const Checkbox = ({ label, id, checked, ...rest }) => {
  return (
    <React.Fragment>
      <StyledCheckboxInput type="checkbox" id={id} {...rest} checked={checked} />
      <ChoiceLabel htmlFor={id}>{label}</ChoiceLabel>
    </React.Fragment>
  );
};

const StyledValueCheckboxInput = CheckboxInput.withComponent(InformedValueCheckbox);
export const ValueCheckbox = ({ label, html, id, round, ...rest }) => {
  return (
    <React.Fragment>
      <StyledValueCheckboxInput type="checkbox" id={id} {...rest} />
      <ChoiceLabel html={html} htmlFor={id} round={round}>
        {html ?
          <GdprWrapper>
            <HtmlComponent html={label}>
            </HtmlComponent>
          </GdprWrapper>
          :
          label
        }
      </ChoiceLabel>
    </React.Fragment>
  );
};

export const RadioGroup = ({
  field,
  children,
  nomarginbottom = false,
  isvalid,
  message,
  ...rest
}) => {
  return (
    <InformedRadioGroup field={field} {...rest}>
      <RadioGroupElement nomarginbottom={nomarginbottom}>{children}</RadioGroupElement>
      {!isvalid && message && <ErrorMessage fail={!isvalid ? 1 : 0}>{message}</ErrorMessage>}
    </InformedRadioGroup>
  );
};

export const CheckboxGroup = ({
  field,
  children,
  nomarginbottom = false,
  isvalid = true,
  message,
  ...rest
}) => {
  return (
    <InformedCheckboxGroup field={field} {...rest}>
      <RadioGroupElement nomarginbottom={nomarginbottom}>{children}</RadioGroupElement>
      {!isvalid && message && <ErrorMessage fail={!isvalid ? 1 : 0}>{message}</ErrorMessage>}
    </InformedCheckboxGroup>
  );
};

export { Scope };

export default Form.withComponent(Informed);
