import styled from 'styled-components/macro';
import fonts from 'style/fonts/fonts';
import { colors as Colors, LightenDarkenColor } from 'constants/colors';
import { zIndex } from 'constants/settings';
import PropTypes from 'prop-types';

/**
 * CountyFilterHeader Base(div)
 */
export const CountyFilterHeaderBase = styled.span`
  font-family: ${fonts.APERCU.WEB};
  display: block;
  letter-spacing: -0.1px;
  font-size: ${props => (props.ispuffblockfilter ? '1rem' : '1.583rem')};
  color: ${props => props.color || Colors.WHITE};
  &:after {
    content: ${props => (props.active ? ' ' : '  ')};
    position: relative;
  }
`;

/**
 * FilterBlockHeader (div)
 */
export const CountyFilterHeader = styled(CountyFilterHeaderBase).attrs(props => ({
  padding: props.active ? '0 .5rem 0 .5rem' : '0',
  color: props.color ? props.color : Colors.WHITE,
  cursor: props.cursor ? props.cursor : 'auto'
}))`
  transition: color 0.2s ease-in;
  background-color: transparent;
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  padding: ${props => props.padding};
  z-index: ${zIndex.ABOVE_MODALS};
  color: ${props => props.color};
  animation: ${props => props.selected};
  margin-left: 0.2em;
  font-size: ${props => (props.ispuffblockfilter ? '1rem' : '1.583rem')};
  &:hover {
    color: ${props => LightenDarkenColor(props.color, -15)};
  }
`;

export const CountyFilterHeaderInput = styled(CountyFilterHeaderBase.withComponent('input')).attrs(
  props => ({
    color: props.color ? props.color : Colors.WHITE,
    size: props.width ? props.width : 20
  })
)`
  outline: none;
  width: ${props => props.width};
  border: none;
  ::placeholder {
    color: ${props => props.color};
  }
`;

CountyFilterHeader.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)),
  //hover state
  hover: PropTypes.bool,
  //selected state
  selected: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  cursor: PropTypes.string
};
