import React from 'react';
import { ErrorHandler } from '../../utils';
import PrettyJson from '../PrettyJson/';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
      info: ''
    };
  }

  componentDidCatch(error, info) {
    ErrorHandler.LogError(error, info);
    console.log(error, info);
    this.setState({
      hasError: true,
      errorMessage: error,
      info: info
    });
  }

  render() {
    const { children } = this.props;
    const { hasError, errorMessage, info } = this.state;
    if (hasError && process.env.NODE_ENV === 'development') {
      return (
        <div>
          <h2>Error message</h2>
          {errorMessage.toString()}
          <h2>Info</h2>
          <PrettyJson data={info} />
        </div>
      );
    } else if (hasError) {
      return <div>något gick fel</div>;
    } else {
      return children;
    }
  }
}
