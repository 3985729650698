import styled from 'styled-components/macro';
import fonts from 'style/fonts/fonts';
import { colors as Colors } from 'constants/colors';
import { Title2 } from 'components/text';
import { media } from 'style/media';

export const DateWrapper = styled.span.attrs(props => ({
  font: props.font ? props.font : fonts.LLBrown.REGULAR,
  textalign: props.textalign ? props.textalign : 'left',
  fontsize: props.fontsize ? props.fontsize : '0.9rem',
  texttransform: props.texttransform ? props.texttransform : 'none',
  margin: props.margin ? props.margin : '0 0 0.5em 0'
}))`
  font-family: ${({ font }) => font};
  font-size: ${({ fontsize }) => fontsize};
  letter-spacing: 0.5px;
  display: block;
  margin: ${({ margin }) => margin};
  color: ${Colors.DARKER_GRAY};
  text-align: ${({ textalign }) => textalign};
  text-transform: ${({ texttransform }) => texttransform};
`;

export const DateTitle = styled(Title2)`
  font-size: 24px;
  ${media.desktop`
    font-size: 20px;
  `};
  ${media.mediumtablet`
    font-size: 18px;
  `};
`;